import { fetchPreferences, updatePreferences } from '@/api/user'

/** Sets a new or updated user profile to the store */
const setUser = ({ commit }, userIdentity) => {
  commit('setUser', userIdentity)
}

const getUserPreferences = ({ commit, state }) => {
  fetchPreferences(state.user.sso).then((response) => {
    commit('setPreferences', response.data)
  })
}

const postPreferences = ({ commit }, data) => {
  updatePreferences(data).then((res) => {
    commit('setPreferences', data)
    return res
  })
    .catch(error => {
      return error
    })
}

export default {
  setUser,
  getUserPreferences,
  postPreferences
}
