<template>
  <a
    v-if="slotIsUsed"
    :href="url"
    target="_blank"
    :class="{'download-link': true, 'no-underline': !underline, 'disabled': disabled}">
    <slot :disabled="disabled"></slot>
  </a>
  <v-btn
    v-else
    :href="url"
    :disabled="disabled"
    :class="{'download-link': true, 'no-underline': !underline}"
    target="_blank"
    color="default"
    fab
    small>
    <v-icon>file_download</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'DownloadLink',
  props: {
    href: {
      type: String,
      required: true
    },
    underline: {
      default: false,
      type: Boolean
    },
    disabled: {
      required: false,
      default: false,
      type: Boolean
    }
  },
  computed: {
    url () {
      if (
        this.$environment !== 'Test'
      ) {
        // Require https if a full url is passed
        if (this.href.includes('http') && !this.href.includes('https://')) {
          throw new Error('Download link must use https.')
        }
      }
      return `${this.href}`
    },
    slotIsUsed () {
      return !!this.$slots.default || !!this.$scopedSlots.default
    }
  }
}
</script>

<style lang="scss" scoped>
.download-link {
  color: inherit;

  ::v-deep i.v-icon {
    width: auto;
    height: auto;
  }
}

a.disabled {
  pointer-events: none;
}

</style>
