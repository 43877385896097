<template>
  <inactive-customers-report-base
    :loading="loading"
    :export-loading="exportLoading"
    :inactive-customers="inactiveCustomers"
    @export-report="exportReport"></inactive-customers-report-base>
</template>

<script>
import { lifecycles } from '@/api/customer-lifecycle'
import { downloadFileResult } from '@/globals/utils'
import { InactiveCustomersReportBase } from '@/components/Reports'

export default {
  name: 'LifecycleInactiveReport',
  components: {
    InactiveCustomersReportBase
  },
  data () {
    return {
      inactiveCustomers: [],
      loading: true,
      exportLoading: false
    }
  },
  async mounted () {
    await this.fetchData()
    this.$store.commit('setPageHeader', {
      page: 'Reports',
      text: 'Customer Lifecycle - Inactive'
    })
  },
  methods: {
    async fetchData () {
      this.loading = true

      try {
        const results = await lifecycles.inactive.getReport()
        this.inactiveCustomers = results
      } catch (error) {
        this.$store.commit('setToastMessage', {
          message: 'Error fetching Lifecycle Inactive Report',
          status: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async exportReport () {
      this.exportLoading = true

      const result = await lifecycles.inactive.getExport()
      if (result) {
        downloadFileResult(result)
      }

      this.exportLoading = false
    }
  }
}
</script>
