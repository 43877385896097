import axios from 'axios'
import Vue from 'vue'
import store from '@/store'

const prohibitOnDemo = () => {
  if (Vue.$environment === 'Demo') {
    window.alert('Sign in is not available for Demo')
    throw Error('Sign in is not available for Demo')
  }
}

export const bannerSiteLogin = (account, isAdminLoggingIn = false) => {
  prohibitOnDemo()
  switch (store.getters['user/banner']) {
    case 'platt':
      plattDotComForceSignIn(account.accountId, isAdminLoggingIn)
      break
    case 'rexel':
      window.open(`https://www.rexelusa.com/usr/assisted-service/emulate?customerId=${account.userName}`, '_blank')
      break
    case 'gexpro':
      window.open(`https://www.gexpro.com/usg/assisted-service/emulate?customerId=${account.userName}`, '_blank')
      break
    default:
      console.error('User does not have a valid banner assigend to them')
  }
}

/* Platt customer login */

const getOverride = (accountId) => {
  return axios.get(`/api/customer/platt-sign-in?id=${accountId}`)
}

export const getGlobalCustomerId = (companyCustomerNumber) => {
  return axios.get(`/api/customer/get-global-customer-id?id=${companyCustomerNumber}`)
}

export const forceBranchSignIn = (branchId) => {
  axios.get(`/api/branch-account-number?branchId=${branchId}`)
    .then((res) => {
      plattDotComForceSignIn(res.data)
    })
    .catch((error) => {
      window.alert('Error getting branch accountid')
      console.error('Error getting branch accountid', error)
      throw Error('Error getting branch accountid')
    })
}

export const plattDotComForceSignIn = (accountId, isAdminLoggingIn = false) => {
  prohibitOnDemo()

  getOverride(accountId)
    .then((res) => {
      if (res.data != null) {
        const signInUrl = (Vue.prototype.$environment === 'Test' ? 'https://mtest.platt.com?pfapp=' : 'https://m.platt.com?pfapp=') +
          res.data.guid

        store.commit('user/setPlattDotComSignInUrl',
          {
            plattDotComSignInUrl: signInUrl,
            isAdminLoggingIn
          })
      } else {
        store.commit('setToastMessage', {
          message: `Error logging in as ${accountId}`,
          status: 'error'
        })
      }
    })
    .catch(() => {
      store.commit('setToastMessage', {
        message: `Error logging in as ${accountId}`,
        status: 'error'
      })
    })
}
