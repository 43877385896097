<template>
  <responsive-table
    must-sort
    :headers="headers"
    :items="filteredCustomersAtRisk"
    :pagination.sync="pagination"
    mobile-break-point="smAndDown"
    :mobile-expansion-panels="true"
    :rows-per-page-items="[5, 10, 25]"
    no-data-text="No Data"
    :search="search"
    :loading="loading">
    <template #customerName="{item}">
      <div class="v2-d-flex">
        <Anchor :to="{ name: 'customer-profile', params: { customerId: item.customerId}}">
          {{ item.customerNameAndNumber }}
        </Anchor>
        <v-spacer></v-spacer>
        <div
          class="v2-d-flex"
          style="min-width:24px;">
          <v-tooltip
            top>
            <template #activator="{ on }">
              <v-icon
                v-if="item.hasRecentContact"
                class="cursor-default v2-d-flex"
                v-on="on">
                check_circle
              </v-icon>
            </template>
            <span>Cleared</span>
          </v-tooltip>
        </div>
      </div>
    </template>
    <template #action="{item}">
      <v-menu
        v-if="item.atHighRisk"
        min-width="190px"
        bottom
        left
        :close-on-content-click="true"
        p>
        <template #activator="{ on }">
          <v-icon
            data-qa="more_vert"
            color="black"
            v-on="on">
            more_vert
          </v-icon>
        </template>
        <v-list>
          <v-list-item
            data-qa="highRiskCreateActivity"
            @click="createActivity(item)">
            <v-list-item-title>Create Activity</v-list-item-title>
          </v-list-item>
          <v-list-item
            :disabled="item.hasRecentContact"
            data-qa="highRiskTakeAction"
            @click="openActionDialog(item)">
            <v-list-item-title class="v2-d-flex justify-space-between align-center">
              <span>{{ item.hasRecentContact ? 'Cleared' : 'Clear' }}</span>
              <v-spacer></v-spacer>
              <span v-if="item.hasRecentContact">
                ({{ item.maxChurnContactLast28Days | formatDate }})
              </span>
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            data-qa="SendAtRiskEmail"
            @click="openAtRiskEmailForm(item)">
            <v-list-item-title>Send At-Risk Email</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu
        v-else-if="!item.isEnterprise"
        bottom
        left
        :close-on-content-click="true">
        <template #activator="{ on }">
          <v-icon
            data-qa="more_vert"
            color="black"
            v-on="on">
            more_vert
          </v-icon>
        </template>
        <v-list>
          <v-list-item
            data-qa="createActivity"
            @click="createNonHighRiskActivity(item)">
            <v-list-item-title>Create Activity</v-list-item-title>
          </v-list-item>
          <v-list-item
            data-qa="SendAtRiskEmail"
            @click="openAtRiskEmailForm(item)">
            <v-list-item-title>Send At-Risk Email</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </responsive-table>
</template>

<script>
import ResponsiveTable from '@/components/ResponsiveTable/ResponsiveTable.vue'
import Anchor from '@/components/Anchor.vue'

export default {
  name: 'CustomersAtRiskTable',
  components: {
    ResponsiveTable,
    Anchor
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    search: {
      type: String,
      required: false,
      default: null
    },
    itemsPerPage: {
      type: Number,
      required: false,
      default: 25
    },
    filteredCustomersAtRisk: {
      type: Array,
      required: false,
      default: null
    }
  },
  data () {
    return {
      pagination: {
        sortBy: 'totalSales',
        sortDesc: true,
        itemsPerPage: 25
      },
      headers: [
        {
          text: 'Customer Name',
          align: 'left',
          sortable: true,
          value: 'customerNameAndNumber',
          slot: 'customerName',
          title: true
        },
        {
          text: 'Total Sales',
          align: 'left',
          sortable: true,
          value: 'totalSales',
          type: 'money'
        },
        {
          text: 'At Risk Type',
          align: 'left',
          sortable: false,
          value: 'atRiskTypeForDisplay'
        },
        {
          text: 'Last Order',
          align: 'left',
          sortable: true,
          value: 'lastOrder',
          type: 'date'
        },
        {
          text: 'Last Completed Activity',
          align: 'left',
          sortable: true,
          value: 'lastActivity',
          type: 'date'
        },
        {
          text: 'Last Created Activity',
          align: 'left',
          sortable: true,
          value: 'lastActivityCreated',
          type: 'date'
        },
        {
          text: 'Sales Person',
          align: 'left',
          sortable: true,
          value: 'assignedOutsideSalesRepName',
          type: 'firstAndLastName'
        },
        {
          sortable: false,
          slot: 'action',
          type: 'action'
        }
      ]
    }
  },
  methods: {

    createActivity (item) {
      this.$emit('createActivity', item)
    },
    createNonHighRiskActivity (item) {
      this.$emit('createNonHighRiskActivity', item)
    },
    openActionDialog (item) {
      this.$emit('openActionDialog', item)
    },
    openAtRiskEmailForm (item) {
      this.$emit('openAtRiskEmailForm', item)
    }
  }
}

</script>
