import store from '../store'

const Activities = () => import('@/pages/Activities/Activities.vue')
const ActivitiesTab = () => import('@/pages/Activities/ActivitiesTab/ActivitiesTab.vue')
const V2EditActivity = () => import('@/pages/Activities/form/V2EditActivity.vue')
const CreateActivity = () => import('@/pages/Activities/form/CreateActivity.vue')
const EditActivity = () => import('@/pages/Activities/form/EditActivity.vue')
const OutlookSync = () => import('@/pages/OutlookSync.vue')

/*
  Routes for Activities pages
*/

export const activitiesRoutes = [
  {
    path: 'activities',
    name: 'activities',
    component: Activities,
    children: [
      // Activities component handles default routing in order to redirect mobile to list

      {
        path: 'month/:yearMonth?', // yearMonth: YYYY-MM
        component: ActivitiesTab,
        name: 'activities--month-tab',
        props: { view: 'month' },
        meta: { title: 'Activities By Month' }
      },
      {
        path: 'week/:timestamp?', // timestamp: YYYY-MM-DD
        component: ActivitiesTab,
        name: 'activities--week-tab',
        props: { view: 'week' },
        meta: { title: 'Activities By Week' }

      },
      {
        path: 'day/:timestamp?/:activityId?', // timestamp: YYYY-MM-DD
        component: ActivitiesTab,
        name: 'activities--day-tab',
        props: { view: 'day' },
        meta: { title: 'Activities By Day' }

      },
      {
        path: 'list',
        component: ActivitiesTab,
        name: 'activities--list-tab',
        props: { view: 'list' },
        meta: { title: 'Activities List' }

      }
    ]
  },
  {
    path: 'activities/create',
    name: 'create-activity',
    component: CreateActivity,
    meta: { title: 'Create Activity' },
    beforeEnter (to, from, next) {
      if (store.getters['user/banner'] !== 'platt') {
        next({ name: 'v2-create-activity', query: to.query, params: to.params })
      }
      next()
    }
  },
  {
    path: 'activities/:type/:rrn/:webId',
    name: 'edit-activity',
    component: EditActivity,
    meta: { title: 'Edit Activity' },
    beforeEnter (to, from, next) {
      if (store.getters['user/banner'] !== 'platt') {
        next({ name: 'activities' })
      }
      next()
    }
  },
  {
    path: 'activities/v2-create',
    name: 'v2-create-activity',
    component: V2EditActivity,
    meta: { title: 'Create Activity' },
    beforeEnter (to, from, next) {
      if (store.getters['user/banner'] === 'platt') {
        next({ name: 'create-activity', query: to.query })
      }
      next()
    }
  },
  {
    path: 'activities/v2-edit/:id?/:recurringId?',
    name: 'v2-edit-activity',
    component: V2EditActivity,
    meta: { title: 'Edit Activity' },
    beforeEnter (to, from, next) {
      if (store.getters['user/banner'] === 'platt') {
        next({ name: 'activities' })
      }
      next()
    }
  },
  {
    path: 'activities/outlook-sync',
    name: 'outlook-sync',
    component: OutlookSync,
    meta: { title: 'Activities' }
  }
]
