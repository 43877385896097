<template>
  <v-navigation-drawer
    :value="openNav"
    :class="primaryClass"
    class="sidebar-navigation-global"
    style="z-index: 8;"
    dark
    persistent
    clipped
    app
    :mobile-breakpoint="1281"
    width="240"
    @input="emitOpenNav">
    <div class="sidebar-navigation-global__content">
      <user-avatar-global
        :user-data="userData"
        class="sidebar-navigation-global__avatar">
      </user-avatar-global>
      <slot name="bannerSwitcher"></slot>
      <div class="sidebar-navigation-global__sidebar-section">
        <v-list
          color="primary"
          class="pa-0 sidebar-navigation-global__sidebar-section__list">
          <template v-for="(link, i) in links">
            <v-list-item
              :key="i"
              :data-qa="link.qa"
              :to="link.value"
              :href="link.staticUrl"
              :target="link.staticUrl ? '_blank' : null"
              ripple>
              <v-list-item-action>
                <v-icon
                  v-if="link.icon"
                  dark>
                  {{ link.icon }}
                </v-icon>
                <img
                  v-else-if="link.customIcon"
                  :src="link.customIcon"
                  class="custom-icon" />
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="link-title">
                  {{ link.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </div>
      <div
        class="sidebar-navigation-global__sidebar-section__footer grow">
        <div class="sidebar-navigation-global__sidebar-section__footer__content text-caption">
          <v-icon
            class="cursor-default"
            size="12"
            :color="debug === true ? 'red' : 'white'"
            @click="toggleDebug">
            copyright
          </v-icon>
          Copyright {{ new Date().getFullYear() }} Rexel USA Inc, <br />
          All Rights Reserved
        </div>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
/* ***
 * This component is found in:
 * - Sales: @/layout/SidebarNavigationGlobal/SidebarNavigationGlobal.vue
 * - BranchAdmin: @/layout/SidebarNavigationGlobal/SidebarNavigationGlobal.vue
 * The plan is for these components to be added as an NPM dependency at a later date.
 * For now, just make sure any changes made in this component are made in both projects.
 * ***/
import UserAvatarGlobal from './UserAvatar.vue'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'SidebarNavigationGlobal',
  components: {
    UserAvatarGlobal
  },
  props: {
    openNav: {
      type: Boolean,
      required: true
    },
    primaryClass: {
      type: String,
      required: false,
      default: 'primaryAlt'
    },
    userData: {
      type: Object,
      required: false,
      default: () => {}
    },
    links: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data () {
    return {
      showOriginalAvatar: false
    }
  },
  computed: {
    ...mapState(['debug'])
  },
  methods: {
    ...mapActions(['toggleDebug']),
    emitOpenNav (value) {
      this.$emit('openNav', value)
    }
  }
}
</script>

<style lang="scss">

$white: #ffffff;
$highlight: #4C687A;

.sidebar-navigation-global {
  color: rgba($white,.9);
  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &__avatar {
    margin: 12px 0;
  }
  &__sidebar-section {
    &__list {
      .v-list__tile--active .v-list__tile__title {
        color: $white !important;
      }
      .v-list__tile {
        background-color: rgba($white,.15);
        color: $white;
        .v-list__tile__action {
          min-width: 45px;
        }
        .link-title {
          font-size: 12px;
          font-weight: 500;
          text-transform: uppercase;
        }
        &.v-list__tile--active {
          border-right: solid 4px lighten($highlight, 30);
          color: $white !important;
        }
      }
    }
    &__footer {
      opacity: .5;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      &__content {
        padding: 12px 16px;
      }
    }
  }
}
</style>
