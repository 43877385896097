<template>
  <v-tooltip
    :disabled="!isTooltipEnabled"
    :max-width="tooltipWidth"
    bottom>
    <template #activator="{ on }">
      <v-chip
        :close="selected"
        :value="selected"
        :color="color"
        :text-color="textColor"
        :class="$style.chip"
        @click:close="toggleSelected"
        v-on="on"
        @click="toggleSelected"
        @input="toggleSelected">
        <v-icon
          v-if="item.icon"
          class="mr-1"
          small>
          {{ item.icon }}
        </v-icon>
        <img
          v-if="item.image"
          :src="item.image"
          :class="$style.chipImage"
          class="mr-1" />
        <slot></slot>
      </v-chip>
    </template>

    <span>{{ item.tooltipContent }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'Chip',
  props: {
    item: {
      default () {
        return {}
      },
      type: Object,
      required: false
    },
    id: {
      type: [String, Number],
      required: true
    },
    selected: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    isTooltipEnabled () {
      const isMobile = this.$vuetify.breakpoint.xs
      if (isMobile) {
        return false
      }
      return this.item.tooltipContent != null
    },
    tooltipWidth () {
      const isTooltipContentAvailable = this.item.tooltipContent != null
      if (isTooltipContentAvailable) {
        if (this.item.tooltipContent.length > 60) {
          return 600
        }
      }
      return 'auto'
    },
    color () {
      return this.selected ? 'primary lighten-1' : 'grey'
    },
    textColor () {
      return this.selected ? 'white' : 'black'
    }
  },
  methods: {
    toggleSelected () {
      const selected = !this.selected
      this.$emit('update:selected', selected)
      this.$emit('toggleSelected', this.id)
    }
  }
}
</script>

<style lang="scss" module>
// vuetify overrides
.chip {
  >span {
    cursor: pointer !important;
  }
  &:focus:not(.v-chip--selected) {
    border-color: transparent;
    box-shadow: none;

    &:after {
      content: none;
    }
  }
  .chipImage {
    padding-right: 3px;
  }
}
</style>
