import { callApi } from '@/globals/utils'
import axios from 'axios'

export const fetchUser = (sso, banner = '') => {
  let requestUrl = `/api/user/details?sso=${sso}`
  if (banner) requestUrl += `&banner=${banner}`
  return axios.get(requestUrl)
}

export const fetchPreferences = (sso) =>
  axios.get(`/api/preferences?sso=${sso}`)

export const updatePreferences = (prefs) =>
  callApi({
    url: '/api/preferences',
    method: 'POST',
    payload: prefs,
    noInterceptors: false,
    messages: {
      success: 'Successfully updated Preferences',
      error: 'Failed to update Preferences'
    },
    noMessages: false,
    forceToast: true
  })

export const sendFeedback = (form) => {
  return axios.post('/api/feedback', form)
}

/**
 * Delete a file that was to be attached to a newly submitted piece of feedback.
 *
 * @param {string} token File attachment token of file to delete
 * @param {Object} [options] Request options
 * @param {boolean} [options.silent=false] If true will not show success nor error toasts
 */
export const deleteFeedbackAttachment = (token, options = { silent: false }) =>
  callApi({
    url: '/api/feedback/attachment/delete',
    method: 'POST',
    params: { token },
    noInterceptors: true,
    messages: {
      success: 'Successfully removed file',
      error: 'Failed to remove file'
    },
    noMessages: options.silent
  })
