import axios from 'axios'

export const fetchBranch = (branchId) => axios.get(`/api/branch/${branchId}`)

export const fetchBranchDetail = (branchId) => axios.get(`/api/branch-detail/${branchId}`)

export const fetchBranches = () => axios.get('/api/branches')

export const fetchBranchesAndStates = () => axios.get('/api/branches/states')

export const fetchBranchesByState = (stateId) =>
  axios.get(`/api/branches/states/${stateId}`)

export const fetchBranchEmployees = (branchId) => axios.get(`/api/branch/${branchId}`)

export const getSearchBranchCustomersUrl = () => '/api/search/branch-customers?term='

export const getBranchSalesData = ({ branchId, branchIds }) => {
  return axios.get('/api/report/branch-sales-report-data/', {
    params: { branchIds: branchId || branchIds }
  })
}

export const getRegion = (regionCode) => axios.get(`/api/regions/${regionCode}`)

// groups

export const fetchGroups = () => axios.get('/api/people/groups')

export const fetchGroup = (groupId) => axios.get(`/api/people/groups/${groupId}`)

export const postGroup = (body) =>
  axios.post('/api/people/groups/update', body)

export const deleteGroup = (groupId) =>
  axios.post(`/api/people/groups/delete/${groupId}`)
