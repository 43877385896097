import axios from 'axios'
import { BannerDetails } from './models'

const getBanners = async () => {
  return await axios.get<BannerDetails[]>('/api/banners')
}

const getBannerDetails = async () => {
  return await axios.get<BannerDetails>('/api/banners/detail')
}

export {
  getBannerDetails,
  getBanners
}
