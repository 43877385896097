// https://github.com/vuetifyjs/vuetify/blob/be8e7a77eafad8925432a4a3abf22f3b8e6f04f8/packages/vuetify/src/util/helpers.ts#L72
/**
 *
 * @param {any} obj
 * @param {(string | number)[]} path
 * @param {any | undefined} fallback
 * @returns
 */
const getNestedValue = (obj, path, fallback) => {
  const last = path.length - 1

  if (last < 0) return obj === undefined ? fallback : obj

  for (let i = 0; i < last; i++) {
    if (obj == null) {
      return fallback
    }
    obj = obj[path[i]]
  }

  if (obj == null) return fallback

  return obj[path[last]] === undefined ? fallback : obj[path[last]]
}

// https://github.com/vuetifyjs/vuetify/blob/be8e7a77eafad8925432a4a3abf22f3b8e6f04f8/packages/vuetify/src/util/helpers.ts#L116
/**
 *
 * @param {any} obj
 * @param {string} path
 * @param {any | undefined} fallback
 * @returns
 */
const getObjectValueByPath = (obj, path, fallback) => {
  // credit: http://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-with-string-key#comment55278413_6491621
  if (obj == null || !path || typeof path !== 'string') return fallback
  if (obj[path] !== undefined) return obj[path]
  path = path.replace(/\[(\w+)\]/g, '.$1') // convert indexes to properties
  path = path.replace(/^\./, '') // strip a leading dot
  return getNestedValue(obj, path.split('.'), fallback)
}

// Customized sort items to handle cases of null, undefined or blank strings
// Pulled from Vuetify as this is the sort behavior that's already used with their data tables (in v2.x)
// https://github.com/vuetifyjs/vuetify/blob/be8e7a77eafad8925432a4a3abf22f3b8e6f04f8/packages/vuetify/src/util/helpers.ts#L307
/**
 *
 * @param {T[]} items
 * @param {string[]} sortBy
 * @param {boolean[]} sortDesc
 * @param {string} locale
 * @param {Record<string, DataTableCompareFunction<T>> | undefined} customSorters
 * @param {boolean} nullsToBottom Should nulls always be pushed to the bottom regardless of sort order
 * @returns Sorted items
 */
const sortItems = (
  items,
  sortBy,
  sortDesc,
  locale,
  customSorters,
  nullsToBottom = false
) => {
  if (sortBy === null || !sortBy.length) return items
  const stringCollator = new Intl.Collator(locale, { sensitivity: 'accent', usage: 'sort' })

  return items.sort((a, b) => {
    for (let i = 0; i < sortBy.length; i++) {
      const sortKey = sortBy[i]

      let sortA = getObjectValueByPath(a, sortKey)
      let sortB = getObjectValueByPath(b, sortKey)

      if (sortDesc[i]) {
        [sortA, sortB] = [sortB, sortA]
      }

      if (customSorters && customSorters[sortKey]) {
        const customResult = customSorters[sortKey](sortA, sortB)

        if (!customResult) continue

        return customResult
      }

      // Check if both cannot be evaluated
      if (sortA === null && sortB === null) {
        return -1
      }

      const aIsNull = sortA === null || sortA === undefined
      const bIsNull = sortB === null || sortB === undefined;

      [sortA, sortB] = [sortA, sortB].map(s => (s || '').toString().toLocaleLowerCase())

      if (sortA !== sortB) {
        if (!isNaN(sortA) && !isNaN(sortB)) return Number(sortA) - Number(sortB)

        if (aIsNull || bIsNull) {
          // To ensure we push null or undefined values to the bottom we inverse the sort return if we're sorting descending
          const modifier = sortDesc[i] && nullsToBottom ? -1 : 1
          if (aIsNull && !bIsNull) return 1 * modifier
          if (aIsNull && bIsNull) return 0 * modifier
          if (!aIsNull && bIsNull) return -1 * modifier
        }

        return stringCollator.compare(sortA, sortB)
      }
    }

    return 0
  })
}

export default sortItems
