const getEmployeeSalesStatsById = (state) => (id) => {
  return state.employeeSalesStats[id]
}

const getEmployeeDetailsById = (state) => (id) => {
  return state.employeeDetails[id]
}

export default {
  getEmployeeSalesStatsById,
  getEmployeeDetailsById
}
