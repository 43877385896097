import {
  fetchCustomer,
  fetchCustomerAccounts,
  fetchCustomerContacts,
  fetchCustomerSalesStats,
  fetchCustomerMisc,
  fetchCustomerSubAccounts,
  getMissionAndStrategies,
  updateMissionAndStrategies,
  deleteMissionAndStrategies,
  createNote,
  deleteNote,
  addOrderAcknowledgementEmails,
  deleteOrderAcknowledgementEmails,
  updateCustomerContact,
  deleteCustomerContact,
  updateWebsite,
  updateCustomerWebAccount,
  deleteCustomerWebAccount,
  bookmarkCustomer,
  unbookmarkCustomer,
  updateEnrichedData,
  updateKeyAndTarget,
  updateGrayMarketReseller,
  getOrderWriters
} from '@/api/customers'

function structureCustomerPayload (payload) {
  return {
    customerId: typeof payload.customerId !== 'undefined' ? payload.customerId : payload,
    customerType: typeof payload.customerType !== 'undefined' ? payload.customerType : 'customer',
    showBillTo: typeof payload.showBillTo !== 'undefined' ? payload.showBillTo : false,
    includeJobAccounts: typeof payload.showJobAccounts !== 'undefined' ? payload.showJobAccounts : false
  }
}

export const getCustomer = ({ commit }, customerId) => {
  commit('setLoaded', false)
  return fetchCustomer(customerId).then((res) => {
    const data = res?.data
    if (data) {
      commit('setCustomer', data)
      commit('setLoaded', true)
    }
  })
}

export const getCustomerByType = ({ commit }, payload) => {
  const { customerId, customerType, showBillTo, includeJobAccounts } = payload
  return fetchCustomer(customerId, showBillTo, includeJobAccounts).then((response) => {
    const customerObj = { ...response.data }
    commit('setCustomerByType', { customerObj, customerType })
  })
}

export const getActiveCustomerType = ({ commit }, value) => {
  commit('setActiveCustomerType', value)
}

export const getSelectedCustomer = ({ commit, state }, customerType) => {
  let selectedCustomerObject
  switch (customerType) {
    case 'billTo':
      selectedCustomerObject = state.billToCustomer
      break
    case 'shipTo':
      selectedCustomerObject = state.shipToCustomer
      break
    case 'shipToWithJobs':
      selectedCustomerObject = state.jobsCustomer
      break
    default:
      selectedCustomerObject = state.customer
  }
  commit('setSelectedCustomer', selectedCustomerObject)
}

export const getResetCustomerObjects = ({ commit }) => {
  commit('setResetCustomerObjects')
}

export const getCustomerProfileObject = ({ commit }, payload) => {
  const { customerId, showBillTo } = payload
  return fetchCustomer(customerId, showBillTo).then(({ customerObject }) => {
    commit('setCustomerProfileType', { customerObject, showBillTo })
  })
}

export const updateCustomerWebsite = async ({ commit }, { customerId, url }) => {
  const result = await updateWebsite(customerId, url)
  if (!result.errors) {
    commit('setWebsite', url)
  }
  return result
}

// this gets web accounts, contacts, and pims
export const getAccounts = ({ state, commit }, { customerId, companyId, parentCustomerId }) => {
  return fetchCustomerAccounts(customerId, companyId, parentCustomerId).then(({ data }) => {
    if (customerId === state.customer.customerId) {
      commit('setAccounts', data)
    }
  })
}
export const getContacts = ({ state, commit, rootGetters }, payload) => {
  const { customerId, customerType } = typeof payload.customerType !== 'undefined'
    ? payload
    : { customerId: payload, customerType: 'customer' }

  return fetchCustomerContacts(customerId, rootGetters.banner).then(({ data }) => {
    const dataObj = (customerId === state.customer.customerId && !customerType) ? data : { contacts: data, customerType }
    commit('setContacts', dataObj)
  })
}

export const getSalesStats = ({ state, commit }, payload) => {
  const { customerId, customerType, showBillTo, includeJobAccounts } = payload

  return fetchCustomerSalesStats({ customerId, showBillTo, includeJobAccounts }).then(({ data }) => {
    const dataObj = { statsObj: data, customerType }
    commit('setSalesStats', dataObj)
  })
}

export const getOrderWritersForCustomer = ({ commit }, payload) => {
  const { customerId, customerType } = structureCustomerPayload(payload)
  getOrderWriters(customerId).then((res) => {
    const orderWriters = res.data
    commit('setCustomerOrderWriters', { customerType, orderWriters })
  })
}

export const getProfileMisc = ({ state, commit }, payload) => {
  const customerId = payload.customerId
  const showBillTo = payload.showBillTo

  return fetchCustomerMisc({ customerId, showBillTo }).then(({ data }) => {
    if (customerId === state.customer.customerId) {
      commit('setProfileMisc', data)
    }
  })
}

// get enterprise customer's subaccounts
export const getSubAccounts = ({ commit }, customerId) => {
  return fetchCustomerSubAccounts(customerId).then(({ data }) => {
    commit('setSubAccounts', data)
  })
}

export const addAccountNote = async ({ commit }, { customerId, note }) => {
  const { data } = await createNote(customerId, note)
  if (data) commit('addAccountNote', data)
}
export const deleteAccountNote = async ({ commit }, { customerId, noteId }) => {
  await deleteNote(noteId)
  commit('deleteAccountNoteById', noteId)
}

export const addAckEmail = async ({ commit }, { customerId, email }) => {
  const { data } = await addOrderAcknowledgementEmails(customerId, email)
  if (data) commit('addAckEmail', email)
}
export const deleteAckEmail = async ({ commit }, { customerId, email }) => {
  await deleteOrderAcknowledgementEmails(customerId, email)
  commit('deleteAckEmail', email)
}

export const addContact = async ({ commit }, props) => {
  const { data } = await updateCustomerContact(props)
  if (data) {
    // newly create Platt contactscome back with a legacyContactId
    commit('addContact', {
      ...props?.body,
      isNew: data.isNew,
      contactId: data.contactId,
      legacyContactId: data.legacyContactId,
      contactEmailIdPlattOnly: data.contactEmailIdPlattOnly
    })
  }
  return data
}
export const updateContact = async ({ commit }, props) => {
  const { data } = await updateCustomerContact(props)
  if (data) {
    commit('updateContact', {
      ...props?.body
    })
  }
  return data
}
export const deleteContact = async ({ commit }, { body }) => {
  await deleteCustomerContact(body)
  commit('deleteContact', body)
}
export const updateWebAccount = async ({ commit }, { formData, accountId }) => {
  const { data } = await updateCustomerWebAccount(accountId, formData)
  if (data) commit('updateWebAccount', data)
  return data
}
export const deleteWebAccount = async ({ commit }, accountId) => {
  await deleteCustomerWebAccount(accountId)
  commit('deleteWebAccountById', accountId)
}

// set enriched data
export const setEnriched = async ({ commit }, { customerId, enrichedCustomerData }) => {
  await updateEnrichedData(customerId, enrichedCustomerData)
  commit('setEnriched', enrichedCustomerData)
}

// set customer's followed state
export const setFollowed = async ({ commit }, { customerId, isFollowed }) => {
  isFollowed
    ? await bookmarkCustomer(customerId)
    : await unbookmarkCustomer(customerId)
  commit('setFollowed', isFollowed)
}

// update current customer's key or target status
export const setKeyAndTarget = async (
  { commit },
  { customerId, isExclusivelyUpdatingKey, isKeyCustomer, isTargetCustomer, isDevelopmentCustomer, grayMarketReseller }
) => {
  const data = {
    isKeyCustomer,
    isTargetCustomer,
    isDevelopmentCustomer,
    grayMarketReseller
  }
  const { errors } = await updateKeyAndTarget(customerId, isExclusivelyUpdatingKey, data)
  if (errors) return
  commit('setKeyAndTarget', data)
}

export const setGreyMarketReseller = async (
  { commit },
  { customerId, grayMarketReseller }
) => {
  const data = {
    grayMarketReseller
  }
  const { errors } = await updateGrayMarketReseller(customerId, data)
  if (errors) return
  commit('setGreyMarketReseller', data)
}

export const fetchStrategies = async ({ commit }, { customerId, budgetYear }) => {
  const { errors, data } = await getMissionAndStrategies(customerId, budgetYear)
  if (errors) return

  // check format of data... reformat if necessary to { count, data }
  const strategies = data.count
    ? data
    : { count: data.length, data }
  commit('setStrategies', strategies)
}
export const addStrategy = async ({ commit }, strategy) => {
  const { data } = await updateMissionAndStrategies(strategy)
  const newStrategy = { ...strategy, sequenceId: data }
  if (data) commit('addStrategy', newStrategy)
  return newStrategy
}
export const updateStrategy = async ({ commit }, strategy) => {
  await updateMissionAndStrategies(strategy)
  commit('updateStrategy', strategy)
  return strategy
}
export const deleteStrategy = async ({ commit }, { customerId, sequenceId, budgetYear }) => {
  await deleteMissionAndStrategies({ customerId, sequenceId, budgetYear })
  commit('deleteStrategy', { sequenceId })
}

// Update Customer PIMs data
export const editCustomerPimNamesById = ({ commit }, payload) => {
  commit('EDIT_CUSTOMER_PIM_NAMES_BY_ID', payload)
}
