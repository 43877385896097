<template>
  <div>
    <!-- Mobile -->
    <template v-if="$vuetify.breakpoint[mobileBreakPoint]">
      <!-- Display a skeleton loader if the data is not available when the component is mounted -->
      <v-skeleton-loader
        v-if="itemsLoading || !items"
        :loading="true"
        type="list-item@3, divider, list-item@3, divider, list-item@3">
      </v-skeleton-loader>
      <responsive-expansion-panel
        v-else-if="mobileExpansionPanels"
        :items="items"
        :headers="headers"
        :mobile-break-point="mobileBreakPoint"
        :clickable-rows="routeSettings.clickableRows"
        :action-header="actionHeader"
        @selectRow="selectRow">
        <template
          v-for="(index, name) in $scopedSlots"
          #[name]="data">
          <slot
            :name="name"
            v-bind="data"></slot>
        </template>
      </responsive-expansion-panel>
      <v-data-iterator
        v-else
        :items="items"
        :footer-props="{ 'items-per-page-options': rowsPerPageItems }"
        :no-data-text="noDataText"
        :search="search"
        :server-items-length="ownTotalItems"
        :options.sync="ownPagination"
        :hide-default-footer="hideActions">
        <template
          #item="{item}">
          <v-divider></v-divider>
          <responsive-table-mobile-layout
            :headers="headers"
            :item="item"
            :mobile-break-point="mobileBreakPoint"
            :clickable-rows="routeSettings.clickableRows"
            :action-header="actionHeader"
            @selectRow="selectRow">
            <template
              v-for="(index, name) in $scopedSlots"
              #[name]="data">
              <slot
                :name="name"
                v-bind="data"></slot>
            </template>
          </responsive-table-mobile-layout>
        </template>
        <template #no-results>
          <v-row justify="center">
            <v-col cols="auto">
              {{ noDataText }}
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </template>
    <!-- Not Mobile -->
    <template v-else>
      <v-divider v-if="hideHeader"></v-divider>
      <!-- Display a skeleton loader if the data is not available when the component is mounted -->
      <v-skeleton-loader
        v-if="itemsLoading || !items"
        :loading="true"
        type="table-tbody">
      </v-skeleton-loader>
      <v-data-table
        v-else
        :fixed-header="fixedHeader"
        :height="height"
        :headers="headers"
        :hide-default-header="hideHeader"
        :items="items"
        :footer-props="{ 'items-per-page-options': rowsPerPageItems }"
        :search="search"
        :server-items-length="ownTotalItems"
        :options.sync="ownPagination"
        :no-data-text="noDataText"
        :hide-default-footer="hideActions">
        <template #item="{ item }">
          <tr>
            <template v-for="(header, index) in headers">
              <td
                v-if="routeSettings.clickableRows"
                :key="index"
                :data-qa="header.slot"
                :class="header.type === 'action' && 'text-xs-right'"
                style="cursor: pointer"
                @click="selectRow(item)">
                <slot
                  v-if="header.slot"
                  :name="header.slot"
                  :item="item">
                </slot>
                <responsive-table-cell
                  v-else-if="header.text || header.value"
                  v-bind="{header, item}"
                  :data-qa="`${header.value}Value`">
                </responsive-table-cell>
                <v-icon
                  v-else-if="header.text == ''">
                  arrow_forward
                </v-icon>
              </td>
              <td
                v-else
                :key="index"
                :data-qa="header.slot"
                :class="header.type === 'action' && 'text-xs-right'">
                <slot
                  v-if="header.slot"
                  :name="header.slot"
                  :item="item">
                </slot>
                <responsive-table-cell
                  v-else-if="header.text || header.value"
                  v-bind="{header, item}"
                  :data-qa="`${header.value}Value`">
                </responsive-table-cell>
              </td>
            </template>
          </tr>
        </template>
        <template #no-results>
          <v-row justify="center">
            <v-col cols="auto">
              {{ noDataText }}
            </v-col>
          </v-row>
        </template>
        <!-- TEMP FIX - Awaiting Vuetify fix for issue: https://github.com/vuetifyjs/vuetify/issues/14405 -->
        <!-- eslint-disable -->
        <template v-slot:footer.page-text="props">
          {{ props.pageStart }}-{{ props.pageStop }} of {{ props.itemsLength.toLocaleString('en-us') }}
        </template>
        <!-- eslint-enable -->
      </v-data-table>
    </template>
  </div>
</template>

<script>
import deepEqual from 'fast-deep-equal'
import ResponsiveTableMobileLayout from './ResponsiveTableMobileLayout.vue'
import ResponsiveExpansionPanel from './ResponsiveExpansionPanel.vue'
import ResponsiveTableCell from './ResponsiveTableCell.vue'

export default {
  name: 'ResponsiveTable',
  components: {
    ResponsiveTableCell,
    ResponsiveExpansionPanel,
    ResponsiveTableMobileLayout
  },
  props: {
    headers: {
      type: [Array],
      required: true
    },
    fixedHeader: {
      type: Boolean,
      required: false,
      default: false
    },
    height: {
      type: [Number, String],
      required: false,
      default: undefined
    },
    items: {
      type: [Array, null],
      required: false,
      default: null
    },
    search: {
      type: String,
      required: false,
      default: null
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    mobileBreakPoint: {
      type: String,
      required: false,
      default: 'xs'
    },
    mobileExpansionPanels: {
      type: Boolean,
      required: false,
      default: false
    },
    pagination: {
      type: Object,
      required: true
    },
    rowsPerPageItems: {
      type: Array,
      required: false,
      default () {
        return [5, 10, 25]
      }
    },
    hideActions: {
      type: Boolean,
      required: false,
      default: false
    },
    noDataText: {
      type: String,
      required: false,
      default: 'No items'
    },
    routeSettings: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    },
    totalItems: {
      type: Number,
      required: false,
      default: undefined
    },
    hideHeader: {
      type: Boolean,
      required: false,
      default: undefined
    }
  },
  data () {
    return {
      itemsLoading: true
    }
  },
  computed: {
    actionHeader () {
      return this.headers.find(header => header.type === 'action')
    },
    ownTotalItems () {
      // Total items cannot be null, it can be anything else
      return this.totalItems ?? undefined
    },
    ownPagination: {
      get: function () {
        if (!this.pagination) return null

        // Valid values are true, false, or array
        // We can't do this normally since checking `this.pagination.sortDesc ? :` breaks on a "false" value
        const sortDescInvalid = (this.pagination.sortDesc !== true && this.pagination.sortDesc !== false) && !Array.isArray(this.pagination.sortDesc)

        // Ew, nested ternaries, but these are consistently simple:
        // Default to [] if falsy. If not falsy, check if it's an array, if not it into an array
        const sortBy = this.pagination.sortBy ? Array.isArray(this.pagination.sortBy) ? this.pagination.sortBy : [this.pagination.sortBy] : []
        const sortDesc = !sortDescInvalid ? Array.isArray(this.pagination.sortDesc) ? this.pagination.sortDesc : [this.pagination.sortDesc] : []
        const groupBy = this.pagination.groupBy ? Array.isArray(this.pagination.groupBy) ? this.pagination.groupBy : [this.pagination.groupBy] : []
        const groupDesc = this.pagination.groupDesc ? Array.isArray(this.pagination.groupDesc) ? this.pagination.groupDesc : [this.pagination.groupDesc] : []

        // These need to match, we will truncate the arrays to ensure that happens in case callers are not being consistent
        if (sortBy.length !== sortDesc.length) {
          // If sortBy is longer we will assume that sortDesc is true for all sorting columns
          if (sortBy.length > sortDesc.length) {
            for (let i = 0; i < sortBy.length; i++) {
              if (sortDesc.length <= i) {
                sortDesc.push(true)
              }
            }
          } else { // Otherwise, go ahead and truncate
            const length = Math.min(sortBy.length, sortDesc.length)

            sortBy.length = length
            sortDesc.length = length
          }
        }

        return {
          page: this.pagination.page || 1,
          itemsPerPage: this.pagination.itemsPerPage,
          sortBy,
          sortDesc,
          groupBy,
          groupDesc,
          multiSort: this.pagination.multiSort || false,
          mustSort: this.pagination.mustSort || false
        }
      },
      set: function (pagination) {
        if (!deepEqual(pagination, this.pagination)) {
          this.$emit('update:pagination', pagination)
          this.$emit('paginationChanged')
        }
      }
    }
  },
  watch: {
    pagination: {
      deep: true,
      handler (val) {
        if (!deepEqual(this.pagination, val)) {
          this.ownPagination = val
        }
      }
    },
    items: {
      deep: true,
      handler (val) {
        if (Array.isArray(val)) {
          this.itemsLoading = false
        }
      }
    },
    loading: {
      deep: true,
      handler (val) {
        if (val === false) {
          this.itemsLoading = false
        }
      }
    }
    // ownPagination (val) {
    //   if (!deepEqual(this.pagination, val)) {
    //     this.$emit('update:pagination', val)
    //     this.$emit('paginationChanged')
    //   }
    // }
  },
  mounted () {
    if (Array.isArray(this.items)) {
      this.itemsLoading = false
    }
  },
  methods: {
    /*
      Route to a new page by clicking on the table row
      Props must include:
        routeSettings {
          clickableRow: boolean
          eventWithArgs: this boolean can be set to routeSettings to emit an event instead of routing using the parameterNames array
          parameterNames: array of parameter names ex. parameterNames: ['contractNumber', 'banner'] || If eventWithArgs, this property can be used as an array of arguments
          routeName: string of the route name ex. routeName: 'customers-on-expiring-contracts'
        }
    */
    selectRow (item) {
      const { routeName, parameterNames, eventWithArgs } = this.routeSettings
      const params = {}
      // eventWithArgs = true: this property can be added to route settings to emit an event instead of routing using the parameterNames array
      if (eventWithArgs) {
        parameterNames.forEach(parameterName => {
          params[parameterName] = item[parameterName]
        })
        params.item = item
        return this.$emit('rowClickEvent', params)
      }
      // eventWithArgs = false
      parameterNames.forEach(parameterName => {
        params[parameterName] = item[parameterName]
      })

      if (!item.banner) {
        params.banner = this.$store.getters['user/banner']
      }
      this.$router.push({
        name: routeName,
        params,
        query: params
      })
    }
  }
}
</script>
