<template>
  <div :class="cellClasses">
    <template v-if="header.type === 'date'">
      {{ displayValue | formatDate }}
    </template>
    <template v-else-if="header.type === 'firstAndLastName'">
      {{ displayValue | formatFirstLastName }}
    </template>
    <template v-else-if="header.type === 'money'">
      {{ displayValue | formatMoney }}
    </template>
    <template v-else-if="header.type === 'percent'">
      {{ displayValue | formatPercent }}
    </template>
    <template v-else-if="header.type === 'number'">
      {{ displayValue | formatNumber }}
    </template>
    <template v-else-if="header.type === 'email'">
      <a
        v-if="rawValue"
        :href="`mailto:${item[header.value]}`">
        {{ displayValue }}
      </a>
      <template v-else>
        -
      </template>
    </template>
    <template v-else-if="header.type === 'phone'">
      <span v-if="rawValue && $vuetify.breakpoint.smAndDown">
        <a
          :href="`tel:${item[header.value]}`">
          {{ displayValue }}
        </a>
        {{ item.extension ? 'EXT:'+item.extension : '' }}
      </span>
      <span
        v-else-if="rawValue && $vuetify.breakpoint.mdAndUp">
        {{ displayValue | formatPhone }} {{ item.extension ? 'EXT:'+item.extension : '' }}
      </span>
      <template v-else>
        -
      </template>
    </template>
    <template v-else-if="header.type === 'cell'">
      <a
        v-if="rawValue && $vuetify.breakpoint.smAndDown"
        :href="`tel:${item[header.value]}`">
        {{ displayValue | formatPhone }}
      </a>
      <span
        v-else-if="rawValue && $vuetify.breakpoint.mdAndUp">
        {{ displayValue | formatPhone }}
      </span>
      <template v-else>
        -
      </template>
    </template>
    <template v-else>
      {{ displayValue || '-' }}
    </template>
  </div>
</template>

<script>
export default {
  name: 'ResponsiveTableCell',
  props: {
    header: {
      type: Object,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    mobileBreakPoint: {
      type: String,
      required: false,
      default: 'xs'
    },
    /** Expects to be a vuetify color class, like red--text or grey--text */
    color: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    cellClasses () {
      const classes = []

      if (this.$vuetify.breakpoint.smAndDown) {
        classes.push('text-subtitle-1')
        classes.push(this.header.mobileClass)
      } else {
        classes.push('text-table-body-2')
      }

      if (this.color) {
        classes.push(this.color)
      }

      return classes
    },
    rawValue () {
      return this.item[this.header.value]
    },
    displayValue () {
      const { header, item } = this
      const displayValue = header.displayValue ? item[header.displayValue] : item[header.value]
      return header.formatter ? header.formatter(displayValue) : displayValue
    }
  }
}
</script>

<style lang="scss" scoped>
.text-table-body-2 {
  font-size: 13px; // This is how it used to look
  font-weight: 400;
}
</style>
