<template>
  <customer-nth-order-report-base
    :first-order-report-data="firstOrderReportData"
    :loading="loading"
    :first-order="true"
    :export-loading="exportLoading"
    @export-report="exportReport">
  </customer-nth-order-report-base>
</template>

<script>
import { mapGetters } from 'vuex'
import { lifecycles } from '@/api/customer-lifecycle'
import { CustomerNthOrderReportBase } from '@/components/Reports'
import { downloadFileResult } from '@/globals/utils'

export default {
  name: 'LifecycleFirstOrderReport',
  components: {
    CustomerNthOrderReportBase
  },
  data () {
    return {
      firstOrderReportData: [],
      loading: true,
      exportLoading: false
    }
  },
  computed: {
    ...mapGetters('user', ['user'])
  },
  async mounted () {
    await this.fetchData()
    this.$store.commit('setPageHeader', {
      page: 'Reports',
      text: 'Customer Lifecycle - First order'
    })
  },
  methods: {
    async fetchData () {
      this.loading = true

      try {
        const result = await lifecycles.firstOrder.getReport()
        this.firstOrderReportData = result
      } catch (error) {
        this.$store.commit('setToastMessage', {
          message: 'Error fetching Lifecycle First Order report',
          status: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async exportReport () {
      this.exportLoading = true

      const result = await lifecycles.firstOrder.getExport()
      if (result) {
        if (result) {
          downloadFileResult(result)
        }
      }

      this.exportLoading = false
    }
  }
}
</script>
