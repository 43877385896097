const setAccountTab = ({ commit, state, rootState }, tabIndex) => {
  const customerId = rootState.currentCustomer.customer.customerId

  const setTabIndex = tabIndex === null
    ? customerId !== state.customerId
      ? 0
      : tabIndex
    : tabIndex

  commit('SET_ACCOUNT_TAB', setTabIndex)
  commit('SET_CUSTOMER_ID', customerId)
}

const setDisplayNonUpgradedModal = ({ commit }, value) => {
  commit('SET_DISPLAY_NON_UPGRADED_MODAL', value)
}

const setNonUpgradedAccountsState = ({ commit }, data) => {
  commit('SET_NON_UPGRADED_ACCOUNTS', data)
}

const setCurrentCustomerId = ({ commit }, data) => {
  commit('SET_CURRENT_CUSTOMER_ID', data)
}

const setWebAccountsJson = ({ commit }, data) => {
  const jsonWebAccounts = JSON.stringify(data)
  commit('SET_WEB_ACCOUNTS_JSON', jsonWebAccounts)
}

const removeNonUpgradedAccount = ({ commit, state }, email) => {
  const foundAccountIndex = state.nonUpgradedAccounts.findIndex((a) => a.email === email)

  if (foundAccountIndex > -1) {
    commit('REMOVE_NON_UPGRADED_ACCOUNT', foundAccountIndex)
  }
}

export default {
  setAccountTab,
  setDisplayNonUpgradedModal,
  setNonUpgradedAccountsState,
  setCurrentCustomerId,
  setWebAccountsJson,
  removeNonUpgradedAccount
}
