import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  categories: [],
  statuses: [],
  types: [],
  duplicatedActivity: null,
  startDate: null,
  endDate: null
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
