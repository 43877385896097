import Vue from 'vue'
import router from '@/router'
import store from '@/store'

import { fetchUser } from '@/api/user'
import { addQueryToEveryRequest, removeQueryFromEveryRequest } from '@/globals/config'
import { QUERY_PARAMS, STORAGE_KEYS } from '@/globals/constants'
import { getLocalStorageSSOKey, setLocalStorageSSOKey } from '@/globals/utils'
import { User } from 'oidc-client'
import { AuthManager } from './authManager'
import { RawAuthProfileSchema } from './models/rawAuthProfileSchema'
import { UserIdentity } from './models/userIdentity'
import { getBannerName, parseBannersFromIdentity, parseRolesFromIdentity } from './utilities'
import { ApiUserDetailsModel } from './models/apiUserDetailsModel'
import { AVAILABLE_BANNERS, IdentityLifecycle } from './constants'

// Debug logging helpers that can be enabled/disabled to show identity debug information

const DEBUG_LOGGING = false
const SHOW_STACK_TRACE = false // Show call stack on each message, very handy if needed

const debugLog = (message: string, context?: Record<string, any>) => {
  if (DEBUG_LOGGING) {
    message = `%c ${message}`
    const color = 'background: #222; color: #bada55' // Colors the message so it's easier to find

    if (SHOW_STACK_TRACE) {
      const stack = (new Error('StackLog')).stack

      console.log(message, color, context, stack)
    } else {
      console.log(message, color, context)
    }
  }
}

class IdentityService {
  private _authManager: AuthManager | null = null
  private _identityProfile: UserIdentity | null = null

  private _initialized = false
  private _initPromise : Promise<unknown> | null = null

  // Callbacks that will execute at the appropriate lifecycle points
  // these callbacks are meant to be blocking, guarantee execution before the app state changes
  private lifecycleHooks: Map<IdentityLifecycle, Array<() => void>> = new Map()
  private asyncLifecycleHooks:Map<IdentityLifecycle, Array<() => Promise<void>>> = new Map()

  private _changingViewAsUser = false

  get initialized () {
    return this._initialized
  }

  get initializationPromise () {
    return this._initPromise
  }

  /** Gets the authManager instance.
   *
   * Throws if the instance has not been set. If you encounter this throwing, evaluate where in the app lifecycle this is being called from.  */
  get authManager () {
    if (!this._authManager) {
      throw new Error('Attempt to access IdentityService.authManager before authManager has been set')
    }

    return this._authManager
  }

  get identityProfile () {
    if (!this._identityProfile) {
      throw new Error('Attempt to access a null IdentityProfile. Ensure profile is initialized before use')
    }

    return this._identityProfile
  }

  get isViewingAs () {
    return this.identityProfile.isViewingAs
  }

  get selectedBanner () {
    return this.identityProfile.getDefinitiveBanner()
  }

  /** Registers a blocking lifecycle hook to be called BEFORE the provided lifecycle. */
  public registerLifecycleHook (lifecycle: IdentityLifecycle, callback: () => void) {
    if (lifecycle === 'WaitingForAuth') throw new Error('WaitingForAuth lifecycle hook not supported at this time')

    if (!this.lifecycleHooks.has(lifecycle)) {
      this.lifecycleHooks.set(lifecycle, [])
    }

    this.lifecycleHooks.get(lifecycle)!.push(callback)
  }

  /** Registers a blocking lifecycle hook to be called BEFORE the provided lifecycle.
   * We have async callbacks to ensure that we are blocking continuation until async code completes
  */
  public registerAsyncLifecycleHook (lifecycle: IdentityLifecycle, callback: () => Promise<void>) {
    if (lifecycle === 'WaitingForAuth') throw new Error('WaitingForAuth lifecycle hook not supported at this time')

    if (!this.asyncLifecycleHooks.has(lifecycle)) {
      this.asyncLifecycleHooks.set(lifecycle, [])
    }

    this.asyncLifecycleHooks.get(lifecycle)!.push(callback)
  }

  /** Required identity service initialization that sets up auth/user/identity state
   *
   * Must be called in main.ts BEFORE vue app initialization, or initialization of anything that interfaces with auth or identity
  */
  public async init (authManager: AuthManager) {
    debugLog('IDENTITY_SERVICE/init(): Init State', { windowLocation: window.location, routerRoute: router.currentRoute })
    this._authManager = authManager

    // Creates a promise & resolver that callers can await to delay caller continuation untill auth is initialized
    let resolver!: (value?: unknown) => void
    const initPromise = new Promise((resolve) => {
      resolver = resolve
    })
    this._initPromise = initPromise

    // Handle auth callback to update user profile when auth changes
    // Callback necessary as we cannot await the authManager at this point, since we may encounter a page reload
    // This callback triggers once authManager.getUser() is called or when auth updates
    authManager.registerUserLoadedCallback(async (user) => {
      debugLog('IDENTITY_SERVICE/init(): registerUserLoadedCallback() called', { rawUserProfile: user })

      this.runLifecycleHooks('InitializingProfile')
      await this.runAsyncLifecycleHooks('InitializingProfile')

      await this.initializeUserProfile(user)

      this.runLifecycleHooks('AfterInitialized')
      await this.runAsyncLifecycleHooks('AfterInitialized')

      // We don't need callbacks hanging around, clear these out
      this.clearLifecycleHooks()

      if (!this.initialized) {
        this._initialized = true
        resolver()
        this._initPromise = null
      }

      store.commit('user/setLoaded')
    })

    // auth-callback means we got a redirect callback from oidc client
    // Extracted from AuthCallback component so we can handle this without waiting for the vue app to boot
    if (window.location.pathname === '/auth-callback') {
      try {
        const user = await this.authManager.signinRedirectCallback()
        if (user.state && user.state.indexOf('auth-callback') > -1) {
          user.state = '/' // <<< we don't want to send users "back" to this callback page
        }
        const { state = '/' } = user
        const path = state.replace(/"/g, '')
        window.location.assign(window.location.origin + path) // Try and make sure user is directed back to their original path
      } catch (error) {
        console.error('Signing redirect callback failed', error)
        window.sessionStorage.clear()
        window.localStorage.clear()
        this.authManager.signInOrExpireSession()
      }
    } else {
      authManager.getUser()
    }
  }

  /** Organizes the various moving parts of setting up the user profile & state for sales */
  private async initializeUserProfile (rawUser: User) {
    if (!rawUser) {
      throw new Error('Cannot setup user identity, rawUser is not available')
    }

    debugLog('IDENTITY_SERVICE/initializeUserProfile():', { rawUserProfile: rawUser })

    const rawAuthProfile = rawUser.profile as unknown as RawAuthProfileSchema
    const availableBanners = parseBannersFromIdentity(rawAuthProfile)
    const roles = parseRolesFromIdentity(rawAuthProfile)

    // We want to avoid erasing the banner selection on auth refresh
    // We also want to pull the banner (if it exists) from localStorage if this is during boot
    let cachedSelectedBanner = this.tryGetSelectedBanner(rawAuthProfile.sso)
    const urlBanner = this.getUrlBanner() || cachedSelectedBanner

    debugLog('IDENTITY_SERVICE/initializeUserProfile(): cachedSelectedBanner & UrlBanner retrieved', { cachedSelectedBanner, urlBanner })

    if (cachedSelectedBanner !== urlBanner) {
      debugLog('IDENTITY_SERVICE/initializeUserProfile(): UrlBanner different from cachedSelectedBanner. Setting cachedSelectedBanner to UrlBanner', { cachedSelectedBanner, urlBanner })
      cachedSelectedBanner = urlBanner
    }

    // If we found a cached banner, and it is not one of user's available banners, then change to user's default
    if (cachedSelectedBanner && !availableBanners.includes(cachedSelectedBanner)) {
      debugLog('IDENTITY_SERVICE/initializeUserProfile(): cachedSelectedBanner unavailable for user', { initialValue: cachedSelectedBanner, changingTo: rawAuthProfile.banner })
      // Alternatively this could be nulled, but I feel this is more explicit
      cachedSelectedBanner = rawAuthProfile.banner
    }

    const userDetails = await fetchUser(rawAuthProfile.sso, cachedSelectedBanner || rawAuthProfile.banner)
    const userIdentity = new UserIdentity(rawAuthProfile, userDetails.data, cachedSelectedBanner || rawAuthProfile.banner, availableBanners, roles)

    this._identityProfile = userIdentity // Set early as following functions may need this to be set

    this.setGoogleAnalytics(userIdentity.authedSso)

    this.runLifecycleHooks('InitializingViewAs')
    await this.runAsyncLifecycleHooks('InitializingViewAs')

    // After normal identity has been setup, check for viewAs, and setup view as identity if needed
    const viewAsSso = this.tryGetViewAsSso(rawAuthProfile.sso)
    if (viewAsSso) {
      await this.setViewAsUser(viewAsSso, userIdentity.selectedBanner)
    } else {
      // Ensure that we are pre-running query param & localStorage setting if this is boot
      // If this is an auth refresh, then this ensures we are not wiping the user's selected banner
      this.setSelectedBannerStates(userIdentity.selectedBanner, userIdentity.selectedBanner, userIdentity.authedSso)
    }

    this.updateStoreUser(this.identityProfile)

    return userIdentity
  }

  public async setViewAsUser (viewAsSso: number, viewAsBanner: string) {
    this._changingViewAsUser = true

    // Some callers call with upper case banner names
    viewAsBanner = viewAsBanner.toLocaleLowerCase()

    const userDetails = (await fetchUser(viewAsSso, viewAsBanner)).data as ApiUserDetailsModel

    // The requested viewAsBanner may not match the banner for the ViewAs user
    // Check and correct this
    if (getBannerName(userDetails.companyId) !== viewAsBanner) {
      viewAsBanner = getBannerName(userDetails.companyId)
    }

    store.dispatch('branches/resetBranches')

    addQueryToEveryRequest(QUERY_PARAMS.banner, viewAsBanner)
    addQueryToEveryRequest(QUERY_PARAMS.viewAs, viewAsSso)
    setLocalStorageSSOKey(STORAGE_KEYS.viewAsUser, viewAsSso, this.identityProfile.authedSso)

    this.identityProfile.setViewAsUser(viewAsSso, viewAsBanner, userDetails)

    this.updateSelectedBanner(viewAsBanner, false)
    this.updateStoreUser(this.identityProfile)

    // We only want to change the route if the app is initialized
    // If we push this route when it's initializing, we cause a navigation cancel and an error
    if (this.initialized) {
      router.push({
        name: 'dashboard',
        params: { banner: this.selectedBanner }
      })
    }

    this._changingViewAsUser = false
  }

  public async stopViewingAs () {
    this._changingViewAsUser = true

    removeQueryFromEveryRequest(QUERY_PARAMS.viewAs)
    setLocalStorageSSOKey(STORAGE_KEYS.viewAsUser, null, this.identityProfile.authedSso)

    this.identityProfile.clearViewAs()

    this.updateSelectedBanner(this.identityProfile.banner, false)
    this.updateStoreUser(this.identityProfile)

    router.push({
      name: 'dashboard',
      params: { banner: this.selectedBanner }
    })

    this._changingViewAsUser = false
  }

  /** Updates the selected banner on the user profile. Updates Axios request query params, and sets the local storage key and returns the new profile */
  public updateSelectedBanner (banner: string, updateStore = true) {
    if (!this._initialized) {
      return false
    }
    const previousBanner = this.identityProfile.selectedBanner

    // If it already matches, no action necessary
    if (previousBanner === banner) {
      return this.identityProfile
    }

    // Short circuit if the provided banner is invalid or cannot be used by this user
    // Set the query param & localStorage items to make sure these values are valid
    if (!this.identityProfile.hasBanner(banner)) {
      this.setSelectedBannerStates(this.identityProfile.selectedBanner, previousBanner, this.identityProfile.authedSso)
      return false
    }

    this.identityProfile.selectedBanner = banner
    this.setSelectedBannerStates(banner, previousBanner, this.identityProfile.authedSso)

    if (updateStore) {
      this.updateStoreUser(this.identityProfile)
    }

    return this.identityProfile
  }

  /** Attempts to get the currently selected banner from an existing identityProfile, or from localStorage if no profile exists */
  public tryGetSelectedBanner (sso: string | number | null = null): string | null {
    let selectedBanner = this._identityProfile?.selectedBanner ?? null

    // If no selected banner exists, and _identityProfile || sso does exist, then attempt to retrieve banner from localstorage
    if (!selectedBanner && (this._identityProfile || sso)) {
      selectedBanner = getLocalStorageSSOKey(STORAGE_KEYS.selectedBanner, sso || this._identityProfile?.authedSso)
    }

    return selectedBanner || null
  }

  public getUrlBanner (): string | null {
    const path = window.location.pathname

    for (let i = 0; i < AVAILABLE_BANNERS.length; i++) {
      if (path.includes(AVAILABLE_BANNERS[i])) {
        return AVAILABLE_BANNERS[i]
      }
    }

    return null
  }

  /** Attempts to get the viewAs SSO if one currently exists. Used to check if the user was using viewAs before the last app load */
  public tryGetViewAsSso (sso: string | number) {
    return getLocalStorageSSOKey(STORAGE_KEYS.viewAsUser, sso) || null
  }

  /** INTERNAL USE ONLY. Sets the necessary application states to this banner
   * Updates:
   *    Axios: All requests query params
   *    Local Storage: Selected Banner
   *    Router 'banner' param
   */
  private setSelectedBannerStates (banner: string, previousBanner: string, sso: string | number) {
    debugLog('IDENTITY_SERVICE/setSelectedBannerStates():', { banner, previousBanner, sso })
    addQueryToEveryRequest(QUERY_PARAMS.banner, banner)
    setLocalStorageSSOKey(STORAGE_KEYS.selectedBanner, banner, sso)

    // Extracted from BannerSwitcher.vue

    const invalidRoutes = ['not-authorized', 'not-found']
    const currentRoute = router.currentRoute
    const sameBanner = banner === previousBanner

    // When changing viewAs user, we don't want to trigger a duplicate route change again
    // We don't want to trigger route change during initialization either, as this causes a navigation cancel error
    if (!sameBanner && !this._changingViewAsUser && this.initialized) {
      // If the current route is not authorized/found, it might be on a valid path for a
      // different banner, so instead try simply replacing the banner in the URL
      // Also removes duplicate slashes, which can appear when switching banners.
      if (invalidRoutes.includes(currentRoute.name || '') && currentRoute.path.includes(previousBanner)) {
        router.push({
          path: currentRoute.path.replace(previousBanner, banner).replace(/\/{2,}/, '/'),
          params: { banner }
        })
      } else {
        const routeName = currentRoute.name || 'dashboard'
        router.push({
          name: routeName,
          params: { banner }
        })
      }
    }

    // Pulled from previous updateSelectedBanner() logic
    store.dispatch('activities/resetActivitiesOptions')
    store.dispatch('opportunities/resetOpportunitiesOptions')
    store.dispatch('branches/resetBranches')
  }

  // Sets the updated user profile in the store, should be called after ANY changes to the user profile
  private updateStoreUser (userIdentity: UserIdentity) {
    store.dispatch('user/setUser', userIdentity)
  }

  private setGoogleAnalytics (sso: string | number) {
    // set Analytics
    (Vue as any).$ga.set('dimension1', sso)
  }

  /** Will execute any existing lifecycle hooks for the provided lifecycle */
  private runLifecycleHooks (lifecycle: IdentityLifecycle) {
    if (!this.lifecycleHooks.has(lifecycle)) return

    const hooks = this.lifecycleHooks.get(lifecycle)!

    debugLog('IDENTITY_SERVICE/runLifecycleHooks():', { lifecycle, hooks })

    hooks.forEach((callback) => {
      callback()
    })
  }

  /** Will execute any existing async lifecycle hooks for the provided lifecycle */
  private async runAsyncLifecycleHooks (lifecycle: IdentityLifecycle) {
    if (!this.asyncLifecycleHooks.has(lifecycle)) return

    const hooks = this.asyncLifecycleHooks.get(lifecycle)!

    debugLog('IDENTITY_SERVICE/runAsyncLifecycleHooks():', { lifecycle, hooks })

    for (let i = 0; i < hooks.length; i++) {
      await hooks[i]()
    }
  }

  private clearLifecycleHooks () {
    this.lifecycleHooks.clear()
    this.asyncLifecycleHooks.clear()
  }
}

const identityService = new IdentityService()
export default identityService
