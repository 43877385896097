export default {
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Number],
      required: false
    },
    mask: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      maskedValue: '',
      unmaskedValue: ''
    }
  },
  computed: {
    fullMask () {
      switch (this.mask) {
        case 'phone':
          return '(###) ###-####'
        case 'ssn':
          return '###-##-####'
        case 'ein':
          return '##-#######'
        default:
          return this.mask
      }
    }
  },
  watch: {
    value (newVal) {
      if (this.unmaskedValue !== newVal) {
        this.maskedValue = newVal
      }
    }
  },
  created () {
    this.maskedValue = this.value
  },
  methods: {
    handleInput (event) {
      this.maskedValue = event.target.value
      this.unmaskedValue = event.target.unmaskedValue

      if (this.unmaskedValue !== this.value) {
        this.$emit('input', this.unmaskedValue)
      }
    }
  }
}
