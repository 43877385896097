<template>
  <customers-at-risk-report-base
    :customers-at-risk="customersAtRisk"
    :churn-reasons="churnReasons"
    :churn-responses="churnResponses"
    :loading.sync="loading"
    :export-loading="exportLoading"
    :from-action-plan="false"
    :from-lifecycle="true"
    @reload-data="fetchData()"
    @export-report="exportReport">
    <template #desktop-definition>
      <v-card flat>
        For definitions of customer lifecycles
        <anchor :to="{ name: 'definitions' }">
          definitions
        </anchor>
      </v-card>
    </template>
    <template #mobile-definition>
      <h3>
        Customer Lifecycle Definitions
        <back-button
          :to="{ name: 'definitions' }"
          :point-right="true">
        </back-button>
      </h3>
    </template>
  </customers-at-risk-report-base>
</template>

<script>
import { mapGetters } from 'vuex'
import Anchor from '@/components/Anchor.vue'
import BackButton from '@/components/BackButton.vue'
import { CustomersAtRiskReportBase } from '@/components/Reports/AtRisk'
import { getChurnReasonsAndResponsesAsync } from '@/api/activities'
import { lifecycles } from '@/api/customer-lifecycle'
import { downloadFileResult } from '@/globals/utils'

export default {
  name: 'CustomersAtHighRiskReport',
  components: {
    CustomersAtRiskReportBase,
    Anchor,
    BackButton
  },
  props: {},
  data () {
    return {
      churnReasons: [],
      churnResponses: [],
      customersAtRisk: [],
      loading: true,
      exportLoading: false
    }
  },
  computed: {
    ...mapGetters('user', ['user']),
    exportReportLink () {
      return lifecycles.atHighRisk.exportUrl()
    }
  },
  async mounted () {
    await this.fetchData()
    this.setPageHeader()
  },
  methods: {
    async fetchData () {
      try {
        const churnSelections = await getChurnReasonsAndResponsesAsync(this.user.companyId)
        const churnCustomers = await lifecycles.atHighRisk.getReport()

        this.customersAtRisk = churnCustomers
        this.churnReasons = churnSelections.reasons
        this.churnResponses = churnSelections.responses
        this.loading = false
      } catch (err) {
        this.loading = false
        this.$store.commit('setToastMessage', {
          message: 'Error fetching Lifecycle At High Risk Report',
          status: 'error'
        })
      }
    },
    async exportReport () {
      this.exportLoading = true

      const result = await lifecycles.atHighRisk.getExport()
      if (result) {
        downloadFileResult(result)
      }

      this.exportLoading = false
    },
    setPageHeader () {
      this.$store.commit('setPageHeader', {
        page: 'Reports',
        text: 'Customer Lifecycle - At High Risk'
      })
    }
  }
}
</script>
