<template>
  <div
    v-if="show"
    data-qa="banner_switcher">
    <!-- TODO: v-select -->
    <v-select
      v-model="selectedSwitchBanner"
      :items="availableBanners"
      hide-details
      filled
      class="mb-2 primary text-capitalize"
      @change="changeBanner">
      <span
        slot="label"
        class="white--text text-uppercase">
        Banner
      </span>
      <v-list-item
        slot="prepend-item">
        <v-list-item-title class="text-uppercase text-body-1 ">
          Select a Banner
        </v-list-item-title>
      </v-list-item>
      <span
        slot="item"
        :key="props.item"
        slot-scope="props"
        class="text-capitalize"
        :class="props.item === banner ? 'font-weight-bold' : ''">
        {{ props.item }}
      </span>
    </v-select>
  </div>
</template>

<script>
import { identityService } from '@/features/identity'
import { mapGetters } from 'vuex'

export default {
  name: 'BannerSwitcher',
  components: {
  },
  props: {
  },
  data () {
    return {
      selectedSwitchBanner: null
    }
  },
  computed: {
    ...mapGetters('user', ['user', 'banner', 'availableBanners']),
    show () {
      return this.availableBanners.length > 1
    }
  },
  watch: {
    'user.role': {
      immediate: true,
      handler (val) {
        if (val) this.selectedSwitchBanner = this.banner
      }
    },
    banner (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.selectedSwitchBanner = newVal
      }
    }
  },
  methods: {
    changeBanner (val) {
      if (val !== this.banner) {
        identityService.updateSelectedBanner(val, true)
      }
    }
  }
}
</script>

<style lang="scss">
</style>
