const setAccessToken = (state, token) => {
  state.accessToken = token
}

const setLoaded = (state) => {
  state.userLoaded = true
}

const setUser = (state, user) => {
  state.user = {
    ...state.user,
    ...user
  }
}

const setPreferences = (state, preferences) => {
  state.preferences = {
    ...preferences,
    smsPhoneNumber: (preferences.smsPhoneNumber || '').replace(/^\+1/, '')
  }
}

const setPlattDotComSignInUrl = (state, payload) => {
  state.plattDotComSignInUrl = payload.plattDotComSignInUrl
  state.isAdminLoggingIn = payload.isAdminLoggingIn
}

export default {
  setAccessToken,
  setLoaded,
  setUser,
  setPreferences,
  setPlattDotComSignInUrl
}
