const setCategories = (state, categories) => {
  state.categories = categories
}
const setStatuses = (state, statuses) => {
  state.statuses = statuses
}
const setTypes = (state, types) => {
  state.types = types
}
const setProbabilities = (state, probabilities) => {
  state.probabilities = probabilities
}
const setInitiatives = (state, initiatives) => {
  state.initiatives = initiatives
}
const setSpecialists = (state, specialists) => {
  state.specialists = specialists
}
const setProductCategories = (state, productCategories) => {
  state.productCategories = productCategories
}

const setBusinessUnits = (state, businessUnits) => {
  state.businessUnits = businessUnits
}

const setSavedFilters = (state, savedFilters) => {
  state.savedFilters = savedFilters
}

export default {
  setCategories,
  setStatuses,
  setTypes,
  setProbabilities,
  setInitiatives,
  setSpecialists,
  setProductCategories,
  setBusinessUnits,
  setSavedFilters
}
