<template>
  <div>
    <v-menu
      left
      bottom
      offset-y>
      <template #activator="{ on }">
        <v-icon
          v-on="on">
          more_vert
        </v-icon>
      </template>

      <v-list>
        <v-list-item
          v-for="{ title, icon, customIcon, dialog, event, route, href } in shownMenuItems"
          :key="title"
          @click="handleMenuItem(dialog, event, route, href)">
          <v-list-item-avatar class="list-avatar">
            <img
              v-if="customIcon"
              :src="customIcon"
              class="custom-icon" />
            <v-icon v-else>
              {{ icon }}
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-title>{{ title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <request-edit
      :show.sync="dialogs.edit"
      :customer="customer"
      :user="user">
    </request-edit>
    <request-update-url
      :show.sync="dialogs.updateUrl">
    </request-update-url>
    <request-notes-change
      :show.sync="dialogs.notesChange"
      :customer="customer">
    </request-notes-change>
    <request-close-account
      :show.sync="dialogs.closeAccount"
      :customer="customer"
      :user="user">
    </request-close-account>
    <designations-and-badges-menu
      :show.sync="dialogs.badgesMenu"
      :customer="customer">
    </designations-and-badges-menu>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import taskAddIcon from '@/assets/icons/task_add_dark.svg'
import RequestEdit from './actions/RequestEdit.vue'
import RequestNotesChange from './actions/RequestNotesChange.vue'
import RequestCloseAccount from './actions/RequestCloseAccount.vue'
import RequestUpdateUrl from './actions/RequestUpdateUrl.vue'
import DesignationsAndBadgesMenu from './actions/DesignationsAndBadgesMenu.vue'

export default {
  name: 'CustomerSummaryMenu',
  components: {
    RequestEdit,
    RequestNotesChange,
    RequestCloseAccount,
    RequestUpdateUrl,
    DesignationsAndBadgesMenu
  },
  props: {
    showEditUrlOption: {
      type: Boolean,
      required: false,
      default: false
    },
    customer: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      dialogs: {
        edit: false,
        notesChange: false,
        closeAccount: false,
        updateUrl: false,
        badgesMenu: false
      }
    }
  },
  computed: {
    ...mapGetters('user', ['banner', 'isPlattBanner']),
    ...mapGetters(['ordersBaseUri']),
    ...mapState('user', ['user']),
    shownMenuItems () {
      const menuItems = [
        {
          title: 'Edit',
          icon: 'edit',
          dialog: 'edit',
          exclude: this.customer.isEnterprise
        },
        {
          title: 'Add or Edit URL',
          icon: 'link',
          dialog: 'updateUrl',
          exclude: !this.showEditUrlOption
        },
        {
          title: 'OE Notes Change',
          icon: 'sms',
          dialog: 'notesChange',
          exclude: !this.isPlattBanner
        },
        {
          title: 'Close Account',
          icon: 'cancel',
          dialog: 'closeAccount'
        },
        {
          title: 'Designations & Badges',
          icon: 'bookmark',
          dialog: 'badgesMenu',
          exclude: (this.isPlattBanner && this.customer.isEnterprise)
        },
        {
          title: 'Create Activity',
          icon: 'assignment_turned_in',
          route: 'create-activity',
          exclude: this.isPlattBanner && this.customer.isEnterprise
        },
        {
          title: 'Create Task',
          customIcon: taskAddIcon,
          route: 'create-task',
          exclude: (this.isPlattBanner && this.customer.isEnterprise) || !this.$hasFeature('Feature_Tasks')
        },
        {
          title: 'Create Quote',
          icon: 'check_circle',
          href: 'create-quote',
          // Rex & Gex, cannot create quotes for bill-to only customers.
          exclude: this.isBillToOnly
        },
        {
          title: 'Create Opportunity',
          icon: 'thumb_up',
          route: 'create-opportunity'
        },
        {
          title: 'Missions and Strategies',
          icon: 'assessment',
          route: 'mission-and-strategies',
          exclude: !this.$hasFeature('Customer_MissionAndStrategies')
        }
      ]
      return menuItems.filter((item) => !item.exclude)
    },
    isBillToOnly () {
      if (!this.isPlattBanner) {
        return this.customer.billTo && !(this.customer.shipTo || this.customer.jobAccount)
      }
      return false
    },
    isDemo () {
      return this.$root.$environment === 'Demo'
    },
    isTest () {
      return this.$root.$environment === 'Test'
    }
  },
  methods: {
    handleMenuItem (dialogKey, event, route, href) {
      if (event) {
        this.$emit(event)
      }
      if (dialogKey) {
        this.dialogs[dialogKey] = !this.dialogs[dialogKey]
      }
      if (route) {
        this.$router.push({
          name: route,
          query: { customerId: this.customer.customerId }
        })
      }
      if (href) {
        window.open(`${this.ordersBaseUri}/${this.banner}/quote?customerId=${this.customer.accountId}`)
      }
    }
  }
}
</script>
