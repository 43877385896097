import store from '../store'
import { identityService } from '@/features/identity'
import { getGlobalCustomerId } from '@/api/bannerSiteLogin'
import { fetchCustomer } from '@/api/customers'
import { bannerFromId, checkRouteMeta } from '@/globals/utils'
import { validBanners } from '@/globals/constants'
import { NavigationGuard, NavigationGuardNext, Route } from 'vue-router'

// Note: Refactored out of previous beforeEnter mess
// Future Note:This sort of stuff should probably exist as an abstraction.
//    Per-route data fetching can easily grow out of control if it needs to be applied to more routes
const handleCustomerRoute = async (to: Route, next: NavigationGuardNext<Vue>, userBanner: string) => {
  try {
    const customerIdResponse = await getGlobalCustomerId(to.query.custacct)
    const customerResponse = await fetchCustomer(customerIdResponse.data)

    if (!customerResponse?.data) {
      throw Error('Customer not found')
    }

    next({
      path: `/${userBanner}/customer/${customerIdResponse.data}`,
      params: {
        banner: bannerFromId(customerResponse.data.companyId)
      },
      replace: true
    })
  } catch (error) {
    store.commit('setToastMessage', {
      message: 'Customer number ' + to.query.custacct + ' could not be found for this banner',
      status: 'error'
    })
  }
}

const beforeEnter: NavigationGuard = async (to, from, next) => {
  try {
    // If identity has not bee initialized, we will wait for it to be done
    if (!identityService.initialized) {
      await identityService.initializationPromise
    }

    const userBanner = identityService.selectedBanner
    const bannerParam = to.params.banner

    if (to.query.custacct) {
      await handleCustomerRoute(to, next, userBanner)
    } else if (to.name === 'root' || to.name === 'base' || userBanner !== bannerParam) {
      const availableBanners = identityService.isViewingAs
        ? [userBanner]
        : identityService.identityProfile.availableBanners

      const validUrlBanner = validBanners.includes(bannerParam)
      const userHasAccessToBanner = availableBanners.includes(bannerParam)
      const nonBannerPathExists = Boolean(to.path
        .split('/')
        .filter(s => validUrlBanner ? s !== bannerParam : s)
        .filter(Boolean)
        .length)

      if (!validUrlBanner && userBanner) {
        next({
          path: `/${userBanner}${nonBannerPathExists ? to.path : '/dashboard'}`,
          replace: true
        })
      } else if (validUrlBanner && userHasAccessToBanner && nonBannerPathExists && bannerParam !== userBanner) {
        identityService.updateSelectedBanner(bannerParam, true)
      } else {
        next({
          name: 'dashboard',
          query: to.query,
          params: {
            ...to.params,
            banner: validUrlBanner && userHasAccessToBanner ? bannerParam : userBanner
          },
          replace: true
        })
      }
    } else {
      next()
    }
  } catch (error) {
    console.error('Exception occurred during route beforeEnter: ', error)
    next()
  }
}

// load user and route protection
const beforeEach: NavigationGuard = async (to, from, next) => {
  // set last route before entering new route
  store.dispatch('setLastRoute', from)

  // skip if logging in
  if (checkRouteMeta(to, 'auth')) {
    next()
  } else if (checkRouteMeta(to, 'base')) { // only need to wait for user on base route
    // If identity has not been initialized, we will wait for it to be done
    if (!identityService.initialized) {
      await identityService.initializationPromise
    }

    store.dispatch('authenticateRoute', { to, from, next })
  }
  // If the banner in the URL changes without using the banner switcher
  // (i.e. by using fwd/back buttons), update the selected banner in vuex
  if (to.params.banner !== store.getters['user/banner']) {
    identityService.updateSelectedBanner(to.params.banner, true)
  }

  if (to.params.banner === 'capitol-light' && to.name === 'storefront-accounts') {
    next('/customers')
    return
  }
  next()
}

const afterEach: NavigationGuard = async (to, from) => {
  const appTitle = store.getters['user/banner']
    ? 'Sales Central'
    : 'Sales'
  store.commit('setTitle', to.meta?.title || '')
  if (to.meta?.title) {
    store.dispatch('setHtmlTitle', `${appTitle} - ${to.meta.title}`)
  } else if (to.name === 'AuthCallback') {
    store.dispatch('setHtmlTitle', `${appTitle} - ${'Authorizing'}`)
  } else {
    store.dispatch('setHtmlTitle', `${appTitle}`)
  }
}

export {
  beforeEnter,
  beforeEach,
  afterEach
}
