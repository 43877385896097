import { AVAILABLE_BANNERS } from './constants'
import { RawUserRole } from './models/rawUserRole'
import { RawAuthProfileSchema } from './models/rawAuthProfileSchema'
import { UserRole } from './models/userRole'
import { bannerProvider } from '../banners'

/** Retrieves the appropriate, numeric, banner id from the provided banner code/name */
const getBannerId = (banner: string) => {
  return bannerProvider.tryGetId(banner)
}

/** Retrieves the appropriate, string, banner name from the provided numeric banner Id. Throws if banner does not exist */
const getBannerName = (bannerId: number) => {
  return bannerProvider.getCode(bannerId)
}

/** Retrieves an array of banners from the user's raw identity profile */
const parseBannersFromIdentity = (profile: RawAuthProfileSchema): string[] => {
  const includeBanners: Set<string> = new Set(AVAILABLE_BANNERS)
  const profileRoles = getJwtRolesArray(profile)
  const uniqueBanners = new Set<string>([profile.banner])

  profileRoles.forEach((role) => {
    if (!role || !role.banners || role.banners.length === 0) return

    role.banners.forEach((banner) => {
      if (includeBanners.has(banner) && !uniqueBanners.has(banner)) {
        uniqueBanners.add(banner)
      }
    })
  })

  return Array.from(uniqueBanners.keys())
}

const parseRolesFromIdentity = (profile: RawAuthProfileSchema) => {
  const profileRoles = getJwtRolesArray(profile)
  const roles: UserRole[] = [] // Final output

  profileRoles.map((role) => {
    if (role) {
      roles.push({
        apps: role.apps || [],
        level: role.level || null,
        name: role.name || null,
        userTypeId: role.userTypeId || null,
        locations: role.locations || [],
        banners: role.banners || []
      })
    }
    return role
  })

  return roles
}

/** Returns A JSON parsed array from the 'role' prop of the JWT */
const getJwtRolesArray = (profile: RawAuthProfileSchema): RawUserRole[] => {
  let jwtStrRoles: string[] = [] // RAW JSON string array

  // role is an escaped JSON string or an array of escaped JSON strings
  if (typeof profile.role === 'string') {
    jwtStrRoles = [profile.role]
  } else {
    jwtStrRoles = profile.role
  }

  return jwtStrRoles.map((strRole) => {
    return JSON.parse(strRole)
  })
}

export {
  getBannerId,
  getBannerName,
  parseBannersFromIdentity,
  parseRolesFromIdentity,
  getJwtRolesArray
}
