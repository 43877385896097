<template>
  <div
    class="customer-summary-sales-breakdown d-flex justify-center align-center"
    :class="{ 'text-left' : $vuetify.breakpoint.smAndDown }">
    <v-container
      fluid
      :class="{'px-0': $vuetify.breakpoint.smAndDown}">
      <v-row
        v-for="(sales, index) in salesBreakdown"
        :key="index"
        :class="$vuetify.breakpoint.smAndDown && index <= 0 ? 'mb-3' : ''"
        class="customer-summary-sales__row">
        <v-col
          cols="12"
          lg="7"
          xl="8"
          class="py-0">
          {{ sales.label }}:
          <span class="font-weight-bold">
            {{ sales.last365 }}
          </span>
        </v-col>
        <v-col
          cols="12"
          lg="5"
          xl="4"
          class="py-0">
          <v-row
            no-gutters>
            <v-col>
              <span class="mr-2">
                <trend-arrow
                  small
                  :color="sales.color"
                  :delta="sales.delta">
                </trend-arrow>
              </span>
              <span :class="`${sales.color}--text`">
                {{ sales.trend }}% YOY Growth
              </span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import TrendArrow from '@/components/TrendArrow.vue'
import { formatMoney } from '@/globals/formatters'

export default {
  name: 'CustomerSummarySales',
  components: {
    TrendArrow
  },
  props: {
    customer: {
      type: Object,
      required: true
    }
  },
  computed: {
    salesBreakdown () {
      const { last365, yoyGrowth, yoyGrowthDigital, totalSalesLast365Digital } = this.customer
      return [
        {
          delta: Number(yoyGrowth),
          label: 'Total Sales Last 365',
          last365: formatMoney(last365),
          trend: (yoyGrowth ?? 0).toFixed(1),
          color: yoyGrowth >= 0 ? 'success' : 'error'
        },
        {
          delta: Number(yoyGrowthDigital),
          label: 'Total Digital Sales Last 365',
          last365: formatMoney(totalSalesLast365Digital),
          trend: (yoyGrowthDigital ?? 0).toFixed(1),
          color: yoyGrowthDigital >= 0 ? 'success' : 'error'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~styl/colors';

.customer-summary-sales-breakdown {
  border: 1px solid $pastelGray;
  padding: 1rem;
  width: 100%;
  font-size: 14px;
}

</style>
