<template>
  <v-row class="mx-0">
    <v-list-item-title>
      {{ item.text }}
    </v-list-item-title>
    <v-list-item-subtitle>
      <span
        v-if="$hasFeature('Customer_ShipToBillTo')"
        class="font-italic">
        {{ shipToBillToStatus }}
      </span>
      <span class="ml-2">
        {{ `${customer.branchName}` }} -
      </span>
      <span
        v-if="$vuetify.breakpoint.smAndUp"
        class="ml">
        {{ customer.address.address1 }}
      </span>
    </v-list-item-subtitle>
  </v-row>
</template>
<script>

export default {
  name: 'CustomerItemSlot',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    customer () {
      return this.item.value
    },
    shipToBillToStatus () {
      const statuses = []
      const { billToFlag, shipToFlag, jobAccountFlag } = this.customer
      if (shipToFlag) {
        statuses.push('Ship-To')
      }
      if (billToFlag) {
        statuses.push('Bill-To')
      }
      if (jobAccountFlag) {
        statuses.push('Job Account')
      }
      return `(${statuses.join(', ')})`
    }
  }
}
</script>
<style scoped>
/* .fill-width {
  max-width: 100%;
} */
</style>
