<template>
  <v-card class="customer-summary">
    <page-loading-progress v-if="loading">
    </page-loading-progress>
    <v-card-text
      v-else
      fluid
      class="py-6">
      <v-row
        :class="{'pa-4': $vuetify.breakpoint.xs}">
        <v-col
          cols="12"
          sm="6"
          md="7"
          lg="6"
          class="customer-summary__left">
          <v-row no-gutters>
            <v-col
              cols="11"
              lg="12">
              <customer-summary-info
                :summary-customer="summaryCustomer"
                :linked="linked"
                @bookmarkAction="bookmarkAction">
                <template
                  v-if="$vuetify.breakpoint.smAndUp"
                  #badges>
                  <div
                    class="badges mt-2">
                    <customer-summary-badges
                      :customer="summaryCustomer"
                      :platt-customer="isPlattBanner">
                    </customer-summary-badges>
                  </div>
                </template>
              </customer-summary-info>
            </v-col>
            <v-col
              v-if="$vuetify.breakpoint.xs"
              class="d-flex justify-end"
              cols="1">
              <customer-summary-menu
                :show-edit-url-option="allowEditsToUrl"
                :customer="summaryCustomer">
              </customer-summary-menu>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="5"
          lg="6"
          class="customer-summary__right d-flex align-center justify-end"
          :class="{'pa-0': $vuetify.breakpoint.smAndDown}">
          <optional-expansion-panel
            flat
            bottom
            :enabled="$vuetify.breakpoint.xs"
            class="customer-summary__expansion-panel">
            <customer-summary-sales
              v-if="!isCustomerPresent"
              :customer="customer">
            </customer-summary-sales>
            <div
              v-if="$vuetify.breakpoint.xs"
              class="badges mt-2">
              <customer-summary-badges
                :customer="summaryCustomer"
                :platt-customer="isPlattBanner">
              </customer-summary-badges>
            </div>
          </optional-expansion-panel>
          <div
            v-if="$vuetify.breakpoint.smAndUp"
            class="menu-wrapper ml-2">
            <customer-summary-menu
              :show-edit-url-option="allowEditsToUrl"
              :customer="summaryCustomer">
            </customer-summary-menu>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import PageLoadingProgress from '../PageLoadingProgress.vue'
import CustomerSummaryMenu from './CustomerSummaryMenu.vue'
import CustomerSummaryBadges from '@/components/CustomerSummary/CustomerSummaryBadges.vue'
import CustomerSummaryInfo from '@/components/CustomerSummary/CustomerSummaryInfo.vue'
import CustomerSummarySales from '@/components/CustomerSummary/CustomerSummarySales.vue'
import OptionalExpansionPanel from '@/components/OptionalExpansionPanel.vue'

export default {
  name: 'CustomerSummary',
  components: {
    PageLoadingProgress,
    CustomerSummaryBadges,
    CustomerSummaryMenu,
    CustomerSummaryInfo,
    CustomerSummarySales,
    OptionalExpansionPanel
  },
  props: {
    linked: {
      type: Boolean,
      required: false,
      default: false
    },
    customerId: {
      type: [String, Number],
      required: false,
      default: null
    },
    customer: {
      type: Object,
      required: false,
      default: () => {}
    },
    allowEditsToUrl: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      loading: true,
      summaryCustomer: {},
      comapnyLogo: ''
    }
  },
  computed: {
    ...mapGetters('user', ['user', 'banner', 'isPlattBanner']),
    ...mapState(['isCustomerPresent'])
  },
  watch: {
    customer: {
      immediate: true,
      handler (val) {
        this.summaryCustomer = {}
        this.loading = true
        if (val) {
          this.summaryCustomer = val
          this.$emit('loaded')
          this.loading = false
        }
      }
    },
    customerId: {
      immediate: true,
      async handler (val) {
        if (this.customer && this.customer.customerId) {
          return
        }
        this.summaryCustomer = {}
        this.loading = true
        if (val) {
          const customer = await this.getCustomer(val)
          this.summaryCustomer = customer
          this.$emit('loaded')
          this.loading = false
        }
      }
    }
  },
  methods: {
    ...mapActions('currentCustomer', ['setFollowed']),
    ...mapActions('customers', ['getCustomer']),
    bookmarkAction () {
      const { customerId, isFollowed } = this.summaryCustomer
      this.setFollowed({ customerId, isFollowed: !isFollowed })
      this.summaryCustomer.isFollowed = !isFollowed
    },
    goToCustomerWebsite (profileLink) {
      if (profileLink || !this.summaryCustomer.website) {
        return
      }
      const webAddress = this.summaryCustomer.website.startsWith('http') ? this.summaryCustomer.website : `https://${this.summaryCustomer.website}`
      window.open(webAddress, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~styl/colors';
.customer-summary__expansion-panel {
  width: 100%;
  >>> .v-expansion-panel-content__wrap {
    padding: 0;
  }
}
.menu-wrapper {
  height: 100%;
}
.website-link {
  text-decoration: underline;
  cursor: pointer;
}
</style>
