const Customers = () => import('@/pages/Customers/Customers.vue')
const CreateCustomerAccount = () => import('@/pages/CreateCustomerAccount/CreateAccount.vue')

const CustomerLayout = () => import('@/pages/customer/CustomerLayout.vue')
const Contacts = () => import('@/pages/Contacts.vue')
const CreatePim = () => import('@/pages/CreatePim.vue')
const CustomerOrders = () => import('@/pages/CustomerOrders.vue')
const CustomerOrderWriters = () => import('@/pages/CustomerOrderWriters.vue')
const CustomerProfile = () => import('@/pages/CustomerProfile/CustomerProfile.vue')
const MissionAndStrategies = () => import('@/pages/MissionAndStrategies.vue')
const PimDetail = () => import('@/pages/PimDetail.vue')
const SearchTrends = () => import('@/pages/SearchTrends.vue')
const CustomerJobAccounts = () => import('@/pages/CustomerJobAccounts.vue')
const StoreFrontAccounts = () => import('@/pages/StoreFrontAccounts.vue')
const StoreFrontPims = () => import('@/pages/StoreFrontPims.vue')

/*
  Routes for Customer pages
*/

export const customerRoutes = [
  {
    path: 'customers',
    name: 'customers',
    meta: { title: 'Customers' },
    props: (route) => {
      let filters = []
      const filtersFromQuery = route.query.filters
      if (Array.isArray(filtersFromQuery)) {
        filters = filtersFromQuery
      } else if (filtersFromQuery != null) { // a single value in filters will be read as a string
        filters.push(filtersFromQuery)
      }
      return {
        initialSelected: filters
      }
    },
    component: Customers
  },
  {
    path: 'customers/create',
    name: 'create-customer',
    component: CreateCustomerAccount,
    meta: {
      title: 'Create Customer'
    }
  },

  {
    // redirect route for old URLs pointed at customer/:id
    path: 'customer/:customerId',
    redirect: 'customers/:customerId'
  },
  {
    path: 'customers',
    component: CustomerLayout,
    meta: { title: 'Customers' },
    children: [
      {
        path: ':customerId',
        name: 'customer-profile',
        component: CustomerProfile,
        meta: {
          title: 'Customer'
        }
      },
      {
        path: ':customerId/contacts',
        name: 'contacts',
        component: Contacts,
        meta: {
          title: 'Contacts'
        }
      },
      {
        path: ':customerId/goals-and-strategies',
        redirect: 'mission-and-strategies'
      },
      {
        path: ':customerId/mission-and-strategies',
        name: 'mission-and-strategies',
        component: MissionAndStrategies,
        meta: {
          title: 'Mission And Strategies'
        }
      },
      {
        path: ':customerId/orders',
        name: 'customer-orders',
        component: CustomerOrders,
        meta: {
          title: 'Customer Orders'
        }
      },
      {
        path: ':customerId/order-writers',
        name: 'customer-order-writers',
        component: CustomerOrderWriters,
        meta: {
          title: 'Customer Order Writers'
        }
      },
      {
        path: ':customerId/customer-job-accounts',
        name: 'customer-job-accounts',
        component: CustomerJobAccounts,
        meta: {
          title: 'Customer Job Accounts'
        }
      },
      {
        path: ':customerId/pim',
        name: 'pim-management',
        component: StoreFrontPims,
        meta: {
          title: 'Pims',
          plattUserOnly: true,
          allowSpecialPim: true
        }
      },
      {
        path: ':customerId/pim/create',
        name: 'create-pim',
        component: CreatePim,
        meta: {
          title: 'Create Pim',
          plattUserOnly: true,
          allowSpecialPim: true
        }
      },
      {
        path: ':customerId/pim/:pimId',
        name: 'pim-detail',
        component: PimDetail,
        meta: {
          title: 'Web Pim Detail',
          plattUserOnly: true,
          allowSpecialPim: true
        }
      },
      {
        path: ':customerId/search-trends',
        name: 'search-trends',
        component: SearchTrends,
        meta: {
          title: 'Search Trends'
        }
      },
      {
        path: ':customerId/storefront-accounts',
        name: 'storefront-accounts',
        component: StoreFrontAccounts,
        meta: {
          title: 'Web Accounts'
        }
      }
    ]
  }
]
