<template>
  <div>
    <v-tooltip
      v-for="{ label, icon, tooltipText, color } in shownBadges"
      :key="label"
      :disabled="disableTooltips || $vuetify.breakpoint.smAndDown || !tooltipText"
      max-width="300"
      bottom>
      <template #activator="{ on }">
        <v-chip
          small
          label
          :color="color"
          class="badge no-border-radius ma-1"
          v-on="on">
          <v-icon dark>
            {{ icon }}
          </v-icon>
          <span class="white--text pl-2">
            {{ label }}
          </span>
        </v-chip>
      </template>

      <span>{{ tooltipText }}</span>
    </v-tooltip>
    <customer-at-risk-badge
      v-if="isAtRisk"
      :customer-id="customer.customerId"
      :customer-name="customer.name"
      :branch-name="customer.branchName"
      :branch-telephone="customer.branchTelephone"
      :at-risk-types="customerAtRiskTypes"
      :user-name="userName"
      :user-email="user.email">
    </customer-at-risk-badge>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CustomerAtRiskBadge from './CustomerAtRiskBadge.vue'

export default {
  name: 'CustomerSummaryBadges',
  components: {
    CustomerAtRiskBadge
  },
  props: {
    disableTooltips: {
      type: Boolean,
      required: false,
      default: false
    },
    customer: {
      type: Object,
      required: true
    },
    plattCustomer: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters('user', ['user']),
    userName () {
      if (this.user) {
        return `${this.user.firstName} ${this.user.lastName}`
      }
      return ''
    },
    shownBadges () {
      return this.badges.filter((badge) => this.customer[badge.isCheck])
    },
    badges () {
      return [
        {
          label: 'Plus',
          icon: 'add',
          tooltipText: 'Customer has signed in to their web account in the last 6 months. This can take overnight to show on the app. It does not count when logged in through the sales app, or if the customer is logged on through a branch computer.',
          isCheck: 'isPlattPlus',
          color: 'primary'
        },
        {
          label: 'PIM',
          icon: 'check',
          tooltipText: 'Customer participates in Platt In Motion program.',
          isCheck: 'isPim',
          color: 'primary'
        },
        {
          label: 'Key',
          icon: 'vpn_key',
          tooltipText: 'An established account that contributes significantly to branch sales.',
          isCheck: 'isKeyCustomer',
          color: 'primary'
        },
        {
          label: 'Target',
          icon: 'adjust',
          tooltipText: 'An account the branch focuses on to develop the relationship and grow business.',
          isCheck: 'isTargetCustomer',
          color: 'primary'
        },
        {
          label: 'Development',
          icon: 'insert_chart_outlined',
          tooltipText: 'An account that is not a Key or Target customer and is also not assigned to a sales person.',
          isCheck: 'isDevelopmentCustomer',
          color: 'primary'
        },
        {
          label: 'Credit Account',
          icon: 'adjust',
          tooltipText: 'Customer has a line of credit with Platt.',
          isCheck: 'isCreditAccount',
          color: 'primary'
        },
        {
          label: 'Tax Exempt',
          icon: 'do_not_disturb_alt',
          isCheck: 'taxExemptFlag',
          color: 'primary'
        },
        {
          label: this.priceClassBadgeLabel,
          icon: 'attach_money',
          tooltipText: this.customer.priceClassDescription,
          isCheck: 'priceClass',
          color: 'primary'
        },
        {
          label: this.secondaryClassBadgeLabel,
          icon: 'radio_button_checked',
          tooltipText: this.customer.secondaryClass,
          isCheck: 'secondaryClass',
          color: 'primary'
        },
        {
          label: 'Gray Market',
          icon: 'receipt',
          tooltipText: 'Customer is a Gray Market Reseller',
          isCheck: 'grayMarketReseller',
          color: '#999999'
        }
      ]
    },
    isAtRisk () {
      return (this.customer.isAtRisk || this.customer.isAtHighRisk) || false
    },
    customerAtRiskTypes () {
      const atRiskTypes = []
      if (this.customer.isAtHighRisk) {
        atRiskTypes.push('At High Risk (Churn)')
      }
      if (this.customer.isAtRiskByDollars) {
        atRiskTypes.push('Dollar')
      }
      if (this.customer.isAtRiskByOrders) {
        atRiskTypes.push('Order Count')
      }
      if (this.customer.isAtRiskByWebLogin) {
        atRiskTypes.push('Web Account')
      }
      if (this.customer.isAtRiskBySKUs) {
        atRiskTypes.push('SKUs')
      }
      return atRiskTypes
    },
    priceClassBadgeLabel () {
      if (this.plattCustomer) {
        return `Discount Profile: ${this.customer.priceClass}`
      }
      return `Price Class: ${this.customer.priceClass}`
    },
    secondaryClassBadgeLabel () {
      return `Secondary Class: ${this.customer.secondaryClass}`
    }
  }
}
</script>

<style scoped>
.badge.v-chip {
  height: 28px;
}
</style>
