<template>
  <div class="sidebar-navigation-local">
    <sidebar-navigation-global
      :open-nav="openNav"
      :user-data="userData"
      :links="filteredLinks"
      primary-class="accent"
      @openNav="emitOpenNav">
      <template #bannerSwitcher>
        <banner-switcher v-if="!isViewingAs">
        </banner-switcher>
      </template>
    </sidebar-navigation-global>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { mapGetters } from 'vuex'
import SidebarNavigationGlobal from '@/layout/SidebarNavigationGlobal/SidebarNavigationGlobal.vue'
import BannerSwitcher from '@/layout/BannerSwitcher.vue'
import { NavLink } from '@/features/sideNav'
import { filterByPermissions } from '@/features/authorization/utilities'
import { UserIdentity } from '@/features/identity/models/userIdentity'
import { bannerFeatureProvider } from '@/features/authorization/bannerFeatureProvider'

const taskAddIcon = require('@/assets/icons/task_add.svg')

export default Vue.extend({
  name: 'SidebarNavigationLocal',
  components: {
    SidebarNavigationGlobal,
    BannerSwitcher
  },
  props: {
    openNav: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      myProfileIframeIsOpen: false
    }
  },
  computed: {
    ...mapGetters('user', ['user', 'banner', 'isViewingAs']),
    ...mapGetters(['ordersBaseUri']),
    userData (this: { user: UserIdentity, $options: { filters: { ellipsis: (arg0: string, arg1: number) => string } }}) {
      const { branchName, imageUrl, picture, firstName, lastName } = this.user as UserIdentity

      // BranchName can be null.
      // TODO future: Implement consistent IdentityService that handles details like this (Ensuring it's an empty string instead of null)
      const branch: string = branchName?.length > 0 ? this.$options?.filters?.ellipsis(branchName, 20) : '' ?? ''

      return {
        branch,
        imageUrl: imageUrl || picture,
        name: `${firstName} ${lastName}`
      }
    },
    links (this: { banner: string, ordersBaseUri: string }) {
      // eslint-disable-next-line no-unused-vars
      const banner = this.banner // Kludge that plugs this computed into the reactivity system

      const links: NavLink[] = [
        {
          text: 'Dashboard',
          icon: 'timeline',
          value: { name: 'dashboard' },
          perms: bannerFeatureProvider.getBannerFeature('Nav_Dashboard'),
          qa: 'nav_dashboard'
        },
        {
          text: 'Customers',
          icon: 'people',
          value: { name: 'customers' },
          perms: bannerFeatureProvider.getBannerFeature('Nav_Customers'),
          qa: 'nav_customers'
        },
        {
          text: 'Web Users',
          icon: 'person',
          value: { name: 'users' },
          perms: bannerFeatureProvider.getBannerFeature('Nav_Customers'),
          qa: 'nav_users'
        },
        {
          text: 'Leads',
          icon: 'person_search',
          value: { name: 'leads' },
          perms: bannerFeatureProvider.getBannerFeature('Nav_Leads'),
          qa: 'nav_leads'
        },
        {
          text: 'Quotes',
          icon: 'check_circle',
          staticUrl: this.ordersBaseUri as string,
          perms: bannerFeatureProvider.getBannerFeature('Nav_Quotes'),
          qa: 'nav_quotes'
        },
        {
          text: 'Sales Planning',
          icon: 'attach_money',
          value: { name: 'sales-planning' },
          perms: {
            ...bannerFeatureProvider.getBannerFeature('Feature_SalesPlanning'),
            userTypes: ['AsstMgr', 'Branch', 'Mgr', 'RVP', 'Sales']
          },
          qa: 'nav_salesPlanning'
        },
        {
          text: 'Opportunity',
          icon: 'thumb_up',
          value: { name: 'opportunities' },
          perms: bannerFeatureProvider.getBannerFeature('Nav_Opportunities'),
          qa: 'nav_opportunity'
        },
        {
          text: 'Activities',
          icon: 'assignment_turned_in',
          value: { name: 'activities' },
          perms: bannerFeatureProvider.getBannerFeature('Nav_Activities'),
          qa: 'nav_activities'
        },
        {
          text: 'Tasks',
          customIcon: taskAddIcon,
          value: { name: 'tasks' },
          perms: bannerFeatureProvider.getBannerFeature('Feature_Tasks'),
          qa: 'nav_tasks'
        },
        {
          text: 'Reports',
          icon: 'find_in_page',
          value: { name: 'reports' },
          perms: bannerFeatureProvider.getBannerFeature('Nav_Reports'),
          qa: 'nav_reports'
        },
        {
          text: 'Customer Map',
          icon: 'map',
          value: { name: 'customer-status-map' },
          perms: bannerFeatureProvider.getBannerFeature('Nav_CustomerStatusMap'),
          qa: 'nav_customerMap'
        },
        {
          text: 'People',
          icon: 'people',
          value: { name: 'people' },
          perms: bannerFeatureProvider.getBannerFeature('Nav_People'),
          qa: 'nav_people'
        }
      ]

      return links
    },
    filteredLinks (): NavLink[] {
      return filterByPermissions(this.links)
    }
  },
  methods: {
    emitOpenNav (value: boolean) {
      this.$emit('openNav', value)
    }
  }
})
</script>

<style scoped>

</style>
