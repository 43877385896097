<template>
  <base-dialog
    :max-width="maxWidth"
    :show="show"
    :valid="valid && hasChanges"
    @close="close"
    @submit="submit"
    @keydown.esc="close">
    <template slot="title">
      Add URL
    </template>
    <v-form
      ref="form"
      slot="content"
      v-model="valid"
      spellcheck="false">
      <v-textarea
        v-model="website"
        v-required
        rows="2"
        auto-grow
        filled
        :label="label"
        :rules="rules">
      </v-textarea>
    </v-form>
  </base-dialog>
</template>

<script>
import { maxLength, isUrl } from '@/rules'
import BaseDialog from '@/components/BaseDialog.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'RequestUpdateUrl',
  components: {
    BaseDialog
  },
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      rules: [maxLength(100, 'Maximum 100 characters allowed'), isUrl('Must be a valid URL')],
      valid: false,
      label: 'Customer Website URL',
      website: '',
      initialValue: '',
      maxWidth: '640px'
    }
  },
  computed: {
    ...mapGetters('currentCustomer', ['customerWebsite', 'customerId']),
    hasChanges () {
      return this.website !== this.initialValue
    }
  },
  mounted () {
    this.website = this.customerWebsite ? this.customerWebsite.trim() : ''
    this.initialValue = this.customerWebsite ? this.customerWebsite.trim() : ''
  },
  methods: {
    ...mapActions('currentCustomer', ['updateCustomerWebsite']),
    ...mapActions('customers', ['updateCachedCustomer']),
    async submit () {
      if (this.$refs.form.validate()) {
        const { errors } = await this.updateCustomerWebsite({ customerId: this.customerId, url: this.website.trim() })
        if (!errors) {
          this.website = this.customerWebsite
          this.initialValue = this.customerWebsite
          this.updateCachedCustomer(this.customerId)
          this.close()
        }
      }
    },
    close () {
      this.$emit('update:show', false)
    }
  }
}
</script>
