import { Banner } from '@/features/banners'
import { identityService } from '@/features/identity'
import { BannerFeatureFlagName } from './constants'
import { AccessPerms, BannerFeatureFlag } from './models'
import { LogType, ParsedStackTrace, StackPartType, debugWriter } from './debugHelper'

// Secondary, design-time flag for toggling debug mode for just this
const DEBUG_LOGGING = false

class BannerFeatureProvider {
  private _flags: BannerFeatureFlag[] = []
  private _flagsHash: Record<BannerFeatureFlagName | string, Record<Banner | string, boolean>> = {} // O(1) lookups for flags, gotta go fast

  get flags () {
    return this._flags
  }

  get flagsHash () {
    return this._flagsHash
  }

  public init (flags: BannerFeatureFlag[]) {
    this._flags = flags

    flags.forEach(bannerFeatureFlag => {
      this._flagsHash[bannerFeatureFlag.featureFlag] = {}

      if (bannerFeatureFlag.banners) {
        bannerFeatureFlag.banners.forEach((banner) => {
          this._flagsHash[bannerFeatureFlag.featureFlag][banner] = true
        })
      }
    })
  }

  /** Gets an array of banners for the provided flag */
  public getBannerFeature (feature: BannerFeatureFlagName): AccessPerms {
    if (!feature) return {}
    if (!(feature in this._flagsHash)) return {}

    return this._flags.find((x) => x.featureFlag === feature) ?? {}
  }

  public hasBannerFeature = (feature: BannerFeatureFlagName, debug: boolean = false) => {
    if (DEBUG_LOGGING || debug) {
      return this.hasBannerFeatureDebug(feature)
    }

    if (!feature) return false
    if (!(feature in this._flagsHash)) return false

    const user = identityService.identityProfile
    const userBanner = user.getDefinitiveBanner()

    if (!(userBanner in this._flagsHash[feature])) {
      return false
    }

    return this._flagsHash[feature][userBanner]
  }

  public hasBannerFeatureDebug = (feature: BannerFeatureFlagName) => {
    if (!feature) {
      debugWriter.debugLog(LogType.Error, feature, 'No Feature Provided')
      return false
    }

    if (!(feature in this._flagsHash)) {
      debugWriter.debugLog(LogType.Error, feature, 'Feature not found')
      return false
    }

    const user = identityService.identityProfile
    const userBanner = user.getDefinitiveBanner()

    if (!(userBanner in this._flagsHash[feature])) {
      debugWriter.debugLog(LogType.Warning, feature, '', `User's Banner ${userBanner} does not have access to this feature`)
      return false
    }

    if (!this._flagsHash[feature][userBanner]) {
      debugWriter.debugLog(LogType.Warning, feature, '', `User's Banner ${userBanner} does not have access to this feature`)
      return false
    } else {
      debugWriter.debugLog(LogType.Positive, feature, '', `User's Banner ${userBanner} has access to this feature`)
      return true
    }
  }
}

const bannerFeatureProvider = new BannerFeatureProvider()

export {
  bannerFeatureProvider
}
