<template>
  <v-icon v-bind="{ small, color }">
    {{ icon }}
  </v-icon>
</template>

<script>
export default {
  name: 'TrendArrow',
  props: {
    small: {
      type: Boolean,
      required: false,
      default: false
    },
    color: {
      type: String,
      required: false,
      default: 'black'
    },
    delta: {
      required: true,
      validator: (p) => {
        return ['string', 'number', 'null'].includes(typeof p)
      },
      default: null
    }
  },
  data () {
    return {}
  },
  computed: {
    deltaNumber () {
      if (this.delta === null) return 0
      if (typeof this.delta === 'number') return this.delta
      if (this.delta.charAt(0) === '$') return this.delta.replace(/[^0-9.-]+/g, '')
      return parseFloat(this.delta)
    },
    icon () {
      if (this.deltaNumber > 0) return 'trending_up'
      if (this.deltaNumber < 0) return 'trending_down'
      return 'trending_flat'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~styl/colors';

.delta {
  color: $black;
}
</style>
