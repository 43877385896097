import { bannerProvider } from '@/features/banners'

export const customerCompanyId = ({ customer }) => customer.companyId

export const customerId = ({ customer }) => {
  return customer ? customer.customerId : null
}

export const customerWebsite = ({ customer }) => {
  return customer ? customer.website : null
}

export const isParentAccount = ({ customer }) => {
  if (!customer) return null

  const { isEnterprise, associatedAccounts } = customer
  return isEnterprise || (associatedAccounts && associatedAccounts.length > 0)
}

export const customerBanner = ({ customer }) => {
  const { companyId } = customer

  return bannerProvider.tryGetCode(companyId)
}

export const customerShortTitle = ({ customer }) => {
  if (!customer) {
    return ''
  }

  // If there's both name and number show both, otherwise show whichever one is present.
  const { accountId, companyCustomerNumber, customerName, name: parentName } = customer
  const number = companyCustomerNumber || accountId
  let name = parentName || customerName
  if (name && name.length > 22) {
    name = name.substring(0, 20) + '...'
  }
  return number && name
    ? `${name} (${number})`
    : name || number
}

export const salesDirectData = (state) => {
  const {
    previousCalendarYearSalesDirect,
    previousCalendarYearMarginPctDirect,
    salesGoalDirect,
    gmGoalDirect
  } = state.customer
  return {
    previousCalendarYearSalesDirect,
    previousCalendarYearMarginPctDirect,
    salesGoalDirect,
    gmGoalDirect
  }
}

export const salesStockData = (state) => {
  const {
    previousCalendarYearSalesStock,
    previousCalendarYearMarginPctStock,
    salesGoalStock,
    gmGoalStock
  } = state.customer
  return {
    previousCalendarYearSalesStock,
    previousCalendarYearMarginPctStock,
    salesGoalStock,
    gmGoalStock
  }
}

export const activeCustomerType = state => state.activeCustomerType

export const billToCustomer = state => state.billTo

export const shipToCustomer = state => state.shipTo

export const withJobsCustomer = state => state.withJobs

export const allWebAccounts = state => state.allWebAccounts
