<template>
  <v-card
    tile
    flat
    :disabled="disabled"
    :class="{ 'active': selectedSync}"
    class="px-3 mt-0 row-item-card"
    @click="handleClick()">
    <v-row class="align-center my-0">
      <v-col
        cols="auto"
        class="hidden-xs-only">
        <v-checkbox
          v-model="selectedSync"
          :disabled="disabled"
          class="checkbox mt-0"
          hide-details
          @click="handleClick()">
        </v-checkbox>
      </v-col>
      <v-col
        cols="auto"
        class="hidden-xs-only mr-2">
        <v-avatar
          v-if="item.initials"
          size="40"
          class="primary elevation-4 customer-avatar-gradient">
          <span class="white--text">
            {{ item.initials }}
          </span>
        </v-avatar>
        <v-avatar
          v-else
          size="40">
          <img
            v-if="item.imageUrl"
            :src="item.imageUrl"
            :alt="`${entityType} image`" />
          <img
            v-else
            src="https://rexel-cdn.com/products/$%7Bp.catNum%7D.jpg?i=0EA7A6C5-7DD2-4482-9229-D628D3AFAE9D&f=80"
            :alt="`${entityType} image not found`" />
        </v-avatar>
      </v-col>
      <v-col cols="auto">
        <div class="mb-1 font-weight-bold text-body-2">
          {{ item.name }}
        </div>
        <div class="text-caption">
          <span
            v-if="item.extraText"
            class="grey--text text--darken-2">
            {{ item.extraText }}:
          </span> {{ item.extraValue }}
          <span :class="isMobile ? 'display-block' : 'pl-3'">
            <span
              v-if="item.address"
              class="grey--text text--darken-2">
              Address:
            </span> {{ item.address }}
          </span>
        </div>
      </v-col>
      <v-spacer></v-spacer>
      <v-col
        cols="auto"
        class="d-flex flex-column justify-end">
        <div
          v-if="!$vuetify.breakpoint.xs"
          class="text-body-2"
          style="font-weight: bold">
          {{ item.salesData | formatMoney }}
        </div>
        <v-icon v-else>
          arrow_forward
        </v-icon>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'SelectionTableItemCard',
  props: {
    item: {
      type: Object,
      required: true
    },
    selected: {
      type: Boolean,
      required: true
    },
    itemKeyProp: {
      type: String,
      required: true
    },
    entityType: { // The entity name of sorts
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
    }
  },
  computed: {
    selectedSync: {
      get: function () {
        return this.selected
      },
      set: function (value) {
        this.$emit('update:selected', value)
      }
    },
    key () {
      return this.item[this.itemKeyProp]
    },
    isMobile () {
      return this.$vuetify.breakpoint.xs
    }
  },
  methods: {
    handleClick () {
      if (this.selectedSync) {
        this.deselect()
      } else {
        this.select()
      }
    },
    select () {
      this.selectedSync = true
      this.$emit('selected')
    },
    deselect () {
      this.selectedSync = false
      this.$emit('deselected')
    }
  }
}
</script>
