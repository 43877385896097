const accountTab = (state) => {
  return state.accountTab
}

const customerId = (state) => {
  return state.customerId
}

const displayNonUpgradedModal = (state) => {
  return state.displayNonUpgradedModal
}

const nonUpgradedAccounts = (state) => {
  return state.nonUpgradedAccounts
}

const currentCustomerId = (state) => {
  return state.currentCustomerId
}

const webAccountsJson = (state) => {
  return state.webAccountsJson
}

export default {
  accountTab,
  customerId,
  displayNonUpgradedModal,
  nonUpgradedAccounts,
  currentCustomerId,
  webAccountsJson
}
