<template>
  <v-row
    class="pa-2">
    <v-col>
      <v-row>
        <template
          v-for="(header, index) in headers">
          <template v-if="header.type !== 'action' && header.type !== 'title'">
            <v-col
              v-if="header.slot"
              :key="index"
              cols="12">
              <slot
                :name="header.slot"
                :item="item">
              </slot>
            </v-col>
            <v-col
              v-else-if="header.text || header.value"
              :key="index"
              cols="12"
              sm="6">
              <div
                :class="$vuetify.breakpoint.smAndDown && 'subtitle-1'"
                class="grey--text text--darken-4">
                {{ header.text }}
              </div>
              <responsive-table-cell
                color="black--text"
                v-bind="{header, item, mobileBreakPoint}">
              </responsive-table-cell>
            </v-col>
          </template>
        </template>
      </v-row>
    </v-col>
    <v-col class="shrink d-flex align-center">
      <slot
        v-if="actionHeader && actionHeader.slot"
        :name="actionHeader.slot"
        :item="item">
      </slot>
      <v-spacer style="padding-bottom:100px">
      </v-spacer>
      <v-row
        v-if="clickableRows"
        justify="end">
        <v-col class="shrink">
          <v-icon
            style="font-size:40px"
            clickable
            @click="$emit('selectRow', item)">
            arrow_forward
          </v-icon>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import ResponsiveTableCell from './ResponsiveTableCell.vue'

export default {
  name: 'ResponsiveTableMobileLayout',
  components: {
    ResponsiveTableCell
  },
  props: {
    headers: {
      type: [Array],
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    mobileBreakPoint: {
      type: String,
      required: false,
      default: 'xs'
    },
    clickableRows: {
      type: Boolean,
      required: false,
      default: false
    },
    actionHeader: {
      type: Object,
      required: false,
      default: () => null
    }
  },
  data: () => ({}),
  computed: {
    titleHeader () {
      return this.headers.find(header => header.title)
    },
    nonTitleHeaders () {
      return this.headers.filter(header => !header.title)
    }
  }
}
</script>
