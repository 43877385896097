import Oidc, { UserManager, User } from 'oidc-client'
import store from '@/store'
import { AuthInitProps } from './models/authInitProps'
import { RawAuthProfileSchema } from './models/rawAuthProfileSchema'

// Refactored out of src/auth.js
// Which was monkey patching UserManager with custom behavior

/** Wrapper over the OIDC UserManager */
export class AuthManager {
  public userManager: UserManager
  public onUserLoadedCallbacks: Array<(user: User) => void> = []

  constructor (props: AuthInitProps) {
    this.userManager = new UserManager({
      authority: props.authority,
      client_id: props.clientId,
      redirect_uri: props.redirectUri,
      scope: props.scope,
      post_logout_redirect_uri: props.postLogoutRedirectUri,
      response_type: props.responseType,
      silent_redirect_uri: props.silentRedirectUri,
      staleStateAge: 300, // docs say default is 300 secs, actual default is 900 secs (15 mins) -- 300 seems better
      automaticSilentRenew: true,
      acr_values: 'idp:keycloak'
    })

    // Debugging, commented and not removed so future devs don't have to hunt for how to do this
    // Oidc.Log.logger = console
    // Oidc.Log.level = Oidc.Log.INFO

    // Register event listeners
    this.userManager.events.addUserLoaded((user) => this.onUserLoaded(user)) // <<< fired during user/auth reload
    this.userManager.events.addSilentRenewError(() => this.signInOrExpireSession())
    this.userManager.events.addUserSignedOut(() => this.signInOrExpireSession())

    this.userManager.clearStaleState()
  }

  /** Registers a new callback to handle userLoaded events from auth manager
   * This event occurs when auth has been refreshed, and should be handled to refresh user identity profile
   */
  public registerUserLoadedCallback (callback: (user: User) => void) {
    this.userManager.events.addUserLoaded(callback)
    this.onUserLoadedCallbacks.push(callback)
  }

  public signinRedirectCallback (url?: string | undefined) {
    return this.userManager.signinRedirectCallback(url)
  }

  public async signinRedirect (args?: any) {
    return await this.userManager.signinRedirect(args)
  }

  public async signoutRedirect (args?: any) {
    return await this.userManager.signoutRedirect(args)
  }

  public signinSilentCallback (url?: string | undefined) {
    this.userManager.signinSilentCallback(url)
  }

  public async signInOrExpireSession () {
    // Attach current path as state
    const { hash, pathname, search } = window.location
    const state = JSON.stringify(`${pathname}${search}${hash}`)

    try {
      return await this.userManager.signinRedirect({ state })
    } catch (error) {
      this.sessionExpired()
    }
  }

  public async getUser (): Promise<User | void> {
    const user = await this.userManager.getUser()
    if (!user || !user.access_token || user.expired) {
      return this.signInOrExpireSession()
    }

    // this.userManager.events.load(user)
    this.onUserLoaded(user)
    this.triggerOnUserLoadedCallbacks(user)

    return user
  }

  private async onUserLoaded (data: User) {
    await store.dispatch('setAccessToken', data.access_token)
  }

  private sessionExpired () {
    store.dispatch('setAccessToken', null)
  }

  private triggerOnUserLoadedCallbacks (user: User) {
    this.onUserLoadedCallbacks.forEach((callback) => {
      callback(user)
    })
  }
}
