import {
  fetchCustomerSalesPlans,
  fetchCustomerSalesPlan,
  saveCustomerSalesPlan,
  bulkSalesPlanUpdate
} from '@/api/salesPlanning'

const getSalesPlan = ({ commit }, { data }) => {
  if (!data) {
    return
  }
  commit('setSalesPlans', [data])
}

const getSalesPlans = async ({ commit }, myCustomersOnly) => {
  commit('setIsFetching', true)
  const { data, errors } = await fetchCustomerSalesPlans(myCustomersOnly)
  if (errors) return
  commit('setSalesPlans', data)
  commit('setIsFetching', false)
}

const getSalesPlansById = async ({ commit }, payload) => {
  const { customerId, customerType } = payload
  const { data, errors } = await fetchCustomerSalesPlan(customerId)
  if (errors || !data) return
  commit('setSalesPlans', { salesPlans: data, customerType })
}

const updateSalesPlan = async ({ commit }, updatedPlan) => {
  commit('setIsSaving', true)
  const { errors } = await saveCustomerSalesPlan(updatedPlan)
  if (errors) {
    return errors
  }

  commit('updateSalesPlan', updatedPlan)
  commit('setIsSaving', false)
}

const updateSalesPlanBulkSalesGoals = async ({ commit }, bulkSalesGoals) => {
  commit('setIsSaving', true)
  const { errors } = await bulkSalesPlanUpdate(bulkSalesGoals)
  if (errors) {
    return
  }
  commit('setIsSaving', false)
}

export default {
  getSalesPlan,
  getSalesPlans,
  getSalesPlansById,
  updateSalesPlan,
  updateSalesPlanBulkSalesGoals
}
