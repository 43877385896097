<template>
  <div>
    <v-card class="my-4">
      <v-card-text>
        <v-row>
          <v-col
            cols="10"
            sm="6"
            md="4">
            <v-text-field
              v-model="search"
              label="Search for Cat Name"
              hide-details
              append-icon="search"
              filled>
            </v-text-field>
          </v-col>
          <v-col class="text-right">
            <download-link
              v-if="exportUrl"
              :href="exportUrl">
              <v-btn
                small
                fab>
                <v-icon>file_download</v-icon>
              </v-btn>
            </download-link>
          </v-col>
        </v-row>
        <div class="text-right font-weight-bold">
          Sales and SKUs from last 365
        </div>
        <responsive-table
          v-if="salesData"
          :loading="loading"
          :route-settings="routeSettings"
          :total-items="totalProductSales"
          :rows-per-page-items="refetchRules.onPagination ? [25] : [5, 10, 25]"
          :headers="formattedHeaders"
          :items="salesData"
          :pagination.sync="pagination"
          :search="refetchRules.onPagination ? null : search"
          :custom-sort="customSort"
          mobile-break-point="smAndDown"
          :no-data-text="loading ? 'Loading product data' : 'No products found'"
          @rowClickEvent="rowClickEvent">
          <template
            #image="{item}">
            <v-row

              justify="start">
              <v-col class="ml-1 shrink">
                <a
                  v-if="item.imageFileFolderName"
                  :href="`${baseProductUrl}${item.itemNumber}`"
                  target="_blank">
                  <img
                    :src="`https://rexel-cdn.com/Products/${item.catNum}.jpg?i=${item.imageFileFolderName}&f=35`"
                    alt="Product image" />
                </a>
                <img
                  v-else
                  src="https://rexel-cdn.com/products/$%7Bp.catNum%7D.jpg?i=0EA7A6C5-7DD2-4482-9229-D628D3AFAE9D&f=35"
                  alt="Product image not found" />
              </v-col>
              <v-col
                v-if="$vuetify.breakpoint.smAndDown"
                class="ml-2">
                <h3>
                  {{ item.catNum }}
                </h3>
                <h4> {{ item.description }}</h4>
              </v-col>
            </v-row>
          </template>
          <template
            #itemNumber="{item}">
            <v-row>
              <v-col
                v-if="$vuetify.breakpoint.smAndDown">
                {{ item.itemNumber }}
              </v-col>
              <v-col
                v-else>
                <a
                  :href="`${baseProductUrl}${item.itemNumber}`"
                  target="_blank">
                  {{ item.itemNumber }}
                </a>
              </v-col>
            </v-row>
          </template>
        </responsive-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EntityParams from './ListingEntityParams.mixin'
import ProductLevelParams from './ProductLevelParams.mixin'
import DownloadLink from '@/components/DownloadLink.vue'
import ResponsiveTable from '@/components/ResponsiveTable/ResponsiveTable.vue'

export default {
  name: 'ProductTableCompare',
  components: {
    DownloadLink,
    ResponsiveTable
  },
  mixins: [EntityParams, ProductLevelParams],
  props: {
    salesData: {
      type: Array,
      required: true
    },
    entityType: {
      type: String,
      required: true
    },
    qualifiedEntityIds: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    totalProductSales: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      pagination: {
        sortDesc: false,
        page: 1,
        itemsPerPage: 25,
        sortBy: 'catNum'
      },
      search: null
    }
  },
  computed: {
    ...mapGetters(['baseProductUrl']),
    ...mapGetters('user', ['banner']),
    productId () {
      return this.$route.params.productId
    },
    staticHeaders () {
      return (
        [
          {
            text: '',
            sortable: false,
            value: '',
            slot: 'image'
          },
          {
            text: 'Cat Name',
            align: 'left',
            sortable: true,
            value: 'catNum',
            class: 'text-capitalize'
          },
          {
            text: 'Item #',
            align: 'left',
            sortable: true,
            value: this.$vuetify.breakpoint.smAndDown ? 'itemNumber' : '',
            slot: this.$vuetify.breakpoint.smAndDown ? '' : 'itemNumber'
          }
        ]
      )
    },
    routeSettings () {
      return {
        clickableRows: this.$vuetify.breakpoint.smAndDown,
        eventWithArgs: true,
        parameterNames: ['itemNumber']
      }
    },
    formattedHeaders () {
      const headers = this.staticHeaders.slice()
      const dataHeaders = this.qualifiedEntityIds.map((id, index) => {
        const name = this.entityNames[id.id]

        const branchSalesValue = id.qualified != null ? `salesForBranch${index}` : ''

        const formattedHeader = {
          text: name,
          align: 'left',
          sortable: true,
          value: branchSalesValue,
          type: 'money'
        }
        return formattedHeader
      })
      headers.splice(3, 0, ...dataHeaders)
      return headers
    },
    isDataEmpty () {
      const { salesData } = this
      return !salesData || (Array.isArray(salesData) && !salesData.length)
    },
    searchPropertyNamesForCompareExport () {
      // get staticHeader values for searchPropertyNames
      return this.formattedHeaders.filter(header => header.value).map((headers) => headers.value)
    },
    totalItems () {
      if (this.isDataEmpty) return 0
      return this.totalProductSales
    }
  },
  watch: {
    pagination (newVal, oldVal) {
      const options = {
        pagination: newVal,
        filters: {},
        search: this.search
      }
      if (this.refetchRules.onPagination && oldVal.page) this.$emit('refetch', options)
    },
    search (newVal, oldVal) {
      const options = {
        pagination: this.pagination,
        filters: {},
        search: newVal
      }
      if (this.refetchRules.onPagination) this.$emit('refetch', options)
    }
  },
  mounted () {
    this.getNames()
  },
  methods: {
    changeSort (column) {
      if (this.pagination.sortBy === column) {
        this.pagination.sortDesc = !this.pagination.sortDesc
      } else {
        this.pagination.sortBy = column
        this.pagination.sortDesc = false
      }
    },
    customSort (items, index, isDescending) {
      return items.sort(function (a, b) {
        if (a[index] === b[index]) return 0

        if (!isDescending) {
          return a[index] < b[index] ? -1 : 1
        } else {
          return a[index] < b[index] ? 1 : -1
        }
      })
    },
    rowClickEvent (params) {
      window.open(`${this.baseProductUrl}${params.itemNumber}`, '_blank').focus()
    }
  }
}
</script>
