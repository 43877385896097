<template>
  <div class="file-list">
    <v-list>
      <v-list-item
        v-for="(file, index) in uploads"
        :key="index">
        <v-row class="mb-1">
          <v-col
            cols="1"
            class="d-flex align-center pl-0">
            <v-progress-circular
              v-if="file.status === uploadStatuses.processing"
              :size="32"
              color="primary"
              indeterminate>
            </v-progress-circular>
            <file-icon
              v-else
              class="mt-1"
              :filename="file.data.name"
              :type="file.data.type">
            </file-icon>
          </v-col>
          <v-col
            class="d-flex align-center"
            :class="[
              { 'error--text font-italic' : file.status === uploadStatuses.failed },
              { 'success--text font-italic' : file.status === uploadStatuses.success }
            ]">
            {{ file.data.name }}
          </v-col>
          <v-col
            class="d-flex align-center justify-end">
            <v-btn
              icon
              @click="remove(index)">
              <v-icon>close</v-icon>
            </v-btn>
          </v-col>
          <v-col
            v-if="index < uploads.length - 1"
            :cols="12"
            class="pa-0">
            <v-divider></v-divider>
          </v-col>
        </v-row>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import FileIcon from '@/components/SupportFeedback/FileIcon.vue'
import { deleteFeedbackAttachment } from '@/components/SupportFeedback/support-feedback'
import { UPLOAD_STATUSES } from '@/components/SupportFeedback/upload-statuses'
export default {
  name: 'FileList',
  components: {
    FileIcon
  },
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  computed: {
    uploadStatuses () {
      return UPLOAD_STATUSES
    },
    uploads: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    async remove (index) {
      const upload = this.uploads[index]
      const { token, data: { name } } = upload

      if (token) {
        try {
          await deleteFeedbackAttachment(token)
          this.$emit('removeToken', token)
        } catch (e) {
          this.setToastMessage(`Failed to remove file: ${name}`, 'error')
          return
        }
      }

      const files = [...this.uploads]
      files.splice(index, 1)
      this.uploads = files
      this.setToastMessage(`File removed: ${name}`, 'success')
    },
    setToastMessage (message, status = 'error') {
      this.$store.commit('setToastMessage', {
        message,
        status
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.file-list {
  max-height: 240px;
  overflow: auto;
  ::v-deep .v-list.v-sheet.theme--light {
    background-color: transparent;
  }
}
</style>
