import _differenceInYears from 'date-fns/difference_in_years'
import _format from 'date-fns/format'
import accounting from 'accounting'

const hasValue = (val) => val !== undefined && val !== null

export const formatDate = (val, format = 'MM/DD/YYYY', defaultValue = '-') => {
  if (!hasValue(val) || val === '0001-01-01T00:00:00') return defaultValue

  return _format(val, format)
}

export const formatHireDate = (val) => {
  const datePart = formatDate(val, 'MM/DD/YY')
  if (datePart === '-') return '-'

  // calculate number of years since hire data
  const yearDifference = _differenceInYears(new Date(), new Date(val))
  return `${datePart} (${yearDifference} yr${yearDifference !== 1 ? 's' : ''})`
}

export const formatMonthYearDate = (val) => formatDate(val, 'MMMM YYYY')

export const formatDecimal = (val, precision = 1, defaultValue = '0.0') =>
  hasValue(val) ? accounting.formatNumber(val, precision) : defaultValue

export const formatMoney = (val, precision = 2, defaultValue = '$0.00') =>
  hasValue(val) ? accounting.formatMoney(val, { precision }) : defaultValue

export const formatNumber = (val, defaultValue = '0', precision = 0) =>
  hasValue(val) ? accounting.formatNumber(val, precision) : defaultValue

/**
 * Returns a string for formattedNumber with additional input handling
 *
 * @value {string|number}
 */
export const formatNumberWithCommas = (val, defaultValue = '0', precision = 0) => {
  if (val === undefined || val === null) {
    return val
  }

  if (typeof val === 'number') {
    return formatNumber(val, defaultValue, precision)
  }

  // We avoid some awkwardness with how the accountign lib handles decimals
  // with formatting that interacts poorly with live edited values
  const numberParts = val.split('.')
  let formatted = formatNumber(numberParts[0], defaultValue)
  if (numberParts.length > 1) {
    formatted += `.${numberParts[1]}`
  }
  return formatted
}

export const formatPercent = (val, precision = 1, defaultValue = '0%') => {
  if (val === 1) precision = 0
  return hasValue(val)
    ? `${accounting.formatNumber(val * 100, precision)}%`
    : defaultValue
}

export const replacePhoneZeroes = (val, defaultValue = null) => val === '(000) 000-0000' ? defaultValue : val

export const formatPhone = (val, defaultValue = '(000) 000-0000') => {
  const s2 = ('' + val).replace(/\D/g, '')
  const m = s2.match(/^(\d{3})(\d{3})(\d{4})$/)
  return !m ? defaultValue : '(' + m[1] + ') ' + m[2] + '-' + m[3]
}

export const formatShowSign = (val, defaultValue = '0') => {
  // append '+' if val is positive
  if (!hasValue(val)) return defaultValue
  if (typeof val === 'string') {
    return val.substr(0, 1) === '-'
      ? val
      : '+' + val
  } else if (typeof val === 'number') {
    return val < 0 ? val : '+' + val
  }
  return defaultValue
}

export const formatTitleCase = (val) => {
  return !val
    ? val
    : val.split(' ')
      .map(w => w[0].toUpperCase() + w.substr(1).toLowerCase())
      .join(' ')
}

export const formatFirstLastName = (val) => {
  return !val ? val : val.split(',').join(', ')
}

export const uncapitalize = (val) =>
  val && val.length
    ? val.split(' ')
      .filter(Boolean)
      .map(w => w[0].toLowerCase() + w.slice(1))
      .join(' ')
    : ''

/**
 * Return string of formatted address
 * @typedef Obj
 * @property {String} address single string address
 * @property {String} address1 multi-string line1
 * @property {String} address2 multi-string line2
 * @property {String} address3 multi-string line3
 * @property {String} city
 * @property {String} state
 * @property {String} zip
 * @property {String} postalCode
 */
export const formatAddress = (obj, defaultValue) => {
  if (!obj) return defaultValue
  const { address, address1, address2, address3, city, state, zip, postalCode } = obj
  const addr = address ||
    [address1, address2, address3].filter(Boolean).join(', ')

  const addr2 = [city, state, zip, postalCode].filter(Boolean).join(', ')

  return `${addr} ${addr2}`
}

/**
 * @typedef Obj
 * @property {Object} address Address object
 * @property {String} branchName Branch nickname
 * @property {String|Number} branchNumber Branch number
 */
/**
 * Create the string that displays for a branch name throughout the application.
 * @param {BranchObj} branch Branch object.
 */
export const formatBranchName = (obj, defaultValue) => {
  if (!obj) return defaultValue
  const { branchName, branchNumber, address } = obj
  if (!branchName) return defaultValue

  const city = (address && address.city) || ''
  const state = (address && address.state) || ''

  // If branch name and city are the same thing, only show one of them.
  const isBranchNameSameAsCity = branchName.toLowerCase().includes(city.toLowerCase())
  const branchTitle = city
    ? isBranchNameSameAsCity ? branchName : `${branchName}, ${city}`
    : branchName

  // This could potentially result in the state not being displayed,
  // but only if there is a matching uppercase sub-string in the branch title that is not a state.
  return state && !branchTitle.includes(`, ${state.toUpperCase()}`)
    ? `${branchTitle}, ${state} (${branchNumber})`
    : `${branchTitle} (${branchNumber})`
}

/**
 * Return string of customer's name, and number in parentheses
 * @typedef CustomerObj
 * @property {String} accountId Customer number
 * @property {String} companyCustomerNumber Customer number (alternate form)
 * @property {String} customerName Customer name
 * @property {String} name Customer name (alternate form)
 */
export const formatCustomerTitle = (customer, defaultValue) => {
  if (!customer) return defaultValue

  // If there's both name and number show both, otherwise show whichever one is present.
  const { accountId, companyCustomerNumber, customerName, name: parentName } = customer
  const number = companyCustomerNumber || accountId
  const name = parentName || customerName
  return number && name
    ? `${name} (${number})`
    : name || number
}

/**
 * Return string of customer account type (ship to, bill to job account, etc.)
 * @typedef CustomerObj -- this formatter only works for non-Platt customers
 * @property {String} shipToFlag ship to boolean
 * @property {String} billToFlag bill to boolean
 */
export const formatCustomerType = (customer, defaultValue) => {
  if (!customer) return defaultValue

  const billTo = customer.billToFlag === true || customer.billToFlag === 'Y'
  const shipTo = customer.shipToFlag === true || customer.shipToFlag === 'Y'
  const jobAccount = customer.jobAccountFlag === true || customer.jobAccountFlag === 'Y'

  if (billTo && shipTo) {
    return 'Bill To / Ship To'
  } else if (billTo) {
    return 'Bill To'
  } else if (jobAccount) {
    return 'Job'
  } else if (shipTo) {
    return 'Ship To'
  }
  return null
}

/**
 * Return string of formatted address
 * @typedef Obj
 * @property {String} vendorName vendor name
 * @property {String} vendor vendor name alternate
 * @property {String} vendorNumber vendor number
 * @property {String} vendorId vendor number alternate
 */
export const formatVendor = (obj, defaultValue) => {
  if (!obj) return defaultValue
  const {
    vendorName,
    vendor,
    vendorNumber,
    vendorId
  } = obj
  const name = vendorName || vendor
  const number = vendorNumber || vendorId

  return name && number
    ? `${name} (${number})`
    : name || number
}

/** Abbreviates numbers, with control over the significant digits, and the maximum character length of the number (not including the suffix)
 *
 * @param {*} val The value to abbreviate
 * @param {*} min The min value to return early and not abbreviate
 * @param {*} maxSignificantDigits The number of significant digits after the decimal point we want to use
 * @param {*} maxLength The total max length of the numeric part of the output (excluding the suffix)
 * @param {*} minLength The minimum length of the numeric part of the output (excluding the suffix). The number will have excess trailing zeros truncated to this
 * @returns The abbreviated number
 */
export const formatAbbreviatedNumber = (val, min = 999, maxSignificantDigits = 2, minLength = 3, maxLength = 3) => {
  if (val === undefined || val === null || isNaN(val)) {
    return val
  }

  if (val <= min) {
    return formatNumber(Math.floor(val))
  }

  const digits = Math.log(val) * Math.LOG10E + 1 | 0

  // Digits - digits % 3 gives us a constant number to use for each 1000's place
  // Since the top of each thousands has 6, 9, 12...etc digits we need to default to 3 if the modulus == 0
  const wholeDigits = digits % 3 || 3 // Number of whole digits before the decimal 1, 2, or 3
  const abbreviationMod = Math.pow(10, digits - wholeDigits)

  const abbreviateReady = val / abbreviationMod // Number who's whole parts represent the proper abbreviation output. ie 55.12k 555.456k 1.273mill...etc
  const fixedPoint = parseFloat(abbreviateReady.toFixed(maxSignificantDigits))

  const abbreviateReadyDigits = Math.log(abbreviateReady) * Math.LOG10E + 1 | 0
  let idealTruncateLen = Math.min(Math.max(abbreviateReadyDigits + maxSignificantDigits, minLength), maxLength) // The preferred truncate length based on the significant digits, min length, and max length
  idealTruncateLen = Math.max(abbreviateReadyDigits, idealTruncateLen) // If the maxDigits is shorter than what we can handle (ie max = 2, and this needs 3 digits to represent)
  const truncated = fixedPoint.toPrecision(idealTruncateLen) // We're doing this vs a Math.round(num * n) / n to avoid floating point rounding errors

  let stringified = truncated.toLocaleString()
  if (stringified.length < minLength) { // If the stringified length is less than the minLength then it needs zeros padding
    if (stringified.length === wholeDigits) { // If len == whole digits then the stringified is a whole number
      stringified += '.'
    }
    stringified = stringified.padEnd(minLength + 1, '0')
  } else if (stringified.length === minLength) {
    // If string is same length as min length & whole digits is 1. Then we have a missing decimal place
    // The period is counted towards the length. ie 1.3 vs 1.30 for a 3-length maximum
    if (wholeDigits === 1) {
      stringified = stringified.padEnd(minLength + 1, '0')
    }
  }

  if (val < 1000000) { // 1k -> 999k
    return stringified + 'K'
  }

  if (val < 1000000000) { // 1mill -> 999mill
    return stringified + 'M'
  }

  if (val < 1000000000000) { // 1bill -> 999bill
    return stringified + 'B'
  }

  if (val < 1000000000000000) { // 1trill -> 999trill
    return stringified + 'T'
  }
}
