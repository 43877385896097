export const setCustomer = (state, customer) => {
  state.customer = customer
}

export const setCustomerByType = (state, payload) => {
  const { customerObj, customerType } = payload

  switch (customerType) {
    case 'billTo':
      state.billTo = customerObj
      break
    case 'shipTo':
      state.shipTo = customerObj
      break
    case 'withJobs':
      state.withJobs = customerObj
      break
  }
}

export const setActiveCustomerType = (state, value) => {
  state.activeCustomerType = value
}

export const setCustomerOrderWriters = (state, payload) => {
  const { customerType, orderWriters } = payload
  state[customerType].orderWriters = {
    count: orderWriters.length,
    data: orderWriters
  }
}

export const setSelectedCustomer = (state, selectedCustomerObject) => {
  state.selectedCustomer = selectedCustomerObject
}

export const setResetCustomerObjects = (state) => {
  state.billTo = {}
  state.shipTo = {}
  state.withJobs = {}
  state.selectedCustomer = {}
  state.activeCustomerType = false
}

export const setCustomerProfileType = (state, payload) => {
  const { customerObject, showBillTo } = payload
  if (showBillTo) {
    state.billToCustomer = customerObject
    return
  }
  state.shipToCustomer = customerObject
}

export const setLoaded = (state, loading) => {
  state.loaded = loading
}

export const setAccounts = (state, accountsObj) => {
  state.customer = { ...state.customer, ...accountsObj }
}

export const setWebsite = (state, url) => {
  state.customer.website = url
  if (state.customer.billTo) {
    state.billTo.website = url
  }
  if (state.customer.shipTo) {
    state.shipTo.website = url
  }
}

export const setContacts = (state, payload) => {
  const { contacts, customerType } = typeof payload.customerType !== 'undefined'
    ? payload
    : { contacts: payload, customerType: 'customer' }
  const contactsObj = {
    count: contacts?.length ?? 0,
    data: contacts
  }
  state[customerType] = { ...state[customerType], contacts: contactsObj }
}

export const setSalesStats = (state, payload) => {
  const { statsObj, customerType } = typeof payload.customerType !== 'undefined'
    ? payload
    : { statsObj: payload.statsObj, customerType: 'customer' }
  state[customerType] = { ...state[customerType], ...statsObj }
}

export const setProfileMisc = (state, miscObj) => {
  state.customer = { ...state.customer, ...miscObj }
}

export const setSubAccounts = (state, subAccounts) => {
  state.customer.subAccounts = subAccounts
}

export const setEnriched = (state, enrichedCustomerData) => {
  state.customer = { ...state.customer, enrichedCustomerData }
}

export const setFollowed = (state, isFollowed) => {
  state.customer = { ...state.customer, isFollowed }
}

export const setKeyAndTarget = (state, { isKeyCustomer, isTargetCustomer, isDevelopmentCustomer }) => {
  state.customer = { ...state.customer, isKeyCustomer, isTargetCustomer, isDevelopmentCustomer }
  if (state.customer.billTo) {
    state.billTo = { ...state.billTo, isKeyCustomer, isTargetCustomer, isDevelopmentCustomer }
  }
  if (state.customer.shipTo) {
    state.shipTo = { ...state.shipTo, isKeyCustomer, isTargetCustomer, isDevelopmentCustomer }
  }
}

export const setGreyMarketReseller = (state, { grayMarketReseller }) => {
  state.customer = { ...state.customer, grayMarketReseller }
  if (state.customer.billTo) {
    state.billTo = { ...state.billTo, grayMarketReseller }
  }
  if (state.customer.shipTo) {
    state.shipTo = { ...state.shipTo, grayMarketReseller }
  }
}

export const addAccountNote = (state, note) => {
  const accountNotes = {
    count: state.customer.accountNotes.count + 1,
    data: [...state.customer.accountNotes.data, note]
  }
  state.customer = { ...state.customer, accountNotes }
}
export const deleteAccountNoteById = (state, noteId) => {
  const accountNotes = {
    count: state.customer.accountNotes.count - 1,
    data: state.customer.accountNotes.data.filter(n => n.noteId !== noteId)
  }
  state.customer = { ...state.customer, accountNotes }
}

export const addAckEmail = (state, email) => {
  const orderEmails = {
    count: state.customer.orderEmails.count + 1,
    data: [...state.customer.orderEmails.data, email]
  }
  state.customer = { ...state.customer, orderEmails }
}
export const deleteAckEmail = (state, email) => {
  const orderEmails = {
    count: state.customer.orderEmails.count - 1,
    data: state.customer.orderEmails.data.filter(e => e !== email)
  }
  state.customer = { ...state.customer, orderEmails }
}

export const addContact = (state, contact) => {
  const contacts = {
    count: state.customer.contacts.count + 1,
    data: [...state.customer.contacts.data, contact]
  }
  state.customer = { ...state.customer, contacts }
}
export const updateContact = (state, contact) => {
  const contacts = [...state.customer.contacts.data]
  const index = state.customer.contacts.data.findIndex(c =>
    c.contactId === contact.contactId &&
    c.legacyContactId === contact.legacyContactId
  )
  contacts[index] = contact
  state.customer.contacts.data = contacts
}
export const deleteContact = (state, contact) => {
  const contacts = {
    count: state.customer.contacts.count - 1,
    data: state.customer.contacts.data.filter(c =>
      c.contactId !== contact.contactId ||
      c.legacyContactId !== contact.legacyContactId
    )
  }
  state.customer = { ...state.customer, contacts }
}

export const addWebAccount = (state, account) => {
  const accounts = {
    count: state.customer.accounts.count + 1,
    data: [...state.customer.accounts.data, account]
  }
  state.customer = { ...state.customer, accounts }
}
export const updateWebAccount = (state, account) => {
  const accounts = [...state.customer.accounts.data]
  const index = state.customer.accounts.data.findIndex((c) =>
    c.accountId === account.accountId
  )
  accounts[index] = account
  state.customer.accounts.data = accounts
}
export const deleteWebAccountById = (state, accountId) => {
  const accounts = {
    count: state.customer.accounts.count - 1,
    data: state.customer.accounts.data.filter(n => n.accountId !== accountId)
  }
  state.customer = { ...state.customer, accounts }
}

export const setStrategies = (state, strategies) => {
  state.customer = { ...state.customer, strategies }
}
export const addStrategy = (state, strategy) => {
  const strategies = {
    count: state.customer.strategies.count + 1,
    data: [...state.customer.strategies.data, strategy]
  }
  state.customer = { ...state.customer, strategies }
}
export const updateStrategy = (state, strategy) => {
  const strategies = [...state.customer.strategies.data]
  const index = state.customer.strategies.data.findIndex((c) =>
    c.sequenceId === strategy.sequenceId
  )
  strategies[index] = strategy
  state.customer.strategies.data = strategies
}
export const deleteStrategy = (state, { sequenceId }) => {
  const strategies = {
    count: state.customer.strategies.count - 1,
    data: state.customer.strategies.data.filter(n => n.sequenceId !== sequenceId)
  }
  state.customer = { ...state.customer, strategies }
}

export const EDIT_CUSTOMER_PIM_NAMES_BY_ID = (state, { pimId, pimName }) => {
  const pimIndex = state.customer.pims.data.findIndex(p => p.vanId === pimId)
  state.customer.pims.data[pimIndex].vanName = pimName
}
