<template>
  <span>
    <v-btn
      slot="activator"
      small
      center
      color="red"
      class="badge ma-1"
      @click="atRiskDialog = true">
      <v-icon
        dark>
        warning
      </v-icon>
      <span class="white--text pl-2">
        At Risk
      </span>
      <v-icon
        class="chevron"
        dark>
        chevron_right
      </v-icon>
    </v-btn>

    <v-dialog
      v-model="atRiskDialog"
      scrollable
      :fullscreen="$vuetify.breakpoint.smAndDown"
      width="600px"
      label="At Risk Customer"
      @keydown.esc="atRiskDialog = false">
      <v-card class="at-risk-dialog-card">
        <v-toolbar
          flat
          dark
          color="primary">
          <v-toolbar-title>At Risk Customer</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            dark
            @click="atRiskDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <p class="mb-2 font-weight-bold">
            {{ displayRiskLabel }}:
          </p>
          <div
            v-for="(riskType, i) in displayRiskTypes"
            :key="i"
            class="mb-4">
            <div
              v-if="!isHighRisk"
              class="mb-1">
              By {{ riskType }}
            </div>
            <div>
              "{{ displayRiskText(riskType) }}"
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-spacer></v-spacer>
        <v-card-actions
          shrink
          :class="{stacked: $vuetify.breakpoint.smAndDown}">
          <v-spacer v-if="!$vuetify.breakpoint.smAndDown"></v-spacer>
          <v-btn
            text
            @click="atRiskDialog = false">
            Close
          </v-btn>
          <v-btn
            color="primary"
            @click="goToCreateActivity">
            Create Activity
          </v-btn>
          <v-btn
            color="primary"
            @click="atRiskEmailDialog = true">
            Send At-Risk Email
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="atRiskEmailDialog"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      scrollable
      width="760px"
      label="At Risk Email"
      @keydown.esc="atRiskEmailDialog = false">
      <v-card class="at-risk-dialog-card">
        <v-toolbar
          flat
          dark
          color="primary">
          <v-toolbar-title>At Risk Email</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            dark
            @click="atRiskEmailDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-col>
              <v-select
                v-model="atRiskEmail.to"
                :items="atRiskEmailToOptions"
                label="To">
              </v-select>
              <v-text-field
                v-model="atRiskEmail.subject"
                label="Subject">
              </v-text-field>
              <v-textarea
                v-model="atRiskEmail.body"
                light
                background-color="#fff"
                auto-grow
                label="Message"
                filled>
              </v-textarea>
              <v-checkbox
                v-model="sendToMe"
                :label="`Email me a copy`">
              </v-checkbox>
            </v-col>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions :class="{stacked: $vuetify.breakpoint.smAndDown}">
          <v-spacer v-if="!$vuetify.breakpoint.smAndDown"></v-spacer>
          <file-uploader
            input-label="Add File"
            :on-upload="uploadDocument"
            :on-remove="removeDocument">
          </file-uploader>
          <v-btn
            class="no-text-transform"
            text
            @click="atRiskEmailDialog = false">
            Close
          </v-btn>
          <v-btn
            class="no-text-transform"
            color="primary"
            @click="sendAtRiskEmail">
            Send
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import { mapGetters } from 'vuex'
import { getWelcomeEmailRecipientList, postAtRiskEmail, createActivityAfterAtRiskEmailSent } from '@/api/customers'
import FileUploader from '../../components/FormFileUploader.vue'
import { bannerProvider } from '@/features/banners'

export default {
  name: 'CustomerAtRiskBadge',

  components: {
    FileUploader
  },
  props: {
    customerId: {
      type: [Number, String],
      required: true
    },
    atRiskTypes: {
      type: Array,
      required: true
    },
    customerName: {
      type: String,
      required: true
    },
    branchName: {
      type: String,
      required: true
    },
    branchTelephone: {
      type: String,
      required: true
    },
    userName: {
      type: String,
      required: true
    },
    userEmail: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      atRiskDialog: false,
      atRiskEmailDialog: false,
      atRiskEmail: {
        to: '',
        subject: '',
        body: ''
      },
      atRiskEmailToOptions: [],
      highRiskTitle: 'At High Risk (Churn)',
      selectedFile: null,
      sendToMe: false
    }
  },
  computed: {
    ...mapGetters('user', ['banner']),
    isHighRisk () {
      return this.atRiskTypes.indexOf(this.highRiskTitle) > -1
    },
    displayRiskLabel () {
      return this.isHighRisk ? this.highRiskTitle : 'At Risk By'
    },
    displayRiskTypes () {
      return this.isHighRisk ? [this.highRiskTitle] : this.atRiskTypes
    },
    formattedBranchPhone () {
      return this.branchTelephone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
    },
    bannerInfo () {
      return bannerProvider.getDetails(this.banner)
    }
  },
  watch: {
    atRiskEmailDialog (newVal) {
      if (newVal) {
        this.atRiskDialog = false
      }
      this.setAtRiskEmailFields()
      this.getEmailAddressesForCustomer()
    }
  },
  methods: {
    goToCreateActivity () {
      this.$router.push({
        name: 'create-activity',
        query: {
          customerId: this.customerId,
          atRisk: true
        }
      })
    },
    getEmailAddressesForCustomer () {
      getWelcomeEmailRecipientList(this.customerId)
        .then((response) => {
          this.atRiskEmailToOptions = response.data.map((x) => ({
            text: `${x.name} - (${x.emailAddress})`,
            value: x.emailAddress
          }))

          this.atRiskEmail.to = this.atRiskEmailToOptions[0].value
        })
        .catch((error) => {
          console.error(error)
        })
    },
    setAtRiskEmailFields () {
      this.atRiskEmail.from = ''
      this.atRiskEmail.body = `${this.customerName},\n\nThank you for being our customer! We appreciate your business and want to let you know about some new promotions that you might be interested in. Visit us at ${this.bannerInfo.url} where you can login, manage your account information, and order online. Need help? We'd love to hear from you. Please reach out to your ${this.branchName} ${this.bannerInfo.bannerName} Branch at ${this.formattedBranchPhone}.
      \nAgain, we do appreciate you being a valued customer of ours and we look forward to helping you with all your electrical product needs.
      \nIf you have any questions please call us here at the branch or email me at ${this.userEmail}.\n\nThank You, \n\n${this.userName}`
    },
    sendAtRiskEmail () {
      const formData = new FormData()
      if (this.selectedFile) {
        formData.append('attachment', this.selectedFile)
      }
      formData.append('to', this.atRiskEmail.to)
      formData.append('from', `${this.userName} <${this.userEmail}>`)
      formData.append('subject', this.atRiskEmail.subject)
      formData.append('body', this.atRiskEmail.body)
      if (this.sendToMe) {
        formData.append('additionalTo', this.userEmail)
      }

      postAtRiskEmail(formData)
        .then((res) => {
          createActivityAfterAtRiskEmailSent(this.bannerInfo.bannerId, this.customerId)
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.atRiskEmailDialog = false
        })

      this.selectedFile = null
    },
    displayRiskText (riskType) {
      switch (riskType) {
        case 'At High Risk (Churn)':
          return 'High risk customers are considered the most at risk to become inactive and most in need of next actions.'
        case 'Dollar':
          return 'Customer sales dollars for the previous month are down by 80% or more compared to the previous 12 month average.'
        case 'Order Count':
          return 'Customer order count for the previous month is down by 80% or more compared to the previous 12 month average.'
        case 'Web Account':
          return 'Customer\'s last account login date was more than 6 months ago.'
        case 'SKUs':
          return 'Customer\'s purchased SKU count is down by 20% or more for the last rolling 12 month period.'
        default:
          return ''
      }
    },
    uploadDocument (file) {
      this.selectedFile = file
    },
    removeDocument () {
      this.selectedFile = null
    }
  }
}
</script>

<style lang="scss" scoped>
.v-btn .v-icon {
  font-size: 20px !important;

  &.chevron {
    position: relative;
    left: 4px;
  }
}

.no-text-transform {
  text-transform: none !important;
}

.at-risk-dialog-card .v-card__actions.stacked {
  flex-direction: column-reverse;
  align-items: stretch;

  button {
    margin-left: 0;
    margin-right: 0;

    &:not(:first-child) {
      margin-bottom: 8px;
    }
  }
}
</style>
