<template>
  <customers-report-base
    :customers="customers"
    :loading.sync="loading"
    :export-loading="exportLoading"
    @export-report="fetchExport">
  </customers-report-base>
</template>

<script>
import { mapGetters } from 'vuex'
import { downloadFileResult } from '@/globals/utils'
import { lifecycles } from '@/api/customer-lifecycle'
import { CustomersReportBase } from '@/components/Reports/Customers'

export default {
  name: 'LifecycleNewCustomersReport',
  components: {
    CustomersReportBase
  },
  data () {
    return {
      customers: [],
      loading: true,
      exportLoading: false
    }
  },
  computed: {
    ...mapGetters('user', ['user'])
  },
  async mounted () {
    await this.fetchData()
    this.$store.commit('setPageHeader', {
      page: 'Reports',
      text: 'Customer Lifecycle - New Customers'
    })
  },
  methods: {
    async fetchData () {
      try {
        const newCustomers = await lifecycles.newCustomer.getReport()
        this.customers = newCustomers
        this.loading = false
      } catch (error) {
        this.loading = false
        this.$store.commit('setToastMessage', {
          message: 'Error fetching Customer Lifecycle - New Customers report',
          status: 'error'
        })
      }
    },
    async fetchExport (exportData) {
      this.exportLoading = true

      const { sortBy, descending, search, accountTypes, autoDelete, searchPropertyNames } = exportData
      const result = await lifecycles.newCustomer.getExport(sortBy, descending, search, accountTypes, autoDelete, searchPropertyNames)

      if (result) {
        downloadFileResult(result)
      }

      this.exportLoading = false
    }
  }
}
</script>
