import { searchCustomers, fetchCustomer } from '@/api/customers'
import { fetchCustomersForSearches, fetchCustomerSearchTrends } from '@/api/reports'

export const updateCustomersSearchParams = ({ commit, dispatch }, customersFiltersObj) => {
  commit('setCustomersSearchParams', customersFiltersObj)
  dispatch('searchCustomersByParams')
}

export const searchCustomersByParams = ({ commit, state }) => {
  commit('setCustomersLoading', true)

  return searchCustomers(state.customersSearchParams).then(response => {
    commit('setCustomersResult', {
      customers: response.data.value,
      total: response.data['odata.count']
    })
    commit('setCustomersLoading', false)
  })
}

export const updateCachedCustomer = async ({ commit, state: { cachedCustomers } }, customerId) => {
  const { data } = await fetchCustomer(customerId)
  commit('setCachedCustomer', data)
}

export const getCustomer = async ({ commit, state: { cachedCustomers } }, customerId) => {
  const cachedCustomer = cachedCustomers[customerId]
  if (cachedCustomer) return cachedCustomer

  const { data } = await fetchCustomer(customerId)
  commit('setCachedCustomer', data)
  return data
}

export const getCustomersForSearchTrends = async ({ commit }, dates) => {
  try {
    const { data } = await fetchCustomersForSearches(dates)
    commit('setCustomersForSearchTrends', data)
    return data
  } catch (error) {
    commit(
      'setToastMessage',
      { message: 'Error fetching customers' },
      { root: true }
    )
    return error
  }
}

export const getCustomerSearchTrends = async ({ commit }, query) => {
  try {
    const { customerId, startDate, endDate } = query
    const { data } = await fetchCustomerSearchTrends(customerId, startDate, endDate)
    commit('setCustomerSearchTrends', { data, customerId })
    return data
  } catch (error) {
    commit(
      'setToastMessage',
      { message: 'Error fetching search trends' },
      { root: true }
    )
    return error
  }
}
