<template>
  <div>
    <v-menu
      v-model="isOpened"
      left
      bottom
      offset-y>
      <template #activator="{ on }">
        <v-icon
          v-on="on">
          more_vert
        </v-icon>
      </template>
      <slot></slot>
      <v-divider></v-divider>
      <v-list>
        <v-list-item
          v-for="{ title, dialog, event, route } in selectedOptionalMenuItems"
          :key="title"
          @click="handleMenuItem(dialog, event, route)">
          <v-list-item-title>{{ title }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          class="add-action-tile"
          @click="dialogs.editActions = true">
          <v-list-item-avatar>
            <v-icon>
              add
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Optional Actions</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <enriched-data-dialog
      v-if="dialogs.enrichedData"
      :is-dialog-open.sync="dialogs.enrichedData"
      :customer="customer"
      @close="dialogs.enrichedData = false">
    </enriched-data-dialog>
    <customer-action-selection-modal
      v-if="dialogs.editActions"
      :show.sync="dialogs.editActions"
      :action-menu-items="menuItemOptions">
    </customer-action-selection-modal>
    <mission-and-strategy-form
      v-if="dialogs.missionsAndStrategies"
      :show.sync="dialogs.missionsAndStrategies"
      :selected-customer-id="customer ? customer.customerId : null"
      :customer-title="formattedCustomerName">
    </mission-and-strategy-form>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import EnrichedDataDialog from '@/pages/CustomerProfile/EnrichedDataDialog.vue'
import CustomerActionSelectionModal from './ActionSelectionModal.vue'
import MissionAndStrategyForm from '@/components/MissionAndStrategyForm.vue'

/*
 * This menu of customer options is meant to be used within any list of customers.
   If there is already a menu, the content of the existing menu can be passed into the slot.
   To avoid duplicating items in the original menu content, use the defaultCustomerActionItems Property.

   menuItems whose customerActionOption property match one of the strings in the defaultCustomerActionMenuItems prop will be
   excluded from the optional action menu items (below the divider).
 */
export default {
  name: 'CustomerActionMenu',
  components: {
    EnrichedDataDialog,
    CustomerActionSelectionModal,
    MissionAndStrategyForm
  },
  props: {
    customerId: {
      type: Number,
      required: true
    },
    // Array of strings to filter menu items by matching on the customerActionOption property
    defaultCustomerActionMenuItems: {
      type: Array,
      required: false,
      default: () => []
    },
    customerMissionCount: {
      type: Number,
      required: false,
      default: undefined
    }
  },
  data () {
    return {
      currentYear: new Date().getFullYear(),
      dialogs: {
        enrichedData: false,
        editActions: false,
        missionsAndStrategies: false
      },
      isOpened: false
    }
  },
  computed: {
    ...mapGetters('user', ['banner', 'isPlattBanner']),
    ...mapState('user', ['user', 'preferences']),
    ...mapState('currentCustomer', ['customer']),
    menuItemOptions () {
      const menuItems = [
        {
          title: 'Create Activity',
          route: 'create-activity',
          exclude: this.customer ? this.isPlattBanner && this.customer.isEnterprise : false,
          customerActionOption: 'createActivity'
        },
        {
          title: 'Create Opportunity',
          route: 'create-opportunity',
          customerActionOption: 'createOpportunity'
        },
        {
          title: this.customerMissionCount ? `Create Mission / Strategy (${this.customerMissionCount})` : 'Create Mission / Strategy',
          dialog: 'missionsAndStrategies',
          exclude: !this.$hasFeature('Customer_MissionAndStrategies_CreateEdit'),
          customerActionOption: 'createMissionAndStrategy'
        },
        {
          title: 'Edit Enriched Customer Data',
          dialog: 'enrichedData',
          customerActionOption: 'editEnrichedCustomerData'
        }
      ]
      return menuItems.filter((item) => !item.exclude && !this.defaultCustomerActionMenuItems.includes(item.customerActionOption))
    },
    // all possible option actions from preferences
    optionalCustomerActions () {
      return this.preferences.customerListActions ? Object.keys(this.preferences.customerListActions) : []
    },
    // customer list actions set to true from preferences, pre checked in ActionSelectionModal, to be displayed in bottom section of menu
    selectedOptionalCustomerActions () {
      return this.optionalCustomerActions.filter(option => this.preferences.customerListActions[option])
    },
    defaultMenuItemOptions () {
      return this.menuItemOptions.filter(x => this.defaultCustomerActionMenuItems.includes(x.customerActionOption))
    },
    selectedOptionalMenuItems () {
      return this.menuItemOptions.filter(menuItem => this.selectedOptionalCustomerActions.includes(menuItem.customerActionOption) && !this.defaultCustomerActionMenuItems.includes(menuItem.customerActionOption))
    },
    formattedCustomerName () {
      return this.customer ? `${this.customer.name} (${this.customer.accountId})` : ''
    }
  },
  watch: {
    isOpened (val) {
      if (val) {
        if (!this.customer || (this.customer.customerId !== this.customerId)) {
          this.fetchCustomer(this.customerId)
        }
      }
    }
  },
  methods: {
    ...mapActions('currentCustomer', ['getCustomer']),
    fetchCustomer (customerId) {
      this.getCustomer(customerId)
    },
    handleMenuItem (dialogKey, event, route) {
      if (event !== undefined) {
        this.$emit(event)
      }
      if (dialogKey !== undefined) {
        this.dialogs[dialogKey] = !this.dialogs[dialogKey]
      }
      if (route !== undefined) {
        this.$router.push({
          name: route,
          query: { customerId: this.customer.customerId }
        })
      }
    }
  }
}
</script>

<style lang="scss">
.add-action-tile {
  .v-list__tile {
    padding-left: 0px;
  }
}
</style>
