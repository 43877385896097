<template>
  <v-row dense>
    <v-col cols="auto">
      <v-avatar
        :size="30"
        :class="actionItemData.avatarClass"
        class="action-plan__avatar">
        {{ actionCount }}
      </v-avatar>
    </v-col>

    <v-col
      cols="auto"
      class="d-flex flex-column flex-grow-1 text-subtitle-2 justify-center action-plan__text">
      {{ actionItemData.text }}
    </v-col>

    <template v-if="actionItemData.route">
      <v-col cols="auto">
        <v-icon>arrow_forward</v-icon>
      </v-col>
      <router-link
        class="action-plan__link"
        :to="actionItemData.route">
      </router-link>
    </template>
  </v-row>
</template>

<script>
export default {
  name: 'ActionPlanRow',
  props: {
    actionCount: {
      type: Number,
      required: true
    },
    actionText: {
      type: [String, Boolean],
      required: false,
      default: false
    },
    reportId: {
      type: [Number, Boolean],
      required: false,
      default: false
    }
  },
  computed: {
    actionItemData () {
      const avatarParentBem = 'action-plan__avatar--'
      const itemData = {
        text: '',
        avatarClass: '',
        routeName: false
      }
      if (this.actionText) {
        itemData.text = this.actionText
        itemData.avatarClass = `${avatarParentBem}action-required`
        return itemData
      }
      if (!this.reportId) {
        return itemData
      }
      if (this.reportId === 1) {
        const plural = this.determinePlural()
        itemData.text = `New Customer${plural} 1st Order`
        itemData.avatarClass = `${avatarParentBem}customer-first-order`
        itemData.route = { name: 'customer-first-order' }
      }
      if (this.reportId === 2) {
        itemData.text = 'At High Risk (Churn)'
        itemData.avatarClass = `${avatarParentBem}high-risk`
        itemData.route = { name: 'customers-at-risk', params: { fromDashboard: true } }
      }
      if (this.reportId === 3) {
        const plural = this.determinePlural()
        itemData.text = `Expiring Contract${plural} in the next 30 days`
        itemData.avatarClass = `${avatarParentBem}expiring`
        itemData.route = { name: 'expiring-contracts-report' }
      }

      return itemData
    }
  },
  methods: {
    determinePlural () {
      return this.actionCount > 1 ? 's' : ''
    }
  }
}
</script>

<style scoped lang="scss">
  @import '~styl/colors';
  .action-plan {
    &__avatar {
      color: $white;
      background-color: $primary;
      &--action-required {
        background-color: $actionRequired;
      }
      &--high-risk {
        background-color: $actionHighRisk;
      }
      &--expiring {
        background-color: $actionExpiring;
      }
      &--customer-first-order {
        background-color: $actionFirstOrder;
      }
    }
    &__link {
      position: absolute;
      display: block;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
    }
    ::v-deep .v-expansion-panel__header {
      background-color: $actionHeaderBg;
    }
  }
</style>
