<template>
  <v-dialog
    v-model="showing"
    :max-width="maxWidth"
    @keydown.esc="cancel">
    <v-card class="text-center pa-4">
      <v-card-text>{{ title }}</v-card-text>
      <v-btn
        text
        color="primary"
        @click="cancel">
        Cancel
      </v-btn>
      <v-btn
        :color="actionColor"
        :disabled="actionDisabled"
        data-qa="confirmRemoveAccount"
        :loading="actionLoading"
        @click="confirmAction">
        {{ actionLabel }}
      </v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmationDialog',
  props: {
    show: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: false,
      default: 'Are you sure?'
    },
    actionLabel: {
      type: String,
      required: true
    },
    actionColor: {
      type: String,
      required: false,
      default: 'primary'
    },
    actionDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    actionLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    confirmAction: {
      type: Function,
      required: true
    },
    cancelAction: {
      type: Function,
      required: true
    },
    maxWidth: {
      type: String,
      required: false,
      default: 'none'
    }
  },
  data () {
    return {
      showing: false
    }
  },
  watch: {
    show (newVal) {
      this.showing = this.show
    },
    showing (newVal) {
      if (!newVal) this.cancelAction()
    }
  },
  methods: {
    cancel () {
      this.showing = false
    }
  }
}
</script>
