<template>
  <v-select
    :items="allFilteredCustomerSourceOptions"
    :value="customersSearchParams.source"
    class="search-source"
    hide-details
    single-line
    filled
    @change="selectCustomerSourceOption">
  </v-select>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  props: {
    onlyMine: {
      type: Boolean,
      required: false,
      default: false
    },
    additionalSources: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  computed: {
    ...mapState('customers', ['customersSearchParams']),
    ...mapGetters('user', ['user', 'banner']),
    customerSourceOptions () {
      const { userType } = this.user

      return [
        {
          text: 'All',
          value: 'all',
          include: true
        },
        {
          text: 'My',
          value: 'my',
          include: this.$hasFeature('CustomersSearch_MySourceFilter')
        },
        {
          text: 'Branch',
          value: 'branch',
          include: !this.onlyMine && this.$hasFeature('CustomersSearch_BranchSourceFilter')
        },
        {
          text: 'Region',
          value: 'region',
          include: !this.onlyMine && this.$hasFeature('CustomersSearch_RegionSourceFilter')
        }
      ]
    },
    filteredCustomerSourceOptions () {
      return this.customerSourceOptions.filter((option) => option.include)
    },
    allFilteredCustomerSourceOptions () {
      if (this.additionalSources.length > 0) {
        const dividerData = [{ divider: true }]
        return this.filteredCustomerSourceOptions.concat(dividerData, this.additionalSources)
      }
      return this.filteredCustomerSourceOptions
    }
  },
  methods: {
    selectCustomerSourceOption (sourceOption) {
      const searchActionObj = {
        ...this.customersSearchParams,
        source: sourceOption,
        regionCode: this.user.regionCode,
        page: 1,
        ssoNumber: sourceOption === 'my' ? this.user.sso : null
      }

      this.$store.dispatch('customers/updateCustomersSearchParams', searchActionObj)
      this.$emit('sourcechanged', sourceOption)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~styl/colors';

.search-source {
  max-width: 115px;

  & ::v-deep .v-input__control {
    border-right: 1px solid $lightGrey;
  }
}

</style>
