<template>
  <div>
    <div
      class="sub-title-section">
      Branch: {{ `${customer.branchName} - ${customer.branchNumber}` }}
    </div>
    <div
      v-if="customer.shippingAddress"
      class="sub-title-section">
      Address: {{ customer.shippingAddress.address1 }}
    </div>
    <div
      v-else-if="customer.address"
      class="sub-title-section">
      Address: {{ customer.address.address1 }}
    </div>
    <div
      class="sub-title-section">
      Banner: {{ banner }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomerListTileSubtitle',
  props: {
    customer: {
      type: Object,
      required: true
    },
    banner: {
      type: String,
      required: true
    }
  },
  data () {
    return {
    }
  }
}
</script>
<style  lang="scss" scoped>
@import '~styl/colors';

.sub-title-section {
  display: inline-block;
  margin-right: 15px;
  background-color: #eee;
  font-size: 12px;
  padding: 3px 10px;
  margin-top: 5px;
}

</style>
