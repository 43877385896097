<template>
  <v-dialog
    :value="isSessionExpired"
    width="400"
    persistent>
    <v-card>
      <v-toolbar
        class="primary"
        flat
        dark>
        <div class="text-h5">
          Session Expired
        </div>
      </v-toolbar>
      <v-card-text>
        Your session has expired. Please reload to continue.
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="reload">
          Reload
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'SessionExpiredModal',
  data: () => {
    return {
      isSessionExpired: false
    }
  },
  computed: {
    ...mapState('user', ['accessToken'])
  },
  watch: {
    accessToken (token) {
      this.isSessionExpired = token === null
    }
  },
  methods: {
    reload () {
      window.location.reload()
    }
  }
}
</script>
