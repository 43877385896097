export default {
  data () {
    return {
      categoryLevels: ['section', 'group', 'category', 'subcategory', 'item', 'product']
    }
  },
  computed: {
    categoryLevel () {
      return this.$route.params.categoryLevel
    }
  },
  methods: {
    clickRow (categoryId) {
      const {
        entityId,
        entityIds,
        entityType,
        categoryLevel,
        categoryLevels
      } = this
      const childLevelIndex = categoryLevels.indexOf(categoryLevel) + 1
      const isUncategorizedSection = Number.parseInt(categoryId) === -1 && categoryLevel === 'section'
      const isCompany = entityType === 'company'
      const route = {
        name: 'sales-by-category-report-list' + isCompany ? '' : '-company',
        params: {
          categoryLevel: isUncategorizedSection ? 'subcategory' : categoryLevels[childLevelIndex],
          entityType: this.entityType.toLowerCase(),
          categoryId
        }
      }
      if (entityIds) {
        route.query = {
          ids: entityIds.join(',')
        }
      } else {
        route.params.id = entityId
      }
      this.$router.push(route)
    }
  }
}
