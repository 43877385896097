<template>
  <span></span>
</template>

<script>
import { identityService } from '@/features/identity'
export default {
  name: 'AuthCallbackSilent',
  created () {
    identityService.authManager.signinSilentCallback()
  }
}
</script>
