<template>
  <v-row>
    <v-col
      v-if="!readonly"
      cols="12"
      md="3"
      sm="12">
      <v-switch
        v-model="globalCustomerSearch"
        label="Search Company-wide"
        data-qa="searchCompanyWideToggle">
      </v-switch>
    </v-col>
    <v-col
      cols="12"
      md="9"
      sm="12">
      <auto-complete
        v-required="required"
        :loading="loading"
        :force-loading="loading"
        :rules="required ? rules.required : []"
        clearable
        :clear-on-select="false"
        has-item-slot
        :endpoint="searchEndpoint"
        :label="globalCustomerSearch ? 'Search company customers' : `Search ${customerSource} customers`"
        :initial-value="initialCustomer"
        :readonly="readonly"
        @select="select">
        <template
          #item="{ item }">
          <div data-qa="searchResultsItem">
            <customer-list-tile-layout
              :prepend-sales-stats="false"
              :customer="formatCustomerObj(item.value)">
            </customer-list-tile-layout>
          </div>
        </template>
      </auto-complete>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex'
import { isRequired } from '@/rules'
import { searchCustomers, fetchCustomer } from '@/api/customers'
import AutoComplete from '@/components/AutoComplete.vue'
import CustomerListTileLayout from './CustomerListTileLayout.vue'

export default {
  name: 'CustomerSearch',
  components: {
    AutoComplete,
    CustomerListTileLayout
  },
  props: {
    customerId: {
      type: [Number, String],
      required: false,
      default: ''
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    filters: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      rules: {
        required: [isRequired()]
      },
      loading: false,
      initialCustomer: null,
      globalCustomerSearch: true
    }
  },
  computed: {
    ...mapGetters('user', ['user', 'isPlattBanner']),
    customerSource () {
      if (this.globalCustomerSearch) return 'all'
      if (this.user.userType === 'Mgr' || this.user.userType === 'AsstMgr') {
        return 'branch'
      } else if (this.user.userType === 'RVP') {
        return 'region'
      } else {
        return 'my'
      }
    },
    searchEndpoint () {
      const branchId = this.customerSource === 'branch'
        ? parseInt(this.user.branchId, 10)
        : null

      return (searchTerm) => searchCustomers({
        term: searchTerm,
        source: this.customerSource,
        ssoNumber: this.user.sso,
        isEnterprise: this.isPlattBanner ? false : null,
        filters: this.filters,
        bannerRegionCode: this.user.bannerRegionCode,
        branchId
      })
    }
  },
  watch: {
    customerId: {
      immediate: true,
      handler (newVal) {
        if (newVal) this.getInitialCustomer(newVal)
      }
    }
  },
  created () {
    this.globalCustomerSearch = this.user.userType !== 'Sales'
  },
  methods: {
    // get customer data to populate autocomplete text (default and active states)
    async getInitialCustomer (customerId) {
      this.loading = true
      if (!customerId) return

      const dataObject = await fetchCustomer(customerId)
      if (typeof dataObject !== 'object') { return }
      const data = dataObject.data
      if (this.isPlattBanner && data.isEnterprise) {
        this.$store.commit('setToastMessage', {
          message: 'Cannot be created for an Enterprise customer',
          status: 'error'
        })
      } else {
        const { customerId, name, accountId, address, branchName, branchNumber, shipTo, billTo } = data
        this.initialCustomer = {
          companyCustomerNumber: accountId,
          customerName: name,
          customerId,
          address,
          branchName,
          branchNumber,
          shipToFlag: shipTo,
          billToFlag: billTo
        }
        this.select(this.initialCustomer)
      }
      this.loading = false
    },
    select (selectedCustomer) {
      this.$emit('update', selectedCustomer)
    },
    formatCustomerObj (customer) {
      return {
        ...customer,
        customerNumber: customer.companyCustomerNumber
      }
    }
  }
}
</script>
