const SET_ACCOUNT_TAB = (state, value) => {
  state.accountTab = value
}

const SET_CUSTOMER_ID = (state, value) => {
  state.customerId = value
}

const SET_DISPLAY_NON_UPGRADED_MODAL = (state, value) => {
  state.displayNonUpgradedModal = value
}

const SET_NON_UPGRADED_ACCOUNTS = (state, data) => {
  state.nonUpgradedAccounts = data
}

const SET_CURRENT_CUSTOMER_ID = (state, data) => {
  state.currentCustomerId = data
}

const SET_WEB_ACCOUNTS_JSON = (state, data) => {
  state.webAccountsJson = data
}

const REMOVE_NON_UPGRADED_ACCOUNT = (state, accountIndex) => {
  state.nonUpgradedAccounts.splice(accountIndex, 1)
}

export default {
  SET_ACCOUNT_TAB,
  SET_CUSTOMER_ID,
  SET_DISPLAY_NON_UPGRADED_MODAL,
  SET_NON_UPGRADED_ACCOUNTS,
  SET_CURRENT_CUSTOMER_ID,
  SET_WEB_ACCOUNTS_JSON,
  REMOVE_NON_UPGRADED_ACCOUNT
}
