<template>
  <v-container
    fluid>
    <selected-cards
      v-if="!isMobile && canCompare"
      :selected="selectedEntities"
      :entity-type="entityType"
      :entity-type-plural="entityTypePlural"
      @remove="removeFromComparison">
      <template slot="button">
        <v-btn
          color="primary"
          :to="selectedEntities.length > 1 ? toCompare : toSingleEntity">
          {{ selectedEntities.length > 1 ? 'Compare' : 'View Report' }}
          <v-icon class="pl-1">
            arrow_forward
          </v-icon>
        </v-btn>
      </template>
    </selected-cards>

    <v-row>
      <v-col
        cols="12"
        md="9"
        class="d-flex">
        <customers-source></customers-source>
        <customers-search-bar></customers-search-bar>
      </v-col>
    </v-row>
    <v-row class="pt-4">
      <v-col class="text-body-2">
        Select a {{ entityType | formatTitleCase }}
        <em :class="[{ 'hidden': !canCompare }]">
          (Compare up to 3 at a time)
        </em>
      </v-col>
      <v-col class="table-legend">
        Sales from last 365
      </v-col>
    </v-row>
    <v-data-table
      :items="filteredEntities"
      :server-items-length="totalCustomers"
      :options.sync="pagination"
      :footer-props="{ 'items-per-page-options': [25] }"
      :loading="loading"
      class="table-border-light"
      hide-default-header
      :no-data-text="loading ? 'Loading customers' : 'No customers found'">
      <!-- HIDE FOR NOW... :headers="headers" -->
      <template
        slot="item"
        slot-scope="props">
        <tr
          :key="props.item.id"
          class="clickable"
          :disabled="entitiesToCompare.length >= 3 && !entitiesToCompare.includes(props.item.id)"
          :active="entitiesToCompare.includes(props.item.id)"
          @click="clickRow(props.item)">
          <td
            width="50"
            :class="[{ 'hidden': !canCompare }]">
            <v-checkbox
              v-model="entitiesToCompare"
              :disabled="entitiesToCompare.length >= 3 && !entitiesToCompare.includes(props.item.id)"
              :value="props.item.id"
              class="checkbox"
              hide-details
              @click="clickRow(props.item)">
            </v-checkbox>
          </td>
          <td>
            <v-row class="py-2">
              <v-col
                class="mr-2 hidden-xs-only shrink">
                <v-avatar
                  v-if="props.item.initials"
                  size="40"
                  class="primary elevation-4 customer-avatar-gradient">
                  <span class="white--text">
                    {{ props.item.initials }}
                  </span>
                </v-avatar>
                <v-avatar
                  v-else
                  size="40">
                  <img
                    v-if="props.item.imageUrl"
                    :src="props.item.imageUrl"
                    :alt="`${entityType} image`" />
                  <img
                    v-else
                    src="https://rexel-cdn.com/products/$%7Bp.catNum%7D.jpg?i=0EA7A6C5-7DD2-4482-9229-D628D3AFAE9D&f=80"
                    :alt="`${entityType} image not found`" />
                </v-avatar>
              </v-col>
              <v-col>
                <div class="mb-1 font-weight-bold text-body-1">
                  {{ props.item.name }} ({{ props.item.companyId || props.item.id }})
                </div>
                <div>
                  <span :class="isMobile ? 'd-block' : 'pr-1'">
                    <span
                      v-if="props.item.extraText"
                      class="grey--text text--darken-2">
                      {{ props.item.extraText }}:
                    </span> {{ props.item.extraValue }}
                  </span>
                  <span :class="isMobile ? 'd-block' : 'pl-2'">
                    <span
                      v-if="props.item.address"
                      class="grey--text text--darken-2">
                      Address:
                    </span> {{ props.item.address }}
                  </span>
                </div>
              </v-col>
            </v-row>
          </td>
          <td
            class="hidden-xs-only"
            style="font-weight: bold">
            {{ props.item.salesData | formatMoney }}
          </td>
          <td
            :class="[{ 'hidden': canCompare }]">
            <v-icon>arrow_forward</v-icon>
          </td>
        </tr>
      </template>
      <!-- TEMP FIX - Awaiting Vuetify fix for issue: https://github.com/vuetifyjs/vuetify/issues/14405 -->
      <!-- eslint-disable -->
      <template v-slot:footer.page-text="props">
        {{ props.pageStart }}-{{ props.pageStop }} of {{ props.itemsLength.toLocaleString('en-us') }}
      </template>
        <!-- eslint-enable -->
    </v-data-table>
  </v-container>
</template>

<script>
import EntityParams from '@/pages/Reports/shared/EntityParams.mixin'
import { mapGetters, mapState } from 'vuex'

import SelectedCards from './SelectedCards.vue'
// TODO: move these to components
import CustomersSearchBar from '@/pages/Customers/CustomersSearchHeader/CustomersSearchBar.vue'
import CustomersSource from '@/pages/Customers/CustomersSearchHeader/CustomersSource.vue'

export default {
  name: 'CustomerSelectionTable',
  components: {
    CustomersSearchBar,
    CustomersSource,
    SelectedCards
  },
  mixins: [EntityParams],
  props: {
    isSelected: {
      type: Boolean,
      required: true,
      default: false
    },
    childRoutes: {
      type: Object,
      required: true
    },
    disableComparison: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      entityType: 'customer',

      filters: [],
      pagination: {
        itemsPerPage: 25
      },

      entities: [],
      entitiesToCompare: [],
      selectedEntities: []
    }
  },
  computed: {
    ...mapGetters('user', ['user', 'banner']),
    ...mapState('customers', [
      'customers',
      'customersSearchParams',
      'totalCustomers',
      'customersLoading'
    ]),

    // Note: hiding headers in data-table for now
    headers () {
      return [
        {
          text: 'Compare',
          align: 'left',
          sortable: false
        },
        {
          text: `${this.entityType} Name`,
          align: 'left',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Sales',
          align: 'left',
          sortable: true,
          value: 'salesData'
        },
        {
          text: ' ', // Vuetify uses text as a key. This has to be a space to differentiate from the other empty header column
          sortable: false,
          value: ''
        }
      ]
    },
    isMobile () {
      return this.$vuetify.breakpoint.xs
    },
    canCompare () {
      return !this.isMobile && !this.disableComparison
    },
    loading () {
      return this.customersLoading
    },
    filteredEntities () {
      const { mapping } = this.entityDataParams
      let entities = this.customers.map(mapping)

      // remove any duplicates
      // ...for elastic, this is only checking the current page of results for duplicates
      entities = entities.filter((entity, index) => {
        const indexFind = entities.findIndex((entitySearch) => entitySearch.id === entity.id)
        if (indexFind < index) console.warn(`Duplicate ${this.entityType} found. Id = ${entity.id}`)
        return indexFind >= index
      })
      return entities
    },
    toSingleEntity () {
      return {
        name: this.childRoutes.single,
        params: {
          entityType: this.entityType.toLowerCase(),
          id: this.entitiesToCompare[0],
          ...this.childRoutes.firstLevel
        }
      }
    },
    toCompare () {
      return {
        name: this.childRoutes.compare,
        params: {
          entityType: this.entityType.toLowerCase(),
          ...this.childRoutes.firstLevel
        },
        query: { ids: this.entitiesToCompare.join(',') }
      }
    }
  },
  watch: {
    pagination (nextPaginationObj, prevPaginationObj) {
      if (nextPaginationObj.page !== prevPaginationObj.page) {
        const searchActionObj = {
          ...this.customersSearchParams,
          page: nextPaginationObj.page
        }
        this.$store.dispatch('customers/updateCustomersSearchParams', searchActionObj)
      }
    },
    customersSearchParams () {
      this.pagination.page = this.customersSearchParams.page
    },
    entitiesToCompare (entitiesToCompare) {
      const { selectedEntities } = this

      if (selectedEntities.length > entitiesToCompare.length) {
        // remove entity from selectedEntities
        this.selectedEntities = selectedEntities.filter((entity) => {
          return this.entitiesToCompare.includes(entity.id)
        })
      } else if (selectedEntities.length < entitiesToCompare.length) {
        // add entity to selectedEntitiesIds
        const addEntityId = entitiesToCompare.filter((entityId) => {
          const index = selectedEntities.findIndex((entity) => entity.id === entityId)
          return index === -1
        })
        const addEntity = this.filteredEntities.filter((entity) =>
          addEntityId[0] === entity.id
        )
        this.selectedEntities.push(addEntity[0])
      }
    },
    isSelected (newVal) {
      if (!newVal) {
        this.entitiesToCompare = []
      } else {
        this.fetchData()
      }
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      const { user, banner } = this
      let defaultSource = 'my'
      if (user.userType === 'Mgr' || user.userType === 'AsstMgr') {
        defaultSource = 'branch'
      } else if (user.userType === 'RVP' || user.userType === 'SuperUser') {
        defaultSource = 'all'
      }
      const searchActionObj = {
        term: '',
        banner,
        source: defaultSource,
        filters: [],
        orderBy: 'sales desc',
        page: 1,
        ssoNumber: user.sso,
        branchId: parseInt(user.branchId, 10)
      }
      this.$store.dispatch('customers/updateCustomersSearchParams', searchActionObj)
    },
    clickRow (entity) {
      if (this.entitiesToCompare.includes(entity.id)) {
        this.entitiesToCompare = this.entitiesToCompare.filter((entityId) => entityId !== entity.id)
      } else {
        if (this.entitiesToCompare.length < 3) this.entitiesToCompare.push(entity.id)
      }
      if (!this.canCompare) {
        this.$router.push(this.toSingleEntity)
      }
    },
    removeFromComparison (id) {
      this.entitiesToCompare = this.entitiesToCompare.filter(
        (entityId) => entityId !== id
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~styl/colors';

.hidden {
  display: none;
}

.table-border-light {
  border-top: 1px solid $pastelGray;

  & ::v-deep .v-table__overflow {
    border-left: 1px solid $pastelGray;
    border-right: 1px solid $pastelGray;
  }
}

.bottom-compare.translucent-stone {
  background: rgba($bigStone, .95)
}
.position-relative {
  position: relative;
}

.align-self-stretch {
  align-self: stretch;

  .layout {
    height: 100%;
  }
}

.remove-button {
  position: absolute;
  top: -13px;
  right: -13px;

  .v-btn {
    height: 25px;
    width: 25px;
    .v-icon {
      height: auto;
      width: auto;
    }
  }
}

.checkbox {
  width: 24px;
}
</style>
