<template>
  <base-dialog
    :show="show"
    :valid="valid"
    @submit="submit"
    @close="close"
    @keydown.esc="close">
    <template slot="title">
      Edit Customer Details
    </template>

    <v-form
      slot="content"
      ref="form"
      v-model="valid">
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="model.address1"
            v-required
            :rules="rules.address1"
            label="Address Line 1"
            filled>
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="model.address2"
            label="Address Line 2 (Apartment, suite, unit, building, floor, etc.)"
            filled>
          </v-text-field>
        </v-col>
        <v-col
          cols="12"
          sm="8">
          <v-text-field
            v-model="model.city"
            v-required
            :rules="rules.city"
            label="City"
            filled>
          </v-text-field>
        </v-col>
        <v-col
          cols="12"
          sm="4">
          <v-select
            v-model="model.state"
            v-required
            :rules="rules.state"
            :items="states"
            label="State"
            filled>
          </v-select>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="model.postalCode"
            v-required
            :rules="rules.postalCode"
            label="Postal Code"
            filled>
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <v-select
            v-model="model.country"
            :items="countries"
            disabled
            label="Country"
            filled>
          </v-select>
        </v-col>
        <v-col
          v-if="user.banner === 'platt'"
          cols="12">
          <masked-input
            v-model="model.phone"
            mask="phone"
            filled
            label="Phone number"
            :rules="rules.phone">
          </masked-input>
        </v-col>
      </v-row>
    </v-form>
  </base-dialog>
</template>

<script>
import { updateCustomerSummary } from '@/api/customers'
import { customerFormRules as rules } from '@/rules/customerFormRules'
import { states } from '@/globals/constants'
import { MaskedInput } from '@/components/inputs'
import BaseDialog from '@/components/BaseDialog.vue'

export default {
  name: 'RequestEdit',
  components: {
    BaseDialog,
    MaskedInput
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    customer: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      rules,
      valid: false,
      states,
      countries: ['United States'],
      model: {}
    }
  },
  watch: {
    show (val) {
      if (val === true) {
        if (!this.customer.billTo) {
          const { customerId, phone, shippingAddress } = this.customer
          this.model = {
            customerId,
            phone,
            ...shippingAddress,
            country: 'United States' // this is contrained to US for now until DB can handle other countries
          }
        } else {
          const { customerId, phone, address } = this.customer
          this.model = {
            customerId,
            phone,
            ...address,
            country: 'United States' // this is contrained to US for now until DB can handle other countries
          }
        }
      }
    }
  },
  methods: {
    test (event) {
      console.log(event)
    },
    async submit () {
      if (this.$refs.form.validate()) {
        const name = `${this.user.firstName} ${this.user.lastName}`
        const payload = {
          ...this.model,
          requestorFullName: name,
          requestorEmail: this.user.email,
          creditRepEmail: this.customer.creditRepEmail
        }

        const { errors } = await updateCustomerSummary(payload)
        if (!errors) {
          this.close()
        }
      }
    },
    close () {
      this.$emit('update:show', false)
      this.$refs.form.reset()
    }
  }
}
</script>
