import {
  getCategoryList,
  getStatusList,
  getTypeList,
  getProbabilityList,
  getInitiativeList,
  getSpecialistList,
  getProductCategoryList,
  getBusinessUnitList,
  requestOpportunity
} from '@/api/opportunities'

const requestIfNotCached = async (commit, { getter, apiCall, mutation, params }) => {
  if (getter.length === 0) {
    const { data } = await apiCall(params)
    return commit(mutation, data)
  }
  return null
}

const getOpportunitiesOptions = async ({ commit, getters }, companyId) => {
  const {
    categories,
    statuses,
    types,
    probabilities,
    initiatives,
    specialists,
    productCategories,
    businessUnits
  } = getters

  // check if options are in store before requesting

  const options = [
    {
      getter: categories,
      apiCall: getCategoryList,
      mutation: 'setCategories'
    },
    {
      getter: statuses,
      apiCall: getStatusList,
      mutation: 'setStatuses'
    },
    {
      getter: types,
      apiCall: getTypeList,
      mutation: 'setTypes'
    },
    {
      getter: probabilities,
      apiCall: getProbabilityList,
      mutation: 'setProbabilities'
    },
    {
      getter: initiatives,
      apiCall: getInitiativeList,
      mutation: 'setInitiatives'
    },
    {
      getter: specialists,
      apiCall: getSpecialistList,
      params: companyId,
      mutation: 'setSpecialists'
    },
    {
      getter: productCategories,
      apiCall: getProductCategoryList,
      mutation: 'setProductCategories'
    },
    {
      getter: businessUnits,
      apiCall: getBusinessUnitList,
      mutation: 'setBusinessUnits'
    }
  ]

  const promises = options.map(async (option) => {
    const awaiting = await requestIfNotCached(commit, option)
    return awaiting
  })
  await Promise.all(promises)
}

const getStatuses = ({ commit, getters }) => {
  return requestIfNotCached(
    commit,
    {
      getter: getters.statuses,
      apiCall: getStatusList,
      mutation: 'setStatuses'
    }
  )
}

const getInitiatives = ({ commit, getters }) => {
  return requestIfNotCached(
    commit,
    {
      getter: getters.initiatives,
      apiCall: getInitiativeList,
      mutation: 'setInitiatives'
    }
  )
}

const getCategories = ({ commit, getters }) => {
  return requestIfNotCached(
    commit,
    {
      getter: getters.categories,
      apiCall: getCategoryList,
      mutation: 'setCategories'
    }
  )
}

const getOpportunity = async ({ commit }, id) => {
  const { data } = await requestOpportunity(id)
  return data
}

const resetOpportunitiesOptions = ({ state }) => {
  state.categories = []
  state.statuses = []
  state.types = []
  state.probabilities = []
  state.initiatives = []
  state.specialists = []
  state.productCategories = []
  state.businessUnits = []
}

const setSavedFilters = ({ commit }, savedFilters) => {
  commit('setSavedFilters', savedFilters)
}

export default {
  getOpportunitiesOptions,
  getOpportunity,
  getStatuses,
  getCategories,
  resetOpportunitiesOptions,
  setSavedFilters,
  getInitiatives
}
