<template>
  <base-dialog
    :show="show"
    @submit="submit"
    @close="close"
    @keydown.esc="close">
    <template slot="title">
      Customer Report Actions
    </template>
    <div
      slot="content"
      class="pl-6">
      <div class="pb-6">
        <h3>Select Actions To Be Included In All Supported Customer Reports</h3>
      </div>
      <v-form
        ref="form">
        <v-row>
          <v-col
            v-for="{title, formKey} in formCheckBoxData"
            :key="formKey"
            class="py-0"
            cols="12">
            <v-checkbox
              v-model="form[formKey]"
              class="my-0 pb-0"
              :label="title">
            </v-checkbox>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </base-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import BaseDialog from '@/components/BaseDialog.vue'

export default {
  name: 'CustomerActionSelectionModal',
  components: {
    BaseDialog
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    actionMenuItems: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      form: {}
    }
  },
  computed: {
    ...mapState('user', ['preferences']),
    optionalActionKeys () {
      return Object.keys(this.form)
    },
    formCheckBoxData () {
      return this.actionMenuItems.filter((item) => this.optionalActionKeys.includes(item.customerActionOption))
        .map(item => {
          return {
            title: item.title,
            formKey: item.customerActionOption
          }
        })
    }
  },
  mounted () {
    this.form = { ...this.preferences.customerListActions }
  },
  methods: {
    ...mapActions('user', ['postPreferences']),
    close () {
      this.$emit('update:show', false)
    },
    submit () {
      const preferences = { ...this.preferences, customerListActions: this.form }
      this.postPreferences(preferences).then(() => {
        this.close()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
