const initializeForm = ({ commit }, { name }) => {
  commit('initForm', { name })
}

const destroyForm = ({ commit }, name) => {
  commit('destroyForm', name)
}

const updateFormStatus = ({ commit }, { name, hasChanges }) => {
  commit('updateFormStatus', { name, hasChanges })
}

export default {
  initializeForm,
  destroyForm,
  updateFormStatus
}
