<template>
  <page-loading-progress v-if="loading">
  </page-loading-progress>
  <v-container
    v-else
    fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col md="4">
                <v-text-field
                  v-model="search"
                  hide-details
                  append-icon="search"
                  label="Search"
                  filled
                  @keypress.enter="searchWeb()">
                </v-text-field>
              </v-col>
              <v-col class="text-right">
                <download-link :href="exportReportUrl">
                  <v-btn
                    color="default"
                    fab
                    small>
                    <v-icon>file_download</v-icon>
                  </v-btn>
                </download-link>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <h4 class="text-right mr-2 pb-4">
              <em>Rolling 12 months</em>
            </h4>
            <v-data-table
              :headers="headers"
              :items="inactiveCustomersFormatted"
              :sort-by.sync="pagination.sortBy"
              :sort-desc.sync="pagination.sortDesc"
              :footer-props="{ 'items-per-page-options': [5, 10, 25] }"
              :search="search"
              :loading="loading">
              <template #item="{ item }">
                <tr>
                  <td>
                    <router-link :to="{ name: 'customer-profile', params: { customerId: item.customerId}}">
                      {{ item.customerNameAndNumber }}
                    </router-link>
                    {{ ` (${item.accountType})` }}
                  </td>
                  <td>{{ item.lastLoginFormatted }}</td>
                  <td>{{ item.lastOrderFormatted }}</td>
                  <td>{{ item.twelveMonthTotalFormatted }}</td>
                  <td>{{ item.distanceFromUserBranch | toFixedVal(1) }}</td>
                  <td>{{ item.closestBranchFormatted }}</td>
                  <td>
                    <v-menu
                      bottom
                      left
                      :close-on-content-click="true"
                      p>
                      <template #activator="{ on }">
                        <v-icon
                          data-qa="more_vert"
                          color="black"
                          v-on="on">
                          more_vert
                        </v-icon>
                      </template>
                      <v-list>
                        <v-list-item
                          @click="createActivity(item)">
                          <v-list-item-title>Create Activity</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </td>
                </tr>
              </template>
              <template slot="no-data">
                No data
              </template>
              <!-- TEMP FIX - Awaiting Vuetify fix for issue: https://github.com/vuetifyjs/vuetify/issues/14405 -->
              <!-- eslint-disable -->
            <template v-slot:footer.page-text="props">
              {{ props.pageStart }}-{{ props.pageStop }} of {{ props.itemsLength.toLocaleString('en-us') }}
            </template>
            <!-- eslint-enable -->
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { formatDate, formatMoney } from '@/globals/formatters'
import PageLoadingProgress from '@/components/PageLoadingProgress.vue'
import DownloadLink from '@/components/DownloadLink.vue'
import { inactiveCustomersReportUrl } from '@/api/reports'

export default {
  name: 'InactiveCustomersReportBase',
  components: {
    PageLoadingProgress,
    DownloadLink
  },
  filters: {
    toFixedVal (val, precision) {
      if (!val || isNaN(val)) {
        return '0'
      }
      return val.toFixed(precision)
    }
  },
  props: {
    inactiveCustomers: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: false,
      default: true
    },
    exportLoading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      headers: [
        {
          text: 'Customer',
          align: 'left',
          sortable: true,
          value: 'customerNameAndNumber'
        },
        {
          text: 'Last Login',
          align: 'left',
          sortable: true,
          value: 'lastLoginDateSort'
        },
        {
          text: 'Last Order',
          align: 'left',
          sortable: true,
          value: 'lastOrderDateSort'
        },
        {
          text: '12 Month Total',
          align: 'left',
          sortable: true,
          value: '?'
        },
        {
          text: 'Miles From Branch',
          align: 'left',
          sortable: true,
          value: 'distanceFromUserBranch'
        },
        {
          text: 'Home Branch',
          align: 'left',
          sortable: true,
          value: 'userBranch'
        },
        {
          sortable: false,
          slot: 'action',
          type: 'action'
        }
      ],
      pagination: {
        sortBy: 'lastLoginDateSort',
        sortDesc: false,
        itemsPerPage: 25
      },
      search: null
    }
  },
  computed: {
    exportReportUrl () {
      return inactiveCustomersReportUrl(this.$store.getters['user/displayName'])
    },
    syncedLoading: {
      get: function () {
        return this.loading
      },
      set: function (value) {
        this.$emit('update:loading', value)
      }
    },
    // TODO Refactor: This should really just rely on filters for value formatting, and proper sorting for pushing null/blank values to the end.
    inactiveCustomersFormatted () {
      if (!this.inactiveCustomers) {
        return []
      }

      return this.inactiveCustomers.map((x) => ({
        ...x,
        lastLoginFormatted: x.lastLoginDate ? formatDate(x.lastLoginDate) : '-',
        lastLoginDateSort: x.lastLoginDate
          ? x.lastLoginDate
          : '2222-12-31T00:00:00', // Move our empty ones to the end
        lastOrderFormatted: x.lastOrderDate ? formatDate(x.lastOrderDate) : '-',
        lastOrderDateSort: x.lastOrderDate
          ? x.lastOrderDate
          : '2222-12-31T00:00:00', // Move our empty ones to the end
        twelveMonthTotalFormatted: formatMoney(x.last365OrderTotal),
        closestBranchFormatted: `${x.userBranch} - ${x.userBranchName}`
      }))
    }
  },
  methods: {
    createActivity (customer) {
      this.$router.push({
        name: 'create-activity',
        query: {
          companyId: customer.companyId,
          customerId: customer.customerId
        }
      })
    }
  }
}
</script>
