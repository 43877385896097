<template>
  <v-snackbar
    v-model="isOpen"
    :timeout="timeout"
    :top="true"
    :class="statusClass"
    data-qa="toastWrapper"
    multi-line
    color="white"
    class="toast">
    <div class="d-flex align-center justify-space-between ml-2">
      <div
        v-if="toast"
        data-qa="confirmationMessage">
        <div
          v-if="toast.icon"
          class="icon-container text-center">
          <v-icon :class="statusClass">
            {{ toast.icon }}
          </v-icon>
        </div>

        <div class="message text-center">
          {{ toast.message }}
        </div>
      </div>

      <v-btn
        icon
        class="ml-auto px-0"
        color="secondary"
        @click.native="isOpen = false">
        <v-icon>close</v-icon>
      </v-btn>
    </div>
  </v-snackbar>
</template>

<script>
export default {
  name: 'Toast',
  props: {
    toast: {
      type: [Object, String],
      required: false,
      default: null
    }
  },
  data () {
    return {
      isOpen: false
    }
  },
  computed: {
    open () {
      return !!this.toast
    },
    status () {
      if (this.toast && this.toast.status) {
        return this.toast.status
      }
      return 'success'
    },
    statusClass () {
      return {
        toastSuccess: this.status === 'success',
        toastError: this.status === 'error',
        toastWarning: this.status === 'warning'
      }
    },
    timeout () {
      if (this.toast && !isNaN(this.toast.timeout)) {
        return this.toast.timeout
      }
      return 3000
    }
  },
  watch: {
    toast (val) {
      if (val) {
        this.isOpen = true
      } else {
        this.isOpen = false
      }
    },
    isOpen (val) {
      if (val === false) {
        this.$emit('close')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../assets/styl/colors';
.toast ::v-deep .v-snack__content {
  // This negative margin is to deal with the Vuetify 2+ upgrade
  margin-right: -8px;
  min-height: 68px;
}

.message {
  font-size: 1.3em;
  font-weight: 400;
  color: black;
}

.toastSuccess ::v-deep .v-snack__content {
  border-bottom: 3px solid $primary;
}

.toastError ::v-deep .v-snack__content {
  border-bottom: 3px solid $error;
}

.toastWarning ::v-deep .v-snack__content {
  border-bottom: 3px solid $warning;
}

@media (min-width: 600px) {
  .toast ::v-deep .v-snack__content {
    width: 360px;
  }
}
</style>
