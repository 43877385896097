<template>
  <router-link
    v-if="to"
    :class="classes"
    :to="to">
    <slot></slot>
  </router-link>
  <a
    v-else-if="href"
    :class="classes"
    :href="href"
    @click.stop>
    <slot></slot>
  </a>
  <a
    v-else
    :class="classes"
    @click.stop="$emit('click')">
    <slot></slot>
  </a>
</template>

<script>
export default {
  name: 'Anchor',
  props: {
    to: {
      type: Object,
      required: false,
      default: null
    },
    href: {
      type: String,
      required: false,
      default: null
    },
    bold: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    classes () {
      if (this.bold) {
        return 'anchor font-weight-bold'
      }

      return 'anchor'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~styl/colors';

.anchor {
  color: $info;
  text-decoration: underline;
}
</style>
