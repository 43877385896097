import { fetchEmployeeSalesStats, fetchEmployeeDetails } from '@/api/employees'

const getEmployeeSalesStats = ({ commit }, { sso, banner }) => {
  fetchEmployeeSalesStats(sso, banner).then((response) => {
    commit('setEmployeeSalesStats', { ...response.data, sso })
  })
}

const getEmployeeDetails = ({ commit }, { sso, banner }) =>
  fetchEmployeeDetails(sso, banner).then((response) => {
    commit('setEmployeeDetails', { ...response.data, sso })
  })

export default {
  getEmployeeSalesStats,
  getEmployeeDetails
}
