import SalesByCategoryReport from '@/pages/Reports/SalesByCategoryReport/SalesByCategoryTabs.vue'
import SalesByCategoryCategoryListing from '@/pages/Reports/SalesByCategoryReport/CategoryListing/CategoryListing.vue'

/*
  Routes for Sales By Category Reports

  entityTypes = [customer, branch, region, employee]
    - company is treated as a special case since it has no comparison

  categoryLevels = [section, group, category, subcategory, item, (product)]
*/

export const salesByCategoryRoutes = [
  {
    path: 'reports/sales-by-category',
    name: 'sales-by-category-report',
    component: SalesByCategoryReport,
    meta: {
      title: 'Sales By Category',
      plattUserOnly: true
    }
  }, {
    // company-wide route
    path: 'reports/sales-by-category/listing/company/:categoryLevel/:categoryId?',
    name: 'sales-by-category-report-list-company',
    component: SalesByCategoryCategoryListing,
    meta: {
      title: 'Sales By Category Listing',
      plattUserOnly: true
    },
    props: (route) => {
      return {
        ...route.params,
        id: null,
        entityType: 'company'
      }
    }
  }, {
    // entity comparison route
    path: 'reports/sales-by-category/compare/:entityType/:categoryLevel/:categoryId?',
    name: 'sales-by-category-report-list-compare',
    component: SalesByCategoryCategoryListing,
    meta: {
      title: 'Sales By Category: Compare',
      plattUserOnly: true
    },
    props: (route) => {
      // cast ids as array of numbers
      const ids = (route.query.ids || '')
        .split(',')
        .map((id) => Number.parseInt(id))
      return {
        ...route.params,
        ids
      }
    }
  }, {
    // single entity (not company)
    path: 'reports/sales-by-category/listing/:entityType/:id/:categoryLevel/:categoryId?',
    name: 'sales-by-category-report-list',
    component: SalesByCategoryCategoryListing,
    meta: {
      title: 'Sales By Category: Report List',
      plattUserOnly: true
    },
    props: (route) => {
      // cast id as number, wrap in array
      const id = Number.parseInt(route.params.id)
      return {
        ...route.params,
        ids: [id]
      }
    }
  }
]
