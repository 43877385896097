<template>
  <v-container
    fluid
    class="pb-1">
    <v-row no-gutters>
      <v-col
        v-if="$vuetify.breakpoint.smAndUp"
        cols="auto"
        class="flex-grow-1">
        <h2
          class="header-text">
          {{ actionsToTakeLabel }}
        </h2>
      </v-col>
      <v-spacer></v-spacer>
      <v-col
        cols="auto"
        class="text-right shrink">
        <portal-target
          name="simplified-dashboard-toggle"
          slim>
        </portal-target>
      </v-col>
    </v-row>
    <v-expansion-panels
      v-if="totalActions > 0"
      v-model="openedArray"
      tile
      class="">
      <v-expansion-panel>
        <v-expansion-panel-header
          color="grey"
          class="dense py-3">
          <action-plan-row
            :action-count="totalActions"
            :action-text="actionHeaderText">
          </action-plan-row>
        </v-expansion-panel-header>

        <v-expansion-panel-content class="pa-0 action-plan-cards">
          <v-col
            v-for="(action, i) in actionPlanSorted"
            :key="i"
            class="px-0 py-2 action-plan-col"
            cols="12">
            <v-card
              tile
              elevation="0"
              class="px-6">
              <action-plan-row
                :action-count="action.reportLines"
                :report-id="action.reportId"
                class="">
              </action-plan-row>
            </v-card>
          </v-col>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import ActionPlanRow from '@/components/ActionPlan/ActionPlanRow.vue'

export default {
  name: 'ActionPlanHeader',
  components: {
    ActionPlanRow
  },
  props: {
    totalActions: {
      type: Number,
      required: true
    },
    actionPlans: {
      type: Array,
      required: true
    },
    expanded: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    actionHeaderText () {
      if (this.totalActions === 1) {
        return 'Item Needing Action'
      }

      return 'Items Needing Action'
    },
    actionPlanSorted () {
      const precedenceOfReportIds = [2, 3, 1]

      return [...this.actionPlans].sort((a, b) => {
        return precedenceOfReportIds.indexOf(a.reportId) - precedenceOfReportIds.indexOf(b.reportId)
      })
    },
    actionsToTakeLabel () {
      return this.totalActions > 0 && this.$vuetify.breakpoint.smAndUp ? 'Actions to take' : ''
    },
    openedArray: {
      get: function () {
        if (this.totalActions === 0) {
          return 0
        }
        return this.expanded === true ? 0 : null
      },
      set: function (expandableValue) {
        this.$emit('updateExpanded', expandableValue === 0)
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import '~styl/colors';

  .action-plan-cards {
    .action-plan-col {
      border-bottom: 1px solid $lightGrey;
      &:last-child {
        border-bottom: none;
      }
    }
  }

  .action-plan-header {
    &__card
      &.theme--light.v-sheet {
        border-bottom: 1px solid $lightGrey;
        &:last-child {
          border-bottom: none;
        }
      }
    &__item {
      padding: .75rem 1.75rem;
    }
    ::v-deep .v-expansion-panel__header {
      background-color: $actionHeaderBg;
    }
  }

</style>
