<template>
  <!-- TODO: v-select -->
  <v-select
    v-model="value"
    :items="items"
    :label="label"
    append-icon="filter_list"
    class="append-icon-no-transform"
    single-line
    multiple
    hide-details
    filled>
    <template
      #selection="{ index }">
      <div v-if="index === 0">
        {{ label }}
      </div>
    </template>
    <template
      #prepend-item>
      <v-list-item
        ripple
        :disabled="noneSelected"
        @click="toggle">
        <v-list-item-content>
          <v-list-item-title>Clear Filters</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>
    <template
      #item="{ item }">
      <v-list-item
        v-if="item.sectionHeader"
        style="display:block"
        class="mt-1">
        <v-list-item-content
          class="v-label theme--light">
          <div>
            {{ item.sectionHeader }}
          </div>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-else
        ripple
        @click.stop="select(item)">
        <v-list-item-action>
          <v-icon color="primary">
            {{ value.includes(item.value) ? 'check_box' : 'check_box_outline_blank' }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="d-flex align-center">
            <img
              v-if="item.image"
              :src="item.image"
              class="ml-1 mr-2 shrink" />
            <v-icon
              v-if="item.icon"
              class="mr-1 shrink"
              color="primary">
              {{ item.icon }}
            </v-icon>
            <span>{{ item.text }}</span>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-select>
</template>

<script>

export default {
  name: 'SelectFilter',
  props: {
    selected: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      required: false,
      default: 'Filter'
    }
  },
  data () {
    return {
      value: []
    }
  },
  computed: {
    noneSelected () {
      return this.selected.length === 0
    }
  },
  watch: {
    value (newVal) {
      this.$emit('update:selected', newVal)
    },
    selected (newVal) {
      this.value = newVal
    }
  },
  mounted () {
    this.value = this.selected
  },
  methods: {
    toggle () {
      this.$nextTick(() => {
        this.value = []
      })
    },
    select ({ value }) {
      const index = this.value.findIndex(filter => filter === value)
      if (index > -1) {
        this.value.splice(index, 1)
      } else {
        this.value.push(value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-menu__content .v-list__tile__action {
  min-width: 32px
}
</style>
