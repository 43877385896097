import { fetchVendorSalesPlans, saveVendorSalesPlan } from '@/api/salesPlanning'

const getSalesPlans = ({ commit }) => {
  commit('setIsFetching', true)

  return fetchVendorSalesPlans()
    .then((res) => {
      commit('setSalesPlans', res.data)
      commit('setIsFetching', false)
    })
}

const updateSalesPlan = ({ commit }, updatedPlan) => {
  commit('setIsSaving', true)

  return saveVendorSalesPlan(updatedPlan)
    .then(() => {
      const { salesGoal, gmGoal, vendorNumber, mdmBuyline, budgetYear, propertySuffix } = updatedPlan
      const vendorKey = mdmBuyline || vendorNumber
      commit('setIsSaving', false)
      commit('updateSalesPlan', { salesGoal, gmGoal, vendorKey, budgetYear, propertySuffix })
    })
}

export default {
  getSalesPlans,
  updateSalesPlan
}
