import {
  LifecycleAtHighRiskReport,
  LifecycleAtRiskReport,
  LifecycleNewCustomersReport,
  LifecycleActivePlusReport,
  LifecycleActiveReport,
  LifecycleMonthlyActiveReport,
  LifecycleInactiveReport,
  LifecycleFirstOrderReport,
  LifecycleThirdOrderReport,
  LifecycleLeadsReport
} from '@/pages/Reports/CustomerLifecycle'

export const customerLifecycleReportRoutes = [
  {
    path: 'reports/customer-lifecycle',
    name: 'CustomerLifecycleReports',
    component: {
      render (createElement) {
        return createElement('router-view')
      }
    },
    children: [
      {
        path: 'at-high-risk',
        component: LifecycleAtHighRiskReport,
        name: 'CustomerLifecycle--AtHighRisk',
        meta: { title: 'Customer Lifecycle - At High Risk' }
      },
      {
        path: 'at-risk',
        component: LifecycleAtRiskReport,
        name: 'CustomerLifecycle--AtRisk',
        meta: { title: 'Customer Lifecycle - At Risk' }
      },
      {
        path: 'new-customers',
        component: LifecycleNewCustomersReport,
        name: 'CustomerLifecycle--NewCustomers',
        meta: { title: 'Customer Lifecycle - New Customers' }
      },
      {
        path: 'active',
        component: LifecycleActiveReport,
        name: 'CustomerLifecycle--Active',
        meta: { title: 'Customer Lifecycle - Active' }
      },
      {
        path: 'active-plus',
        component: LifecycleActivePlusReport,
        name: 'CustomerLifecycle--ActivePlus',
        meta: { title: 'Customer Lifecycle - Active Plus' }
      },
      {
        path: 'monthly-active',
        component: LifecycleMonthlyActiveReport,
        name: 'CustomerLifecycle--MonthlyActive',
        meta: { title: 'Customer Lifecycle - Monthly Active' }
      },
      {
        path: 'inactive',
        component: LifecycleInactiveReport,
        name: 'CustomerLifecycle--Inactive',
        meta: { title: 'Customer Lifecycle - Inactive' }
      },
      {
        path: 'first-order',
        component: LifecycleFirstOrderReport,
        name: 'CustomerLifecycle--FirstOrder',
        meta: { title: 'Customer Lifecycle - First Order' }
      },
      {
        path: 'third-order',
        component: LifecycleThirdOrderReport,
        name: 'CustomerLifecycle--ThirdOrder',
        meta: { title: 'Customer Lifecycle - Third Order' }
      },
      {
        path: 'leads',
        component: LifecycleLeadsReport,
        name: 'CustomerLifecycle--Leads',
        meta: { title: 'Customer Lifecycle - Leads' }
      }
    ]
  }
]
