<template>
  <v-container
    fluid>
    <v-row
      v-if="$vuetify.breakpoint.smAndUp"
      class="fluid"
      align="center"
      :reverse="$vuetify.breakpoint.xs">
      <v-col
        class="pl-6 text-subtitle-1 font-weight-medium">
        <template v-if="isComparison">
          Selected {{ entityTypePlural | formatTitleCase }}
        </template>
        <template v-else-if="entityType !== 'company'">
          Selected {{ entityType | formatTitleCase }}
        </template>
      </v-col>
      <v-col
        class="text-right">
        <v-btn
          :to="{name: 'top-sold-products-report'}"
          color="primary">
          Back to Product Report
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      v-else>
      <v-col
        cols="12">
        <v-btn
          block
          :to="{name: 'top-sold-products-report'}"
          color="primary">
          Back to Product Report
        </v-btn>
        <v-col
          class="pl-4 pt-4 text-subtitle-1 font-weight-medium">
          <template v-if="isComparison">
            Selected {{ entityTypePlural | formatTitleCase }}
          </template>
          <template v-else-if="entityType !== 'company'">
            Selected {{ entityType | formatTitleCase }}
          </template>
        </v-col>
      </v-col>
    </v-row>
    <entity-summary
      :entity-type="entityType"
      :ids="ids"
      :color-by-id="colorById"
      class="mb-4"
      @loaded="summaryLoading = false">
    </entity-summary>
    <template v-if="!isProductLevel && !summaryLoading">
      <template v-if="isComparison">
        <div class="pa-4 text-subtitle-1 font-weight-medium">
          Sales Totals by Month
        </div>
        <product-chart-compare
          :color-by-id="colorById"
          :loading="loading"
          :monthly-data="monthlyChartData"
          :entity-type="entityType"
          :qualified-entity-ids="qualifiedIds">
        </product-chart-compare>
        <product-table-compare
          :loading="loading"
          :sales-data="productSales"
          :entity-type="entityType"
          :qualified-entity-ids="qualifiedIds"
          :total-product-sales="totalProductSales"
          :initial-start-date="initialStartDate"
          :initial-end-date="initialEndDate"
          @refetch="fetchTableData">
        </product-table-compare>
      </template>
      <template v-else>
        <v-row
          v-if="entityType === 'customer'">
          <v-col
            sm="12"
            md="3"
            :class="{'pr-3':$vuetify.breakpoint.mdAndUp, 'mb-3':$vuetify.breakpoint.smAndUp}">
            <div class="pa-4 text-subtitle-1 font-weight-medium">
              Branch Info
            </div>
            <branch-info
              :hide-title="true"></branch-info>
          </v-col>
          <v-col
            sm="12"
            md="9">
            <div class="pa-4 text-subtitle-1 font-weight-medium">
              Sales Totals by Month
            </div>
            <product-chart
              :yearly-data="yearlyChartData"
              :monthly-data="monthlyChartData"
              :loading="loading">
            </product-chart>
          </v-col>
        </v-row>
        <v-row
          v-else>
          <v-col>
            <div class="pa-4 text-subtitle-1 font-weight-medium">
              Sales Totals by Month
            </div>
            <product-chart
              :yearly-data="yearlyChartData"
              :monthly-data="monthlyChartData"
              :loading="loading">
            </product-chart>
          </v-col>
        </v-row>
        <product-table
          :loading="loading"
          :sales-data="productSales"
          :entity-id="singleId"
          :entity-type="entityType"
          :show-preferred="banner === 'platt' && entityType === 'customer'"
          :initial-start-date="initialStartDate"
          :initial-end-date="initialEndDate"
          @refetch="fetchTableData">
        </product-table>
      </template>
    </template>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { formatDate } from '@/globals/formatters'
import { getDateYearBefore, startOfMonth } from '@/globals/time'
import EntityParams from './ListingEntityParams.mixin'
import EntitySummary from '../../shared/EntitySummary.vue'
import ProductChart from './ProductChart.vue'
import ProductChartCompare from './ProductChartCompare.vue'
import ProductTable from './ProductTable.vue'
import ProductTableCompare from './ProductTableCompare.vue'
import BranchInfo from '@/pages/CustomerProfile/BranchInfo.vue'

export default {
  name: 'ProductListing',
  components: {
    EntitySummary,
    ProductChart,
    ProductChartCompare,
    ProductTable,
    ProductTableCompare,
    BranchInfo
  },
  mixins: [EntityParams],
  props: {
    entityType: {
      // route param
      type: String,
      required: true,
      default: null
    },
    ids: {
      // route param
      type: Array,
      required: false,
      default: () => []
    }
  },
  data () {
    return {
      summaryLoading: false,
      loading: false,
      productSales: [],
      totalProductSales: 0,
      productSalesFilterHash: null,
      yearlyChartData: [],
      monthlyChartData: [],

      initialStartDate: formatDate(startOfMonth(getDateYearBefore()), 'YYYY-MM-DD'),
      initialEndDate: formatDate(startOfMonth(new Date()), 'YYYY-MM-DD')
    }
  },
  computed: {
    ...mapGetters('user', ['banner']),
    isProductLevel () {
      return this.$route.params.productLevel === 'product'
    },
    productId () {
      return this.$route.params.productId
    },
    isComparison () {
      return this.ids.length > 1
    },
    entityIsCompany () {
      return this.entityType === 'company'
    },
    singleId () {
      return this.ids[0]
    },
    qualifiedIds () {
      // qualified ID is banner neutral id (e.g. bannerBranchNumber)
      return this.ids.map((id, index) => {
        const prefetchId = this.prefetchedIds[index]
        return { id, qualified: prefetchId }
      })
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (newVal) {
        if (this.isValidEntityType) {
          const { preFetch } = this.entityDataParams.lookupEntity
          if (preFetch) {
            this.loading = true
            const getter = async () => {
              await this.getIds()
              this.fetchData()
            }
            getter()
          } else {
            this.fetchData()
          }
        }
      }
    },
    isValidEntityType: {
      immediate: true,
      handler (newVal) {
        if (!newVal) this.$router.push({ name: 'top-sold-products-report' })
      }
    }
  },
  beforeMount () {
    if (this.entityType === 'customer') {
      this.getCustomer(this.singleId)
    }

    this.$store.commit('setPageHeader', {
      page: 'Reports',
      text: 'Top Sold Products'
    })
    if (this.entityType !== 'company') this.summaryLoading = true
  },
  methods: {
    ...mapActions('currentCustomer', ['getCustomer']),
    fetchData () {
      this.fetchTableData({
        initialStartDate: this.initialStartDate,
        initialEndDate: this.initialEndDate
      })
      this.fetchChartData()
    },
    async fetchTableData (options = {}) {
      const { pagination, filters, search } = options
      const { isComparison, entityIsCompany, fetchMethod, refetchRules, entityIdentifier, ids, singleId } = this
      const hasFilters = !!filters && Object.keys(filters).length > 0
      const objectToString = (obj) => Object.entries(obj).toString()

      this.loading = true

      let fetchArgs = {
        startDate: filters ? filters.startDate : this.initialStartDate,
        endDate: filters ? filters.endDate : this.initialEndDate,
        sortBy: filters ? filters.sortBy : this.sortBy,
        catName: filters ? filters.catName : this.catName
      }
      if (isComparison) {
        const entIds = this.prefetchedIds.filter(Boolean).length ? this.prefetchedIds.join(',') : ids.join(',')
        if (entIds.length === 0) return // NOTE: fetchMethod will fail if call made w/o ids in fetchArgs // ids sometimes not ready when fetchTableData is called
        fetchArgs[entityIdentifier + 's'] = entIds
      } else {
        fetchArgs[entityIdentifier] = entityIsCompany
          ? null
          : this.prefetchedSingleId || singleId
      }

      if (refetchRules.onPagination) {
        fetchArgs = { ...fetchArgs, ...pagination, search }
      }
      if (refetchRules.onFilter) {
        // When searching by a debounced filter the responses can come back with variable timing.
        // We need to verify the results match the last search (the last one typed in the search field).
        // In this case, productSalesFilterHash is a (very simple) hash of the last filters object used for comparison below.
        if (hasFilters) this.productSalesFilterHash = objectToString(filters)
        fetchArgs = { ...fetchArgs, ...filters }
      }

      const { identifier } = this.entityDataParams
      try {
        const res = await fetchMethod(fetchArgs)
        if (hasFilters && refetchRules.onFilter && this.productSalesFilterHash !== objectToString(filters)) {
          // hash does not match, drop data response
        } else if ((identifier === 'branchId' && ids.length === 1) || identifier === 'customerId' || this.entityType === 'company') {
          this.productSales = res.data
        } else if (identifier === 'branchId' && ids.length > 1) {
          this.productSales = res.data.topSoldProducts
          this.totalProductSales = res.data.totalTopSoldProductsToCompare
        }
      } catch (e) {
        console.error(e)
        this.$store.commit('setToastMessage', {
          message: 'Error fetching sales data',
          status: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async fetchChartData () {
      const { isComparison, entityIsCompany, fetchChartMethod, entityIdentifier, ids, singleId } = this

      const fetchArgs = {}
      if (isComparison) {
        const entIds = this.prefetchedIds.filter(Boolean).length ? this.prefetchedIds.join(',') : ids.join(',')
        fetchArgs[entityIdentifier + 's'] = entIds
      } else {
        fetchArgs[entityIdentifier] = entityIsCompany
          ? null
          : this.prefetchedSingleId || singleId
      }
      try {
        const { data } = await fetchChartMethod(fetchArgs)
        const { yearlySalesAccessor, monthlySalesAccessor } = this.entityDataParams
        this.yearlyChartData = yearlySalesAccessor ? yearlySalesAccessor(data) : data
        this.monthlyChartData = monthlySalesAccessor ? monthlySalesAccessor(data) : data
      } catch (e) {
        console.error(e)
        this.$store.commit('setToastMessage', {
          message: 'Error fetching sales chart data',
          status: 'error'
        })
      }
    },
    colorById (id) {
      const { ids, prefetchedIds } = this
      const { graphPrimary, graphSecondary, graphTertiary } = this.$vuetify.theme.currentTheme
      // safely converts both to ints before comparing
      let index = ids.findIndex(i => ~~i === ~~id)
      if (index === -1) index = prefetchedIds.indexOf(id + '')
      if (index === -1) return graphPrimary
      return [graphPrimary, graphSecondary, graphTertiary][index]
    }
  }
}
</script>
