<template>
  <div class="customer-summary-info d-flex text-left">
    <div class="hidden-sm-and-down pa-6">
      <v-avatar
        size="68"
        :class="summaryCustomer.website ? 'primary elevation-4 customer-avatar-gradient pointer' : 'primary elevation-4 customer-avatar-gradient'"
        @click="goToCustomerWebsite(false)">
        <span class="white--text text-h4">
          {{ initials }}
        </span>
      </v-avatar>
    </div>
    <div>
      <h2
        class="font-weight-black"
        :class="summaryCustomer.website ? 'pointer' : ''"
        :style="$vuetify.breakpoint.sm ? 'font-size: 1.2em' : ''">
        <span
          v-if="linked && summaryCustomer.customerId"
          @click="goToCustomerWebsite(linked)">
          <router-link :to="{name: 'customer-profile', params: {customerId: summaryCustomer.customerId}}">
            <span
              v-if="$vuetify.breakpoint.sm">
              {{ customerShortTitle }}
            </span>
            <span v-else>
              {{ summaryCustomer | formatCustomerTitle }}
            </span>
          </router-link>
        </span>
        <span
          v-else
          data-qa="customerName"
          @click="goToCustomerWebsite(linked)">
          <span
            v-if="$vuetify.breakpoint.sm">{{ customerShortTitle }}</span>
          <span v-else>
            {{ summaryCustomer | formatCustomerTitle }}
          </span>
        </span>
        <span>
          <v-tooltip
            :disabled="$vuetify.breakpoint.smAndDown"
            top>
            <template #activator="{ on }">
              <v-icon
                color="primary"
                class="clickable"
                v-on="on"
                @click="bookmarkAction">
                {{ bookmarkIcon }}
              </v-icon>
            </template>
            <span>
              {{ bookmarkTooltipLabel }}
            </span>
          </v-tooltip>
        </span>
      </h2>
      <div class="customer-summary__info font-italic">
        <span class="font-weight-bold mr-2 accent--text">
          {{ bannerName }}
        </span>
        <span class="font-weight-bold mr-2">
          {{ summaryCustomer.customerSegment || 'Other' }}
        </span>
        <span class="light-grey">
          Customer since: {{ summaryCustomer.accountOpenedDate | formatDate('MM/YYYY') }}
        </span>
      </div>
      <div class="summary-row">
        <v-icon class="address-phone-icon">
          location_on
        </v-icon>
        <a
          v-if="summaryCustomer.billTo"
          data-qa="customerAddress"
          :href="`http://maps.google.com/?q=${formatAddress(summaryCustomer.address)}`"
          target="_blank">
          {{ summaryCustomer.address | formatAddress }}
        </a>
        <a
          v-else
          data-qa="customerAddress"
          :href="`http://maps.google.com/?q=${formatAddress(summaryCustomer.shippingAddress)}`"
          target="_blank">
          {{ summaryCustomer.shippingAddress | formatAddress }}
        </a>
      </div>
      <div
        v-if="summaryCustomer.phone"
        class="summary-row">
        <v-icon class="address-phone-icon">
          phone
        </v-icon>
        <a
          data-qa="customerPhone"
          :href="`tel:${summaryCustomer.phone}`">
          {{ summaryCustomer.phone }}
        </a>
      </div>
      <div
        v-if="summaryCustomer.website"
        class="summary-row">
        <v-icon>link</v-icon>
        <a
          class="website-link"
          @click="goToCustomerWebsite(false)">
          {{ summaryCustomer.website }}
        </a>
      </div>
      <slot name="badges"></slot>
    </div>
  </div>
</template>

<script>
import { formatAddress } from '@/globals/formatters'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CustomerSummaryInfo',
  props: {
    linked: {
      type: Boolean,
      required: false,
      default: false
    },
    summaryCustomer: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('user', ['banner', 'isPlattBanner']),
    ...mapGetters('currentCustomer', ['customerShortTitle']),
    bannerName () {
      const bannerId = this.summaryCustomer?.companyId
      switch (bannerId) {
        case 1:
          return 'Platt'
        case 2:
          return 'Rexel USA'
        case 3:
          return 'Gexpro'
        case 7:
          return 'Capitol Light'
        case 10:
          return 'Mayer'
        default:
          return null
      }
    },
    bookmarkIcon () {
      return this.summaryCustomer.isFollowed ? 'bookmark' : 'bookmark_border'
    },
    bookmarkTooltipLabel () {
      return this.summaryCustomer.isFollowed ? 'Unfollow customer' : 'Follow customer'
    },
    initials () {
      if (!this.summaryCustomer.name) return null
      return this.summaryCustomer.name
        .split(' ')
        .map((x) => x[0])
        .slice(0, 3)
        .join('')
    }
  },
  methods: {
    ...mapActions('currentCustomer', ['setFollowed']),
    bookmarkAction () {
      const { customerId, isFollowed } = this.summaryCustomer
      const setIsFollowed = !isFollowed
      this.setFollowed({ customerId, isFollowed: setIsFollowed })
      this.$emit('bookmarkAction', setIsFollowed)
    },
    formatAddress (addr) {
      return formatAddress(addr)
    },
    goToCustomerWebsite (profileLink) {
      if (profileLink || !this.summaryCustomer.website) {
        return
      }
      const webAddress = this.summaryCustomer.website.startsWith('http') ? this.summaryCustomer.website : `https://${this.summaryCustomer.website}`
      window.open(webAddress, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~styl/colors';
.address-phone-icon {
  font-size: 18px;
  color: #acacac
}
.light-grey {
  color: $lightGrey;
}
.pointer {
  cursor: pointer;
}
.summary-row {
  color: $lightGrey;
  .icon {
    font-size: 17px;
  }
}
.website-link {
  text-decoration: underline;
  cursor: pointer;
  i {
    text-decoration: none;
  }
}
</style>
