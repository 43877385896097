<template>
  <!-- Both of these components should have their shared markup broken out into components that they share instead of being duplicated. -->

  <customer-list-tile-desktop
    v-if="$vuetify.breakpoint.smAndUp"
    :customer="customer"
    :prepend-sales-stats="prependSalesStats"
    :append-status="appendStatus"
    :flex-it="flexIt">
    <template #subtitle>
      <slot name="subtitle">
        <customer-list-tile-subtitle
          :customer="customer"
          :banner="getBannerName(customer.companyId)">
        </customer-list-tile-subtitle>
      </slot>
    </template>
  </customer-list-tile-desktop>

  <customer-list-tile-mobile
    v-else
    :customer="customer"
    :prepend-sales-stats="prependSalesStats"
    :append-status="appendStatus"
    :flex-it="flexIt">
    <template #subtitle>
      <slot name="subtitle">
        <customer-list-tile-subtitle
          :customer="customer"
          :banner="getBannerName(customer.companyId)">
        </customer-list-tile-subtitle>
      </slot>
    </template>
  </customer-list-tile-mobile>
</template>
<script>
import { mapGetters } from 'vuex'

import CustomerListTileDesktop from './CustomerListTileDesktop.vue'
import CustomerListTileMobile from './CustomerListTileMobile.vue'
import CustomerListTileSubtitle from './CustomerListTileSubtitle.vue'
import { bannerProvider } from '@/features/banners'

export default {
  name: 'CustomerListTileLayout',
  components: {
    CustomerListTileDesktop,
    CustomerListTileMobile,
    CustomerListTileSubtitle
  },
  props: {
    customer: {
      type: Object,
      required: true
    },
    prependSalesStats: {
      type: Boolean,
      default: true
    },
    appendStatus: {
      type: Boolean,
      default: true
    },
    flexIt: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters('user', ['banner'])
  },
  methods: {
    shipToBillToStatus (customer) {
      const statuses = []
      if (customer.jobAccountFlag) {
        statuses.push('Job Account')
        return `(${statuses.join(', ')})`
      }
      if (customer.shipToFlag) {
        statuses.push('Ship-To')
      }
      if (customer.billToFlag) {
        statuses.push('Bill-To')
      }
      return `${statuses.join(', ')}`
    },
    getBannerName (companyId) {
      return bannerProvider.getNameFromId(companyId)
    }
  }
}
</script>
<style  lang="scss" scoped>
@import '~styl/colors';

.vertical-align-items * {
  vertical-align: middle;
}

.customer-status {
  display: inline-block;
  height: 10px;
  width: 10px;

  border-radius: 50%;
  margin-right: 6px;
  margin-bottom: 3px;
}

.list-title {
  font-weight: 500;
  text-transform: uppercase;
  color: #000 !important
}

.muted {
  color: $spanishGray;
  font-size: 14px;
}

.sub-title-section {
  display: inline-block;
  margin-right: 15px;
  background-color: #eee;
  font-size: 12px;
  padding: 3px 10px;
  margin-top: 5px;
}

.list-sales {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
}

.icon img {
  margin: 2px 0 2px 5px;
}

</style>
