import { identityService } from '@/features/identity'
import { UserType } from '@/globals/constants'
import { BannerFeatureFlagName } from './constants'
import { AccessPerms, BannerFeatureFlag, HasAccessPerms, HasPerms } from './models'
import store from '@/store'
import { UserIdentity } from '../identity/models/userIdentity'

const filterAccessPerm = (item: AccessPerms, userBanner: string, userType: UserType) => {
  if (!item.banners && !item.userTypes) {
    return true
  }

  if (item.banners && item.banners.length && !item.banners.some(x => x === userBanner)) {
    return false
  }

  if (item.userTypes && item.userTypes.length && !item.userTypes.some(x => x === userType)) {
    return false
  }

  return true
}

/** Will filter the provided array of  HasPerms[] against the current user's banner & roles */
const filterByPermissions = <TItem extends HasPerms>(items: TItem[]) => {
  const user = identityService.identityProfile
  const userBanner = user.getDefinitiveBanner()
  const userType = user.getDefinitiveUserType()

  // Type narrowing to branch logic, this is an example of where TS can be annoying
  return items.filter((item: HasPerms) => {
    if ((item as AccessPerms).banners || (item as AccessPerms).userTypes) {
      return filterAccessPerm(item as AccessPerms, userBanner, userType)
    } else if ((item as HasAccessPerms).perms) {
      return filterAccessPerm((item as HasAccessPerms).perms!, userBanner, userType)
    } else {
      return true
    }
  })
}

/** If the current user has permission for any of the provided permission configurations  */
const hasAnyPermission = (permissions: AccessPerms[]) => {
  for (let i = 0; i < permissions.length; i++) {
    const permission = permissions[i]

    if (hasPermission(permission)) {
      return true
    }
  }

  return false
}

/** If the current user has permission for the provided permission configuration  */
const hasPermission = (value?: AccessPerms) => {
  if (!value) return true
  if (!value.banners && !value.userTypes) return true

  const user = identityService.identityProfile
  const userBanner = user.getDefinitiveBanner()
  const userType = user.getDefinitiveUserType()

  if (value.banners && !value.banners.some(x => x === userBanner)) {
    return false
  }

  if (value.userTypes && !value.userTypes.some(x => x === userType)) {
    return false
  }

  return true
}

export {
  filterByPermissions,
  hasPermission,
  hasAnyPermission
}
