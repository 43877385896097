export enum StackPartType {
  Unknown,
  VueTemplate,
  VueScript,
}

// EXAMPLES:
// -- at VueComponent.customerSourceOptions (webpack-internal:///./node_modules/cache-loader/dist/cjs.js?!./node_modules/@vue/cli-plugin-babel/node_modules/babel-loader/lib/index.js!./node_modules/cache-loader/dist/cjs.js?!./node_modules/vue-loader/lib/index.js?!./src/pages/Customers/CustomersSearchHeader/CustomersSource.vue?vue&type=script&lang=js&:53:41)
// -- at Proxy.render (webpack-internal:///./node_modules/cache-loader/dist/cjs.js?{"cacheDirectory":"node_modules/.cache/vue-loader","cacheIdentifier":"5e8277dd-vue-loader-template"}!./node_modules/vue-loader/lib/loaders/templateLoader.js?!./node_modules/cache-loader/dist/cjs.js?!./node_modules/vue-loader/lib/index.js?!./src/pages/Customers/CustomersSearchHeader/index.vue?vue&type=template&id=35c6a17f&scoped=true&:15:15)

/** Stack trace parser meant for extracting vue information */
export class ParsedStackTrace {
  public readonly stackTrace: string | null = null

  public stackPartType: StackPartType = StackPartType.Unknown
  public vueComponentPart: string | null = null
  public vueComponentName: string | null = null
  public vueComponentMember: string | null = null

  get componentNameAndMember () {
    if (!this.vueComponentMember) {
      return this.vueComponentName
    }
    return `${this.vueComponentName}.${this.vueComponentMember}`
  }

  constructor (stackTrace: string | undefined) {
    if (!stackTrace) {
      return
    }

    this.stackTrace = stackTrace
    this.parse(stackTrace)
  }

  private parse (stack: string | undefined) {
    if (!stack) {
      return
    }

    const parts = stack.split('\n')

    // Gets just the parts that are relevant to the vue component making the call
    // stack lines that start with "at Proxy.render" are calls from the vue template
    //   ones that start with "at VueComponent" are calls from the component script
    const relevantParts = parts.filter((x) => {
      return x.includes('.vue') && !x.includes('main.ts')
    })

    if (!relevantParts.length) {
      return
    }

    const mostRelevantPart = relevantParts[0]
    const partType = this.classifyStackPart(mostRelevantPart)
    const componentName = this.getComponentName(mostRelevantPart)
    let componentMember: string | null = null
    if (partType === StackPartType.VueScript) {
      componentMember = this.getComponentMember(mostRelevantPart)
    }

    this.stackPartType = partType
    this.vueComponentPart = mostRelevantPart
    this.vueComponentName = componentName
    this.vueComponentMember = componentMember
  }

  private classifyStackPart (stackPart: string) {
    stackPart = stackPart.trim()

    if (stackPart.includes('at Proxy.render')) {
      return StackPartType.VueTemplate
    } else if (stackPart.includes('at VueComponent')) {
      return StackPartType.VueScript
    }

    return StackPartType.Unknown
  }

  private getComponentName (stackPart: string) {
    const vueIndex = stackPart.indexOf('.vue')
    let componentNameIndex = 0
    for (let i = vueIndex; i > 0; i--) {
      if (stackPart[i] === '/') {
        componentNameIndex = i + 1
        break
      }
    }

    if (!componentNameIndex) {
      return null
    }

    return stackPart.substring(componentNameIndex, vueIndex)
  }

  // For VueScript Type
  private getComponentMember (stackPart: string) {
    const marker = 'VueComponent.'
    const markerIndex = stackPart.indexOf(marker)
    let memberIndex = 0
    for (let i = memberIndex; i < stackPart.length; i++) {
      // Prevent overflow
      if (i + 2 > stackPart.length) {
        break
      }

      // look for " (" to indicate start of code location
      if (stackPart[i + 1] === ' ' && stackPart[i + 2] === '(') {
        memberIndex = i + 1
        break
      }
    }

    if (!memberIndex) {
      return null
    }

    return stackPart.substring(markerIndex + marker.length, memberIndex)
  }
}
