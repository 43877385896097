import { Banner } from '@/features/banners'

/** Banners available for banner switching */
const AVAILABLE_BANNERS: Readonly<Banner[]> = [
  'platt',
  'rexel',
  'gexpro',
  'capitol-light',
  'mayer'
] as const

const IDENTITY_LIFECYCLES = {
  /** Waiting for auth callback that may reload the page */
  WaitingForAuth: 'WaitingForAuth',

  /** Initializing the user profile info and profile set up */
  InitializingProfile: 'InitializingProfile',

  /** Setting up view after profile has been loaded.
   * Technically part of InitializingProfile, but a step near the end
   */
  InitializingViewAs: 'InitializingViewAs',

  /** Profile has been initialized, set, and changes applied.
   * Wrap up such as callbacks & resolvers being completed before setting user state in vuex and returning
  */
  AfterInitialized: 'AfterInitialized'
} as const

type IdentityLifecycle = keyof typeof IDENTITY_LIFECYCLES

export {
  AVAILABLE_BANNERS,
  IDENTITY_LIFECYCLES,
  IdentityLifecycle
}
