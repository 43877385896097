<template>
  <v-container
    fluid>
    <div class="text-right">
      <v-btn
        :to="toCompanyWide"
        outlined>
        View Company Wide
        <v-icon>arrow_forward</v-icon>
      </v-btn>
    </div>
    <v-tabs
      v-model="selectedTab"
      hide-slider>
      <v-tab
        v-for="(entityType) in entityTypes"
        :key="entityType">
        {{ entityType | formatTitleCase }}
      </v-tab>
      <v-tabs-items
        v-model="selectedTab"
        touchless>
        <v-tab-item
          v-for="(entityType) in entityTypes"
          :key="entityType"
          transition="fade-transition"
          reverse-transition="fade-transition">
          <v-card>
            <!--
              customers table handled as special case for elastic;
              TODO: integrate this into entityParams?
            -->
            <customers-selection-table
              v-if="entityType === 'customer'"
              :is-selected="lookupEntityTypeIndex(entityType) === selectedTab"
              :child-routes="childRoutes"
              disable-comparison>
            </customers-selection-table>
            <selection-table
              v-else
              :entity-type="entityType"
              :is-selected="lookupEntityTypeIndex(entityType) === selectedTab"
              :child-routes="childRoutes"
              entity-id-prop-name="branchId">
            </selection-table>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { SelectionTable, CustomersSelectionTable } from '@/components/Tables/SelectionTable'

export default {
  name: 'TopSoldProductsReport',
  components: {
    CustomersSelectionTable,
    SelectionTable
  },
  data () {
    return {
      selectedTab: 0,
      entityTypes: [
        'customer', 'branch' /* 'employee' */
      ],
      childRoutes: {
        single: 'top-sold-products-report-list',
        compare: 'top-sold-products-report-list-compare',
        firstLevel: { productLevel: 'section' }
      }
    }
  },
  computed: {
    ...mapGetters('user', ['user']),
    toCompanyWide () {
      return {
        name: 'top-sold-products-report-list-company',
        params: {
          productLevel: 'section',
          entityType: 'company'
        }
      }
    }
  },
  mounted () {
    this.setDefaultTab()
    this.$store.commit('setPageHeader', {
      page: 'Reports',
      text: 'Top Sold Products'
    })
  },
  methods: {
    async setDefaultTab () {
      const { userType } = this.user

      if (['SuperUser', 'RVP', 'Branch', 'Custom', 'Mgr', 'AsstMgr'].includes(userType)) {
        this.selectedTab = 1
      }
    },
    lookupEntityTypeIndex (entityType) {
      return this.entityTypes.findIndex((type) => type === entityType)
    }
  }
}
</script>
