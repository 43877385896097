import {
  fetchBranchesAndStates,
  fetchBranchesByState,
  fetchBranchEmployees,
  fetchGroups,
  fetchGroup,
  postGroup,
  deleteGroup
} from '@/api/branches'

const getBranchesAndStates = ({ commit }) => {
  commit('getBranchesAndStatesRequest')
  fetchBranchesAndStates().then((response) => {
    commit('getBranchesAndStatesSuccess', response.data)
  })
}

const getBranchesByState = ({ commit }, stateId) =>
  fetchBranchesByState(stateId).then((response) => {
    commit('setBranchByState', response.data)
  })

const getBranchEmployees = ({ commit }, branchId) =>
  fetchBranchEmployees(branchId).then((response) => {
    commit('setBranchEmployees', response.data)
  })

const getGroup = ({ commit }, groupId) =>
  fetchGroup(groupId).then((response) => {
    commit('setCurrentGroup', response.data)
  })

const getGroups = ({ commit }) =>
  fetchGroups().then((response) => {
    commit('setGroups', response.data)
  })

const submitGroupForm = async ({ commit }, body) => {
  try {
    return await postGroup(body)
  } catch (error) {
    commit(
      'setToastMessage',
      { message: `Error: ${error.response.data}` },
      { root: true }
    )
    return error
  }
}

const submitDeleteGroup = ({ commit }, groupId) =>
  deleteGroup(groupId).then(() => {
    commit('resetCurrentGroup')
  })

export default {
  getBranchesAndStates,
  getBranchesByState,
  getBranchEmployees,
  getGroups,
  getGroup,
  submitGroupForm,
  submitDeleteGroup
}
