const categories = (state) => state.categories
const statuses = (state) => state.statuses
const types = (state) => state.types
const probabilities = (state) => state.probabilities
const initiatives = (state) => state.initiatives
const specialists = (state) => state.specialists
const productCategories = (state) => state.productCategories
const businessUnits = (state) => state.businessUnits
const contacts = (state) => state.customerContacts
const savedFilters = (state) => state.savedFilters

const getStatusByLabel = (state) => (opportunityStatus) => {
  return state.statuses.find((status) => status.opportunityStatus === opportunityStatus)
}
const getSpecialistBySso = (state) => (sso) => {
  return state.specialists.find((specialist) => specialist.sso === sso)
}

export default {
  categories,
  statuses,
  getStatusByLabel,
  types,
  probabilities,
  initiatives,
  specialists,
  getSpecialistBySso,
  productCategories,
  businessUnits,
  contacts,
  savedFilters
}
