<template>
  <v-row
    class="header-action"
    :class="{
      'shrink': $vuetify.breakpoint.smAndUp,
      'header-action__mobile': $vuetify.breakpoint.smAndDown}">
    <v-col class="header-action__item">
      <action-plan>
      </action-plan>
    </v-col>
    <v-col
      v-if="$vuetify.breakpoint.smAndDown"
      class="header-action__item">
      <mobile-search-dialog>
      </mobile-search-dialog>
    </v-col>
    <v-col class="header-action__item">
      <page-header-menu
        @toggleFeedback="toggleFeedback">
      </page-header-menu>
    </v-col>
    <support-feedback
      v-model="openFeedback">
    </support-feedback>
  </v-row>
</template>

<script>
import ActionPlan from '@/components/ActionPlan/ActionPlan.vue'
import MobileSearchDialog from './MobileSearchDialog.vue'
import PageHeaderMenu from '@/layout/PageHeader/PageHeaderMenu.vue'
import SupportFeedback from '@/components/SupportFeedback/SupportFeedback.vue'

export default {
  name: 'HeaderActions',
  components: {
    ActionPlan,
    // Feedback,
    SupportFeedback,
    MobileSearchDialog,
    PageHeaderMenu
  },
  props: {
    viewingAs: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      openFeedback: false
    }
  },
  methods: {
    toggleFeedback () {
      this.openFeedback = !this.openFeedback
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-person-off {
  // nudge icon to align with other icons
  position: relative;
  left: 2px;
}

.header-action {
  &.header-action__mobile {
    .header-action__item {
      display: flex;
      justify-content: flex-end;
    }
  }
}

// Adjusts nav-bar padding on very small screens ie: Galaxy Fold
@media only screen and (max-width: 300px) {
  .header-action__mobile {
    flex-wrap: nowrap;
    .header-action__item {
      padding: 4px !important;
    }
  }
}
</style>
