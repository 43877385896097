/*
All functions return a function with a single parameter v that returns either true or a message
All except isRequired should return true if no data passed
Error message should always be the last parameter
*/

import { getDateMonthsBefore } from '@/globals/time'

export const maxLength = (length, message) => (v) =>
  !v || v.length <= length || (message || `Maximum length of ${length} characters allowed`)

export const minLength = (length, message) => (v) =>
  !v || v.length >= length || (message || `Minimum length of ${length} characters required`)

export const betweenLengthInclusive = (min, max, message) => (v) => {
  if (!v || v.length > max || v.length < min) {
    return message || `This field must be ${min} to ${max} characters`
  }
  return true
}

export const exactLength = (length, message) => (v) =>
  !v || v.length === length || message

export const isEmail = (message) => (v) =>
  // eslint-disable-next-line no-useless-escape
  !v || /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || message // Validate non-null values

export const isUrl = (message) => (v) =>
  // eslint-disable-next-line no-useless-escape
  !v || /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&=]*)/g.test(v) || message

export const isPlattEmail = (message) => (v) => {
  const validDomains = ['platt.com']
  const domain = v.split('@')[1]
  const regex = /^\S+@[A-Z0-9.-]+\.[A-Z]{2,18}\b$/i
  return !v || (regex.test(v) && validDomains.includes(domain)) || message
}

export const noBannerEmail = (message) => (v) => {
  const validDomains = ['gexpro.com', 'platt.com', 'rexelusa.com']
  const domain = v.split('@')[1]
  const regex = /^\S+@[A-Z0-9.-]+\.[A-Z]{2,18}\b$/i
  return !v || (regex.test(v) && !validDomains.includes(domain)) || message
}

// Right now this just checks for space
export const isValidUsername = (message) => (v) => {
  const regex = /\s/g
  return !v || !regex.test(v) || message
}

export const isRequired = (message) => (v) =>
  !!v || (message || 'This field is required')

export const isNotBlank = (message) => (v) =>
  !v || v.trim().length > 0 || message

export const isNumberOrBlank = (message) => (v) => {
  if (v === null || v === undefined || v === '') {
    return true
  }

  return isNumber(message)(v)
}

export const isNumber = (message) => (v) =>
  !v || /^\d*\.?\d*$/.test(v) || message

export const isAlphaNumeric = (message) => (v) =>
  !v || /^[a-zA-Z0-9]+$/.test(v) || message

export const isRexGexInvoiceFormat = (message) => (v) =>
  !v || /^[Ss][0-9]+\.[0-9]+$/.test(v) || message

/** Tests is this is a formatted number. Such as 123,456,789.55 */
export const isFormattedNumberOrBlank = (message) => (v) => {
  if (v === null || v === undefined || v === '') {
    return true
  }

  return isFormattedNumber(message)(v)
}

/** Tests is this is a formatted number. Such as 123,456,789.55 */
export const isFormattedNumber = (message) => (v) => {
  if (typeof v === 'string') {
    v = v.replace(/,/g, '') // Remove all commas
  }
  return !v || /^\d*\.?\d*$/.test(v) || message
}

export const isReasonableNumberOrBlank = (message, min = 1, max = 1e12) => (v) => {
  if (v === null || v === undefined || v === '') {
    return true
  }

  return isReasonableNumber(message, min, max)(v)
}

export const isReasonableNumber = (message, min = 1, max = 1e12) => (v) => {
  return (!v && v !== 0) || (v >= min && v <= max) || message
}

export const isReasonableNumberStringOrBlank = (message, min = 1, max = 1e12) => (v) => {
  if (v === null || v === undefined || v === '') {
    return true
  }

  return isReasonableNumberString(message, min, max)(v)
}

/** isReasonableNumber, but for number strings that may or may not be formatted.
 * Such as "123,456,789.55" or "123456"
 */
export const isReasonableNumberString = (message, min = 1, max = 1e12) => (v) => {
  if (typeof v === 'string') {
    v = v.replace(/,/g, '') // Remove all commas
  }

  // Need type coersion, which is why we are using != vs !==
  // eslint-disable-next-line eqeqeq
  return (!v && v != 0) || (v >= min && v <= max) || message
}

export const isReasonablePercentOrBlank = (message, min = 0, max = 999) =>
  isReasonableNumberOrBlank(message, min, max)

export const isReasonablePercent = (message, min = 0, max = 999) =>
  isReasonableNumber(message, min, max)

export const meetsRegex = (regex, message) => (v) =>
  !v || regex.test(v) || message

export const isWithinMonths = (numMonths = -12) => (v) => {
  // check if date is within numMonths of today
  const dateFuture = getDateMonthsBefore(new Date(), numMonths)
  const message = `Please select a date before ${dateFuture.toLocaleDateString()}`
  return !v || new Date(v) < dateFuture || message
}
