import { callApi, generateUrl } from '@/globals/utils'

export const getCategoryList = () => {
  return callApi({ url: '/api/opportunities/category-list' })
}

export const getStatusList = () =>
  callApi({ url: '/api/opportunities/status-list' })

export const getTypeList = () =>
  callApi({ url: '/api/opportunities/type-list' })

export const getProbabilityList = () =>
  callApi({ url: '/api/opportunities/probability-list' })

export const getTaskStatusList = () =>
  callApi({ url: '/api/opportunities/task-status-list' })

export const getInitiativeList = () =>
  callApi({ url: '/api/opportunities/initiative-list' })

export const getSpecialistList = (companyId) =>
  callApi({ url: '/api/opportunities/specialist-list', params: { companyId } })

export const getProductCategoryList = () =>
  callApi({ url: '/api/opportunities/product-category-list' })

export const getBusinessUnitList = () =>
  callApi({ url: '/api/opportunities/business-unit-list' })

// export const addOpportunityTask = (task) =>
//   callApi({
//     method: 'POST',
//     url: `/api/opportunities/opportunity-task/insert`,
//     payload: task
//   })

// export const deleteOpportunityTask = (id) =>
//   callApi({
//     method: 'POST',
//     url: `/api/opportunities/opportunity-task/delete`,
//     payload: id
//   })

export const requestDocumentDetails = (id, environment) => {
  const plattBoxUrl = (environment === 'Test')
    ? 'https://testbox.rexelapps.info'
    : 'https://plattbox.platt.com'
  return callApi({
    url: `${plattBoxUrl}/opportunities/GetOpportunityFiles`,
    params: { id }
  })
}

export const addOpportunityDocument = (id, files) => {
  const formData = new FormData()
  files.forEach((file) => {
    formData.append('file', file)
  })
  const headers = {
    'content-type': 'multipart/form-data'
  }
  const success = files.length > 1
    ? `Successfully added ${files.length} files`
    : 'Successfully added a file'

  return callApi({
    method: 'POST',
    url: `/api/opportunities/${id}/documents/upload`,
    payload: formData,
    headers,
    messages: {
      success,
      error: 'Failed to attach the document(s)'
    }
  })
}

export const deleteOpportunityDocument = (id, fileGuid, fileId) =>
  callApi({
    method: 'POST',
    url: `/api/opportunities/${id}/documents/delete`,
    params: { fileGuid, fileId },
    messages: {
      success: 'Successfully removed file',
      error: 'Failed to remove file'
    }
  })

export const requestOpportunities = (showAllOpportunities) =>
  callApi({ url: '/api/opportunities/get', params: { showAllOpportunities } })

export const requestOpportunity = (id) =>
  callApi({ url: `/api/opportunities/${id}` })

export const addOpportunity = (payload) =>
  callApi({
    method: 'POST',
    url: '/api/opportunities/update/',
    payload,
    messages: {
      success: 'Successfully created opportunity',
      error: 'Failed to create opportunity'
    }
  })

export const updateOpportunity = (payload) =>
  callApi({
    method: 'POST',
    url: '/api/opportunities/update/',
    payload,
    messages: {
      success: 'Successfully updated opportunity',
      error: 'Failed to update opportunity'
    }
  })

export const deleteOpportunity = (id) =>
  callApi({
    url: `/api/opportunities/${id}/delete`,
    messages: {
      success: 'Successfully deleted opportunity',
      error: 'Failed to delete opportunity'
    },
    forceToast: true
  })

export const getOpportunitiesExportUrl = (search, status, sortBy, descending, showAllOpportunities) =>
  generateUrl('/api/opportunities/export', { search, status, sortBy, descending, showAllOpportunities })
