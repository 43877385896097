import { render, staticRenderFns } from "./CustomersSelectionTable.vue?vue&type=template&id=2006d576&scoped=true"
import script from "./CustomersSelectionTable.vue?vue&type=script&lang=js"
export * from "./CustomersSelectionTable.vue?vue&type=script&lang=js"
import style0 from "./CustomersSelectionTable.vue?vue&type=style&index=0&id=2006d576&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2006d576",
  null
  
)

export default component.exports