<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    max-width="290px"
    full-width
    offset-y
    top>
    <template #activator="{ on }">
      <v-text-field
        :value="formattedDate"
        :label="label"
        append-icon="event"
        filled
        readonly
        @click:append="menu = true"
        v-on="on">
      </v-text-field>
    </template>
    <v-date-picker
      v-model="pickerDate"
      :allowed-dates="allowedDates"
      :max="max || null"
      type="month"
      data-qa="selectDate"
      @input="menu = false">
    </v-date-picker>
  </v-menu>
</template>

<script>
import { formatDate } from '@/globals/formatters'

export default {
  name: 'MonthPickerField',
  props: {
    label: {
      type: String,
      required: true
    },
    date: {
      type: String,
      required: false,
      default: () => ''
    },
    allowedDates: {
      type: Function,
      required: false,
      default: () => true
    },
    max: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      menu: false,
      pickerDate: null,
      formattedDate: null
    }
  },
  watch: {
    pickerDate (newVal) {
      this.formattedDate = formatDate(newVal, 'MMM, YYYY')
      if (newVal) this.$emit('update:date', formatDate(newVal, 'YYYY-MM-DD'))
    },
    date (newVal) {
      if (this.pickerDate !== newVal) {
        this.pickerDate = formatDate(newVal || new Date(), 'YYYY-MM')
      }
    }
  },
  mounted () {
    this.pickerDate = formatDate(this.date || new Date(), 'YYYY-MM')
  }
}
</script>
