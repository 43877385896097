import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'

import AuthCallbackSilent from '@/pages/AuthCallbackSilent.vue'
import BaseLayout from '@/layout/BaseLayout.vue'
import { activitiesRoutes } from './activities'
import { customerRoutes } from './customers'
import { peopleRoutes } from './people'
import { customerLifecycleReportRoutes } from './customer-lifecycle'
import { salesByCategoryRoutes } from './SalesByCategory'
import { topSoldProductsRoutes } from './TopSoldProducts'
import { userRoutes } from './users'
import { beforeEnter, beforeEach, afterEach } from './lifecycleHooks'

const ActivitySummaryReport = () => import('@/pages/Reports/ActivitySummaryReport/ActivitySummary.vue')
const ActivitySummaryCustomerDetail = () => import('@/pages/Reports/ActivitySummaryReport/ActivitySummaryCustomerDetail.vue')
const ActivitySummaryBranchDetail = () => import('@/pages/Reports/ActivitySummaryReport/ActivitySummaryBranchDetail.vue')
const ActivitySummaryEmployeeDetail = () => import('@/pages/Reports/ActivitySummaryReport/ActivitySummaryEmployeeDetail.vue')
const OpportunitySummaryReport = () => import('@/pages/Reports/OpportunitySummaryReport/OpportunitySummary.vue')
const OpportunitySummaryCustomerDetail = () => import('@/pages/Reports/OpportunitySummaryReport/OpportunitySummaryCustomerDetail.vue')
const OpportunitySummaryBranchDetail = () => import('@/pages/Reports/OpportunitySummaryReport/OpportunitySummaryBranchDetail.vue')
const OpportunitySummaryEmployeeDetail = () => import('@/pages/Reports/OpportunitySummaryReport/OpportunitySummaryEmployeeDetail.vue')
const OpportunitySummaryVendorDetail = () => import('@/pages/Reports/OpportunitySummaryReport/OpportunitySummaryVendorDetail.vue')
const OpportunitySummaryRegionDetail = () => import('@/pages/Reports/OpportunitySummaryReport/OpportunitySummaryRegionDetail.vue')
const Branch = () => import('@/pages/Branch.vue')
const Branches = () => import('@/pages/Branches.vue')
const BranchesReport = () => import('@/pages/Dashboard/shared/BranchesReport.vue')
const SalesByContractReport = () => import('@/pages/Reports/SalesByContractReport.vue')
const ExpiringContractsReport = () => import('@/pages/Reports/ExpiringContractsReport/ExpiringContractsReport.vue')
const CustomersOnExpiringContracts = () => import('@/pages/Reports/ExpiringContractsReport/CustomersOnExpiringContracts.vue')
const ContractsByCustomerReport = () => import('@/pages/Reports/ContractsByCustomerReport.vue')
const ContractsForCustomerReport = () => import('@/pages/Reports/ContractsForCustomerReport.vue')
const MyCustomerOrders = () => import('@/pages/Reports/CustomerOrdersForUserReport.vue')
const CustomerSalesForContractReport = () => import('@/pages/Reports/CustomerSalesForContractReport.vue')
const CustomerSalesByItemForContractReport = () => import('@/pages/Reports/CustomerSalesByItemForContractReport.vue')
const ContractsByProductReport = () => import('@/pages/Reports/ContractsByProductReport.vue')
const CreateTask = () => import('@/pages/CreateTask.vue')
const EditLists = () => import('@/pages/Tasks/EditLists.vue')
const CustomerOverridesReport = () => import('@/pages/Reports/CustomerOverridesReport.vue')
const CustomerOverrideDetailsReport = () => import('@/pages/Reports/CustomerOverrideDetailsReport.vue')
const MyCustomersReport = () => import('@/pages/Reports/MyCustomersReport.vue')
const CustomerStatusMap = () => import('@/pages/CustomerStatusMap/CustomerStatusMap.vue')
const Dashboard = () => import('@/pages/Dashboard/Dashboard.vue')
const Definitions = () => import('@/pages/Definitions.vue')
const EditTask = () => import('@/pages/EditTask.vue')
const SalesStatsReport = () => import('@/pages/Reports/SalesStatsReport/SalesStatsReport.vue')
const InactiveCustomersReport = () => import('@/pages/Reports/InactiveCustomersReport.vue')
const NotFoundPage = () => import('@/pages/NotFoundPage.vue')
const CustomersByPimReport = () => import('@/pages/Reports/CustomersByPimReport.vue')
const Preferences = () => import('@/pages/Preferences.vue')
const Project = () => import('@/pages/Project.vue')
const ProductRemindersReport = () => import('@/pages/Reports/ProductRemindersReport.vue')
const Projects = () => import('@/pages/Projects.vue')
const Reports = () => import('@/pages/Reports/Reports.vue')
const SalesPlanning = () => import('@/pages/SalesPlanning/SalesPlanning.vue')
const CustomersInactiveSoonReport = () => import('@/pages/Reports/CustomersInactiveSoonReport.vue')
const Tasks = () => import('@/pages/Tasks/Tasks.vue')
const TopNonPlusCustomersReport = () => import('@/pages/Reports/TopNonPlusCustomersReport.vue')
const ProductRecommendationsReport = () => import('@/pages/Reports/ProductRecommendationsReport.vue')
const TopVendorDetails = () => import('@/pages/Reports/TopVendorDetails.vue')
const TopVendorsReport = () => import('@/pages/Reports/TopVendorsReport.vue')
const TopViewedProductsReport = () => import('@/pages/Reports/TopViewedProductsReport.vue')
const WhatsNew = () => import('@/pages/WhatsNew.vue')
const ViewAs = () => import('@/pages/ViewAs/ViewAs.vue')
const OpportunitiesPage = () => import('@/pages/Opportunity/Opportunity.vue')
const OpportunityPage = () => import('@/pages/Opportunity/OpportunityForm/OpportunityForm.vue')
const TopSearchesOnWeb = () => import('@/pages/Reports/TopSearchesOnWeb/TopSearchesOnWeb.vue')
const CustomerTopSearchesOnWeb = () => import('@/pages/Reports/TopSearchesOnWeb/CustomerTopSearchesOnWeb.vue')
const CustomersAtRiskReport = () => import('@/pages/Reports/CustomersAtRiskReport/CustomersAtHighRiskReport.vue')
const CustomerFirstOrderReport = () => import('@/pages/Reports/FirstOrderReport/CustomerFirstOrderReport.vue')
const PlattforceSignout = () => import('@/pages/PlattforceSignout.vue')
const CustomersMissingDetailsByRegionReport = () => import('@/pages/Reports/CustomersMissingDetailsReport/CustomersMissingDetailsByRegionReport.vue')
const CustomersMissingDetailsByBranchReport = () => import('@/pages/Reports/CustomersMissingDetailsReport/CustomersMissingDetailsByBranchReport.vue')
const CustomersMissingDetailsByEmployeeReport = () => import('@/pages/Reports/CustomersMissingDetailsReport/CustomersMissingDetailsByEmployeeReport.vue')
const CustomersMissingDetailsForEmployeeReport = () => import('@/pages/Reports/CustomersMissingDetailsReport/CustomersMissingDetailsForEmployeeReport.vue')
const ActivitiesOnOpportunities = () => import('@/pages/Reports/ActivitiesOnOpportunities/ActivitiesOnOpportunities.vue')
const CurrentMonthSalesSummary = () => import('@/pages/Dashboard/SimplifiedDashboard/SalesStats/CurrentMonthSalesSummary.vue')
const QTDSalesSummary = () => import('@/pages/Dashboard/SimplifiedDashboard/SalesStats/QTDSalesSummary.vue')
const PriorBusinessDaySales = () => import('@/pages/Dashboard/SimplifiedDashboard/SalesStats/PriorBusinessDaySales.vue')
const SimpleDashBoardInvoices = () => import('@/pages/Dashboard/SimplifiedDashboard/SalesStats/SimpleDashboardInvoices.vue')
const LeadsReport = () => import('@/pages/Reports/LeadsReport.vue')
const Leads = () => import('@/pages/Leads/Leads.vue')
const LeadProfile = () => import('@/pages/Leads/LeadProfile.vue')
const DashboardSections = () => import('@/pages/DashboardSections/DashboardSections.vue')
const LabelMakerPage = () => import('@/pages/Labels/LabelMakerPage.vue')
const LabelsPage = () => import('@/pages/Labels/LabelsPage.vue')
const UsersPage = () => import('@/pages/Users/UsersPage.vue')

Vue.use(Router)

const router = new Router({
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    if (!savedPosition) {
      return { x: 0, y: 0 }
    }
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(savedPosition)
      }, 500)
    })
  },
  routes: [
    {
      path: '/silent-auth-callback',
      name: 'AuthCallbackSilent',
      component: AuthCallbackSilent,
      meta: {
        auth: true
      }
    },
    {
      path: '/',
      name: 'root',
      beforeEnter
    },
    {
      path: '/:banner',
      component: BaseLayout,
      meta: {
        base: true
      },
      beforeEnter,
      children: [
        ...activitiesRoutes,
        ...customerRoutes,
        ...peopleRoutes,
        ...salesByCategoryRoutes,
        ...topSoldProductsRoutes,
        ...customerLifecycleReportRoutes,
        ...userRoutes,
        {
          path: '/',
          beforeEnter,
          name: 'base'
        },
        {
          path: 'dashboard',
          name: 'dashboard',
          component: Dashboard,
          meta: {
            title: 'Dashboard'
          }
        },
        {
          path: 'dashboard/sections',
          name: 'dashboard-sections',
          component: DashboardSections,
          meta: {
            title: 'Dashboard'
          }
        },
        {
          path: 'dashboard/region/:region?',
          name: 'region-dashboard',
          component: Dashboard,
          meta: {
            title: 'Region Dashboard'
          }
        },
        {
          path: 'dashboard/business-dev/:bddId?',
          name: 'business-dev-dashboard',
          component: Dashboard,
          meta: {
            title: 'Business Development Dashboard'
          }
        },
        {
          path: 'dashboard/manager/:managerId?',
          name: 'manager-dashboard',
          component: Dashboard,
          meta: {
            title: 'Manager Dashboard'
          }
        },
        {
          path: 'dashboard/branch/:branchId?',
          name: 'branch-dashboard',
          component: Dashboard,
          meta: {
            title: 'Branch Dashboard'
          }
        },

        {
          path: 'branches/:branchNum',
          name: 'branch',
          component: Branch,
          meta: {
            title: 'Branch'
          }
        },
        {
          path: 'branches',
          name: 'branches',
          component: Branches,
          meta: {
            title: 'Branches'
          }
        },
        {
          path: 'branches-report/:region',
          name: 'branches-report',
          component: BranchesReport,
          props: true,
          meta: {
            title: 'Branches Report'
          }
        },
        {
          path: 'customer-status-map',
          name: 'customer-status-map',
          component: CustomerStatusMap,
          meta: {
            title: 'Customer Status Map'
          }
        },
        {
          path: 'definitions',
          name: 'definitions',
          component: Definitions,
          meta: {
            title: 'Definitions'
          }
        },
        {
          path: 'label-maker',
          name: 'label-maker',
          component: LabelMakerPage,
          meta: {
            title: 'Label Maker'
          }
        },
        {
          path: 'labels/:id?',
          name: 'labels',
          component: LabelsPage,
          meta: {
            title: 'Labels'
          }
        },
        {
          path: 'opportunities',
          name: 'opportunities',
          component: OpportunitiesPage,
          meta: {
            title: 'Opportunities'
          }
        },
        {
          path: 'opportunities/create',
          name: 'create-opportunity',
          component: OpportunityPage,
          meta: {
            title: 'Create Opportunity'
          }
        },
        {
          path: 'opportunities/edit/:id?',
          name: 'edit-opportunity',
          component: OpportunityPage,
          meta: {
            title: 'Edit Opportunity'
          }
        },
        {
          path: 'preferences',
          name: 'preferences',
          component: Preferences,
          meta: {
            title: 'Preferences'
          }
        },
        {
          path: 'projects/:id',
          name: 'project',
          component: Project,
          meta: {
            title: 'Project'
          }
        },
        {
          path: 'projects',
          name: 'projects',
          component: Projects,
          meta: {
            title: 'Projects',
            plattUserOnly: true
          }
        },

        {
          path: 'reports',
          name: 'reports',
          component: Reports,
          meta: {
            title: 'Reports'
          }
        },
        {
          path: 'reports/activity-summary',
          name: 'activity-summary',
          component: ActivitySummaryReport,
          meta: {
            title: 'Activity Summary'
          }
        },
        {
          path: 'reports/activity-summary/customer/:customerId',
          name: 'activity-summary-by-customer',
          component: ActivitySummaryCustomerDetail,
          meta: {
            title: 'Customer Activity Summary'
          }
        },
        {
          path: 'reports/activity-summary/branch/:branchId',
          name: 'activity-summary-by-branch',
          component: ActivitySummaryBranchDetail,
          meta: {
            title: 'Branch Activity Summary'
          }
        },
        {
          path: 'reports/activity-summary/employee/:sso',
          name: 'activity-summary-by-employee',
          component: ActivitySummaryEmployeeDetail,
          meta: {
            title: 'Employee Activity Summary'
          }
        },
        {
          path: 'reports/contracts-by-customer',
          name: 'contracts-by-customer-report',
          component: ContractsByCustomerReport,
          meta: {
            title: 'Contracts By Customer'
          }
        },
        {
          path: 'reports/contracts-for-customer',
          name: 'contracts-for-customer-report',
          component: ContractsForCustomerReport,
          meta: {
            title: 'Contracts For Customer'
          }
        },
        {
          path: 'reports/contracts-by-part-number',
          name: 'contracts-by-part-number-report',
          component: ContractsByProductReport,
          meta: {
            title: 'Contracts By Product'
          }
        },
        {
          path: 'reports/expiring-contracts',
          name: 'expiring-contracts-report',
          component: ExpiringContractsReport,
          meta: {
            title: 'Expiring Contracts Report'
          }
        },
        {
          path: 'reports/expiring-contract/:contractNumber/customers',
          name: 'customers-on-expiring-contracts',
          component: CustomersOnExpiringContracts,
          meta: {
            title: 'Customers on Expiring Contracts '
          },
          props: true
        },
        {
          path: 'reports/customers-at-risk',
          name: 'customers-at-risk',
          component: CustomersAtRiskReport
        },
        {
          path: 'dashboard/prior-business-day-sales',
          name: 'prior-business-day-sales',
          component: PriorBusinessDaySales
        },
        {
          path: 'dashboard/current-quarter-sales-summary',
          name: 'current-quarter-sales-summary',
          component: QTDSalesSummary
        },
        {
          path: 'dashboard/current-month-sales-summary',
          name: 'current-month-sales-summary',
          component: CurrentMonthSalesSummary
        },
        {
          path: 'dashboard/invoices/:time_period',
          name: 'simple-dashboard-invoices',
          component: SimpleDashBoardInvoices
        },
        {
          path: 'reports/customer-first-order',
          name: 'customer-first-order',
          component: CustomerFirstOrderReport
        },
        {
          path: 'reports/customers-by-pim',
          name: 'customers-by-pim-report',
          component: CustomersByPimReport,
          meta: {
            title: 'Customers At Risk',
            plattUserOnly: true
          }
        },
        {
          path: 'reports/customers-missing-details-report/customers-missing-details-by-region-report',
          name: 'customers-missing-details-by-region-report',
          component: CustomersMissingDetailsByRegionReport
        },
        {
          path: 'reports/customers-missing-details-report/customers-missing-details-by-branch-report',
          name: 'customers-missing-details-by-branch-report',
          component: CustomersMissingDetailsByBranchReport
        },
        {
          path: 'reports/customers-missing-details-report/customers-missing-details-by-employee-report',
          name: 'customers-missing-details-by-employee-report',
          component: CustomersMissingDetailsByEmployeeReport
        },
        {
          path: 'reports/customers-missing-details-report/customers-missing-details-for-employee-report',
          name: 'customers-missing-details-for-employee-report',
          component: CustomersMissingDetailsForEmployeeReport
        },
        {
          path: 'reports/customers-inactive-soon',
          name: 'customers-inactive-soon-report',
          component: CustomersInactiveSoonReport,
          meta: {
            title: 'Customers Inactive Soon'
          }
        },
        {
          path: 'reports/customer-overrides',
          name: 'customer-overrides-report',
          component: CustomerOverridesReport,
          meta: {
            title: 'Customer Overrides'
          }
        },
        {
          path: 'reports/customer-override-details',
          name: 'customer-override-details-report',
          component: CustomerOverrideDetailsReport,
          meta: {
            title: 'Customer Override Details'
          }
        },
        {
          path: 'reports/customer-sales-for-contract',
          name: 'customer-sales-for-contract-report',
          component: CustomerSalesForContractReport,
          meta: {
            title: 'Sales For Contract'
          }
        }, {
          path: 'reports/customer-sales-by-item-for-contract',
          name: 'customer-sales-by-item-for-contract-report',
          component: CustomerSalesByItemForContractReport,
          meta: {
            title: 'Items Sold For Contract'
          }
        },
        {
          path: 'reports/inactive-customers',
          name: 'inactive-customers-report',
          component: InactiveCustomersReport,
          meta: {
            title: 'Inactive Customers'
          }
        },
        {
          path: 'reports/my-customers',
          name: 'my-customers-report',
          component: MyCustomersReport,
          meta: {
            title: 'My Customers'
          }
        },
        {
          path: 'reports/my-customer-orders',
          name: 'my-customer-orders-report',
          component: MyCustomerOrders,
          meta: {
            title: 'My Customer Orders'
          }
        },
        {
          path: 'reports/opportunity-summary',
          name: 'opportunity-summary',
          component: OpportunitySummaryReport,
          meta: {
            title: 'Opportunity Summary'
          }
        },
        {
          path: 'reports/opportunity-summary/customer/:customerId',
          name: 'opportunity-summary-by-customer',
          component: OpportunitySummaryCustomerDetail,
          meta: {
            title: 'Opportunity Summary: Customer'
          }
        },
        {
          path: 'reports/opportunity-summary/branch/:branchId',
          name: 'opportunity-summary-by-branch',
          component: OpportunitySummaryBranchDetail,
          meta: {
            title: 'Opportunity Summary: Branch'
          }
        },
        {
          path: 'reports/opportunity-summary/employee/:createdBySso',
          name: 'opportunity-summary-by-employee',
          component: OpportunitySummaryEmployeeDetail,
          meta: {
            title: 'Opportunity Summary: Employee'
          }
        },
        {
          path: 'reports/opportunity-summary/vendor/:vendorId',
          name: 'opportunity-summary-by-vendor',
          component: OpportunitySummaryVendorDetail,
          meta: {
            title: 'Opportunity Summary: Vendor'
          }
        },
        {
          path: 'reports/opportunity-summary/region/:regionCode',
          name: 'opportunity-summary-by-region',
          component: OpportunitySummaryRegionDetail,
          meta: {
            title: 'Opportunity Summary: Region'
          }
        },
        {
          path: 'reports/products-recommendations',
          name: 'products-recommendations-report',
          component: ProductRecommendationsReport,
          meta: {
            title: 'Product Recommendations',
            plattUserOnly: true
          }
        },
        {
          path: 'reports/product-reminders',
          name: 'product-reminders-report',
          component: ProductRemindersReport,
          meta: {
            title: 'Product Reminders',
            plattUserOnly: true
          }
        },
        {
          path: 'reports/sales-by-contract',
          name: 'sales-by-contract-report',
          component: SalesByContractReport,
          meta: {
            title: 'Sales By Contract'
          }
        },
        {
          path: 'reports/sales-stats/:sso?',
          name: 'sales-stats-report',
          component: SalesStatsReport,
          meta: {
            title: 'Sales Stats'
          }
        },
        {
          path: 'reports/top-non-plus-customers',
          name: 'top-non-plus-customers-report',
          component: TopNonPlusCustomersReport,
          meta: {
            title: 'Top Non Plus Customers'
          }
        },
        {
          path: 'reports/top-viewed-products',
          name: 'top-viewed-products-report',
          component: TopViewedProductsReport,
          meta: {
            title: 'Top Viewed Products'
          }
        },
        {
          path: 'reports/top-vendors',
          name: 'top-vendors-report',
          component: TopVendorsReport,
          meta: {
            title: 'Top Vendors'
          }
        },
        {
          path: 'reports/top-vendor-details',
          name: 'top-vendor-details',
          component: TopVendorDetails,
          meta: {
            title: 'Vendor Details',
            plattUserOnly: true
          }
        },
        {
          path: 'reports/top-web-searches',
          name: 'top-web-searches-report',
          component: TopSearchesOnWeb,
          meta: {
            title: 'Top Web Searches'
          }
        },
        {
          path: 'reports/:customerId/top-web-searches',
          name: 'customer-top-web-searches-report',
          component: CustomerTopSearchesOnWeb,
          meta: {
            title: 'Top Web Searches: Customer'
          }
        },
        {
          path: 'reports/activities-on-opportunities',
          name: 'activities-on-opportunities',
          component: ActivitiesOnOpportunities,
          meta: {
            title: 'Activities On Opportunities'
          }
        },
        {
          path: 'leads',
          name: 'leads',
          component: Leads,
          meta: {
            title: 'Leads'
          }
        },
        {
          path: 'reports/leads',
          name: 'leads-report',
          component: LeadsReport,
          meta: {
            title: 'Leads Report'
          }
        },
        {
          path: 'leads/lead-profile',
          name: 'lead-profile',
          component: LeadProfile,
          meta: {
            title: 'Lead Profile'
          }
        },
        {
          path: 'sales-planning',
          name: 'sales-planning',
          component: SalesPlanning,
          meta: {
            title: 'Sales Planning'
          }
        },
        {
          path: 'tasks',
          name: 'tasks',
          component: Tasks,
          meta: {
            title: 'Tasks'
          }
        },
        {
          path: 'tasks/create',
          name: 'create-task',
          component: CreateTask,
          meta: {
            title: 'Create Task'
          }
        },
        {
          path: 'tasks/:taskId',
          name: 'edit-task',
          component: EditTask,
          meta: {
            title: 'Edit Task'
          }
        },
        {
          path: 'task-lists',
          name: 'edit-lists',
          component: EditLists,
          meta: {
            title: 'Edit Lists'
          }
        },
        {
          path: 'users',
          name: 'users',
          component: UsersPage,
          meta: {
            title: 'Users'
          }
        },
        {
          path: 'whats-new',
          name: 'whats-new',
          component: WhatsNew,
          meta: {
            title: 'What\'s New'
          }
        },
        {
          path: 'view-as',
          name: 'view-as',
          component: ViewAs,
          meta: {
            title: 'View As',
            elevatedUserOnly: true
          }
        },

        {
          path: 'Account/SignOutCustomer',
          name: 'plattforce-signout',
          component: PlattforceSignout,
          meta: {
            title: 'Sign-out'
          }
        },
        {
          path: 'not-found',
          name: 'not-found',
          component: NotFoundPage,
          meta: {
            title: 'Not Found'
          }
        },
        {
          path: '*',
          name: 'not-found-generic',
          component: NotFoundPage,
          meta: {
            title: 'Not Found'
          },
          beforeEnter: (to, from, next) => {
            store.dispatch('setErrorPage', { statusCode: 404 })
            next()
          }
        },
        {
          path: '*',
          name: 'not-authorized',
          component: NotFoundPage,
          meta: {
            title: 'Not Authorized',
            notAuthorized: true
          },
          beforeEnter: (to, from, next) => {
            store.dispatch('setErrorPage', { statusCode: 401 })
            next()
          }
        }
      ]
    }
  ]
})

// load user and route protection
router.beforeEach(beforeEach)
router.afterEach(afterEach)

export default router
