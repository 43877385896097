import getters from './getters'
import mutations from './mutations'

const state = {
  actionPlanUpdate: false
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
