const getSelectedTabIndexState = ({ commit }, selectedTabIndex) => {
  commit('setSelectedTabIndex', selectedTabIndex)
}
const setSavedDateRange = ({ commit }, savedDateRange) => {
  commit('setSavedDateRange', savedDateRange)
}
export default {
  getSelectedTabIndexState,
  setSavedDateRange
}
