<template>
  <v-text-field
    :value="customersSearchParams.term"
    :placeholder="placeholder"
    append-icon="search"
    hide-details
    single-line
    filled
    @input="debounceInput($event, customersSearchParams, $store)">
  </v-text-field>
</template>

<script>
import { mapState } from 'vuex'
import debounce from 'lodash.debounce'

export default {
  computed: {
    ...mapState('customers', ['customersSearchParams', 'storefrontUserSearchActive']),
    placeholder () {
      const placeHolderSource = this.storefrontUserSearchActive === true ? 'Users' : 'Customers'
      return `${placeHolderSource} search`
    }
  },
  methods: {
    debounceInput: debounce((inputValue, searchParams, store) => {
      const searchActionObj = {
        ...searchParams,
        term: inputValue,
        page: 1
      }
      store.dispatch('customers/updateCustomersSearchParams', searchActionObj)
    }, 500)
  }
}
</script>
