<template>
  <div
    class="py-2 px-2">
    <div v-if="$vuetify.breakpoint.xsOnly">
      {{ label }}
    </div>
    <v-row
      dense
      align="center"
      :class="$vuetify.breakpoint.xsOnly ? 'no-wrap overflow-x-auto' : 'wrap'">
      <v-col
        v-if="!$vuetify.breakpoint.xsOnly"
        cols="auto">
        <span>{{ label }}</span>
      </v-col>
      <v-col
        v-for="item in filterList"
        :key="item[keyAttr] || item"
        cols="auto">
        <chip
          :id="item[keyAttr] || item"
          :item="item"
          :selected.sync="item.selected"
          @toggleSelected="toggleFilter">
          <slot :item="item">
          </slot>
        </chip>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Chip from './Chip.vue'

export default {
  name: 'ChipFilters',
  components: {
    Chip
  },
  props: {
    list: {
      type: Array,
      required: true
    },
    selected: {
      type: Array,
      required: false,
      default: () => []
    },
    keyAttr: {
      type: [String, Number],
      required: false,
      default: ''
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    filterList () {
      return this.list.map((item) => {
        const selected = this.selected.includes(item[this.keyAttr] || item)
        return {
          ...item,
          selected
        }
      })
    }
  },
  methods: {
    toggleFilter (id) {
      let selected = [...this.selected]
      if (selected.includes(id)) {
        selected = selected.filter((f) => f !== id)
      } else {
        selected = this.multiple
          ? selected.concat(id)
          : [id]
      }
      this.$emit('update:selected', selected)
    }
  }
}
</script>
