<template>
  <responsive-table
    must-sort
    :headers="headers"
    :items="orders"
    :pagination.sync="pagination"
    mobile-break-point="smAndDown"
    :mobile-expansion-panels="true"
    :rows-per-page-items="[5, 10, 25]"
    no-data-text="No Data"
    :search="search"
    :loading="loading">
    <template #customerName="{item}">
      <anchor :to="{ name: 'customer-profile', params: { customerId: item.customerId}}">
        {{ item.customerNameAndNumber }}
      </anchor>
    </template>
    <template
      #action="{item}">
      <div class="v2-d-flex justify-end">
        <div v-if="firstOrder && item.activityCreated">
          <v-tooltip
            top>
            <template #activator="{ on }">
              <v-icon
                class="cursor-default mr-1"
                v-on="on">
                looks_one
              </v-icon>
            </template>
            <span>First Activity Created</span>
          </v-tooltip>
        </div>
        <anchor
          :to="createActivityRoute(item)">
          Create Activity
        </anchor>
      </div>
    </template>
  </responsive-table>
</template>

<script>
import ResponsiveTable from '@/components/ResponsiveTable/ResponsiveTable.vue'
import Anchor from '@/components/Anchor.vue'

export default {
  name: 'NthOrderReportTable',
  components: {
    ResponsiveTable,
    Anchor
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    search: {
      type: String,
      required: false,
      default: null
    },
    itemsPerPage: {
      type: Number,
      required: false,
      default: 25
    },
    orders: {
      type: Array,
      required: false,
      default: null
    },
    firstOrder: {
      type: Boolean,
      required: true
    },
    thirdOrder: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      pagination: {
        sortBy: 'customerName',
        ascending: true,
        itemsPerPage: 25
      },
      headers: [
        {
          text: 'Customer Name',
          align: 'left',
          sortable: true,
          value: 'customerNameAndNumber',
          slot: 'customerName',
          title: true
        },
        {
          text: 'Account Type',
          align: 'left',
          sortable: true,
          value: 'accountType'
        },
        {
          text: 'Created On',
          align: 'left',
          sortable: true,
          value: 'createdOn',
          type: 'date'
        },
        {
          text: 'First Order',
          align: 'left',
          sortable: true,
          value: 'firstOrderDate',
          type: 'date'
        },
        {
          text: 'First Order Number',
          align: 'left',
          sortable: true,
          value: 'firstOrderNumber'
        },
        {
          text: 'Order Total',
          align: 'left',
          sortable: true,
          value: 'orderTotal',
          type: 'money'
        },
        {
          text: 'Sales Person',
          align: 'left',
          sortable: true,
          value: 'outsideSalesPersonFullName',
          type: 'firstAndLastName'
        },
        {
          sortable: false,
          slot: 'action',
          type: 'action'
        }
      ]
    }
  },
  methods: {
    createActivityRoute (item) {
      const query = {
        companyId: item.companyId,
        customerId: item.customerId,
        orderId: item.orderId
      }

      if (this.firstOrder) {
        query.firstOrder = true
      } else if (this.thirdOrder) {
        query.thirdOrder = true
      }

      return {
        name: 'create-activity',
        query
      }
    }
  }
}
</script>
