<template>
  <v-autocomplete
    ref="autocomplete"
    v-model="select"
    data-qa="global_search"
    class="customers-autocomplete"
    :autofocus="focus"
    :background-color="backgroundColor"
    :color="color"
    :error-messages="errors"
    :clearable="clearable && !readonly"
    :items="filteredItems"
    :label="label"
    :loading="loading"
    :rules="rules"
    :search-input.sync="search"
    append-icon="search"
    hide-no-data
    :hide-details="hideDetails"
    :readonly="readonly"
    filled
    no-filter
    @click:clear="clear"
    @click:append="emitSearch"
    @keyup.enter="selectItem"
    @blur="blur">
    <template
      slot="item"
      slot-scope="{item}">
      <v-list-item-content
        data-qa="searchResultsItem">
        <slot
          v-if="hasItemSlot"
          name="item"
          v-bind="{item}">
        </slot>
        <v-list-item-title v-else>
          {{ item.text }}
        </v-list-item-title>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import { searchCustomers } from '@/api/customers'
import debounce from 'lodash.debounce'

export default {
  name: 'AutoComplete',

  props: {
    disableSingleItemAutoSelect: {
      type: Boolean,
      required: false,
      default: false
    },
    backgroundColor: {
      type: String,
      required: false,
      default: 'backgroundGrey'
    },
    clearable: {
      type: Boolean,
      default: false,
      required: false
    },
    clearOnSelect: {
      type: Boolean,
      required: false,
      default: true
    },
    color: {
      type: String,
      required: false,
      default: 'black'
    },
    endpoint: {
      default: searchCustomers,
      required: false,
      type: Function
    },
    endpointDataAccessor: {
      type: Function,
      required: false,
      default: (data) => data
    },
    filterFn: {
      type: Function,
      required: false,
      default: (v) => true
    },
    focus: {
      required: false,
      default: false,
      type: Boolean
    },
    format: {
      type: Function,
      required: false,
      default: (x) => `${x.customerName || x.name} - ${x.companyCustomerNumber || x.accountId}`
    },
    hasItemSlot: {
      type: Boolean,
      required: false,
      default: false
    },
    initialValue: {
      type: [String, Object],
      required: false,
      default: ''
    },
    label: {
      type: String,
      required: false,
      default: 'Search All Customers'
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    rules: {
      type: Array,
      required: false,
      default: () => []
    },
    hideDetails: {
      type: Boolean,
      required: false,
      default: false
    },
    forceLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    searchParams: {
      type: Object,
      default: null
    }
  },

  data: () => ({
    errors: null,
    items: [],
    loading: false,
    stopDebouncedSearch: false,
    search: null,
    select: null,
    canKeydownMenu: true
  }),

  computed: {
    filteredItems () {
      return typeof this.filterFn === 'function'
        ? this.items.filter(this.filterFn)
        : this.items
    }
  },

  watch: {
    forceLoading: {
      immediate: true,
      handler (newVal) {
        this.loading = newVal
      }
    },
    initialValue: {
      immediate: true,
      handler (newVal) {
        if (newVal) this.setInitialValue(newVal)
      }
    },
    search (val) {
      if (!val) {
        // Clear items if search is empty
        this.items = []
        if (this.clearable) this.select = null
        this.stopDebouncedSearch = false
      } else if (this.items.some((x) => (x.text === this.search) || (x === this.search))) {
        // Do nothing. Prevent an extra call when selecting an item
      } else {
        if (val.length) {
          // if a user highlighted the field and started typing, reset the search
          this.items = []
          this.stopDebouncedSearch = false
        }
        this.canKeydownMenu = false
        // use searchParams if provided
        if (this.searchParams) {
          this.debouncedQuery({ term: this.search, ...this.searchParams })
        } else {
          this.debouncedQuery(this.search)
        }
      }
    },
    select (newVal, oldVal) {
      if (newVal !== oldVal && newVal !== this.initialValue) this.selectItem()
    }
  },

  mounted () {
    this.$watch(() => {
      return this.$refs.autocomplete.$refs.menu.listIndex
    }, (val) => {
      // As of Vuetify v1.2.8, the default behavior of VSelect and VAutoselect is to highlight the first item in
      // the menu (this.items) if there is only one item in the menu, thus highjacking the 'onEnter' functionality we intend
      if (this.disableSingleItemAutoSelect && this.items.length === 1 && val !== -1 && !this.canKeydownMenu) {
        // Once the debounced search is complete, Vuetify will try and set it's internal component ref `menu.listIndex` to 0
        // (the first item in this.items) once. We want to watch for that, along with a few of our component's conditionals,
        // and instead set the index to -1 (off the menu), then allow the user to keydown through the menu again
        this.$refs.autocomplete.$refs.menu.listIndex = -1
        this.canKeydownMenu = true
      }
    })
  },

  methods: {
    emitSearch () {
      if (this.search) {
        const query = this.search

        if (this.clearOnSelect) {
          this.search = null
          this.select = null
          this.items = []
        }
        // wait for `search` watcher to run before setting stopDebouncedSearch
        // otherwise it resets the value to false before the query runs
        this.$nextTick(() => {
          this.stopDebouncedSearch = true
          this.$emit('search', query)
        })
      }
    },
    mapResults (data) {
      return data.map((x) => ({
        text: this.format(x),
        value: x
      }))
    },
    debouncedQuery: debounce(function (searchVal) {
      if (!this.stopDebouncedSearch) {
        this.loading = true
        this.endpoint(searchVal)
          .then((res) => {
            let data = this.endpointDataAccessor(res.data)
            if (Object.prototype.hasOwnProperty.call(res.data, 'value')) {
              // getCustomerSearchEndpoint nests customer results in value prop
              data = res.data.value
            }
            this.items = this.mapResults(data)

            this.loading = false
          })
          .catch((e) => {
            this.errors = ['Error fetching data']
            this.loading = false
          })
      }
      this.stopDebouncedSearch = false
    }, 500),
    clear () {
      this.$emit('select', null)
    },
    blur () {
      if (!this.select) {
        // this handles the case of manually deleting the search text
        // other cases should be caught by selectItem
        this.$emit('select', null)
      }
    },
    selectItem () {
      this.$refs.autocomplete.blur()

      if (this.select) {
        this.$emit('select', this.select)
        if (this.clearOnSelect) {
          this.search = null
          this.select = null
          this.items = []
        }
      }
      if (this.clearable && !this.select) {
        // emit null value when cleared
        this.$emit('select', this.select)
      } else if (this.search) {
        this.emitSearch()
      }
    },
    setInitialValue (val) {
      this.items = this.mapResults([val])
      this.select = val
    }
  }
}
</script>

<style lang="scss">
@import '~styl/colors';

.customers-autocomplete.v-select--is-menu-active .v-input__icon--append .v-icon {
  transform: none !important;
}

.v-autocomplete__content .v-list:not(.v-list--dense) .v-list__tile{
  height: auto;
  min-height: 48px;
}
</style>
