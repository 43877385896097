import { AxiosStatic } from 'axios'
import { ApiEndpointsModel } from './apiEndpointsModel'

// Refactored out of ad-hoc object constructed in main.js and passed to auth init

/** Model extracted out of ad-hoc object that was being created during app initialization. */
export class AuthInitProps {
  public axios: AxiosStatic
  public authority: string // Was AUTHORITY
  public clientId = 'plattforce.frontend' // Was CLIENT_ID
  public redirectUri = `${window.location.origin}/auth-callback` // Was REDIRECT_URI
  public scope = 'openid profile api' // Was SCOPE
  public monitorSession = process.env.VUE_APP_MONITOR_SESSION !== 'false' // Was MONITOR_SESSION
  public postLogoutRedirectUri = `${window.location.origin}` // Was POST_LOGOUT_REDIRECT_URI
  public responseType = 'id_token token' // Was RESPONSE_TYPE
  public silentRedirectUri = `${window.location.origin}/silent-auth-callback` // Was SILENT_REDIRECT_URI

  constructor (axios: AxiosStatic, endpoints: ApiEndpointsModel) {
    this.axios = axios
    this.authority = endpoints.identityAuthority
  }
}
