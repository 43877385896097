import Vue from 'vue'
import {
  formatDate,
  formatHireDate,
  formatMonthYearDate,
  formatDecimal,
  formatMoney,
  formatNumber,
  formatNumberWithCommas,
  formatPercent,
  formatShowSign,
  formatPhone,
  formatTitleCase,
  formatFirstLastName,
  formatAddress,
  formatBranchName,
  formatCustomerTitle,
  formatCustomerType,
  formatVendor,
  formatAbbreviatedNumber
} from './formatters'

Vue.filter('formatDate', (val, format, formatNumber) =>
  formatDate(val, format, formatNumber)
)
Vue.filter('formatHireDate', (val) =>
  formatHireDate(val)
)
Vue.filter('formatMonthYearDate', (val) =>
  formatMonthYearDate(val)
)
Vue.filter('ISOtoLongDate', val => {
  const isoDate = new Date(val)
  return isoDate.toDateString()
})
Vue.filter('formatDecimal', (val, precision, defaultValue) =>
  formatDecimal(val, precision, defaultValue)
)
Vue.filter('formatMoney', (val, defaultValue) => formatMoney(val, defaultValue))
Vue.filter('formatNumber', (val, defaultValue, precision = 0) =>
  formatNumber(val, defaultValue, precision)
)

Vue.filter('formatNumberWithCommas', (val, defaultValue = 0, precision = 0) => {
  return formatNumberWithCommas(val, defaultValue, precision)
})

Vue.filter('formatPercent', (val, precision, defaultValue) =>
  formatPercent(val, precision, defaultValue)
)
Vue.filter('formatShowSign', (val, defaultValue) =>
  formatShowSign(val, defaultValue)
)

Vue.filter('formatPhone', (val, defaultValue) =>
  formatPhone(val, defaultValue)
)

Vue.filter('formatTitleCase', (val, defaultValue) =>
  formatTitleCase(val, defaultValue)
)

Vue.filter('formatFirstLastName', (val, defaultValue) =>
  formatFirstLastName(val, defaultValue)
)

Vue.filter('ellipsis', (val, maxLength) => {
  return val.length > maxLength ? `${val.substring(0, maxLength)}...` : val
})

Vue.filter('formatAddress', (val, defaultValue) => formatAddress(val, defaultValue))
Vue.filter('formatBranchName', (val, defaultValue) => formatBranchName(val, defaultValue))
Vue.filter('formatCustomerTitle', (val, defaultValue) => formatCustomerTitle(val, defaultValue))
Vue.filter('formatCustomerType', (val, defaultValue) => formatCustomerType(val, defaultValue))
Vue.filter('formatVendor', (val, defaultValue) => formatVendor(val, defaultValue))

Vue.filter('formatYesNo', (val, yes = 'Yes', no = 'No') => {
  return val ? yes : no
})

Vue.filter('numSuffix', (val, min = 999, maxSignificantDigits = 2, minLength = 2, maxLength = 2) => formatAbbreviatedNumber(val, min, maxSignificantDigits, minLength, maxLength))
