import { fetchBranches } from '@/api/branches'

export default {
  computed: {
    entityTypePlural () {
      switch (this.entityType) {
        case 'branch':
          return 'branches'
        default:
          return this.entityType + 's'
      }
    },
    entityDataParams () {
      /*
        For EntityTable: select entities for report listing
        Define parameters for different report entities: customer, branch, employee
        {
          type = entity type (ie. customer, branch, employee)
          accessor = function to grab results nested in response data
            (e.g. (entity) => entity.branches)
          filter = function to filter results
          mapping = define mapping of results to a normalized data set
          fetch = api call

          filterOptions = setup default filters an array of objs {text, value}
          ...OR...
          setupFilters = function to generate filters from results
        }
      */

      // const bannerFilter = (entity) => entity.bannerName.toLowerCase() === this.banner

      switch (this.entityType) {
        case 'customer':
          return {
            // fetch: // handled with elastic
            mapping: this.customerMapping
          }
        case 'branch':
          return {
            fetch: fetchBranches,
            mapping: this.branchMapping,
            setupFilters: (data) => {
              const regions = data.reduce((list, item) => {
                const uniqueKey = item.regionName

                // If this is blank, we're not adding it to the list of regions
                if (!uniqueKey) {
                  return list
                }

                const uniqueKeyIndex = list.findIndex(
                  (listItem) => listItem.text === uniqueKey
                )
                if (uniqueKeyIndex === -1) {
                  list.push({
                    text: item.regionName,
                    value: item.regionName
                  })
                }
                return list
              }, [])
              return regions
            }
          }
        // TODO
        // case 'employee': ...
        // case 'region': ...
        default:
          // entityType not recognized...
          console.error('Report cannot be shown')
          return {}
      }
    }
  },
  methods: {
    customerMapping (entity) {
      return {
        id: entity.customerId,
        companyId: entity.companyCustomerNumber,
        name: entity.customerName,
        initials: this.formatInitials(entity.customerName),
        address: this.formatAddress(entity.address),
        extraText: 'Branch',
        extraValue: `${entity.branchName} (${entity.branchNumber})`,
        salesData: entity.rolling12Sales
      }
    },
    branchMapping (entity) {
      return {
        id: entity.branchNumber,
        branchId: entity.branchId,
        name: entity.branchName + ' (' + entity.branchNumber + ')',
        imageUrl: this.formatBranchImage(entity.imageFolderName),
        extraText: 'Region',
        extraValue: `${entity.regionName} (${entity.regionCode})`,
        salesData: entity.branchStats.totalSalesLast365,
        filterProp: entity.regionName
      }
    },
    formatInitials (name) {
      return name
        .split(' ')
        .map((x) => x[0])
        .slice(0, 3)
        .join('')
    },
    formatAddress (address) {
      const {
        address1,
        address2,
        city,
        state,
        postalCode,
        zip
      } = address
      return `${address1}, ${
        address2 ? `${address2},` : ''
      } ${city}, ${state} ${postalCode || zip}`
    },
    formatBranchImage (imageUrl) {
      const baseImageUrl = 'https://rexel-cdn.com/branches/storefront'
      if (!imageUrl) return null
      return imageUrl.startsWith('http')
        ? imageUrl
        : `${baseImageUrl}/${imageUrl}/640.jpg`
    }
  }
}
