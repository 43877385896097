import { callApi, generateUrl } from '@/globals/utils'

// Single Customer Sales Plan (Customer Profile)
export const fetchCustomerSalesPlan = (id) =>
  callApi({
    method: 'GET',
    url: `/api/sales-plan/${id}`,
    messages: {
      error: 'Error retrieving Customer\'s Sales Plan'
    }
  })

// Customer Sales Planning
export const fetchCustomerSalesPlans = (myCustomersOnly) =>
  callApi({
    method: 'GET',
    url: '/api/sales-plans',
    params: { myCustomersOnly },
    messages: {
      error: 'Error retrieving Customer Sales Plans'
    }

  })

export const saveCustomerSalesPlan = (payload) => {
  return callApi({
    method: 'POST',
    url: '/api/sales-plan',
    payload,
    messages: {
      success: 'Successfully updated Sales Goal',
      error: 'Error updating Sales Goal'
    }
  })
}

// Vendor Sales Planning
export const fetchVendorSalesPlans = () =>
  callApi({
    method: 'GET',
    url: '/api/vendor-sales-plans',
    messages: {
      error: 'Error retrieving Vendor Sales Plans'
    }
  })

export const saveVendorSalesPlan = (payload) =>
  callApi({
    method: 'POST',
    url: '/api/vendor-sales-plan',
    payload,
    messages: {
      success: 'Successfully updated Vendor Sales Goal',
      error: 'Error updating Vendor Sales Goal'
    }
  })

export const bulkSalesPlanUpdate = (payload) => {
  console.log('payload', payload)
  return callApi({
    method: 'POST',
    url: '/api/sales-plans/bulk-sales-goal-update',
    payload,
    messages: {
      success: 'Successfully updated customer sales goals.',
      error: 'Error updating customer sales goals.'
    }
  })
}

export const getVendorSalesPlansExportUrl = (sso, displayName, planningYear, banner) =>
  generateUrl('/api/vendor-sales-plans/export', { sso, displayName, planningYear, banner })

export const getCustomerSalesPlansExportUrl = (sso, displayName, planningYear, myCustomersOnly, banner) =>
  generateUrl('/api/sales-plans/export', { sso, displayName, planningYear, myCustomersOnly, banner })
