import axios from 'axios'

export const deleteFeedbackAttachment = async (token) => {
  return axios.post(`/api/feedback/attachment/delete?token=${encodeURIComponent(token)}`)
}
export const uploadFeedbackFile = (file) => {
  const form = new FormData()
  form.append('file', file)
  return axios.post('/api/feedback/attachment/upload', form, { headers: { 'Content-Type': 'multipart/form-data' } })
}

export const submitFeedback = async (form) => {
  return axios.post('/api/feedback', form)
}
