<template>
  <div
    class="vertical-align-items">
    <template
      v-if="appendStatus">
      <span
        v-if="customer.atRiskInfo && (customer.atRiskInfo.atRisk || customer.churnModelHighRisk)"
        class="customer-status red">
      </span>
      <span
        v-if="!customer.isEnterprise && customer.inactive"
        class="customer-status orange">
      </span>
    </template>
    <span class="list-title shrink">
      {{ `${customer.customerName} (${customer.customerNumber})` }}
    </span>
    <v-icon
      v-if="customer.followedBySso"
      color="primary">
      bookmark
    </v-icon>
    <span
      v-if="$hasFeature('Customer_ShipToBillTo')"
      class="muted font-italic">
      {{ status }}
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CustomerListTileTitle',
  props: {
    customer: {
      type: Object,
      required: true
    },
    appendStatus: {
      type: Boolean,
      default: true
    },
    status: {
      type: String,
      required: true
    }
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters('user', ['banner'])
  }
}
</script>
<style  lang="scss" scoped>
@import '~styl/colors';

.vertical-align-items * {
  vertical-align: middle;
}

.customer-status {
  display: inline-block;
  height: 10px;
  width: 10px;

  border-radius: 50%;
  margin-right: 6px;
  margin-bottom: 3px;
}

.list-title {
  font-weight: 500;
  text-transform: uppercase;
  color: #000 !important
}

.muted {
  color: $spanishGray;
  font-size: 14px;
}

</style>
