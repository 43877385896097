<template>
  <div class="user-avatar">
    <v-container
      class="py-1 px-3">
      <v-row
        class="flex-nowrap"
        dense>
        <v-col
          class="shrink">
          <v-menu
            v-model="showMyProfileModal"
            offset-y
            :close-on-content-click="false">
            <template #activator="{on}">
              <v-btn
                icon
                v-on="on">
                <v-avatar
                  v-if="userData.imageUrl"
                  :size="40"
                  class="elevation-1">
                  <img
                    :src="userData.imageUrl"
                    :alt="userData.name" />
                </v-avatar>
                <v-avatar
                  v-else
                  :size="40"
                  color="secondary">
                  <span
                    class="text-h6">
                    {{ initials }}
                  </span>
                </v-avatar>
              </v-btn>
            </template>
            <v-card
              v-if="showMyProfileModal"
              class="edit-profile-modal theme--dark mt-2"
              dark>
              <v-card-text>
                Edit your profile?
              </v-card-text>
              <v-card-actions>
                <v-btn
                  text
                  @click="toggleMyProfileModal">
                  cancel
                </v-btn>
                <v-btn
                  color="primary"
                  @click="openMyProfileEdit">
                  Yes
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-col>

        <!-- User branch & name -->
        <v-col class="d-flex flex-column justify-center text-body-2 ml-1">
          <div
            v-if="userData.branch"
            data-qa="user_branch_name"
            class="text-caption text-truncate">
            {{ userData.branch }}
          </div>
          <div
            data-qa="user_name">
            {{ userData.name }}
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

/* ***
 * This component is found in:
 * - Sales: @/layout/SidebarNavigationGlobal/UserAvatar.vue
 * - BranchAdmin: @/layout/SidebarNavigationGlobal/UserAvatar.vue
 * The plan is for these components to be added as an NPM dependency at a later date.
 * For now, just make sure any changes made in this component are made in both projects.
 * ***/
export default {
  name: 'UserAvatar',
  props: {
    userData: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  data () {
    return {
      showMyProfileModal: false
    }
  },
  computed: {
    initials () {
      if (this.userData.name) {
        return this.userData.name
          .split(' ')
          .map((x) => x.slice(0, 1).toUpperCase())
          .join('')
      }
      return null
    }
  },
  methods: {
    ...mapActions(['setOpenMyProfile']),
    toggleMyProfileModal () {
      this.showMyProfileModal = !this.showMyProfileModal
    },
    openMyProfileEdit () {
      this.setOpenMyProfile(true)
      this.showMyProfileModal = false
    }
  }
}
</script>
