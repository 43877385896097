<template>
  <v-text-field
    v-facade="fullMask"
    :value="maskedValue"
    v-bind="$attrs"
    @input.native="handleInput($event)"></v-text-field>
</template>

<script>
import maskedInputMixin from './masked.input.mixin'

export default {
  name: 'MaskedInput',
  mixins: [
    maskedInputMixin
  ]
}
</script>
