import { BASE_URI } from './constants'

export class Globals {
  public static environment: Environment = 'Test'

  public static get isTest () {
    return this.environment === 'Test'
  }

  public static get isDemo () {
    return this.environment === 'Demo'
  }

  public static get isProd () {
    return this.environment === 'Production'
  }

  /** Provides the Order Central Base URI depending on the operating environment */
  public static get ordersBaseUri () {
    switch (Globals.environment) {
      case 'Test':
        return BASE_URI.ordersTest
      case 'Demo':
        return BASE_URI.ordersDemo
      default:
        return BASE_URI.ordersProd
    }
  }
}
