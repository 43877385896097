import axios from 'axios'
import { BannerFeatureFlag } from './models/bannerFeatureFlag'

const getBannerFeatureFlags = async () => {
  return await axios.get<BannerFeatureFlag[]>('/api/featureflags')
}

export {
  getBannerFeatureFlags
}
