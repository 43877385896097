<template>
  <v-container>
    <v-row no-gutters>
      <v-col class="text-h6">
        Error loading app
      </v-col>
      <v-col cols="12">
        <v-btn
          color="primary"
          @click="signInRedirect()">
          Try again
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { identityService } from '@/features/identity'
import { removeViewAsSSO } from '@/api'

export default {
  name: 'ErrorLoadingApp',
  methods: {
    signInRedirect () {
      removeViewAsSSO()
      identityService.authManager.signinRedirect({ state: '/' })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
