import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'md'
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#324253',
        highlight: '#4C687A',
        secondary: '#333333',
        accent: '#172735',
        error: '#e7423c',
        info: '#0084d8',
        success: '#00a14d',
        warning: '#febe10',
        banner: '#099c98',
        backgroundGrey: '#eeeeee',
        black: '#000000',
        grey: '#C9C9C9',
        greyMedium: '#999999',
        orangeTang: '#FF8200',
        graphPrimary: '#A10000',
        graphSecondary: '#0084d8',
        graphTertiary: '#999999',
        graphQuaternary: '#FF8200'
      }
    }
  }
})
