<template>
  <v-container class="justify">
    <input
      v-if="inputReady"
      v-show="false"
      :id="`file${id}`"
      ref="fileUpload"
      type="file"
      @change="fileInputHandler()" />
    <v-btn
      class="no-text-transform"
      text
      @click="$refs.fileUpload.click()">
      {{ inputLabel }}
    </v-btn>
    <v-btn
      v-if="fileExists"
      icon
      @click="clearInput()">
      <v-icon>close</v-icon>
    </v-btn>
    <span>{{ fileName }}</span>
  </v-container>
</template>
<script>

export default {
  name: 'FormFileUploader',
  props: {
    inputLabel: {
      type: String,
      required: false,
      default: 'Select File to Upload'
    },
    onUpload: {
      type: Function,
      required: true
    },
    onRemove: {
      type: Function,
      required: true
    },
    id: {
      type: String,
      required: false,
      default: 'file'
    }
  },
  data () {
    return {
      inputReady: true,
      fileExists: false,
      fileName: null
    }
  },
  mounted () {
    this.clearInput()
  },
  methods: {
    fileInputHandler (event) {
      if (this.$refs.fileUpload.files[0]) {
        this.onUpload(this.$refs.fileUpload.files[0])
        this.fileName = this.$refs.fileUpload.files[0].name
        this.fileExists = true
      } else {
        this.fileExists = false
      }
    },
    clearInput () {
      this.inputReady = false
      this.$nextTick(() => {
        this.inputReady = true
        this.onRemove()
        this.fileName = ''
        this.fileExists = false
      })
    }
  }
}

</script>

<style lang="scss" scoped>
.no-text-transform {
  text-transform: none !important;
}
</style>
