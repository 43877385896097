import { getDashboardDataSimplified, updateSimplifiedDashBoardSalesGoals } from '@/api'

export const getSimplifiedDashboardData = ({ commit }, sso) => {
  const setSSO = typeof sso !== 'undefined' ? sso : false
  if (setSSO) {
    return getDashboardDataSimplified(setSSO).then(response => {
      commit('setSimplifiedDashboardData', response.data)
    })
      .catch((error) => {
        console.log(error)
      })
  }
}

export const updateSimplifiedSalesGoals = ({ commit }, data) => {
  return updateSimplifiedDashBoardSalesGoals(data).then(() => {
    commit('setSimplifiedDashboardGoals', data)
  })
}

export const getSimplifiedDashboardToggle = ({ commit }, value) => {
  commit('setSimplifiedDashboardToggle', !!value) // We're bool casting this as some callers pass in incorrect values, such as undefined
}
