const setBranch = (state, branch) => {
  const index = state.branches.findIndex(x => x.branchNumber === branch.branchNumber)
  if (index !== -1) {
    state.branches[index] = branch
  } else {
    state.branches.push(branch)
  }
}

const setBranches = (state, branches) => {
  state.branches = branches
  state.branchesLoaded = true
}

const resetBranches = (state) => {
  state.branches = []
  state.branchesLoaded = false
}

const setBranchById = (state, branch) => {
  const index = state.branchesById.findIndex(x => x.branchId === branch.branchId)
  if (index !== -1) {
    state.branchesById[index] = branch
  } else {
    state.branchesById.push(branch)
  }
}

const setBranchesById = (state, branches) => {
  state.branchesById = branches
  state.branchesByIdLoaded = true
}

const resetBranchesById = (state) => {
  state.branchesById = []
  state.branchesByIdLoaded = false
}

export default {
  setBranch,
  setBranchById,
  setBranches,
  setBranchesById,
  resetBranches,
  resetBranchesById
}
