export const setCustomersSearchParams = (state, customersSearchParams) => {
  state.customersSearchParams = customersSearchParams
}

export const setCustomersResult = (state, { customers, total }) => {
  state.customers = customers
  state.totalCustomers = total
}

export const setCustomersLoading = (state, loading) => {
  state.customersLoading = loading
}

export const setCachedCustomer = (state, customer) => {
  state.cachedCustomers[customer.customerId] = customer
}

export const setCustomersForSearchTrends = (state, payload) => {
  state.searchTrends.customers = payload
}

export const setCustomerSearchTrends = (state, payload) => {
  const { customerId, data } = payload
  state.searchTrends.searchTrends = {
    ...state.searchTrends.searchTrends,
    [customerId]: data
  }
}

export const setStorefrontUserSearchActive = (state, value) => {
  state.storefrontUserSearchActive = value
}
