const initForm = (state, payload) => {
  const { name } = payload
  state[name] = { hasChanges: false }
}

const destroyForm = (state, name) => {
  delete state[name]
}

const updateFormStatus = (state, payload) => {
  const { name, hasChanges } = payload
  if (!state[name]) {
    state[name] = {}
  }
  state[name].hasChanges = hasChanges
}

export default {
  initForm,
  destroyForm,
  updateFormStatus
}
