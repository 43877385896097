<template>
  <span class="file-icon">
    <img
      :alt="filename"
      :src="iconSrc" />
  </span>
</template>

<script>
import anyFile from '@/assets/icons/file-types/any_file.svg'
import csv from '@/assets/icons/file-types/csv.svg'
import img from '@/assets/icons/file-types/img.svg'
import pdf from '@/assets/icons/file-types/pdf.svg'
import txt from '@/assets/icons/file-types/txt.svg'

export default {
  name: 'FileIcon',
  props: {
    filename: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  computed: {
    iconSrc () {
      switch (this.type.split('/')[1]) {
        case 'jpeg':
        case 'jpg':
        case 'png':
        case 'bmp':
        case 'gif':
          return img
        case 'csv':
          return csv
        case 'pdf':
          return pdf
        case 'plain':
        case 'msword':
        case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
          return txt
        default:
          return anyFile
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.file-icon {
  display: inline-block;
  height: 42px;
  img {
    width: 36px;
  }
}
</style>
