<template>
  <base-dialog
    :show="show"
    :valid="valid"
    @close="close"
    @submit="submit">
    <template slot="title">
      OE Notes Change Request
    </template>
    <v-form
      ref="form"
      slot="content"
      v-model="valid">
      <v-textarea
        v-model="notes"
        v-required
        rows="2"
        auto-grow
        filled
        :label="label"
        :rules="rules">
      </v-textarea>
    </v-form>
  </base-dialog>
</template>

<script>
import { isRequired, maxLength } from '@/rules'
import { postOrderEntryNoteChangeRequest } from '@/api/customers'
import BaseDialog from '@/components/BaseDialog.vue'

export default {
  name: 'RequestNotesChange',
  components: {
    BaseDialog
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    customer: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      rules: [isRequired(), maxLength(500, 'Maximum 500 characters allowed')],
      valid: false,
      label: 'Description of OE note change being requested',
      notes: ''
    }
  },
  methods: {
    async submit () {
      if (this.$refs.form.validate()) {
        const payload = {
          companyCustomerNumber: this.customer.accountId,
          notes: this.notes
        }

        const { errors } = await postOrderEntryNoteChangeRequest(payload)
        if (!errors) {
          this.close()
        }
      }
    },
    close () {
      this.$emit('update:show', false)
      this.$refs.form.reset()
    }
  }
}
</script>
