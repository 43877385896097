<template>
  <div
    class="action-plan-notification"
    :class="{'mr-3': $vuetify.breakpoint.smAndUp}"
    @click="clickHandler">
    <v-badge
      :value="totalActions > 0"
      class="cursor-pointer action-plan-notification-badge"
      color="red darken-2"
      offset-x="0.6rem"
      overlap>
      <template
        v-if="totalActions > 0"
        #badge>
        <span>{{ totalActions }}</span>
      </template>
      <v-icon
        color="grey lighten-2">
        notifications_none
      </v-icon>
    </v-badge>
    <portal
      v-if="isDashboard && $hasFeature('ActionPlan_Header')"
      to="dashboard-action-plan">
      <action-plan-header
        v-if="showActionPlanHeader"
        :total-actions="totalActions"
        :action-plans="actionPlans"
        :expanded="active"
        @updateExpanded="updateExpandedHandler">
      </action-plan-header>
    </portal>
  </div>
</template>

<script>
import ActionPlanHeader from './ActionPlanHeader.vue'
import { getActionPlan } from '@/api/action-plan'
import { mapGetters } from 'vuex'

export default {
  name: 'ActionPlan',
  components: {
    ActionPlanHeader
  },
  data () {
    return {
      active: false,
      actionPlans: []
    }
  },
  computed: {
    ...mapGetters('user', ['displayName', 'user']),
    ...mapGetters('actionPlan', ['actionPlanUpdate']),
    isDashboard () {
      return this.$route.name === 'dashboard' || this.$route.name === 'dashboard-sections'
    },
    totalActions () {
      return this.actionPlans.map(plan => plan.reportLines).reduce((sum, reportLine) => sum + reportLine, 0)
    },
    showActionPlanHeader () {
      return ['Mgr', 'AsstMgr', 'Sales'].includes(this.user.userType)
    }
  },
  watch: {
    displayName () {
      this.fetchData()
    },
    actionPlanUpdate () {
      this.fetchData()
    }
  },
  async mounted () {
    this.fetchData()
  },
  methods: {
    clickHandler () {
      if (!this.isDashboard) {
        this.active = true
        this.$router.push({
          name: 'dashboard'
        })
        return
      }
      this.active = !this.active
    },
    updateExpandedHandler (expanded) {
      this.active = expanded
    },
    async fetchData () {
      getActionPlan()
        .then((res) => {
          this.actionPlans = res.data
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~styl/colors';

  .action-plan-notification {
    cursor: pointer;
  }
  ::v-deep .v-badge {
    &__badge {
      height: 1.25rem;
      width: 1.25rem;
      font-size: 0.75rem;
      display: flex;
      justify-content: center;
      align-content: center;
    }
  }
</style>
