import * as actions from './actions'
import * as getters from './getters'
import * as mutations from './mutations'

const state = {
  cachedCustomers: {}, // TODO???: key by customerId
  customersLoading: false,
  customers: [],
  totalCustomers: 0,
  customersSearchParams: {
    term: '',
    banner: '',
    source: 'my',
    filters: [],
    orderBy: 'sales desc',
    page: 1,
    ssoNumber: null,
    branchId: null
  },
  searchTrends: {
    customers: null,
    searchTrends: {}
  },
  storefrontUserSearchActive: false
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
