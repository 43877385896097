<template>
  <v-row>
    <template v-if="hasSearchCategories">
      <!-- Filter by -->
      <v-col
        cols="12"
        sm="4"
        md="3"
        :class="{'pr-0 min-width-105':$vuetify.breakpoint.smAndUp}">
        <v-select
          v-model="filterBy"
          :items="filterByOptions"
          return-object
          hide-details
          single-line
          filled
          :class="{'border-right--light-grey':$vuetify.breakpoint.smAndUp}"
          @change="selectFilterBy">
        </v-select>
      </v-col>

      <!-- Filter input -->
      <v-col
        cols="12"
        sm="8"
        md="3"
        :class="{'pl-0':$vuetify.breakpoint.smAndUp}">
        <v-autocomplete
          v-if="isFilteredByJobNumber"
          v-model="search"
          :items="jobs"
          :placeholder="placeholderVal"
          :loading="jobsLoading"
          :disabled="jobsLoading"
          hide-details
          single-line
          clearable
          filled
          @input="debounceInput($event, updateFilters)">
        </v-autocomplete>
        <v-text-field
          v-else
          v-model="search"
          :placeholder="placeholderVal"
          append-icon="search"
          hide-details
          single-line
          clearable
          filled
          @input="debounceInput($event, updateFilters)">
        </v-text-field>
      </v-col>

      <!-- Filter by date range  -->
      <v-col
        cols="12"
        sm="9"
        md="4"
        class="d-flex align-start">
        <month-picker-field
          label="Start month"
          :allowed-dates="restrictStartDate"
          :date.sync="startDate">
        </month-picker-field>
        <v-spacer class="mx-2"></v-spacer>
        <month-picker-field
          label="End month"
          :allowed-dates="restrictEndDate"
          :date.sync="endDate">
        </month-picker-field>
      </v-col>
    </template>
    <template v-else>
      <v-col
        cols="10"
        sm="6"
        md="4">
        <v-text-field
          v-model="search"
          label="Search for Cat Name"
          hide-details
          append-icon="search"
          filled
          @input="debounceInput($event, updateSearch)">
        </v-text-field>
      </v-col>
    </template>

    <v-col class="text-right">
      <download-link
        v-if="exportUrl"
        :href="exportUrl">
        <v-btn
          small
          fab>
          <v-icon>file_download</v-icon>
        </v-btn>
      </download-link>
    </v-col>
    <v-col
      v-if="hasSearchCategories"
      cols="12"
      class="text-right pt-0 font-italic">
      Results are returned by invoice date
    </v-col>
  </v-row>
</template>

<script>
import debounce from 'lodash.debounce'
import { getDateYearBefore } from '@/globals/time'
import DownloadLink from '@/components/DownloadLink.vue'
import MonthPickerField from '@/components/inputs/MonthPickerField.vue'

export default {
  name: 'ProductTableHeader',
  components: {
    DownloadLink,
    MonthPickerField
  },
  props: {
    entityId: {
      type: Number,
      required: false,
      default: null
    },
    hasSearchCategories: {
      type: Boolean,
      required: false,
      default: false
    },
    fetchJobs: {
      type: Function,
      required: false,
      default: () => []
    },
    initialStartDate: {
      type: String,
      required: true
    },
    initialEndDate: {
      type: String,
      required: true
    },
    exportUrl: {
      type: String,
      required: false,
      default: null
    }
  },
  data () {
    return {
      filterByOptions: [
        { text: 'Cat Name or Item Number', value: 'catName', exactMatch: false }, // partial match accepted
        { text: 'Job Account', value: 'jobNumber', exactMatch: true }, // exact match only
        { text: 'PO #', value: 'poNumber', exactMatch: true } // exact match only

      ],
      filterBy: 'catName',
      isExactMatchFilter: false,
      filters: {
        jobNumber: null,
        poNumber: null,
        catName: null
      },
      earliestAvailableDate: getDateYearBefore(new Date(), 3),
      startDate: null,
      endDate: null,
      search: null,
      jobsLoading: false,
      jobs: []
    }
  },
  computed: {
    isFilteredByJobNumber () {
      return this.filterBy === 'jobNumber'
    },
    placeholderVal () {
      switch (this.filterBy) {
        case 'catName':
          return 'Enter Cat Name or Item Number'
        case 'jobNumber':
          return 'Select a Job Account' // User may choose from an autocomplete of job account names/numbers
        case 'poNumber':
          return 'Enter PO Number'
        default:
          return null
      }
    }
  },
  watch: {
    startDate (newVal) {
      this.updateFilters()
    },
    endDate (newVal) {
      this.updateFilters()
    },
    filterBy (newVal) {
      this.updateFilters()
    }
  },
  beforeMount () {
    this.startDate = this.initialStartDate
    this.endDate = this.initialEndDate
  },
  async mounted () {
    this.jobsLoading = true
    if (this.hasSearchCategories) {
      await this.fetchJobs(this.entityId).then((res) => {
        this.jobs = res.data.map((x) => ({
          text: `${x.jobName} (${x.customerJobNumber})`,
          value: x.customerJobNumber
        }))
      })
    }
    this.jobsLoading = false
  },
  methods: {
    selectFilterBy (filterByOption) {
      // clear any previous filters except selected filter
      Object.keys(this.filters).forEach((filterKey) => {
        this.filters[filterKey] = null
      })

      this.filterBy = filterByOption.value
      this.isExactMatchFilter = filterByOption.exactMatch
      if (this.search) {
        this.search = null
        this.updateFilters()
      }
    },
    debounceInput: debounce((search, callback) => {
      callback(search)
    }, 500),
    updateFilters (search) {
      search = search || this.search
      this.filters[this.filterBy] = search

      this.$emit('updateFilters', {
        filterBy: this.filterBy,
        filters: this.filters,
        startDate: this.startDate,
        endDate: this.endDate,
        isExactMatchFilter: this.isExactMatchFilter
      })
    },
    updateSearch (search) {
      search = search.toLowerCase()
      this.$emit('updateSearch', { search })
    },
    restrictStartDate (date) {
      return new Date(date) < new Date(this.endDate) && new Date(date) > this.earliestAvailableDate
    },
    restrictEndDate (date) {
      return new Date(date) > new Date(this.startDate) &&
        new Date(date) < new Date() &&
        new Date(date) > this.earliestAvailableDate
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~styl/colors';

.min-width-105 {
  min-width: 110px;
}

.border-right--light-grey {
  border-right: 1px solid $lightGrey;
}
</style>
