import {
  getCategoriesV2,
  getStatusTypesV2,
  getTypesV2
} from '@/api/activities-v2'

const getActivitiesOptions = async ({ commit, state }) => {
  const {
    categories,
    statuses,
    types
  } = state
  // check if options are in store before requesting

  const options = [
    {
      state: categories,
      apiCall: getCategoriesV2,
      mutation: 'setCategories'
    },
    {
      state: statuses,
      apiCall: getStatusTypesV2,
      mutation: 'setStatuses'
    },
    {
      state: types,
      apiCall: getTypesV2,
      mutation: 'setTypes'
    }
  ]
  const requestIfNotCached = async ({ state, apiCall, mutation, params }) => {
    if (state.length === 0) {
      const { data } = await apiCall(params)

      commit(mutation, data)
    }
  }
  const promises = options.map(async (option) => {
    const awaiting = await requestIfNotCached(option)
    return awaiting
  })
  await Promise.all(promises)
}

const resetActivitiesOptions = ({ state }) => {
  state.categories = []
  state.statuses = []
  state.types = []
}

const setStartDate = async ({ commit }, startDate) => {
  commit('SET_START_DATE', startDate)
}

const setEndDate = async ({ commit }, endDate) => {
  commit('SET_END_DATE', endDate)
}

export default {
  getActivitiesOptions,
  resetActivitiesOptions,
  setStartDate,
  setEndDate
}
