import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  categories: [],
  statuses: [],
  types: [],
  probabilities: [],
  initiatives: [],
  specialists: [],
  productCategories: [],
  businessUnits: [],
  savedFilters: {}
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
