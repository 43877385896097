import {
  getByProductCustomerReport,
  byProductCustomerExportUrl,
  getByProductForBranchReport,
  byProductForBranchCompareExportData,
  byProductForBranchReportExportUrl,
  getByProductForBranchCompareReport,
  getByProductCompanyReport,
  getCompanySalesData,
  getCustomerJobAccounts
} from '@/api/reports'
import { fetchCustomerSalesStats } from '@/api/customers'
import { mapActions } from 'vuex'
import { getBranchSalesData } from '@/api/branches'
// import { safeAccess } from '@/globals/utils'

export default {
  data () {
    return {
      entityNames: {},
      prefetchedIds: []
    }
  },
  computed: {
    validEntityTypes () {
      return ['customer', 'branch', 'company']
    },
    isValidEntityType () {
      return this.validEntityTypes.includes(this.entityType)
    },
    entityTypePlural () {
      switch (this.entityType) {
        case 'branch':
          return 'branches'
        default:
          return this.entityType + 's'
      }
    },
    entityDataParams () {
      /*
        For ProductListing: product sales data for selected entity(ies)
        Define parameters for different report entities: customer, branch, employee
        {
          fetch = api call for table
          fetchCompare = api call for table comparison
          fetchAccessor = accessor function to grab results in nested prop
          refetchRules = rules to refetch results
            * onPagination = refetch results on pagination
            * onFilter = refetch results on filter (cat name, job#, po#, timeframe)
          hasSearchCategories = show all product category search options (cat name, job#, po#)
          fetchJobs = api call for customer job account list table filter
          totalItemsAccessor = get totalItems from server-side paginated results
          mapping = map response results to normalized list of objects for table
          sortBy = sort by column
          identifier = prop used to identify entity in fetchCompare results

          monthlySalesAccessor = accessor to grab resultss in nested prop
          chartDataIdentifier = id prop in chartData

          lookupEntity = use entity id to get name or id
            - preFetch = call lookup prior to fetch

          exportUrl = export url
        }
      */

      switch (this.entityType) {
        case 'customer':
          return {
            fetch: getByProductCustomerReport,
            // fetchCompare: --> not needed
            // fetchAccessor: --> not needed
            refetchRules: {
              onPagination: false,
              onFilter: true,
              clientOnlyFilters: ['catName'] // these filters will not trigger refetch
            },
            hasSearchCategories: true,
            fetchJobs: getCustomerJobAccounts,
            // totalItemsAccessor: --> not needed
            mapping: this.customerMapping,
            sortBy: 'sales',
            identifier: 'customerId',

            fetchChart: fetchCustomerSalesStats,
            monthlySalesAccessor: resData => resData.monthlyStats,
            yearlySalesAccessor: resData => resData.yearlyStats,
            // chartDataIdentifier: --> not needed
            lookupEntity: {
              getter: this.getCustomer, // returns object
              nameProp: 'name'
            },
            exportUrl: byProductCustomerExportUrl
          }
        case 'branch':
          return {
            fetch: getByProductForBranchReport,
            fetchCompare: getByProductForBranchCompareReport,
            fetchAccessor: resData => resData.productSales.response,
            refetchRules: {
              onPagination: true,
              onFilter: true,
              clientOnlyFilters: []
            },
            hasSearchCategories: false,
            totalItemsAccessor: this.branchTotalItemsAccessor,
            mapping: this.branchMapping,
            sortBy: 'catNum',
            identifier: 'branchId',

            fetchChart: getBranchSalesData,
            monthlySalesAccessor: resData => resData.monthlySales,
            yearlySalesAccessor: resData => resData.yearlySales,
            chartDataIdentifier: 'branchId',
            lookupEntity: {
              preFetch: true,
              getter: this.getBranch, // returns promise
              nameProp: 'branchName',
              idProp: 'branchId'
            },
            exportUrl: byProductForBranchReportExportUrl
          }
        case 'company':
          return {
            fetch: getByProductCompanyReport,
            // fetchCompare: --> not needed
            fetchAccessor: resData => resData.response,
            // identifier: --> not needed
            mapping: this.companyMapping,
            refetchRules: {
              onPagination: true,
              onFilter: false,
              clientOnlyFilters: []
            },
            hasSearchCategories: false,
            totalItemsAccessor: this.companyTotalItemsAccessor,
            sortBy: 'sales',

            fetchChart: getCompanySalesData,
            monthlySalesAccessor: resData => resData.monthlySales,
            yearlySalesAccessor: resData => resData.yearlySales,
            // chartDataIdentifier: --> not needed
            exportUrl: byProductCustomerExportUrl,
            lookupEntity: {}
          }
        default:
          // entityType not recognized...
          console.error('Report cannot be shown')
          return {}
      }
    },
    fetchJobs () {
      return this.entityDataParams.fetchJobs
    },
    fetchMethod () {
      return this.isComparison
        ? this.entityDataParams.fetchCompare
        : this.entityDataParams.fetch
    },
    fetchChartMethod () {
      return this.isComparison
        ? this.entityDataParams.fetchChart
        : this.entityDataParams.fetchChart
    },
    refetchRules () {
      return this.entityDataParams.refetchRules
    },
    hasSearchCategories () {
      return this.entityDataParams.hasSearchCategories
    },
    sortBy () {
      return this.entityDataParams.sortBy
    },
    entityIdentifier () {
      return this.entityDataParams.identifier
    },
    entityChartIdentifier () {
      const { chartDataIdentifier, identifier } = this.entityDataParams
      return chartDataIdentifier || identifier
    },
    exportUrl () {
      const { entityId, qualifiedEntityIds, entityIdentifier, salesData } = this
      const { exportUrl } = this.entityDataParams

      if (entityId) {
        if (entityIdentifier === 'customerId') {
          return exportUrl(
            this.entityId,
            this.filters.poNumber,
            this.filters.jobNumber,
            this.pagination.sortBy,
            this.pagination.sortDesc,
            this.search,
            this.searchPropertyNamesForExport,
            this.startDate,
            this.endDate
          )
        }
        if (entityIdentifier === 'branchId') {
          return exportUrl(
            salesData.branchId,
            this.pagination.sortBy,
            this.pagination.sortDesc,
            this.search,
            this.searchPropertyNamesForExport
          )
        }
        return exportUrl(
          entityId
        )
      }

      if (!qualifiedEntityIds) return null
      const ids = qualifiedEntityIds.map((id) => id.qualified || id.id)

      const isQualifiedEntitiesBranches = (qualifiedEntityIds.length > 1 && entityIdentifier === 'branchId')

      if (isQualifiedEntitiesBranches) {
        return byProductForBranchCompareExportData(
          ids.join(','),
          this.pagination.sortBy,
          this.pagination.sortDesc,
          this.search,
          this.searchPropertyNamesForCompareExport
        )
      }

      return exportUrl(
        ids.join(','),
        this.pagination.sortBy,
        this.pagination.sortDesc,
        this.search,
        this.searchPropertyNamesForCompareExport
      )
    },
    prefetchedSingleId () {
      return this.prefetchedIds ? this.prefetchedIds[0] : null
    }
  },
  methods: {
    ...mapActions('customers', ['getCustomer']),
    ...mapActions('branches', ['getBranch']),
    customerMapping (entity) {
      return {
        catNum: entity.catNum,
        description: entity.description,
        mfrName: entity.mfrName,
        image: entity.imageFileFolderName,
        tickets: entity.tickets,
        maxOrderDate: entity.maxOrderDate,
        itemNumber: entity.itemNumber,
        quantity: entity.quantity,
        preferred: entity.preferred,
        sales: entity.sales,
        dcStocked: entity.dcStocked ? 'Yes' : 'No'
      }
    },
    branchMapping (entity) {
      return {
        catNum: entity.catNum,
        description: entity.description, // don't have this?
        mfrName: entity.mfrName,
        image: entity.imageFileFolder,
        tickets: entity.tickets,
        itemNumber: entity.itemNumber,
        quantity: entity.quantity,
        sales: entity.sales
      }
    },
    companyMapping (entity) {
      return {
        catNum: entity.catNum,
        description: entity.description,
        mfrName: entity.mfrName,
        image: entity.imageFileFolder,
        tickets: entity.tickets,
        itemNumber: entity.itemNumber,
        quantity: entity.quantity,
        sales: entity.sales
      }
    },
    branchTotalItemsAccessor (resData) {
      const accessor = d => d.productSales.totalItems
      return Array.isArray(resData)
        ? accessor(resData[0])
        : accessor(resData)
    },
    companyTotalItemsAccessor (resData) {
      const accessor = d => d.totalItems
      return Array.isArray(resData)
        ? accessor(resData[0])
        : accessor(resData)
    },
    async getIds () {
      // if we don't have the correct id get it from the lookup
      // ...needed to resolved branchId to branchBannerNumber
      const { getter, idProp } = this.entityDataParams.lookupEntity
      const wrappedGetter = async id => {
        const getProp = await getter(id)
        if (Object.prototype.hasOwnProperty.call(getProp, idProp)) {
          return getProp[idProp]
        } else {
          return getProp.data[idProp]
        }
      }
      const promises = this.ids.map(async id => {
        const prefetchedId = await wrappedGetter(id)
        return prefetchedId
      })
      this.prefetchedIds = await Promise.all(promises)
    },
    getNames () {
      const { getter, nameProp } = this.entityDataParams.lookupEntity
      this.qualifiedEntityIds.forEach(async ({ id }) => {
        const entity = await getter(id)
        this.$set(this.entityNames, id, entity[nameProp])
      })
    }
  }
}
