const setApps = (state, apps) => {
  state.apps = apps
}

const setTitle = (state, str) => {
  state.pageTitle = str
}

const setAppDataLoaded = (state) => {
  state.appDataLoaded = true
}
const setLoadingAppFailed = (state) => {
  state.loadingAppFailed = true
}

const setPageHeader = (state, pageHeader) => {
  state.pageHeader = pageHeader
}
const setLastRoute = (state, route) => {
  state.lastRoute = route
}

const setToastMessage = (state, message) => {
  state.toastMessage = message
}

const setErrorPage = (state, { statusCode, message }) => {
  Object.assign(state.errorPage, { statusCode, message })
}

const setCustomerPresent = (state, isPresent) => {
  state.isCustomerPresent = isPresent
}

const toggleCustomerPresent = (state) => {
  const isPresent = !state.isCustomerPresent
  state.isCustomerPresent = isPresent
}

const SET_APOLLO_PROVIDER = (state, apolloProvider) => {
  state.apolloProvider = apolloProvider
}

const setOpenEditMyProfile = (state, value) => {
  state.openEditMyProfile = value
}

const toggleDebug = (state) => {
  const debug = !state.debug
  state.debug = debug
}

export default {
  setApps,
  setPageHeader,
  setLastRoute,
  setAppDataLoaded,
  setLoadingAppFailed,
  setToastMessage,
  setErrorPage,
  setCustomerPresent,
  setTitle,
  toggleCustomerPresent,
  SET_APOLLO_PROVIDER,
  setOpenEditMyProfile,
  toggleDebug
}
