<template>
  <page-loading-progress v-if="loading">
  </page-loading-progress>
  <v-card
    v-else
    class="ma-4">
    <v-card-text class="pa-0">
      <v-container fluid>
        <v-row>
          <v-col
            md="6"
            sm="8"
            cols="12">
            <v-text-field
              v-model="search"
              hide-details
              append-icon="search"
              label="Search for a Customer"
              filled
              @keypress.enter="searchWeb()">
            </v-text-field>
          </v-col>
          <v-col
            v-if="$hasFeature('Customer_ShipToBillTo')"
            md="3"
            sm="6"
            cols="12">
            <select-filter
              :items="formattedFilters"
              :selected.sync="selectedFilters"
              label="Filters">
            </select-filter>
          </v-col>
          <v-col class="text-right">
            <download-link
              v-if="exportReportFunc"
              :href="exportReportLink">
              <v-btn
                color="default"
                fab
                small>
                <v-icon>file_download</v-icon>
              </v-btn>
            </download-link>
            <v-btn
              v-else
              color="default"
              fab
              small
              :loading="exportLoading"
              @click="exportReport()">
              <v-icon style="height: auto;">
                file_download
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-right">
            <strong><em>Sales by Rolling 12 Months</em></strong>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="formattedHeaders"
              :items="filteredCustomers"
              :sort-by.sync="pagination.sortBy"
              :sort-desc.sync="pagination.sortDesc"
              :items-per-page.sync="pagination.itemsPerPage"
              :footer-props="{ 'items-per-page-options': [5, 10, 25] }"
              :search="search"
              :loading="loading"
              :custom-sort="sortCustomers">
              <template
                slot="item"
                slot-scope="props">
                <tr>
                  <td>
                    <span class="pr-2">
                      <Anchor :to="{ name: 'customer-profile', params: { customerId: props.item.customerId}}">
                        {{ props.item.customerName }}
                      </Anchor>
                    </span>
                    <span
                      v-if="$hasFeature('Customer_ShipToBillTo')">
                      ({{ props.item | formatCustomerType }})
                    </span>
                  </td>
                  <td v-if="isManagerOrSales">
                    {{ props.item.insideSalesNames }}
                  </td>
                  <td v-if="isManagerOrSales">
                    {{ props.item.outsideSalesNames }}
                  </td>
                  <td>{{ props.item.lastLoginDate | formatDate }}</td>
                  <td v-if="!isManagerOrSales">
                    {{ props.item.lastLoginDevice || '-' }}
                  </td>
                  <td>{{ props.item.maxOrderDate | formatDate }}</td>
                  <td>{{ props.item.lastActivityDate | formatDate }}</td>
                  <td v-if="!isManagerOrSales">
                    {{ props.item.contactCount }}
                  </td>
                  <td v-if="!isManagerOrSales">
                    {{ props.item.webAccountCount }}
                  </td>
                  <td>{{ props.item.rolling12Sales | formatMoney }}</td>
                  <td>{{ props.item.followedCustomer | formatYesNo('Y', '') }}</td>
                  <td>
                    <customer-action-menu
                      :customer-id="props.item.customerId">
                    </customer-action-menu>
                  </td>
                </tr>
              </template>
              <template slot="no-data">
                No data
              </template>
              <!-- TEMP FIX - Awaiting Vuetify fix for issue: https://github.com/vuetifyjs/vuetify/issues/14405 -->
              <!-- eslint-disable -->
              <template v-slot:footer.page-text="props">
                {{ props.pageStart }}-{{ props.pageStop }} of {{ props.itemsLength.toLocaleString('en-us') }}
              </template>
              <!-- eslint-enable -->
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import sortItems from '@/globals/sortUtils'
import DownloadLink from '@/components/DownloadLink.vue'
import PageLoadingProgress from '@/components/PageLoadingProgress.vue'
import { mapGetters } from 'vuex'
import SelectFilter from '@/components/inputs/SelectFilter.vue'
import Anchor from '@/components/Anchor.vue'
import CustomerActionMenu from '@/components/CustomerActionMenu/CustomerActionMenu.vue'

export default {
  name: 'CustomersReportBase',

  components: {
    DownloadLink,
    SelectFilter,
    PageLoadingProgress,
    Anchor,
    CustomerActionMenu
  },

  props: {
    customers: {
      type: Array,
      required: true
    },
    /** Extracted from MyCustomersReport
     * Expects (sortBy: string, descending: boolean, search: string, accountTypes: string[], autoDelete: string, searchPropertyNames: string[]) => exportUrl
     */
    exportReportFunc: {
      type: Function,
      required: false,
      default: null
    },
    loading: {
      type: Boolean,
      required: false,
      default: true
    },
    exportLoading: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: () => ({
    pagination: {
      sortBy: ['rolling12Sales'],
      sortDesc: [true],
      itemsPerPage: 25
    },
    search: null,
    selectableTypeFilters: [
      {
        text: 'Bill To - Ship To',
        value: 'BillToShipto'
      },
      {
        text: 'Ship To',
        value: 'ShipTo'
      }
    ],
    selectableStatusFilters: [
      {
        text: 'Auto-Deleted-Accounts',
        value: 'autoDelete'
      }
    ],
    selectedFilters: []
  }),

  computed: {
    ...mapGetters('user', ['user', 'isPlattBanner']),
    syncedLoading: {
      get: function () {
        return this.loading
      },
      set: function (value) {
        this.$emit('update:loading', value)
      }
    },
    isManagerOrSales () {
      return this.user.userType === 'Mgr' || this.user.userType === 'AsstMgr' || this.user.userType === 'Sales'
    },
    searchPropertyNamesForExport () {
      // header values should match property names in the response / backend view model property names
      return this.headers.filter(el => el.value).map((headers) => headers.value)
    },
    formattedFilters () {
      return this.formattedTypeFilters.concat(this.formattedStatusFilters)
    },
    formattedTypeFilters () {
      return [{ sectionHeader: 'Type:' }, ...this.selectableTypeFilters]
    },
    formattedStatusFilters () {
      return [{ sectionHeader: 'Status:' }, ...this.selectableStatusFilters]
    },
    selectedTypes () {
      return this.formattedTypeFilters.filter(({ value }) => {
        return this.selectedFilters.includes(value)
      })
    },
    selectedStatus () {
      return this.formattedStatusFilters.filter(({ value }) => {
        return this.selectedFilters.includes(value)
      })
    },
    exportReportLink () {
      if (!this.exportReportFunc) {
        return null
      }

      const accountTypeValues = this.selectedTypes.map(type => type.value)

      let autoDelete = null

      if (this.selectedStatus.length < 1 && this.selectedTypes.length > 0) {
        autoDelete = 'N'
      } else if (this.selectedStatus.length > 0 && this.selectedTypes.length < 1) {
        autoDelete = 'Y'
      } else {
        autoDelete = null
      }

      return this.exportReportFunc(this.pagination.sortBy, this.pagination.sortDesc, this.search, accountTypeValues, autoDelete, this.searchPropertyNamesForExport)
    },
    filteredCustomers () {
      if (this.selectedFilters && this.selectedFilters.length > 0) {
        const filteredResults = this.customers.filter(customer => {
          return (this.selectedFilters.includes('autoDelete') && this.selectedFilters.length === 1)
            ? customer.autoDelete === 'Y'
            : (this.selectedFilters.includes(customer.accountType))
        })
        return filteredResults
      } else {
        return this.customers
      }
    },
    headers () {
      return [
        {
          text: 'Customer Name',
          align: 'left',
          sortable: true,
          value: 'customerName'
        },
        {
          text: 'Inside Sales',
          align: 'left',
          sortable: true,
          value: 'insideSalesFormatted',
          exclude: !this.isManagerOrSales
        },
        {
          text: 'Outside Sales',
          align: 'left',
          sortable: true,
          value: 'outsideSalesNames',
          exclude: !this.isManagerOrSales
        },
        {
          text: 'Last Login',
          align: 'left',
          sortable: true,
          value: 'lastLoginDate'
        },
        {
          text: 'Device',
          align: 'left',
          sortable: true,
          value: 'lastLoginDevice',
          exclude: this.isManagerOrSales
        },
        {
          text: 'Last Order',
          align: 'left',
          sortable: true,
          value: 'maxOrderDate'
        },
        {
          text: 'Last Activity',
          align: 'left',
          sortable: true,
          value: 'lastActivityDate'
        },
        {
          text: 'Contacts',
          align: 'left',
          sortable: true,
          value: 'contactCount',
          exclude: this.isManagerOrSales
        },
        {
          text: 'Web Accounts',
          align: 'left',
          sortable: true,
          value: 'webAccountCount',
          exclude: this.isManagerOrSales
        },
        {
          text: 'Sales',
          align: 'left',
          sortable: true,
          value: 'rolling12Sales'
        },
        {
          text: 'Followed',
          align: 'left',
          sortable: true,
          value: 'followedCustomer'
        },
        {
          sortable: false
        }
      ]
    },
    formattedHeaders () {
      return this.headers.filter((header) => !header.exclude)
    }
  },

  methods: {
    sortCustomers (items, sortBy, isDesc) {
      return sortItems(items, sortBy, isDesc, 'en', undefined, true)
    },
    exportReport () {
      const accountTypeValues = this.selectedTypes.map(type => type.value)

      let autoDelete = null

      if (this.selectedStatus.length < 1 && this.selectedTypes.length > 0) {
        autoDelete = 'N'
      } else if (this.selectedStatus.length > 0 && this.selectedTypes.length < 1) {
        autoDelete = 'Y'
      } else {
        autoDelete = null
      }

      this.$emit('export-report', {
        sortBy: this.pagination.sortBy,
        descending: this.pagination.sortDesc,
        search: this.search,
        accountTypes: accountTypeValues,
        autoDelete,
        searchPropertyNames: this.searchPropertyNamesForExport
      })
    }
  }
}
</script>
