<template>
  <page-loading-progress v-if="loading">
  </page-loading-progress>
  <v-container
    v-else
    fluid>
    <!-- Page Header and Search Layout - Not Mobile -->
    <v-row v-if="!$vuetify.breakpoint['smAndDown']">
      <v-col cols="12">
        <v-card class="mb-4">
          <v-container fluid>
            <v-row no-gutters>
              <v-col
                cols="12"
                md="4">
                <slot name="desktop-definition">
                  <v-card flat>
                    For definitions of at risk types view
                    <anchor :to="{ name: 'definitions' }">
                      definitions
                    </anchor>
                  </v-card>
                </slot>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="search"
                  hide-details
                  append-icon="search"
                  label="Search"
                  filled
                  @keypress.enter="searchWeb()">
                </v-text-field>
              </v-col>
              <v-col
                v-if="!fromActionPlan"
                cols="12"
                sm="5">
                <v-select
                  v-model="selectedOption"
                  :items="atRiskOptions"
                  label="Filter by Risk Type"
                  append-icon="filter_list"
                  class="append-icon-no-transform"
                  hide-details
                  filled>
                </v-select>
              </v-col>
              <v-col
                v-if="fromLifecycle"
                cols="12"
                sm="3">
                <v-switch
                  v-model="hideCleared"
                  label="Hide Cleared"
                  hide-details>
                  <span>Hide Cleared</span>
                </v-switch>
              </v-col>
              <v-col class="text-right">
                <v-btn
                  color="default"
                  fab
                  small
                  :loading="exportLoading"
                  @click="exportReport()">
                  <v-icon style="height: auto;">
                    file_download
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- Begin Mobile -->
    <v-row v-else>
      <v-col cols="12">
        <v-card class="mb-4">
          <v-card-text>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="search"
                  hide-details
                  append-icon="search"
                  label="Search"
                  filled
                  @keypress.enter="searchWeb()">
                </v-text-field>
              </v-col>
              <v-col
                v-if="fromActionPlan"
                class="text-right">
                <v-btn
                  color="default"
                  fab
                  small
                  :loading="exportLoading"
                  @click="exportReport()">
                  <v-icon style="height: auto;">
                    file_download
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card
          v-if="!fromActionPlan"
          class="mb-4">
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                sm="5">
                <v-select
                  v-model="selectedOption"
                  :items="atRiskOptions"
                  label="Filter by Risk Type"
                  append-icon="filter_list"
                  class="append-icon-no-transform"
                  hide-details
                  filled>
                </v-select>
              </v-col>
              <v-col
                v-if="fromLifecycle"
                cols="12"
                sm="3">
                <v-switch
                  v-model="hideCleared"
                  label="Hide Cleared"
                  hide-details>
                  <span>Hide Cleared</span>
                </v-switch>
              </v-col>
              <v-col class="text-right">
                <v-btn
                  color="default"
                  fab
                  small
                  :loading="exportLoading"
                  @click="exportReport()">
                  <v-icon style="height: auto;">
                    file_download
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-container fluid>
          <v-row no-gutters>
            <slot name="mobile-definition">
              <h3>
                At Risk Type Definitions
                <back-button
                  :to="{ name: 'definitions' }"
                  :point-right="true">
                </back-button>
              </h3>
            </slot>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <!-- end Mobile Header Section -->
    <!-- begin table -->
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <div class="table-legend">
              Sales from last 365
            </div>
            <customers-at-risk-table
              :loading="loading"
              :search="search"
              :items-per-page="pagination.itemsPerPage"
              :filtered-customers-at-risk="filteredCustomersAtRisk"
              @openActionDialog="openActionDialog"
              @createActivity="createActivity"
              @createNonHighRiskActivity="createNonHighRiskActivity"
              @openAtRiskEmailForm="openAtRiskEmailForm">
            </customers-at-risk-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <at-risk-take-action-dialog
      v-if="takeActionDialogIsOpen"
      :open.sync="takeActionDialogIsOpen"
      :customer="selectedCustomer"
      :churn-reasons="churnReasons"
      :churn-responses="churnResponses"
      @close="closeDialog()"
      @submit-succeeded="actionSubmitted()"
      @submit-failed="actionSubmitFailed()">
    </at-risk-take-action-dialog>

    <send-at-risk-email
      v-if="showEmailForm"
      :show-email-form="showEmailForm"
      :customer-id="selectedCustomer.customerId"
      :user-name="`${user.firstName} ${user.lastName}`"
      :user-email="user.email"
      :at-risk-email-dialog="showEmailForm"
      @closeAtRiskEmailForm="closeAtRiskEmailForm">
    </send-at-risk-email>
  </v-container>
</template>

<script>

import { mapGetters, mapMutations } from 'vuex'
import PageLoadingProgress from '@/components/PageLoadingProgress.vue'
import Anchor from '@/components/Anchor.vue'
import BackButton from '@/components/BackButton.vue'
import SendAtRiskEmail from '@/components/CustomerSummary/actions/SendAtRiskEmail.vue'

import CustomersAtRiskTable from './CustomersAtRiskTable.vue'
import AtRiskTakeActionDialog from './AtRiskTakeActionDialog.vue'

export default {
  name: 'CustomersAtRiskReportBase',
  components: {
    Anchor,
    PageLoadingProgress,
    BackButton,
    SendAtRiskEmail,
    CustomersAtRiskTable,
    AtRiskTakeActionDialog
  },
  props: {
    customersAtRisk: {
      type: Array,
      required: true
    },
    /** @type { {churnReasonId: number, churnReasonDescription: string}[] } */
    churnReasons: {
      type: Array,
      required: true
    },
    /** @type { {churnResponseId: number, churnResponseDescription: string}[] } */
    churnResponses: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: false,
      default: true
    },
    exportLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    fromActionPlan: {
      type: Boolean,
      required: true
    },
    /** This is used to change the behavior after creating an activity, so it goes back to the correct page
     * This is a kludge, and not an elegant solution. The real problem is the design of the report state for the dashboard flag that has baked this into a corner
     */
    fromLifecycle: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      atRiskOptions: [
        { text: 'All Risk Types', value: 'atRisk' },
        { text: 'By Dollar', value: 'atRiskByDollars' },
        { text: 'By Order Count', value: 'atRiskByOrders' },
        { text: 'By Web Account', value: 'atRiskByWebLogin' },
        { text: 'By SKU', value: 'atRiskBySkus' },
        { text: 'At High Risk (Churn)', value: 'atHighRisk' }
      ],
      selectedOption: typeof this.$route.params.fromDashboard !== 'undefined' ? 'atHighRisk' : 'atRisk',
      pagination: {
        sortBy: 'totalSales',
        sortDesc: true,
        itemsPerPage: 25
      },
      search: null,
      selectedCustomer: null,
      takeActionDialogIsOpen: false,
      showEmailForm: false,
      hideCleared: false
    }
  },
  computed: {
    ...mapGetters('user', ['user']),
    syncedLoading: {
      get: function () {
        return this.loading
      },
      set: function (value) {
        this.$emit('update: loading', value)
      }
    },
    reasonsSelectList () {
      return this.churnReasons.map((x) => {
        return {
          value: x.churnReasonId,
          text: x.churnReasonDescription
        }
      })
    },
    responsesSelectList () {
      return this.churnResponses.map((x) => {
        return {
          value: x.churnResponseId,
          text: x.churnResponseDescription
        }
      })
    },
    filteredCustomersAtRisk () {
      const possibleReasons = this.atRiskOptions.filter((riskOption) => riskOption.value !== 'atRisk').map((riskOption) => {
        return riskOption.value
      })

      if (!this.customersAtRisk) {
        return []
      }

      return this.customersAtRisk.filter((riskObj) => {
        let found = false

        // Don't filter when we don't need to
        if (this.selectedOption !== 'atRisk') {
          possibleReasons.forEach((reason) => {
            if (this.selectedOption === reason && riskObj[reason] && !found) {
              found = true
            }
          })
        } else {
          found = true
        }

        if (this.hideCleared && riskObj.hasRecentContact) {
          found = false
        }

        return found
      })
    }
  },
  methods: {
    ...mapMutations('actionPlan', ['toggleActionPlanUpdate']),
    exportReport () {
      this.$emit('export-report')
    },
    createActivity (customer) {
      this.$router.push({
        name: 'create-activity',
        query: {
          companyId: customer.companyId,
          customerId: customer.customerId,
          highRisk: true,
          returnAfterCreate: this.fromLifecycle
        }
      })
    },
    createNonHighRiskActivity (customer) {
      this.$router.push({
        name: 'create-activity',
        query: {
          companyId: customer.companyId,
          customerId: customer.customerId,
          atRisk: true,
          returnAfterCreate: this.fromLifecycle
        }
      })
    },
    openActionDialog (customer) {
      this.selectedCustomer = customer

      this.takeActionDialogIsOpen = true
    },
    closeDialog () {
      this.takeActionDialogIsOpen = false
      this.clearActionFields()
    },
    clearActionFields () {
      this.selectedCustomer = null
    },
    actionSubmitted () {
      this.$emit('reload-data')
      this.clearActionFields()
      this.takeActionDialogIsOpen = false
      this.toggleActionPlanUpdate()
    },
    actionSubmitFailed () {
      this.clearActionFields()
      this.takeActionDialogIsOpen = false
    },
    openAtRiskEmailForm (customer) {
      this.showEmailForm = true
      this.selectedCustomer = customer
    },
    closeAtRiskEmailForm () {
      this.showEmailForm = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~styl/colors';

.modal-explanation {
  color: $mediumGrey;
  font-style: italic;
  text-align: left;
  padding: 16px 16px 0px;
}
</style>
