import axios from 'axios'
import { callApi, generateUrl, stripLeadingZeros, stringifyArray } from '@/globals/utils'
const encode = (str) => encodeURIComponent(str)

// -- customers endpoints --

export const searchCustomers = (searchParams) => {
  const queryParams = new URLSearchParams()
  // Give the option to search only on term (which implies All customers), or by the maximum given parameters
  if (typeof searchParams === 'string') {
    queryParams.append('term', stripLeadingZeros(searchParams))
    queryParams.append('source', 'all')
  } else {
    const { filters, ...params } = searchParams

    for (const key in params) {
      if (params[key] || params[key] === false) queryParams.append(key, params[key])
    }
    if (searchParams.term) {
      queryParams.set('term', stripLeadingZeros(searchParams.term))
    }
    if (filters) filters.forEach(el => queryParams.append('filters', el))
  }

  return axios.get('/api/v1/customers/search', { params: queryParams })
}

export const getCustomers = () =>
  axios.get('/api/customer')

// -- customer endpoints --

export const fetchCustomer = (customerId, showBillTo = false, includeJobAccounts = false, isIdentifierCustomerNumber = false, allowBadRequests = false) => {
  return callApi({
    url: `/api/v1/customers/${customerId}`,
    params: { showBillTo, includeJobAccounts, isIdentifierCustomerNumber },
    allowBadRequests
  })
}

export const fetchCustomerAccounts = (customerId, companyId, parentCustomerId) => {
  return callApi({
    url: `/api/customer/${customerId}/profile-details`,
    params: { companyId, parentCustomerId }
  })
}

export const fetchCustomerMisc = ({ customerId, showBillTo }) =>
  callApi({
    url: `/api/customer/${customerId}/profile-misc`,
    params: { showBillTo }
  })

export const fetchCustomerSalesStats = ({ customerId, showBillTo, includeJobAccountSales }) =>
  callApi({
    url: `/api/v1/customers/${customerId}/sales-stats`,
    params: { showBillTo, includeJobAccountSales }
  })

export const fetchCustomerSubAccounts = (customerId) =>
  axios.get(`/api/customer/${customerId}/sub-accounts`)

export const postAtRiskEmail = (data) =>
  axios.post('/api/customer/send-email', data)

export const createActivityAfterAtRiskEmailSent = (companyId, customerId) => {
  if (companyId === 1) {
    axios.post(`/api/activities/add/${customerId}/at-risk-email-sent`)
  } else {
    axios.post(`/api/v2/activities/add/${customerId}/at-risk-email-sent`)
  }
}

export const bookmarkCustomer = (customerId) =>
  axios.post(`/api/customer/${customerId}/follow`)

export const unbookmarkCustomer = (customerId) =>
  axios.post(`/api/customer/${customerId}/unfollow`)

/**
 * Update a customer's key and target status given their ID.
 * @param {Number|String} customerId
 * @param {boolean} isExclusivelyUpdatingKey If true, show messages for key status updates,
 *   otherwise show messages for target status updates
 * @param {Object} data
 * @param {boolean} data.isKeyCustomer
 * @param {boolean} data.isTargetCustomer
 */
export const updateKeyAndTarget = (customerId, isExclusivelyUpdatingKey, data) => {
  const updatedStatusLabel = isExclusivelyUpdatingKey ? 'key' : 'target'
  return callApi({
    method: 'POST',
    url: `/api/customer/${customerId}/key-and-target/update`,
    payload: data,
    messages: {
      success: `Updated customer ${updatedStatusLabel} status`,
      error: `Error updating customer ${updatedStatusLabel} status`
    }
  })
}

export const updateGrayMarketReseller = (customerId, data) => {
  return callApi({
    method: 'POST',
    url: `/api/customer/${customerId}/gray-market-reseller/update`,
    payload: data,
    messages: {
      success: 'Updated customer gray market status',
      error: 'Error updating customer gray market status'
    }
  })
}

// Web accounts
export const updateCustomerWebAccount = (accountId, payload) =>
  callApi({
    method: 'POST',
    url: `/api/customer/web-account/update/${accountId}`,
    payload,
    messages: {
      success: 'Web account updated',
      error: 'Failed to update web account'
    }
  })

export const createCustomerWebAccount = (form) =>
  axios.post('/api/customer/web-account', form)

export const resetWebAccountPassword = (accountId, email, username, requesterFullName) =>
  callApi({
    url: '/api/web-accounts/reset-password',
    params: { accountId, email, username, requesterFullName },
    forceToast: true,
    messages: {
      success: 'Password reset email sent',
      error: 'Error sending password reset email'
    }
  })

export const deleteCustomerWebAccount = (accountId) =>
  callApi({
    method: 'POST',
    url: `/api/customer/web-account/delete/${accountId}`,
    messages: {
      success: 'Web account deleted',
      error: 'Failed to delete web account'
    }
  })

// Orders
export const fetchOrders = (customerId, startDate, endDate, arOption, orderType, salesType, otherFilter) => {
  let requestUrl = `/api/orders?customerId=${customerId}&arOption=${arOption}&orderType=${orderType}&salesType=${salesType}&otherFilter=${otherFilter}`
  if (startDate) requestUrl += `&startDate=${startDate}`
  if (endDate) requestUrl += `&endDate=${endDate}`
  return axios.get(requestUrl)
}

export const getOrdersExportUrl = (banner, customerId, startDate, endDate, sortBy, descending, salesType, orderType, otherFilter, search, searchPropertyNames, arOption) =>
  generateUrl('/api/orders/export', {
    banner,
    customerId,
    startDate,
    endDate,
    sortBy,
    descending,
    salesType,
    orderType,
    otherFilter,
    search,
    arOption,
    searchPropertyNames: stringifyArray(searchPropertyNames)
  })

export const getShoppingCartItems = (accountId, cartId) =>
  axios.get(`/api/customer/${accountId}/shopping-cart/${cartId}`)

export const updateCustomerSummary = (payload) => {
  return callApi({
    method: 'POST',
    url: '/api/customer/update-summary',
    payload,
    messages: {
      success: 'Successfully submitted request to change customer information',
      error: 'Failed to submit request to change customer information'
    }
  })
}
export const getSuggestedAddress = (address, address2, city, state, postalCode) =>
  axios.get(`/api/geography/validate?address=${encode(address)}&apt=${encode(address2)}&city=${encode(city)}&state=${state}&zip=${postalCode}`)

export const createCustomerAccount = (payload) => {
  return callApi({
    method: 'POST',
    url: '/api/account/create',
    payload,
    messages: {
      success: 'Successfully created customer account',
      error: 'Failed to create customer account'
    }
  })
}

export const getSimilarAccounts = (companyName, phone, address) =>
  axios.get(`/api/account/similar-accounts?company=${encode(companyName)}&phone=${phone}&address=${encode(address)}`)

export const getPicCodes = () =>
  axios.get('/api/account/pic-codes')

export const getNextCustomerId = () =>
  axios.get('/api/account/next-number')

export const getTemporaryPassword = (companyCustomerNumber) =>
  axios.get(`/api/account/temp-password?customerNumber=${companyCustomerNumber}`)

export const updateEnrichedData = (customerId, payload) =>
  callApi({
    method: 'POST',
    url: `/api/customer/enriched-data/${customerId}`,
    payload
  })

export const updateWebsite = (customerId, webAddress) => {
  const payload = { webAddress, customerId }
  return callApi({
    method: 'POST',
    url: `/api/customer/${customerId}/update-web-address`,
    payload
  })
}

export const getMissionAndStrategies = (customerId, budgetYear) =>
  callApi({
    url: '/api/customer/goals-and-strategies',
    params: { customerId, budgetYear }
  })

export const missionAndStrategiesExportUrl = (customerId, budgetYear, sortBy, descending) => {
  return generateUrl('/api/customer/goals-and-strategies/export', {
    customerId,
    budgetYear,
    sortBy,
    descending
  })
}

export const updateMissionAndStrategies = (payload) =>
  callApi({
    method: 'POST',
    url: '/api/customer/goals-and-strategies',
    payload
  })

export const deleteMissionAndStrategies = (params) =>
  callApi({
    method: 'POST',
    url: '/api/customer/goals-and-strategies/delete',
    params
  })

export const addOrderAcknowledgementEmails = (customerId, email) =>
  axios.post('/api/customer/order-acknowledgement-emails', {
    customerId: parseInt(customerId),
    email
  })

export const deleteOrderAcknowledgementEmails = (customerId, email) =>
  axios.post('/api/customer/order-acknowledgement-emails/delete', {
    customerId,
    email
  })

export const getCustomerQuotes = (customerId) =>
  axios.get(`/api/quotes?customerId=${customerId}`)

export const getCustomerQuotesExportUrl = (customerId) =>
  generateUrl('/api/quotes/export', { customerId })

export const getCustomerSearchTrends = (customerId) =>
  axios.get(`/api/customer/search-trends?customerId=${customerId}`)

export const getCustomerSearchTrendsExportUrl = (customerId) =>
  generateUrl('/api/customer/search-trends/export', { customerId })

export const createNote = (customerId, note) =>
  callApi({
    method: 'POST',
    url: '/api/customer/account-notes/insert',
    params: { customerId, note },
    messages: {
      success: 'Successfully added account note',
      error: 'Failed to add account note'
    }
  })

export const deleteNote = (noteId) =>
  callApi({
    method: 'POST',
    url: `/api/customer/account-notes/delete/${noteId}`,
    messages: {
      success: 'Successfully deleted account note',
      error: 'Failed to delete account note'
    }
  })

export const postOrderEntryNoteChangeRequest = (
  { companyCustomerNumber, notes }
) => {
  return callApi({
    method: 'POST',
    url: '/api/customer/order-entry-notes',
    params: { companyCustomerNumber, notes },
    messages: {
      success: 'Successfully submitted OE note change request',
      error: 'Failed to submit OE note change request'
    }
  })
}

export const postCloseAccountRequest = (
  { companyCustomerNumber, customerCompanyName, requestorFullName, requestorEmail, reason, creditRepEmail }
) => {
  return callApi({
    method: 'POST',
    url: '/api/customer/close-account',
    params: { companyCustomerNumber, customerCompanyName, requestorFullName, requestorEmail, reason, creditRepEmail },
    messages: {
      success: 'Successfully submitted request to close account',
      error: 'Failed to submit request to close account'
    }
  })
}

// Contacts (handle Platt and non-Platt)

export const fetchCustomerContacts = (customerId, banner) =>
  callApi({
    url: '/api/customer/contacts',
    params: { customerId }
  })

export const exportContacts = (customerId, banner) =>
  generateUrl(
    '/api/customer/contacts/export',
    { customerId }
  )

export const updateCustomerContact = (props) => {
  return callApi({
    method: 'POST',
    url: '/api/customer/contacts',
    payload: props.formData
  })
}

export const deleteCustomerContact = (payload) =>
  callApi({
    method: 'POST',
    url: '/api/customer/contacts/delete',
    payload,
    messages: {
      success: 'Successfully deleted contact',
      error: 'Failed to delete contact'
    }
  })

// TODO: call from vuex action -- store in cache
export const getCustomerContactJobTitles = () =>
  axios.get('/api/customer/contacts/job-titles')

export const getWelcomeEmailRecipientList = (customerId) =>
  axios.get(`/api/customer/contacts/all-email-addresses?customerId=${customerId}`)

export const customersProfileWebAccountsAndContactsExportUrl = (customerId, companyId, parentCustomerId, accountId, entityId, isEnterprise) => {
  return generateUrl('/api/customer/web-accounts-and-contacts/export', { customerId, companyId, parentCustomerId, accountId, entityId, isEnterprise })
}

export const getOrderWriters = (customerId) =>
  axios.get(`/api/customer/order-writers?customerId=${customerId}`)

export const createWebAccountConvertedActivity = (payload, noMessages = false) =>
  callApi({
    method: 'POST',
    url: '/api/v1/activities/web-account-migrated',
    payload,
    noMessages,
    messages: {
      success: 'Activity Created',
      error: 'Failed to create activity for web account conversion'
    }
  })

export const updateNotificationOptOuts = (payload) =>
  callApi({
    method: 'POST',
    url: '/api/customer/contacts/notification-opt-outs',
    payload,
    showToast: false
  })

export const getNotificationOptOuts = (email, phone, cell) =>
  axios.get(`/api/customer/contacts/notification-opt-outs?email=${email}&phone=${phone}&cell=${cell}`)

export const updateEskerAndPricingDiscrepancyHold = (companyId, customerId, isEsker, isPricingDiscrepancy, defaultAsPending) =>
  callApi(
    {
      method: 'POST',
      url: `/api/customer/${customerId}/is-esker-and-price-discrepancy-hold/update`,
      payload:
      {
        CompanyId: companyId,
        IsEsker: isEsker,
        PriceDiscrepancyHold: isPricingDiscrepancy,
        DefaultAsPending: defaultAsPending
      }
    })
