<template>
  <v-menu
    class="page-header-menu"
    :class="{'pl-2 pr-2': $vuetify.breakpoint.smAndUp}"
    offset-y>
    <template #activator="{ on }">
      <v-icon
        data-qa="kabobMenu"
        v-on="on">
        more_vert
      </v-icon>
    </template>
    <v-list
      class="page-header-menu__list"
      :class="{'mobile': $vuetify.breakpoint.smAndDown}">
      <template v-if="$vuetify.breakpoint.smAndDown && $hasFeature('Header_ExternalLinks')">
        <v-list-item>
          <v-list-item-title class="grey--text text--darken-2">
            Links
          </v-list-item-title>
        </v-list-item>

        <v-list-item
          v-for="(link, i) in externalLinks"
          :key="`external-link-${i}`"
          :href="`${link.href}`"
          target="_blank">
          <v-list-item-title>
            {{ link.name }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          to="label-maker">
          <v-list-item-title>
            Label Maker Tool
          </v-list-item-title>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item>
          <v-list-item-title class="grey--text text--darken-2">
            Settings
          </v-list-item-title>
        </v-list-item>
      </template>

      <v-list-item
        v-if="isViewingAs"
        data-qa="viewAsOff"
        @click="stopViewingAs">
        <v-list-item-action>
          <v-icon>visibility_off</v-icon>
        </v-list-item-action>
        <v-list-item-title>View as (Off)</v-list-item-title>
      </v-list-item>

      <v-list-item
        v-if="isViewingAs || userCanViewAs"
        :to="{name: 'view-as'}"
        data-qa="viewAs_changeViewAs">
        <v-list-item-action>
          <v-icon>remove_red_eye</v-icon>
        </v-list-item-action>
        <v-list-item-title>{{ isViewingAs ? 'Change Viewing As' : 'View As' }}</v-list-item-title>
      </v-list-item>

      <v-list-item
        @click="toggleCustomerPresent">
        <v-list-item-action>
          <div
            v-if="isCustomerPresent"
            class="icon icon-person-off d-flex">
            <img
              src="@/assets/icons/ic_person_off.svg"
              alt="Customer Not Present" />
          </div>
          <v-icon v-if="!isCustomerPresent">
            person
          </v-icon>
        </v-list-item-action>
        <v-list-item-title>{{ isCustomerPresent ? 'Customer Not Present' : 'Customer Present' }}</v-list-item-title>
      </v-list-item>

      <!-- Create new account within app for Platt only -->
      <v-list-item
        v-if="$hasFeature('Header_Actions_CreateAccountAs400')"
        :to="{ name: 'create-customer' }">
        <v-list-item-action>
          <v-icon>add</v-icon>
        </v-list-item-action>
        <v-list-item-title>Create Account</v-list-item-title>
      </v-list-item>

      <!-- Create new account via Credit App for Rexel/Gexpro only -->
      <v-list-item
        v-if="$hasFeature('Header_Actions_CreateAccountEclipse')"
        href="https://credit.rexelapps.com/admin/"
        target="_blank">
        <v-list-item-action>
          <v-icon>
            add
          </v-icon>
        </v-list-item-action>
        <v-list-item-title>Create Account</v-list-item-title>
      </v-list-item>

      <v-list-item
        v-if="user"
        :to="{name: 'preferences'}">
        <v-list-item-action>
          <v-icon>settings</v-icon>
        </v-list-item-action>
        <v-list-item-title>Preferences</v-list-item-title>
      </v-list-item>

      <v-list-item
        v-if="user"
        :to="{name: 'definitions'}">
        <v-list-item-action>
          <v-icon>description</v-icon>
        </v-list-item-action>
        <v-list-item-title>Definitions</v-list-item-title>
      </v-list-item>

      <v-list-item
        :to="{name: 'whats-new'}">
        <v-list-item-action>
          <v-icon>stars</v-icon>
        </v-list-item-action>
        <v-list-item-title>What's New</v-list-item-title>
      </v-list-item>

      <v-list-item
        ripple
        :disabled="isDemo"
        @click="sendToJiraForm">
        <v-list-item-action>
          <v-icon>feedback</v-icon>
        </v-list-item-action>
        <v-list-item-title>Support</v-list-item-title>
      </v-list-item>

      <v-list-item
        v-if="$hasFeature('Header_Actions_BranchLogin') && !isDemo"
        ripple
        target="_blank"
        :href="branchLoginUrl">
        <v-list-item-action>
          <v-icon>business</v-icon>
        </v-list-item-action>
        <v-list-item-title>Branch Login</v-list-item-title>
      </v-list-item>

      <v-list-item
        v-if="$hasFeature('Header_Actions_ViewReportCard') && !user.branch === 1"
        ripple
        @click="openExportDownloadLink()">
        <v-list-item-action>
          <v-icon>insert_chart</v-icon>
        </v-list-item-action>
        <v-list-item-title>View Report Card</v-list-item-title>
      </v-list-item>

      <!-- Training mode -->
      <v-list-item
        v-if="$hasFeature('Header_Actions_TrainingMode') && !isDemo"
        href="https://demosales.rexelusa.net">
        <v-list-item-action>
          <v-icon>
            school
          </v-icon>
        </v-list-item-action>
        <v-list-item-title>Training Mode</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-else-if="$hasFeature('Header_Actions_TrainingMode') && !demoOnlyUser"
        href="https://sales.rexelapps.com">
        <v-list-item-action>
          <v-icon>
            meeting_room
          </v-icon>
        </v-list-item-action>
        <v-list-item-title>Exit Training Mode</v-list-item-title>
      </v-list-item>

      <v-list-item
        v-if="user"
        @click="logout()">
        <v-list-item-action>
          <v-icon class="error--text">
            power_settings_new
          </v-icon>
        </v-list-item-action>
        <v-list-item-title class="error--text">
          Sign Out
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { getExternalLinks } from '@/features/externalLinks'
import { identityService } from '@/features/identity'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'PageHeaderMenu',
  computed: {
    ...mapState(['isCustomerPresent']),
    ...mapGetters('user', ['user', 'userCanViewAs', 'isViewingAs', 'isPlattBanner', 'banner']),
    branchLoginUrl () {
      if (this.banner === 'rexel') {
        return this.$environment === 'Test' ? 'https://rexelusa.test.rexelapps.info/employee/branch' : 'https://www.rexelusa.com/employee/branch'
      } else if (this.banner === 'gexpro') {
        return this.$environment === 'Test' ? 'https://gexpro.test.rexelapps.info/employee/branch' : 'https://www.gexpro.com/employee/branch'
      } else { return this.$environment === 'Test' ? 'https://platt.test.rexelapps.info/counter' : 'https://www.platt.com/employee/counter' }
    },
    demoOnlyUser () {
      return this.user.sso === '550003712'
    },
    isDemo () {
      return this.$environment === 'Demo'
    },
    externalLinks () {
      const externalLinks = getExternalLinks()
      if (this.isPlattBanner) {
        return externalLinks.filter(link => link.name !== 'PlattNet')
      }
      return externalLinks
    }
  },
  methods: {
    ...mapActions(['toggleCustomerPresent', 'setCustomerPresent']),
    /* toggleFeedback () {
      this.$emit('toggleFeedback')
    }, */
    sendToJiraForm () {
      const path = window.location.pathname
      let url = 'https://jira.rexelusa.com/servicedesk/customer/portal/60/create/513'
      const absoluteURL = new URL(path, window.location.origin).href
      url = url + '?customfield_28400=' + absoluteURL
      url = url + '&customfield_27100=30704'

      window.open(url, '_blank')
    },
    logout () {
      identityService.authManager.signoutRedirect()
    },
    openFeedbackModal () {
      this.openFeedback = true
    },
    async stopViewingAs () {
      await identityService.stopViewingAs()
    },
    openExportDownloadLink () {
      window.open(this.branchReportExportUrl)
    }
  }
}
</script>

<style lang="scss" scoped>
.page-header-menu {
  &__list {
    &.mobile {
      max-height: 90vh;
      overflow-y: scroll;
    }
  }
}
</style>
