import axios from 'axios'
import router from '@/router'
import { checkRouteMeta, sliceString } from '@/globals/utils'
import store from '@/store'
import { bannerProvider } from '@/features/banners'

const setHtmlTitle = (context, title) => {
  document.title = title
}

const setAccessToken = ({ commit }, accessToken) => {
  commit('user/setAccessToken', accessToken)
  // Set bearer token
  axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
}

const setLastRoute = ({ commit }, route) => {
  commit('setLastRoute', route)
}
const goBack = ({ getters }, fallbackRoute = { name: 'dashboard' }) => {
  // if lastRoute exists just go back in history
  // else push router to dashboard as a fallback

  getters.hasLastRoute
    ? router.go(-1)
    : router.push(fallbackRoute)
}

// Catchall routes have an internal param with a key of 0, which is the path
// that it caught on.  This method exploits that param to allow us to "catch"
// what is technically a valid route. This is fragile, and it'd be great if
// we could phase it out or refactor it someday.
const authenticateRoute = async ({ state, getters, rootState }, { to, from, next }) => {
  const originalTo = to.params[1]
  const nextOr404 = (shouldGoToNext) => {
    if (shouldGoToNext) {
      if (originalTo) {
        next({
          name: originalTo.name,
          params: originalTo.params,
          replace: true
        })
      } else {
        next()
      }
    } else if (!originalTo) {
      const pathWithoutBanner = sliceString(from.path, '/', 1)
      next({
        name: 'not-authorized',
        params: [pathWithoutBanner, from],
        replace: true
      })
    } else {
      // unauthorized route to unauthorized route
      next(false)
    }
  }
  const determineRouteToCheck = (to, from) => {
    // remove banner from paths so they compare correctly
    const [fromPath, toPath] = [from.path, to.path].map(p => sliceString(p, '/', 1))
    return (toPath === fromPath && checkRouteMeta(to, 'notAuthorized'))
      ? originalTo
      : to
  }

  const delay = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  const routeToCheck = determineRouteToCheck(to, from)

  if (checkRouteMeta(routeToCheck, 'elevatedUserOnly')) {
    nextOr404(state.user.user.canViewAs)
  } else if (checkRouteMeta(routeToCheck, 'plattUserOnly')) {
    const allowSpecialPim = checkRouteMeta(routeToCheck, 'allowSpecialPim')
    let companyId = rootState.currentCustomer.customer.companyId

    // When viewing Pim Details, if the page is reloaded, companyId is undefined and
    // the user is redirected to the dashboard.
    // This can be removed when PIM is available for all Rex/Gex customers.
    // See https://dev.azure.com/platt-blackops/PlattForce/_workitems/edit/38443
    const maxRetries = 10
    let retries = 0
    while (companyId === undefined && retries < maxRetries) {
      companyId = rootState.currentCustomer.customer.companyId
      await delay(100)
      retries++
    }

    const isRexGex = bannerProvider.isRexGex(companyId)

    // This is a hack to allow specific Rex/Gex customers access to PIM related routes.
    // See https://dev.azure.com/platt-blackops/PlattForce/_workitems/edit/38443
    if (allowSpecialPim && isRexGex) {
      const isRexGexPimEnabled = store.getters['storefrontUsers/isRexGexPimEnabled']
      nextOr404(isRexGexPimEnabled)
      return
    }

    const selectedBanner = getters['user/banner']
    nextOr404(selectedBanner === 'platt')
  } else if (checkRouteMeta(routeToCheck, 'excludePlattUser')) {
    const selectedBanner = getters['user/banner']
    nextOr404(selectedBanner !== 'platt')
  }
  next()
}

const setErrorPage = ({ commit }, { statusCode, message }) => {
  commit('setErrorPage', { statusCode, message })
}
const resetErrorPage = ({ commit }) => {
  commit('setErrorPage', { statusCode: null, message: null })
}

const setCustomerPresent = ({ commit }, { isPresent }) => {
  commit('setCustomerPresent', { isPresent })
}

const toggleCustomerPresent = ({ commit }) => {
  commit('toggleCustomerPresent')
}

const setApolloProvider = ({ commit }, apolloProvider) => {
  commit('SET_APOLLO_PROVIDER', apolloProvider)
}

const setOpenMyProfile = ({ commit }, value) => {
  commit('setOpenEditMyProfile', value)
}

const toggleDebug = ({ commit }) => {
  commit('toggleDebug')
}

export default {
  setAccessToken,
  setLastRoute,
  goBack,
  authenticateRoute,
  setErrorPage,
  resetErrorPage,
  setCustomerPresent,
  setHtmlTitle,
  toggleCustomerPresent,
  setApolloProvider,
  setOpenMyProfile,
  toggleDebug
}
