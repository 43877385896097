const SOURCE_SYSTEM_FAMILY = {
  other: 'other',
  mincron: 'mincron',
  eclipse: 'eclipse'
}

type BannerSourceSystemFamily = keyof typeof SOURCE_SYSTEM_FAMILY

/** Banner codes for strong typing, the key & value should always match */
const BANNERS = {
  platt: 'platt',
  rexel: 'rexel',
  gexpro: 'gexpro',
  'capitol-light': 'capitol-light',
  mayer: 'mayer'
} as const

type Banner = keyof typeof BANNERS

type BannerList = {
  banners?: Banner[],
};

/** Matches the BannerCodeEnum found at /backend/sf/src/Features/Banners/BannerCodeEnum.cs
 * these values are typically used for graphql queries to storefront
*/
const STOREFRONT_BANNERS: Record<Banner, string | null> = {
  platt: 'PLATT',
  rexel: 'REXEL',
  gexpro: 'GEXPRO',
  'capitol-light': null, // Unsupported
  mayer: null // Unsupported
} as const

export {
  SOURCE_SYSTEM_FAMILY,
  BannerSourceSystemFamily,
  BANNERS,
  Banner,
  BannerList,
  STOREFRONT_BANNERS
}
