import {
  getDateMonthsBefore
} from '@/globals/time'
import sortBy from 'lodash.sortby'
import axios from 'axios'
import store from '@/store'
import router from '@/router'
import { searchCustomers } from '@/api/customers'

export const round = (number, decimalPlaces) => {
  const tenFactor = Math.pow(10, decimalPlaces)
  return Math.round(number * tenFactor) / tenFactor
}

// filter to last X number of months of data
export const filterByLastNumberMonths = (collection, dateKey, numMonths) => {
  if (!collection) return 0

  const sortedData = sortBy([...collection], [dateKey])
  const latestMonth = sortedData[collection.length - 1][dateKey]
  const yearBefore = getDateMonthsBefore(latestMonth, numMonths)
  const filteredData = sortedData.filter((item) => new Date(item[dateKey]) > yearBefore)
  return filteredData
}

// filter to last 12 months of data
export const filterByLastTwelveMonths = (collection, dateKey) => {
  return filterByLastNumberMonths(collection, dateKey, 12)
}

// prop can be array of props or prop
export const propAndNotEmptyString = (prop) => {
  if (Array.isArray(prop)) {
    return !prop.some(p => !p || !p.trim())
  }

  if (!prop) return false
  if (typeof prop === 'string') {
    return !!prop.trim()
  }

  return true
}

export const removeItemByKey = (collection, key, value) =>
  collection.filter(obj => obj[key] !== value)

export const stringifyArray = (value) => {
  return Array.isArray(value)
    ? value.join(',')
    : value
}

const banners = {
  platt: 1,
  rexel: 2,
  gexpro: 3
}

/**
 * Get a banner's unique key banner string given its ID
 * @param {number} id Banner ID
 */
export const bannerFromId = (id) => {
  for (const banner in banners) {
    if (banners[banner] === id) {
      return banner
    }
  }
  return ''
}

/**
 * Get a banner's ID given its unique key string
 * @param {string} banner Unique key banner string
 */
export const bannerIdFromString = (banner) => {
  for (const b in banners) {
    if (b === banner) {
      return banners[banner]
    }
  }
  return -1
}

/**
 * Return an array of ERP numbers, extracted from company display names.
 * Optionally filter out numbers that match accountId
 * @param {string} displayNameWithErpNumbers
 * @param {int} accountId
 * @returns {array}
 */
export const extractErpNumbers = (displayNameWithErpNumbers, accountId = -1) => {
  const erpString = displayNameWithErpNumbers.substring(
    displayNameWithErpNumbers.lastIndexOf('(') + 1,
    displayNameWithErpNumbers.lastIndexOf(')')
  )

  // If the string extracted from displayNameWithErpNumbers contains non-numerical characters, it's no good
  const erpNumbers = erpString.split('/')
    .map(erpString => parseInt(erpString))
    .filter(erpString => !isNaN(erpString))

  if (accountId > -1) {
    return erpNumbers.filter(number => number !== accountId)
  }
  return erpNumbers
}

export const getCustomerObjectByName = async (displayName, customerAccountId = null) => {
  return new Promise((resolve, reject) => {
    const erpNumberArray = extractErpNumbers(displayName, customerAccountId)
    const erpNumber = erpNumberArray.length > 0 ? erpNumberArray[0].toString() : null

    if (erpNumber === null) {
      resolve(null)
      return
    }
    searchCustomers(erpNumber).then(response => {
      if (response.status !== 200) {
        reject(response.error)
        return
      }
      const data = response.data.value
      const customerObj = data.find(d => d.customerNumber === parseInt(erpNumber))
      resolve(customerObj)
    })
  })
}

/**
 * If string argument is JSON then return parsed object, else return false
 * @param {string} jsonString possible json string
 */
export const tryParseJSON = (jsonString) => {
  try {
    const o = JSON.parse(jsonString)
    if (o && typeof o === 'object') return o
  } catch (e) {
    // don't worry 'bout it
  }
  return false
}

/**
 * create a separate instance of axios without interceptors that
 * auto-append queries (ie. banner, viewingAs)
 */
export const axiosWithoutInterceptors = axios.create()

/**
 * Array of status codes that should prompt re-routing to the
 * "Not Found" page rather than just showing a toast message
 */
const NOT_FOUND_PAGE_CODES = [404]
const NOT_FOUND_ROUTE_NAMES = ['not-found', 'not-authorized', 'not-found-generic']
/**
 * Call a HTTP API endpoint, automatically displaying toasts for successful and failed requests.
 *
 * @param {Object} options API request options
 * @param {string} [options.method='GET'] HTTP method to use to make the request, defaults to GET
 * @param {string} options.url API enpoint to request
 * @param {Object} [options.params] Query parameters for the request
 * @param [options.payload] Body payload of the request
 * @param {Object.<string, string>} [options.headers] Dictionary of HTTP headers added to the request
 * @param {Object} [options.messages={}] Dictionary of success and/or fail messages shown in the toasts
 * @param {string} [options.messages.success] Success message shown in a toast when the request succeeds
 * @param {string} [options.messages.error] Error message shown in a toast when the request fails
 * @param {boolean} [options.noMessages=false] If true, will not show success nor error toasts
 * @param {boolean} [options.noInterceptors=false] If true, will not auto-append queries (i.e. banner, viewingAs)
 * @param {boolean} [options.forceToast=false] If true, will force the toast success / fail messages to appear
 * @param {boolean} [options.allowBadRequests=false] If true, will force a redirect to dashboard when the request returns a 404
 */
export const callApi = async ({
  method = 'GET',
  url,
  params,
  payload,
  headers = {},
  messages = {},
  noMessages = false,
  noInterceptors = false,
  forceToast = false,
  allowBadRequests = false,
  showToast = true,
  showBackendErrors = true
}) => {
  const http = noInterceptors
    ? axiosWithoutInterceptors
    : axios
  try {
    const { data } = await http({
      method,
      url,
      params,
      data: payload,
      headers
    })

    const allowToast = forceToast
      ? true
      : !noMessages && showToast

    if (data && data.resultStatus) {
      /*
        Check whether there is a resultStatus data structure that reports an error from the backend.
        When support for this is added to an endpoint, it allows passing errors, warnings, and other
        messages both in situations either where there is and is not an HTTP error status code.
      */
      if (data.resultStatus.isSuccess) {
        if (allowToast && showBackendErrors) {
          store.commit('setToastMessage', {
            message: data.resultStatus.message,
            status: 'success'
          })
        }
      } else if (data.resultStatus.isWarning) {
        console.warn(`Warning (${url})`, data.resultStatus)
        if (allowToast && showBackendErrors) {
          store.commit('setToastMessage', {
            message: data.resultStatus.message,
            status: 'warning'
          })
        }
      } else if (data.resultStatus.isError) {
        console.error(`Error (${url})`, data.resultStatus)
        /*
          Throw an error so that catch logic wrapping callApi will fire
          Defer showing a toast to the catch logic below, formatting the data to match existing logic
        */
        throw new Error({
          ...data,
          response: {
            data: {
              resultStatus: data.resultStatus
            }
          }
        })
      }
    } else if ((forceToast || (method.toUpperCase() !== 'GET' && !noMessages)) && showToast) {
      store.commit('setToastMessage', {
        message: messages.success || 'Success',
        status: 'success'
      })
    }
    return { data }
  } catch (data) {
    const { response } = data
    if (response && NOT_FOUND_PAGE_CODES.includes(response.status) && !allowBadRequests) {
      if (!NOT_FOUND_ROUTE_NAMES.includes(router.currentRoute.name)) {
        store.dispatch('setErrorPage', { statusCode: response.status })
        router.push({ name: 'not-found' })
      }
      return
    }

    let errorMessage = ''

    /*
      Logic to support the various ways errors are passed from the backend.
      New and refactored endpoints should use the resultStatus data structure
      to pass errors, warnings, and other messages.
    */
    if (response && response.data) {
      const { data: { errors, message, title, resultStatus } } = response
      if (message) {
        errorMessage = message
      } else if (errors && errors.length) {
        errorMessage = errors[0]
      } else if (title) {
        console.error(`Error (${url})`, title)
        errorMessage = ''
      } else if (resultStatus && resultStatus.isError && resultStatus.message) {
        errorMessage = resultStatus.message
      }
    }

    if (!noMessages && !allowBadRequests) {
      /* Build and display an error message with the provided messages.error and/or error messaging from the backend */
      let fullErrorMessage = ''
      if (messages.error) {
        fullErrorMessage += messages.error
        if (errorMessage) {
          fullErrorMessage += '. '
          fullErrorMessage += errorMessage
        }
      } else {
        fullErrorMessage = errorMessage || ''
      }

      if (fullErrorMessage) {
        store.commit('setToastMessage', {
          message: fullErrorMessage,
          status: 'error'
        })
      }
    }

    return { errors: errorMessage, response }
  }
}

/*
  NOTE: v0.19.x of axios will include a getURI method on the axios instance (currently on v.18.0)
  This will generate the URL without calling any HTTP method.
  Consider using this new method to auto-append request params (ie. banner, viewAs) from the interceptors.
*/
export const generateUrl = (url, params = {}) => {
  const baseUrl = process.env.VUE_APP_API_BASE_URL
  const esc = encodeURIComponent

  // append default query params
  const defaultParams = {
    banner: store.getters['user/banner'],
    viewAs: store.getters['user/viewAsSSO'],
    displayName: store.getters['user/displayName'],
    access_token: store.getters['user/accessToken']
  }
  for (const key in defaultParams) {
    if (!params[key] && params[key] !== null) {
      params[key] = defaultParams[key]
    }
  }

  const paramsString = Object.keys(params)
    .reduce((acc, key) => {
      if (params[key] === null || params[key] === undefined) {
        return acc
      }

      return [...acc, esc(key) + '=' + esc(params[key])]
    }, [])
    .join('&')

  return !paramsString
    ? `${baseUrl}${url}`
    : `${baseUrl}${url}?${paramsString}`
}

// Haversine Formula implementation
// modified from https://stackoverflow.com/a/27943 to accept a unit of measurement param which defaults to miles
export const getDistanceViaCoordinates = (lat1, lon1, lat2, lon2, unit = 'M') => {
  const deg2rad = (deg) => deg * 0.017453292519943295 // Math.PI / 180

  const radlat1 = deg2rad(lat1)
  const radlat2 = deg2rad(lat2)
  const radtheta = deg2rad(lon1 - lon2)

  let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
  dist = Math.acos(dist)
  dist = (dist * 180 / Math.PI) * 60 * 1.1515

  if (unit === 'K') { return dist * 1.609344 } // kilometers
  if (unit === 'N') { return dist * 0.8684 } // nautical miles
  return dist // statute mile
}

// if the query is just a number, parseInt to remove any leading zeroes
export const stripLeadingZeros = (query) => {
  return Number(query) ? parseInt(query) : query
}

/**
 * Returns a copy of the given array without duplicate entries
 * @param {Array<any>} arr The array to make duplicate-free
 */
export const uniqueArray = arr => Array.from(new Set(arr))

// this is how lodash.isNil works
export const isNil = val => val == null

/**
 * Splits a string with separators and returns a sliced version of it
 * @param {String} str String to have slices removed
 * @param {String} separator String/char by which slices of the string are separated
 * @param {Number} numSlices Number of slices of the string to remove
 * @param {Boolean} fromEnd Should the slices be taken from the start or the end
 * @param {Boolean} maintainSeparator Should separators be maintained at the start or end of the string
 */
export const sliceString = (str, separator, numSlices, fromEnd = false, maintainSeparator = true) => {
  let splitString = str.split(separator)
  if (fromEnd) splitString = splitString.reverse()
  return [
    maintainSeparator && (splitString[0] === '' ? separator : ''),
    splitString.filter(Boolean).slice(numSlices).join(separator),
    maintainSeparator && (splitString[splitString.length - 1] === '' ? separator : '')
  ].filter(Boolean).join('')
}

/**
 * Check a route object's `meta` props for specific values.
 * @param {Object} route The route object to check
 * @param {String} metaName Name of the meta property to check
 * @param {any} metaValue The value to check the meta property against. Defaults to `true`.
 */
export const checkRouteMeta = (route, metaName, metaValue = true) =>
  route.matched.some(record => record.meta[metaName] === metaValue)

/**
 * Safely access a nested property of an object, given an array path to that property
 * @param {Object} obj The object to access
 * @param {Array<String|Number>} path Array of strings or indices that should lead to
 * the desired property
 */
export const safeAccess = (obj, path) =>
  path.reduce((xs, x) => (xs && xs[x]) ? xs[x] : null, obj)

/** Replace all commas in a string
 * @param {String} input The string to replace commas on
*/
export const replaceCommas = (input) => {
  if (typeof input !== 'string') {
    return input
  }

  return input.replace(/,/g, '')
}

/**
 * @typedef {Object} FileContentResult
 * @property {string} contentType
 * @property {string} fileContents
 * @property {string?} fileDownloadName
 */
/** Will create a blob and download the file in the current tab. Expects a FileContentResult from ASP.Net core
 * Controller methods that return File() must have a return type of ActionResult<FileContentResult>, not IActionResult and not FileContentResult
 * @param {FileContentResult} fileContentResponse
*/
export const downloadFileResult = (fileContentResponse) => {
  if (!fileContentResponse) {
    return
  }

  if (!fileContentResponse.contentType) {
    throw new Error('Expected contentType to be set on fileContentResponse')
  }

  if (!fileContentResponse.fileContents) {
    throw new Error('Expected fileContents to be set on fileContentResponse')
  }

  if (!fileContentResponse.fileDownloadName) {
    throw new Error('Expected fileDownloadName to be set on fileContentResponse')
  }

  const { contentType, fileContents, fileDownloadName } = fileContentResponse

  // fileContents will be a Base64 encoded Byte[]
  // We need to convert it back into an actual byte array
  // https://stackoverflow.com/a/16245768
  const byteCharacters = atob(fileContents)
  const byteNumbers = new Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }
  const byteArray = new Uint8Array(byteNumbers)

  // We create a blob, an anchor tag, create a DOMString that describes the blob, force a click on the anchor and then clean up
  // The Object URL acts like a reference to an object, as opposed to a clone of it's data
  // This means it needs to be revoked so that memory can be freed
  const blob = new Blob([byteArray], { type: contentType })
  const link = document.createElement('a')
  link.href = URL.createObjectURL(blob)
  link.download = fileDownloadName
  link.click()
  URL.revokeObjectURL(link.href)
  link.remove()
}

/** Performs a simple JSON.stringify clone of a value
 *
 * @param {*} item
 * @returns {*}
 */
export const clone = (item) => {
  if (!item) {
    return item
  }
  return JSON.parse(JSON.stringify(item))
}

/**
 * Validate Email
 * @param {String} email The email being validated
 */
export const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

/** Sets a local storage value  */
export const setLocalStorage = (key, value = null) => {
  if (value === null) {
    localStorage.removeItem(key)
  } else {
    localStorage.setItem(key, value)
  }
}

/** Sets a local storage value additionally keyed by SSO */
export const setLocalStorageSSOKey = (prefixString, value, sso) => {
  const now = new Date()
  const json = JSON.stringify({
    value,
    time: now.toJSON()
  })
  if (sso) setLocalStorage(`${prefixString}-${sso}`, json)
}

/** Gets a local storage value additionally keyed by SSO */
export const getLocalStorageSSOKey = (prefixString, sso) => {
  const obj = localStorage.getItem(`${prefixString}-${sso}`)

  if (!obj) {
    return null
  }

  return JSON.parse(obj).value
}

export const customerIsSubAccount = (customer, isPlattBanner) => {
  const { parentAccount, billTo, shipTo } = customer
  return parentAccount.accountId > 0 || (!isPlattBanner && (billTo || shipTo))
}
