export const setSimplifiedDashboardData = (state, simplifiedDashboardData) => {
  state.simplifiedDashboard = simplifiedDashboardData
  state.loaded = true
}

export const setSimplifiedDashboardGoals = (state, salesGoals) => {
  state.simplifiedDashboard.salesGoals = salesGoals
}

export const setSimplifiedDashboardToggle = (state, value) => {
  state.displaySimplifiedDashboard = value
}
