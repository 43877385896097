import {
  plattProductUrl, rexelProductUrl, gexproProductUrl
} from '@/api/products'
import { bannerFeatureProvider } from '@/features/authorization/bannerFeatureProvider'
import { Globals } from '@/globals/globals'

const apps = (state) => state.apps
const pageHeader = (state) => state.pageHeader
const toastMessage = (state) => state.toastMessage
const errorPage = (state) => state.errorPage
const pageTitle = (state) => state.PageTitle
const openEditMyProfile = (state) => state.openEditMyProfile
const debug = (state) => state.debug

const hasLastRoute = (state) => {
  return state.lastRoute
    ? ![null, 'AuthCallbackSilent'].includes(state.lastRoute.name)
    : false
}

const baseProductUrl = (state, getters) => {
  const banner = getters['user/banner']
  switch (banner) {
    case 'rexel':
      return rexelProductUrl
    case 'gexpro':
      return gexproProductUrl
    default:
      return plattProductUrl
  }
}

/** Provides the Order Central Base URI depending on the operating environment */
const ordersBaseUri = () => Globals.ordersBaseUri

const isCustomerPresent = (state) => {
  return state.isCustomerPresent
}

const getBannerFeatureFlags = (state) => {
  return bannerFeatureProvider.flags
}

const getBannerFeatureFlagsHash = (state) => {
  return bannerFeatureProvider.flagsHash
}

export default {
  apps,
  hasLastRoute,
  pageHeader,
  toastMessage,
  errorPage,
  isCustomerPresent,
  baseProductUrl,
  ordersBaseUri,
  pageTitle,
  openEditMyProfile,
  getBannerFeatureFlags,
  getBannerFeatureFlagsHash,
  debug
}
