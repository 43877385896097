import axios from 'axios'
import { callApi, generateUrl, stringifyArray } from '@/globals/utils'
import store from '@/store'

export const getActivitySummary = (startDate, endDate) =>
  callApi({
    url: '/api/report/activity-summary',
    params: {
      startDate,
      endDate
    },
    messages: {
      error: 'Error fetching Activity Summary data'
    }
  })

export const getActivitiesOnOpportunities = (regionCode, startDate, endDate) =>
  callApi({
    url: '/api/report/activities-on-opportunities',
    params: {
      regionCode,
      startDate,
      endDate
    },
    messages: {
      error: 'Error fetching Activities on Opportunities data'
    }
  })

export const getActivitiesOnOpportunitiesExportUrl = (regionCode, startDate, endDate) =>
  generateUrl('/api/report/activities-on-opportunities/export', { regionCode, startDate, endDate })

export const getActivitySummaryCustomers = (sortBy, itemsPerPage, page, descending, search, startDate, endDate, myCustomersOnly) =>
  axios.get('/api/report/activity-summary/customers', { params: { sortBy, itemsPerPage, page, descending, search, startDate, endDate, myCustomersOnly } })

export const getActivitySummaryCustomersUrl = (startDate, endDate) =>
  generateUrl('/api/report/activity-summary/customers/export', { startDate, endDate })

export const getActivitySummaryBranchesUrl = (region, startDate, endDate) =>
  generateUrl('/api/report/activity-summary/branches/export', { region, startDate, endDate })

export const getActivitySummaryPeopleUrl = (startDate, endDate) =>
  generateUrl('/api/report/activity-summary/people/export', { startDate, endDate })

export const getActivitySummaryRegionsUrl = (startDate, endDate) =>
  generateUrl('/api/report/activity-summary/regions/export', { startDate, endDate })

export const getActivitySummaryByCustomer = (customerId, startDate = null, endDate = null) =>
  callApi({
    url: '/api/report/activity-summary/by-customer',
    params: {
      id: customerId,
      startDate,
      endDate
    },
    messages: {
      error: 'Error fetching Activity Summary by Customer data'
    }
  })

export const getActivitySummaryByCustomerUrl = (id, startDate, endDate) =>
  generateUrl('/api/report/activity-summary/by-customer/export', { id, startDate, endDate })

export const getActivitySummaryByBranch = (branchId, startDate = null, endDate = null) =>
  callApi({
    url: '/api/report/activity-summary/by-branch',
    params: {
      id: branchId,
      startDate,
      endDate
    },
    messages: {
      error: 'Error fetching Activity Summary by Branch data'
    }
  })

export const getActivitySummaryByBranchUrl = (id, startDate, endDate) =>
  generateUrl('/api/report/activity-summary/by-branch/export', { id, startDate, endDate })

export const getActivitySummaryByEmployee = (sso, startDate = null, endDate = null) =>
  callApi({
    url: '/api/report/activity-summary/by-employee',
    params: {
      sso,
      startDate,
      endDate
    },
    messages: {
      error: 'Error fetching Activity Summary by Employee data'
    }
  })

export const getActivitySummaryByEmployeeUrl = (sso, startDate, endDate) =>
  generateUrl('/api/report/activity-summary/by-employee/export', { sso, startDate, endDate })

export const getOpportunitySummary = (startDate, endDate) =>
  callApi({
    url: '/api/report/opportunity-summary',
    params: {
      startDate,
      endDate
    },
    messages: {
      error: 'Error fetching Opportunity Summary data'
    }
  })

export const getOpportunitySummaryCustomersUrl = (startDate, endDate, { mineOnly }) =>
  generateUrl('/api/report/opportunity-summary/customers/export', { startDate, endDate, mineOnly })

export const getOpportunitySummaryBranchesUrl = (startDate, endDate, { regionCode }) =>
  generateUrl('/api/report/opportunity-summary/branches/export', { startDate, endDate, regionCode })

export const getOpportunitySummaryPeopleUrl = (startDate, endDate) =>
  generateUrl('/api/report/opportunity-summary/people/export', { startDate, endDate })

export const getOpportunitySummaryRegionsUrl = (startDate, endDate) =>
  generateUrl('/api/report/opportunity-summary/regions/export', { startDate, endDate })

export const getOpportunitySummaryVendorsUrl = (startDate, endDate) =>
  generateUrl('/api/report/opportunity-summary/vendors/export', { startDate, endDate })

export const getOpportunitySummaryByCustomer = (customerId, startDate = null, endDate = null) =>
  callApi({
    url: '/api/report/opportunity-summary/by-customer',
    params: {
      id: customerId,
      startDate,
      endDate
    },
    messages: {
      error: 'Error fetching Opportunity Summary by Customer data'
    }
  })

export const getOpportunitySummaryByCustomerUrl = (id, startDate, endDate) =>
  generateUrl('/api/report/opportunity-summary/by-customer/export', { id, startDate, endDate })

export const getOpportunitySummaryByBranch = (branchId, startDate = null, endDate = null) =>
  callApi({
    url: '/api/report/opportunity-summary/by-branch',
    params: {
      id: branchId,
      startDate,
      endDate
    },
    messages: {
      error: 'Error fetching Opportunity Summary by Branch data'
    }
  })

export const getOpportunitySummaryByBranchUrl = (id, startDate, endDate) =>
  generateUrl('/api/report/opportunity-summary/by-branch/export', { id, startDate, endDate })

export const getOpportunitySummaryByEmployee = (sso, startDate = null, endDate = null) =>
  callApi({
    url: '/api/report/opportunity-summary/by-employee',
    params: {
      sso,
      startDate,
      endDate
    },
    messages: {
      error: 'Error fetching Opportunity Summary by Employee data'
    }
  })

export const getOpportunitySummaryByEmployeeUrl = (sso, startDate, endDate) =>
  generateUrl('/api/report/opportunity-summary/by-employee/export', { sso, startDate, endDate })

export const getOpportunitySummaryByRegion = (regionCode, startDate = null, endDate = null) =>
  callApi({
    url: '/api/report/opportunity-summary/by-region',
    params: {
      regionCode,
      startDate,
      endDate
    },
    messages: {
      error: 'Error fetching Opportunity Summary by Region data'
    }
  })

export const getOpportunitySummaryByRegionUrl = (regionCode, startDate, endDate) =>
  generateUrl('/api/report/opportunity-summary/by-region/export', { regionCode, startDate, endDate })

export const getOpportunitySummaryByVendor = (vendorId, startDate = null, endDate = null) =>
  callApi({
    url: '/api/report/opportunity-summary/by-vendor',
    params: {
      vendorId,
      startDate,
      endDate
    },
    messages: {
      error: 'Error fetching Opportunity Summary by Vendor data'
    }
  })

export const getOpportunitySummaryByVendorUrl = (vendorId, startDate, endDate) =>
  generateUrl('/api/report/opportunity-summary/by-vendor/export', { vendorId, startDate, endDate })

export const getSalesByContractReport = () =>
  axios.get('/api/report/sales-by-contract')

export const salesByContractReportUrl = () =>
  generateUrl('/api/report/sales-by-contract/export')

export const getExpiringContractsReport = () =>
  axios.get('/api/v1/report/expiring-contracts')

export const expiringContractsReportUrl = () =>
  generateUrl('/api/v1/report/expiring-contracts/export')

export const getExpiringContractCustomers = (contractNumber) =>
  axios.get(`/api/v1/report/expiring-contracts/customers?contractNumber=${contractNumber}`)

export const expiringContractCustomersReportUrl = (contractNumber) =>
  generateUrl('/api/v1/report/expiring-contracts/customers/export', { contractNumber })

export const resolveExpiringContract = (payload) =>
  axios.post('/api/v1/report/expiring-contracts/resolve', payload)

export const getContractsByCustomerReport = () =>
  axios.get('/api/report/contracts-by-customer')

export const contractsByCustomerReportUrl = () =>
  generateUrl('/api/report/contracts-by-customer/export')

export const getContractsByProductReport = (productId) =>
  axios.get(`/api/report/contracts-by-product/${productId}`)

export const contractsByProductReportUrl = (productId) =>
  generateUrl('/api/report/contracts-by-product/export', { productId })

export const getContractsForCustomerReport = (customerId) =>
  axios.get(`/api/report/contracts-by-customer/for-customer/${customerId}`)

export const contractsForCustomerReportUrl = (customerId) =>
  generateUrl(`/api/report/contracts-by-customer/for-customer/${customerId}/export`)

export const getCustomersMissingDetailsByRegionReport = () =>
  axios.get('/api/report/customers-missing-details/region')

export const customersMissingDetailsByRegionReportUrl = () =>
  generateUrl('/api/report/customers-missing-details/region/export')

export const getCustomersMissingDetailsByBranchReport = (region) =>
  axios.get(`/api/report/customers-missing-details/branch?region=${region}`)

export const customersMissingDetailsByBranchReportUrl = (region) =>
  generateUrl('/api/report/customers-missing-details/branch/export', { region })

export const getCustomersMissingDetailsByEmployeeReport = (branchId) =>
  axios.get(`/api/report/customers-missing-details/people?branchId=${branchId}`)

export const customersMissingDetailsByEmployeeReportUrl = (branchId) =>
  generateUrl('/api/report/customers-missing-details/people/export', { branchId })

export const getCustomersMissingDetailsForEmployeeReport = (sso, branchId) =>
  axios.get(`/api/report/customers-missing-details/employee?sso=${sso}&branchId=${branchId}`)

export const customersMissingDetailsForEmployeeReportUrl = (sso, branchId, filters) =>
  generateUrl('/api/report/customers-missing-details/employee/export', { sso, branchId, filters })

export const getCustomerOrdersReport = ({
  sso,
  startDate,
  endDate,
  sortBy,
  itemsPerPage,
  page,
  descending,
  orderType,
  salesType,
  otherFilter,
  search
}) => {
  return axios.get('/api/report/customer-orders', {
    params: {
      sso,
      startDate,
      endDate,
      sortBy,
      itemsPerPage,
      page,
      descending,
      orderType,
      salesType,
      otherFilter,
      search
    }
  })
}

export const getCustomerOrdersKeyedReport = ({
  sso,
  startDate,
  endDate,
  sortBy,
  itemsPerPage,
  page,
  descending,
  orderType,
  salesType,
  otherFilter,
  search
}) => {
  return axios.get('/api/report/customer-orders-keyed', {
    params: {
      sso,
      startDate,
      endDate,
      sortBy,
      itemsPerPage,
      page,
      descending,
      orderType,
      salesType,
      otherFilter,
      search
    }
  })
}

export const customerOrdersReportUrl = (sso,
  startDate,
  endDate,
  sortBy,
  descending,
  salesType,
  orderType,
  otherFilter,
  search,
  customerTableHeaders,
  keyedOrderTableHeaders) => {
  return generateUrl('/api/report/customer-orders/export', {
    sso,
    startDate,
    endDate,
    sortBy,
    descending,
    salesType,
    orderType,
    otherFilter,
    search,
    customerTableHeaders: stringifyArray(customerTableHeaders),
    keyedOrderTableHeaders: stringifyArray(keyedOrderTableHeaders)
  })
}

export const inactiveCustomersReportUrl = (displayName) => {
  return generateUrl('/api/report/inactive-customers/export', { displayName })
}

export const getCustomerOverrideDetailsReport = (customerId, startDate, endDate) =>
  axios.get(`/api/report/customer-override-details/${customerId}?startDate=${startDate}&endDate=${endDate}`)

export const customerOverrideDetailsReportUrl = (customerId, startDate, endDate) =>
  generateUrl(`/api/report/customer-override-details/${customerId}/export`, { startDate, endDate })

export const getCustomerOverridesReport = (dates) =>
  axios.get(`/api/report/customer-overrides?startDate=${dates.startDate}&endDate=${dates.endDate}`)

export const customerOverridesReportUrl = (startDate, endDate) =>
  generateUrl('/api/report/customer-overrides/export', { startDate, endDate })

export const getCustomerSalesByItemForContractReport = (vendorId, contractNumber, customerId) =>
  axios.get(`/api/report/sales-by-contract/sales-by-item-for-customer?vendorId=${vendorId}&contractNumber=${contractNumber}&customerId=${customerId}`)

export const customerSalesByItemForContractReportUrl = (vendorId, contractNumber, customerId) =>
  generateUrl('/api/report/sales-by-contract/sales-by-item-for-customer/export', { vendorId, contractNumber, customerId })

export const getCustomerSalesForContractReport = (vendorId, contractNumber, allCustomers) =>
  axios.get(`/api/report/sales-by-contract/sales-by-customer?vendorId=${vendorId}&contractNumber=${contractNumber}&allCustomers=${allCustomers}`)

export const customerSalesForContractReportUrl = (vendorId, contractNumber, allCustomers) =>
  generateUrl('/api/report/sales-by-contract/sales-by-customer/export', { vendorId, contractNumber, allCustomers })

export const getEmployeeSalesByProgram = (sso) => {
  const params = sso ? { sso } : null
  return callApi({
    url: '/api/report/sales-by-program/employee',
    params,
    messages: {
      error: 'Error fetching Sales by Program data'
    }
  })
}

export const getCustomerSalesByProgram = (customerId) =>
  callApi({
    url: '/api/report/sales-by-program/customer',
    params: {
      customerId
    },
    messages: {
      error: 'Error fetching Sales by Program data'
    }
  })

export const getCustomersReport = () =>
  axios.get('/api/report/customers')

export const customersReportUrl = (sortBy, descending, search, accountType, autoDelete, searchPropertyNames) => {
  return generateUrl('/api/report/customers/export', {
    sortBy,
    descending,
    search,
    accountType: stringifyArray(accountType),
    autoDelete,
    searchPropertyNames: stringifyArray(searchPropertyNames)
  })
}

export const customersAtRiskUrl = (ssoNumber, userType, branchId) => generateUrl(
  '/api/v1/customers/export',
  {
    page: 1,
    top: 10000,
    filters: 'atRisk',
    orderBy: 'sales desc',
    ssoNumber,
    branchId,
    source: userType === 'Sales' ? 'my' : 'branch'
  }
)

export const getInactiveCustomersReport = () =>
  axios.get('/api/report/inactive-customers')

export const branchReportCardUrl = (branchId) => {
  if (store.getters['user/banner'] !== 'platt') return 'https://metrics.rexelusa.com'
  return generateUrl('/api/report/branch-report-card', { branchId })
}

export const employeeReportCardUrl = (sso) => {
  if (store.getters['user/banner'] !== 'platt') return 'https://metrics.rexelusa.com'
  return generateUrl('/api/report/report-card', { sso })
}

export const getProductRemindersReport = () =>
  axios.get('/api/report/product-reminders')

export const productRemindersReportUrl = () =>
  generateUrl('/api/report/product-reminders/export')

export const salesByCategoryCustomersExportUrl = (purchasedAt = null, showKeyedOrdersOnly = null) => {
  generateUrl('/api/report/customer-sales-by-category/export', {
    purchasedAt,
    showKeyedOrdersOnly
  })
}

export const getCategoryForCompanyReport = (categoryId = null) => {
  return axios.get('/api/report/by-category', { params: { categoryId } })
}
export const byCategoryForCompanyReportExportUrl = (reportName, companyId = null, categoryId) => {
  return generateUrl('/api/report/by-category/export', {
    reportName,
    categoryId
  })
}

export const getCategoryForEmployeeReport = (employeeIds, categoryId = null) => {
  // TODO -- proc not ready yet
  return axios.get('/api/report/by-category', {
    params: {
      winNts: stringifyArray(employeeIds),
      categoryId
    }
  })
}
export const byCategoryForEmployeeReportExportUrl = (reportName, employeeIds, categoryId) => {
  // TODO -- proc not ready yet
  return generateUrl(
    '/api/report/by-category/export', {
      reportName,
      winNts: stringifyArray(employeeIds),
      categoryId
    }
  )
}

export const getCategoryForBranchReport = (branchIds, categoryId = null) => {
  return axios.get('/api/report/by-category-for-branches', {
    params: {
      branchList: stringifyArray(branchIds),
      categoryId
    }
  })
}
export const byCategoryForBranchReportExportUrl = (reportName, branchIds, categoryId) => {
  return generateUrl(
    '/api/report/by-category-for-branches/export', {
      reportName,
      branchList: stringifyArray(branchIds),
      categoryId
    }
  )
}

export const getByCategoryForCustomerReport = (customerIds, categoryId = null) => {
  return axios.get('/api/report/by-category-for-customers', {
    params: {
      customerList: stringifyArray(customerIds),
      categoryId
    }
  })
}
export const byCategoryForCustomerReportExportUrl = (reportName, customerIds, categoryId) => {
  return generateUrl(
    '/api/report/by-category-for-customers/export', {
      reportName,
      customerList: stringifyArray(customerIds),
      categoryId
    }
  )
}

export const getCategoryInfo = (categoryId) =>
  axios.get(`/api/report/by-category-for-customers/category-info?categoryId=${categoryId}`)

export const getProductInfo = (productId) =>
  axios.get(`/api/report/by-category-for-customers/product-info?productId=${productId}`)

export const getProductStatsForCustomer = (customerId, productId) =>
  axios.get(`/api/report/by-category-for-customers/product-stats?customerId=${customerId}&productId=${productId}`)

// NOTE: will we need these later?
//
// export const getCategoryStatsForCustomerByCategoryReport = (customerId, categoryId) =>
//   axios.get(`/api/report/by-category-for-customers/category-stats?customerId=${customerId}&categoryId=${categoryId}`)
//
// export const getProductLedgerForCustomerByCategoryReport = (customerId, productId) =>
//   axios.get(`/api/report/by-category-for-customers/ledger?customerId=${customerId}&productId=${productId}`)
//
// export const getProductLedgerForCustomerByCategoryReportExportUrl = (banner, customerId, productId) =>
// `${baseUrl}/api/report/by-category-for-customers/ledger/export?banner=${banner}&customerId=${customerId}&productId=${productId}`

export const getCustomersInactiveSoonReport = () => {
  return axios.get('/api/report/customers-inactive-soon')
}

export const customersInactiveSoonReportExportUrl = () => // Does not take a banner parameter
  generateUrl('/api/report/customers-inactive-soon/export')

export const getTopNonPlattPlusCustomersReport = () =>
  axios.get('/api/report/top-non-platt-plus-customers')

export const topNonPlattPlusCustomersReportUrl = () =>
  generateUrl('/api/report/top-non-platt-plus-customers/export')

export const getProductRecommendationsReport = (customerId) =>
  axios.get(`/api/report/top-products-by-category?customerId=${customerId}`)

export const productRecommendationsReportUrl = (customerId) =>
  generateUrl('/api/report/top-products-by-category/export', { customerId })

export const getByProductCustomerReport = ({
  customerId,
  poNumber,
  jobNumber,
  catName,
  startDate,
  endDate
}) => {
  return axios.get('/api/report/top-products', {
    params: {
      customerId,
      poNumber,
      jobNumber,
      catName,
      startDate,
      endDate
    }
  })
}

export const byProductCustomerExportUrl = (
  customerId = null,
  poNumber = null,
  jobNumber = null,
  sortBy,
  descending,
  search,
  searchPropertyNames,
  startDate,
  endDate

) => {
  return generateUrl('/api/report/top-products/export', {
    customerId,
    poNumber,
    jobNumber,
    sortBy,
    descending,
    search,
    searchPropertyNames: stringifyArray(searchPropertyNames),
    startDate,
    endDate
  })
}

export const getCustomerJobAccounts = (customerId) =>
  callApi({
    method: 'GET',
    url: '/api/report/top-products/job-accounts',
    params: {
      customerId
    },
    messages: {
      error: 'Error retrieving customer job accounts'
    }
  })

export const getByProductForBranchReport = ({
  branchId,
  sortBy,
  itemsPerPage,
  page,
  descending,
  search
}) => {
  return axios.get('/api/report/branch-sales-by-product-branch', {
    params: {
      branchId,
      sortBy,
      itemsPerPage,
      page,
      descending,
      search
    }
  })
}

export const byProductForBranchReportExportUrl = (branchId, sortBy, descending, search, searchPropertyNames) =>
  generateUrl('/api/report/branch-sales-by-product-branch/export', {
    branchId,
    sortBy,
    descending,
    search,
    searchPropertyNames: stringifyArray(searchPropertyNames)
  })

export const getByProductForBranchCompareReport = ({
  branchIds,
  descending,
  page,
  itemsPerPage,
  search,
  sortBy,
  startDate,
  endDate
}) =>
  axios.get('/api/report/compare-branch-sales-by-product', {
    params: {
      branchIds,
      descending,
      page,
      itemsPerPage,
      search,
      sortBy,
      startDate,
      endDate
    }
  })

export const byProductForBranchCompareExportData = (
  branchIds,
  sortBy,
  descending,
  search,
  searchPropertyNames) =>
  generateUrl('/api/report/compare-branch-sales-by-product/export', {
    branchIds,
    sortBy,
    descending,
    search,
    searchPropertyNames: stringifyArray(searchPropertyNames)
  })

export const getByProductCompanyReport = ({
  sortBy,
  descending,
  page,
  itemsPerPage,
  search,
  startDate,
  endDate
}) => {
  return axios.get('/api/report/companywide-sales-by-product', {
    params: {
      sortBy,
      descending,
      page,
      itemsPerPage,
      search,
      startDate,
      endDate
    }
  })
}

export const getCompanySalesData = () =>
  axios.get('/api/report/companywide-sales-report-data')

export const setPreferredProduct = (customerId, productId, isPreferred) => {
  const action = isPreferred ? 'remove' : 'set'
  return axios.post(`/api/report/top-products/${action}-preferred`, {
    customerId,
    productId
  })
}

export const getTopVendorDetailsReport = (customerId, vendorNumber) =>
  axios.get(`/api/report/top-vendors/details?customerId=${customerId}&vendorId=${vendorNumber}`)

export const topVendorDetailsReportExportUrl = (customerId, vendorId, companyCustomerNumber) =>
  generateUrl('/api/report/top-vendors/details/export', { customerId, vendorId, companyCustomerNumber })

export const getTopVendorsReport = (customerId) =>
  axios.get(`/api/report/top-vendors?customerId=${customerId}`)

export const topVendorsReportExportUrl = (customerId) =>
  generateUrl('/api/report/top-vendors/export', { customerId })

export const getTopViewedProductsReport = (customerId) =>
  axios.get(`/api/report/top-viewed-products?customerId=${customerId}`)

export const topViewedProductsReportExportUrl = (customerId) =>
  generateUrl('/api/report/top-viewed-products/export', { customerId })

export const fetchPimSummaryReport = () =>
  axios.get('/api/report/pim-summary')

export const pimSummaryReportExportUrl = () =>
  generateUrl('/api/report/pim-summary/export')

export const fetchCustomersForSearches = (dates) =>
  axios.get(`/api/report/customer-searches?startDate=${dates.startDate}&endDate=${dates.endDate}`)

export const fetchCustomerSearchTrends = (customerId, startDate, endDate) =>
  axios.get(`/api/customer/search-trends?customerId=${customerId}&startDate=${startDate}&endDate=${endDate}`)

export const customersSearchesReportExportUrl = (startDate, endDate) =>
  generateUrl('/api/report/customer-searches/export', { startDate, endDate })

export const customerSearchTrendsReportExportUrl = (customerId) =>
  generateUrl('/api/customer/search-trends/export', { customerId })

export const getCustomersAtHighRiskReport = (companyId) =>
  axios.get(`/api/v1/customers-at-high-risk?companyId=${companyId}`)

export const saveHighRiskCustomerAction = (action) =>
  axios.post(`/api/v1/customers-at-high-risk/${action.customerId}/take-action`, action)

/**
   * @param {{companyId: number, customerId: number, reasonId: number, responseId: number}} action
   * @returns {Boolean}
   */
export const saveHighRiskCustomerActionAsync = async (action) => {
  try {
    await axios.post(`/api/v1/customers-at-high-risk/${action.customerId}/take-action`, action)
    return true
  } catch (error) {
    // TODO: There really should be a message service that follows an adapter pattern in order to send error messages to the $store
    return false
  }
}

export const exportCustomersAtHighRiskReportUrl = (companyId) =>
  generateUrl('/api/v1/customers-at-high-risk/export', { companyId })

export const getCustomersAtHighRiskReportExport = async (companyId) => {
  const result = await callApi({ method: 'GET', url: '/api/v1/customers-at-high-risk/export', params: { companyId } })
  return result.data
}

export const getCustomerFirstOrderReport = (companyId) =>
  axios.get(`/api/v1/customers-first-order?companyId=${companyId}`)

export const setCustomerFirstActivityCreated = (companyId, customerId) =>
  axios.post(`/api/v1/customers-first-order/${companyId}/${customerId}/activity-created`)

export const exportCustomerFirstOrderReportUrl = (companyId) =>
  generateUrl('/api/v1/customers-first-order/export', { companyId })

export const getCustomerFirstOrderReportExport = async (companyId) => {
  const result = await callApi({ method: 'GET', url: '/api/v1/customers-first-order/export', params: { companyId } })
  return result.data
}

export const getJobFunctionCodes = () =>
  axios.get('/api/people/job-function-code-mapping')

export const getLeadsReport = async (showDeleted = false) => {
  const result = await callApi({
    url: '/api/report/leads',
    params: { showDeleted }
  })
  return result.data
}

export const getLeadsReportExport = async (showDeleted = false) => {
  const result = await callApi({
    url: '/api/report/leads/export',
    params: { showDeleted }
  })
  return result.data
}
