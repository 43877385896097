import axios from 'axios'

const interceptors = {}

export const addQueryToEveryRequest = (key, value) => {
  // Remove old interceptor if it exists
  if (interceptors[key]) {
    axios.interceptors.request.eject(interceptors[key])
  }
  // Create interceptor
  const interceptor = axios.interceptors.request.use((config) => {
    if (!config.url.includes(`${key}=`)) {
      const separator = config.url.includes('?') ? '&' : '?'
      config.url += `${separator}${key}=${value}`
    }
    return config
  })
  // Add to our list so we can remove later if needed
  interceptors[key] = interceptor
}

export const removeQueryFromEveryRequest = (key) => {
  axios.interceptors.request.eject(interceptors[key])
}
