import * as actions from './actions'
import * as getters from './getters'
import * as mutations from './mutations'

const state = {
  customer: {},
  billTo: {},
  shipTo: {},
  withJobs: {},
  selectedCustomer: {},
  activeCustomerType: false,
  loaded: false,
  allWebAccounts: []
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
