<template>
  <v-app v-if="loadingAppFailed">
    <error-loading-app></error-loading-app>
  </v-app>
  <v-app
    v-else-if="userLoaded"
    :class="['backgroundGrey', { isViewingAs }]">
    <toast
      :toast="toastMessage"
      @close="resetToastMessage()">
    </toast>
    <demo-header v-if="isDemo">
    </demo-header>
    <page-header
      :toolbar-height="toolbarHeight"
      @toggleSidebar="openNav = !openNav">
    </page-header>
    <sidebar-navigation-local
      :open-nav="openNav"
      @openNav="toggleOpenNav">
    </sidebar-navigation-local>
    <v-main
      class="jons-green-border">
      <v-container
        fluid
        class="pa-0">
        <session-expired-modal></session-expired-modal>
        <page-title-global
          :page-name="pageHeader.page"
          :page-sub-text="pageHeader.text"
          :is-demo="isDemo">
          <template #backButton>
            <back-button
              v-if="pageHeader.backButton !== false"
              :to="pageHeader.backButton">
            </back-button>
          </template>
        </page-title-global>
        <transition
          name="slide"
          mode="out-in">
          <router-view
            :key="banner"
            class="main-section">
          </router-view>
        </transition>
      </v-container>
    </v-main>

    <edit-my-profile-dialog></edit-my-profile-dialog>
    <v-sheet
      v-if="environment === 'Test'"
      color="primary"
      class="text-body-2 px-1 white--text"
      style="position: fixed; bottom: 4px; right: 4px;">
      {{ $vuetify.breakpoint.name }}
    </v-sheet>
  </v-app>
  <page-loading-progress v-else>
  </page-loading-progress>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import BackButton from '@/components/BackButton.vue'
import ErrorLoadingApp from './ErrorLoadingApp.vue'

import EditMyProfileDialog from '@/layout/EditMyProfileDialog.vue'
import PageHeader from './PageHeader/PageHeader.vue'
import PageLoadingProgress from '@/components/PageLoadingProgress.vue'
import Toast from '@/components/Toast.vue'
import DemoHeader from './PageHeader/DemoHeader.vue'

import SessionExpiredModal from '@/components/SessionExpiredModal.vue'
import PageTitleGlobal from '@/components/PageTitleGlobal.vue'
import SidebarNavigationLocal from '@/layout/SidebarNavigationLocal.vue'

export default {
  name: 'BaseLayout',
  components: {
    PageTitleGlobal,
    BackButton,
    ErrorLoadingApp,
    PageHeader,
    PageLoadingProgress,
    Toast,
    SessionExpiredModal,
    DemoHeader,
    SidebarNavigationLocal,
    EditMyProfileDialog
  },
  data () {
    return {
      openNav: this.$vuetify.breakpoint.mdAndUp,
      demoHeaderHeight: 32,
      mobileToolbarHeight: 56,
      desktopToolbarHeight: 0
    }
  },
  computed: {
    ...mapGetters('user', ['user', 'banner', 'isViewingAs']),
    ...mapState('user', {
      userLoaded: state => state.userLoaded,
      loggedInUser: state => state.user.user
    }),
    ...mapState(['pageHeader', 'toastMessage', 'loadingAppFailed']),
    isDemo () {
      return this.$environment === 'Demo'
    },
    environment () {
      return this.$environment
    },
    toolbarHeight () {
      if (this.$vuetify.breakpoint.smAndDown) {
        return this.mobileToolbarHeight
      } else {
        return this.desktopToolbarHeight
      }
    },
    sidebarTopPosition () {
      return this.isDemo
        ? this.$vuetify.breakpoint.mdAndDown
          ? this.demoHeaderHeight
          : this.toolbarHeight
        : 0
    }
  },
  watch: {
    // force reload of components when banner is switched
    // triggerGuard enables the beforeEnter route guard to be refired
    banner: {
      handler (val) {
        this.$triggerGuard(this.authenticateRouteBannerSwitch)
      }
    }
  },
  beforeMount () {
    if (this.user.sso === '550003712' && !this.isDemo) {
      window.location.href = 'https://demosales.rexelusa.net'
    }
  },
  methods: {
    toggleOpenNav (value) {
      this.openNav = value
    },
    resetToastMessage () {
      this.$store.commit('setToastMessage', null)
    },
    authenticateRouteBannerSwitch (to, from, next) {
      this.$store.dispatch('authenticateRoute', { to, from, next })
    }
  }

}
</script>

<style lang="scss">
@import '~styl/colors';

$tabletMaxWidth: 1263px;
$mobileMaxWidth: 767px;

// static values based on data properties
$demoBannerHeight: 32px;
$mobileToolbarHeight: 56px;
$desktopToolbarHeight: 64px;

// override z-index values
aside.v-navigation-drawer {
  z-index: 9 !important;
}
div.v-overlay {
  z-index: 8 !important;
}
.jons-green-border {
  border-right: 16px solid $accent;

  @media (max-width: $tabletMaxWidth) {
    border-right: 16px solid $accent;
    border-left: 16px solid $accent;
  }

  @media (max-width: $mobileMaxWidth) {
    border: none;
  }
}

main.content {
  background-color: $backgroundGrey;
}

.main-section  {
  overflow: auto;

  & > .container {
    background-color: $backgroundGrey;
  }
}

.headline {
  $left: 63px;

  .page {
    position: absolute;
    font-size: 13px;
    color: $spanishGray;
    font-weight: 400;
    left: $left;
    bottom: 23px;
  }

  .page-text {
    position: absolute;
    font-size: 20px;
    left: $left;
    bottom: 5px;
  }
}

img.custom-icon {
  height: 25px !important;
}
</style>
