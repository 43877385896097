import { bannerProvider } from '@/features/banners'
const roles = (state) => state.roles
const entityId = (state) => state.entityId
const webUserParent = (state) => state.webUserParent
const rexGexPimEntityId = (state) => state.rexGexPimEntityId
const entityIdForPim = (state, getters, rootState) => {
  const { entityId, rexGexPimEntityId } = state
  return rexGexPimEntityId || entityId
}
const storeFrontUsers = (state) => state.users
const impersonationUrl = (state) => state.impersonationUrl

const rexGexImpersonationUrl = (state) => state.rexGexPimImpersonationUrl
const pims = (state) => state.pims
const newPimsLoaded = (state) => state.newPimsLoaded
const pimsAreMigrated = (state) => state.pimsAreMigrated
const newPimId = (state) => state.newPimId

const isRexGexPimEnabled = (state, getters, rootState) => {
  const { accountId, companyId } = rootState.currentCustomer.customer
  const isRexGex = bannerProvider.isRexGex(companyId)
  if (isRexGex) {
    return state.pimEnabledRexGexAccountIds.includes(accountId)
  }
  return false
}

export default {
  roles,
  entityId,
  webUserParent,
  rexGexPimEntityId,
  entityIdForPim,
  storeFrontUsers,
  impersonationUrl,
  rexGexImpersonationUrl,
  pims,
  newPimsLoaded,
  pimsAreMigrated,
  newPimId,
  isRexGexPimEnabled
}
