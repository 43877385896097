import { callApi } from '@/globals/utils'
import { CUSTOMER_LIFECYCLES } from '@/globals/constants'

/**
 * @typedef {Object} CustomerLifecycleTotals
 * @property {number} leads
 * @property {number} newCxs
 * @property {number} firstOrder
 * @property {number} thirdOrder
 * @property {number} active
 * @property {number} activePlus
 * @property {number} monthlyActive
 * @property {number} atRisk
 * @property {number} atHighRisk
 * @property {number} inactive
 */

/**
 * @typedef {Object} LifecycleCustomerViewModel
 * @property {number} customerId
 * @property {number} companyId
 * @property {number} companyCustomerNumber
 * @property {string} customerName
 * @property {string?} lastLoginDate
 * @property {string} lastLoginDevice
 * @property {string?} maxOrderDate
 * @property {string?} lastActivityDate
 * @property {number} contactCount
 * @property {number} webAccountCount
 * @property {number} webAccountCount
 * @property {number} rolling12Sales
 * @property {number[]} salespersonSsoList
 * @property {boolean} followedCustomer
 * @property {string} billToFlag
 * @property {string} shipToFlag
 * @property {string} jobAccountFlag
 * @property {string} accountType
 * @property {string} autoDelete
 * @property {string} insideSalesNames
 * @property {string} outsideSalesNames
 * @property {string} lifecycleName
 */

/**
 * @typedef {Object} LifecycleCustomerAtRiskViewModel
 * @property {number} companyId
 * @property {number} customerId
 * @property {number} companyCustomerNumber
 * @property {string} customerName
 * @property {string} billToFlag
 * @property {string} shipToFlag
 * @property {number?} totalSales
 * @property {string?} lastActivity
 * @property {string?} lastActivityCreated
 * @property {string?} lastOrder
 * @property {string} customerNameAndNumber
 * @property {string} assignedOutsideSalesRepName
 * @property {boolean} atRisk
 * @property {boolean} atRiskBySkus
 * @property {boolean} atRiskByOrders
 * @property {boolean} atRiskByDollars
 * @property {boolean} atRiskByWebLogin
 * @property {boolean} atHighRisk
 * @property {string} atRiskTypeForDisplay
 * @property {boolean} hasRecentContact
 * @property {string  | null} maxChurnContactLast28Days
 */

/**
 * @typedef {Object} LifecycleCustomerNthOrderViewModel
 * @property {number} companyId
 * @property {number} customerId
 * @property {string} CustomerName
 * @property {string} CustomerNameAndNumber
 * @property {string} accountType
 * @property {string} createdOn
 * @property {string} firstOrderDate
 * @property {string} firstOrderNumber
 * @property {number} orderTotal
 * @property {string} outsideSalesPersonFullName
 * @property {string} shipTo
 * @property {string} billTo
 * @property {string} jobAccount
 * @property {boolean} activityCreated
 */

/**
 * @typedef {Object} LifecycleCustomerInactiveViewModel
 * @property {number} customerId
 * @property {number} companyCustomerNumber
 * @property {string} customerName
 * @property {string?} lastLoginDate
 * @property {string?} lastOrderDate
 * @property {number} rolling12Sales
 * @property {string} customerZip
 * @property {string} customerZipIntegrated
 * @property {string} userBranchZip
 * @property {number} userBranch
 * @property {string} userBranchName
 * @property {number?} distanceFromUserBranch
 * @property {number?} latitude
 * @property {number?} longitude
 * @property {string} closestBranch
 * @property {string} customerNameAndNumber
 */

/**
 * @typedef {Object} LifecycleLeadsViewModel
 * @property {number} leadId
 * @property {number} companyId
 * @property {number | null} contactId
 * @property {number | null} eRPContactNumber
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} fullName
 * @property {string} title
 * @property {string} lastActivityDate
 * @property {string} companyName
 * @property {string} email
 * @property {string} streetAddress
 * @property {string} streetAddress2
 * @property {string} zipCode
 * @property {string} city
 * @property {string} state
 * @property {number | null} mobilePhone
 * @property {number | null} phone
 * @property {number | null} phoneExt
 * @property {number | null} fax
 * @property {string | null} lastUpdated
 * @property {string} imageFile
 * @property {string} createdBySSO
 * @property {string} createdByName
 * @property {string | null} createDate
 * @property {string} updatedBySSO
 * @property {string} updatedByName
 * @property {string | null} updateDate
 * @property {boolean} optOutOfMarketingCommunications
 * @property {boolean | null} qualified
 * @property {number | null} customerNumber
 * @property {number | null} customerId
 * @property {string} customerName
 */

const getCustomersExportRequestModel = (sortBy, descending, search, accountTypes, autoDelete, searchPropertyNames) => {
  return {
    sortBy: Array.isArray(sortBy) ? sortBy[0] : sortBy,
    descending: Array.isArray(descending) ? descending[0] : descending,
    search,
    accountTypes,
    autoDelete,
    searchPropertyNames
  }
}

const getLifecycleReport = async (lifecycleId) => {
  const result = await callApi({ url: `/api/report/lifecycle/${lifecycleId}` })
  return result.data
}

const getLifecycleExport = async (lifecycleId) => {
  const result = await callApi({ url: `/api/report/lifecycle/${lifecycleId}/export` })
  return result.data
}

const getCustomersLifecycleExport = async (lifecycleId, sortBy, descending, search, accountTypes, autoDelete, searchPropertyNames) => {
  const model = getCustomersExportRequestModel(sortBy, descending, search, accountTypes, autoDelete, searchPropertyNames)
  const result = await callApi({ method: 'POST', url: `/api/report/lifecycle/${lifecycleId}/export`, payload: model })

  return result.data
}

/**
 * @returns  { CustomerLifecycleTotals }
 */
const getLifecycleTotals = async () => {
  const result = await callApi({ url: '/api/report/lifecycle/totals' })
  return result.data
}

/** Contains the functions to get the various lifecycle reports & exports */
const lifecycles = {
  leads: {
    /**
    * @returns { LifecycleLeadsViewModel[] }
    */
    getReport: async () => await getLifecycleReport(CUSTOMER_LIFECYCLES.Leads),
    getExport: async () => await getLifecycleExport(CUSTOMER_LIFECYCLES.Leads)
  },
  newCustomer: {
    /**
    * @returns { LifecycleCustomerViewModel[] }
    */
    getReport: async () => await getLifecycleReport(CUSTOMER_LIFECYCLES.NewCustomer),
    getExport: async (sortBy, descending, search, accountTypes, autoDelete, searchPropertyNames) =>
      await getCustomersLifecycleExport(CUSTOMER_LIFECYCLES.NewCustomer, sortBy, descending, search, accountTypes, autoDelete, searchPropertyNames)
  },
  firstOrder: {
    /**
    * @returns { LifecycleCustomerNthOrderViewModel[] }
    */
    getReport: async () => await getLifecycleReport(CUSTOMER_LIFECYCLES.FirstOrder),
    getExport: async () => await getLifecycleExport(CUSTOMER_LIFECYCLES.FirstOrder)
  },
  thirdOrder: {
    /**
    * @returns { LifecycleCustomerNthOrderViewModel[] }
    */
    getReport: async () => await getLifecycleReport(CUSTOMER_LIFECYCLES.ThirdOrder),
    getExport: async () => await getLifecycleExport(CUSTOMER_LIFECYCLES.ThirdOrder)
  },
  active: {
    /**
    * @returns { LifecycleCustomerViewModel[] }
    */
    getReport: async () => await getLifecycleReport(CUSTOMER_LIFECYCLES.Active),
    getExport: async (sortBy, descending, search, accountTypes, autoDelete, searchPropertyNames) =>
      await getCustomersLifecycleExport(CUSTOMER_LIFECYCLES.Active, sortBy, descending, search, accountTypes, autoDelete, searchPropertyNames)
  },
  activePlus: {
    /**
    * @returns { LifecycleCustomerViewModel[] }
    */
    getReport: async () => await getLifecycleReport(CUSTOMER_LIFECYCLES.ActivePlus),
    getExport: async (sortBy, descending, search, accountTypes, autoDelete, searchPropertyNames) =>
      await getCustomersLifecycleExport(CUSTOMER_LIFECYCLES.ActivePlus, sortBy, descending, search, accountTypes, autoDelete, searchPropertyNames)
  },
  monthlyActive: {
    /**
    * @returns { LifecycleCustomerViewModel[] }
    */
    getReport: async () => await getLifecycleReport(CUSTOMER_LIFECYCLES.MonthlyActive),
    getExport: async (sortBy, descending, search, accountTypes, autoDelete, searchPropertyNames) =>
      await getCustomersLifecycleExport(CUSTOMER_LIFECYCLES.MonthlyActive, sortBy, descending, search, accountTypes, autoDelete, searchPropertyNames)
  },
  atRisk: {
    /**
    * @returns { LifecycleCustomerAtRiskViewModel[] }
    */
    getReport: async () => await getLifecycleReport(CUSTOMER_LIFECYCLES.AtRisk),
    getExport: async () => await getLifecycleExport(CUSTOMER_LIFECYCLES.AtRisk)
  },
  atHighRisk: {
    /**
    * @returns { LifecycleCustomerAtRiskViewModel[] }
    */
    getReport: async () => await getLifecycleReport(CUSTOMER_LIFECYCLES.AtHighRisk),
    getExport: async () => await getLifecycleExport(CUSTOMER_LIFECYCLES.AtHighRisk)
  },
  inactive: {
    /**
    * @returns { LifecycleCustomerInactiveViewModel[] }
    */
    getReport: async () => await getLifecycleReport(CUSTOMER_LIFECYCLES.Inactive),
    getExport: async () => await getLifecycleExport(CUSTOMER_LIFECYCLES.Inactive)
  }
}

export {
  getLifecycleTotals,
  lifecycles
}
