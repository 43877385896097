import axios from 'axios'
import { callApi, tryParseJSON, generateUrl } from '@/globals/utils'
import { differenceInSeconds } from '@/globals/time'
import store from '@/store'

const setLocalStorage = (key, value = null) => {
  if (value === null) {
    localStorage.removeItem(key)
  } else {
    localStorage.setItem(key, value)
  }
}
const setLocalStorageSSOKey = (prefixString, value) => {
  const { user } = store.state.user
  const now = new Date()
  const json = JSON.stringify({
    value,
    time: now.toJSON()
  })
  if (user.authedSso) setLocalStorage(`${prefixString}-${user.authedSso}`, json)
}

export const baseUrl = process.env.VUE_APP_API_BASE_URL || ''

export const getAppsUrl = () => 'https://apps.rexeltools.com/'

export const getEndpoints = () =>
  axios.get('/api/endpoints')

export const getCountries = () =>
  axios.get('/api/geography/countries')

export const getCitiesForPostalCode = (country, postalCode) =>
  axios.get(`/api/geography/city-for-postalcode?country=${country}&postalCode=${postalCode}`)

export const getStates = (country) =>
  axios.get(`/api/geography/country-subdivisions?country=${country}`)

export const getDefinitions = () =>
  callApi({ url: '/api/definitions' })

export const getEmployeeSkills = () =>
  axios.get('/api/employee-skills')

export const getWhatsNewItems = () =>
  axios.get('/api/whats-new')

export const saveEmployeeSkills = (data) =>
  axios.post('/api/employee-skills', data)

export const getDashboardSections = (options = {}) => {
  const { name, branch, region, sso, useCache, excludeUserStats } = options
  const url = `/api/dashboard-sections/${name}`
  const params = {
    params: {
      branchNumber: branch,
      regionCode: region,
      useCache,
      sso,
      excludeUserStats: excludeUserStats || false
    }
  }

  return axios.get(url, params)
}

export const getDashboardData = (options = {}) => {
  const { branch, region, sso, excludeUserStats, useCache } = options
  let url = `/api/dashboard?excludeUserStats=${excludeUserStats || false}`
  if (branch) {
    url += `&branch=${branch}`
  } else if (region) {
    url += `&region=${region}`
  } else if (sso) {
    url += `&sso=${sso}`
  }
  if (useCache === false) {
    url += '&useCache=false'
  }
  return axios.get(url)
}

export const getDashboardDataSimplified = (setSSO) => {
  let url = '/api/simplified-dashboard'
  if (setSSO) {
    url += `?sso=${setSSO}`
  }
  return axios.get(url)
}

export const updateSimplifiedDashBoardSalesGoals = (data) => {
  return axios.post('/api/simplified-dashboard/simple-dash-goals-insert-update', data)
}

export const getDashboardBranchDetails = (region) => {
  return axios.get(`/api/dashboard/branches?region=${region}`)
}

export const getSalesRevenueExportLink = (options = {}) => {
  const { branch, region, sso } = options
  const url = '/api/export/sales-revenue'
  if (branch) {
    return generateUrl(url, { branch })
  } else if (region) {
    return generateUrl(url, { region })
  } else if (sso) {
    return generateUrl(url, { sso })
  }
  return generateUrl(url)
}

export const setViewAsSSO = (sso = null) => {
  setLocalStorageSSOKey('viewAsUser', sso)
}

export const getViewAsSSO = (sso = null) => {
  const { user } = store.state.user
  const viewAsObj = localStorage.getItem(`viewAsUser-${sso || user.authedSso}`)
  if (viewAsObj) return JSON.parse(viewAsObj).value
  return null
}

export const removeViewAsSSO = () => setViewAsSSO()

export const getPriorDaySalesReport = () =>
  axios.get('/api/simplified-dashboard/prior-day-sales-employee')

export const getMonthlySalesSummaryReport = () =>
  axios.get('/api/simplified-dashboard/current-month-sales-summary')

export const getQuarterlySalesSummaryReport = () =>
  axios.get('/api/simplified-dashboard/qtd-sales-summary')

export const getSimplifiedDashboardInvoices = (period) => {
  return axios.get(`/api/simplified-dashboard/simple-dashboard-invoices?period=${period}`)
}

export const clearStaleLocalStorage = () => {
  // Selectively clear out localStorage selectedBanner and viewAsUser data.
  // Data is cleared if:
  //  - key does NOT contain current user's sso
  //  - AND time is older than staleStateAge

  const staleStateAge = 300 // in secs (5 mins)
  const { user } = store.state.user
  const now = new Date()

  Object.keys(localStorage).forEach((key) => {
    const value = tryParseJSON(localStorage[key])
    if (!key.includes(user.authedSso) && value && value.time) {
      if (differenceInSeconds(now, new Date(value.time)) > staleStateAge) {
        localStorage.removeItem(key)
      }
    }
  })
}
