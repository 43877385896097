const Dashboard = () => import('@/pages/Dashboard/Dashboard.vue')
const People = () => import('@/pages/People/People.vue')
const PeopleTab = () => import('@/pages/People/PeopleTab/PeopleTab.vue')
const GroupsTab = () => import('@/pages/People/GroupsTab/GroupsTab.vue')
const PeopleGroupForm = () => import('@/pages/People/PeopleGroupForm.vue')
const PeopleGroupView = () => import('@/pages/People/PeopleGroupView.vue')
const BranchPeople = () => import('@/pages/People/BranchPeople.vue')
const SalesStatsReport = () => import('@/pages/Reports/SalesStatsReport/SalesStatsReport.vue')

/*
  Routes for all People, Groups pages
*/

export const peopleRoutes = [
  {
    path: 'people',
    component: People,
    children: [
      {
        path: '',
        name: 'people',
        redirect: { name: 'people--people-tab' }
      },
      {
        path: 'people',
        name: 'people--people-tab',
        meta: { title: 'People' },
        component: PeopleTab
      },
      {
        path: 'groups',
        name: 'people--groups-tab',
        component: GroupsTab,
        meta: { title: 'Groups' }
      }
    ],
    meta: {
      title: 'People'
    }
  },
  {
    path: 'dashboard/sales-person/:sso?',
    name: 'salesperson-dashboard',
    component: Dashboard,
    meta: {
      title: 'DashBoard'
    }
  },
  {
    path: 'people/employee/:sso',
    name: 'people-sales-stats',
    component: SalesStatsReport,
    meta: {
      title: 'Sales Stats'
    }
  },
  {
    path: 'people/branch/:id',
    name: 'branch-people',
    component: BranchPeople,
    meta: {
      title: 'Branch People'
    }
  },
  {
    path: 'people/group/:id',
    name: 'people-group',
    component: PeopleGroupView,
    meta: {
      title: 'People Group'
    }
  },
  {
    path: 'people/group/create',
    name: 'people-group-create',
    component: PeopleGroupForm,
    meta: {
      title: 'Create People Group'
    }
  },
  {
    path: 'people/group/:id/edit',
    name: 'people-group-edit',
    component: PeopleGroupForm,
    meta: {
      title: 'People Group Form'
    }
  }
]
