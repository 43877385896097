<template>
  <base-dialog
    width="100%"
    :show="isOpen"
    :valid="isValid && !isLoading"
    :submit-label="submitButtonText"
    :submitting="isLoading"
    @submit="submit"
    @close="close">
    <template #title>
      {{ title }}
    </template>
    <template #content>
      <div>
        <v-textarea
          v-model="form.comment"
          :rules="rules.comment"
          rows="4"
          filled
          :label="commentsFieldLabel">
        </v-textarea>
      </div>
      <div class="attachments">
        <v-expansion-panels flat>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span class="font-weight-bold">
                Attached Files ({{ form.uploads.length }})
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="upload">
                <vue2-dropzone
                  id="supportFeedbackDropzone"
                  ref="dropzone"
                  :options="dropzoneOptions"
                  :include-styling="false"
                  :use-custom-slot="true"
                  class="mb-4"
                  @vdropzone-file-added="fileAdded">
                  <v-card
                    color="transparent"
                    flat>
                    <v-card-text
                      class="text-center"
                      :class="{ 'border-dashed': $vuetify.breakpoint.smAndUp }">
                      <template v-if="$vuetify.breakpoint.smAndUp">
                        <div
                          class="text-h6">
                          Drag & Drop Files
                        </div>
                        <div>or</div>
                      </template>
                      <v-btn
                        :block="$vuetify.breakpoint.xs"
                        outlined>
                        Select
                      </v-btn>
                    </v-card-text>
                  </v-card>
                </vue2-dropzone>
                <v-alert
                  v-if="rejected.length > 0"
                  class="rejected-alert my-2"
                  color="error">
                  <span class="white--text">
                    {{ rejected.length }} files did not upload ({{ rejectedItems }})
                  </span>
                </v-alert>
                <file-list
                  v-if="form.uploads.length > 0"
                  v-model="form.uploads"
                  @removeToken="removeToken">
                </file-list>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </template>
  </base-dialog>
</template>

<script>
// External libraries
import { mapGetters, mapState } from 'vuex'
import vue2Dropzone from 'vue2-dropzone'

// Components
import BaseDialog from '@/components/BaseDialog.vue'
import FileList from '@/components/SupportFeedback/FileList.vue'

// Constants
import { UPLOAD_STATUSES } from '@/components/SupportFeedback/upload-statuses'

// Services / Rules
import { uploadFeedbackFile, submitFeedback } from '@/components/SupportFeedback/support-feedback'
import { isRequired } from '@/rules'

export default {
  name: 'SupportFeedback',
  components: {
    vue2Dropzone,
    BaseDialog,
    FileList
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    successToastMessage: {
      type: String,
      required: false,
      default: 'Feedback submitted successfully.'
    },
    commentsFieldLabel: {
      type: String,
      required: false,
      default: 'Questions/Comments'
    },
    title: {
      type: String,
      required: false,
      default: 'Have a problem?'
    },
    submitButtonText: {
      type: String,
      required: false,
      default: 'Send'
    }
  },
  data () {
    return {
      form: {
        comment: '',
        uploads: []
      },
      rules: {
        comment: [
          isRequired('This field is required.')
        ]
      },
      tokens: [],
      isLoading: false
    }
  },
  computed: {
    ...mapState('user', ['accessToken']),
    ...mapGetters('user', ['authenticatedUser']),
    isValid () {
      return this.form.comment.trim().length > 0 && this.rejected.length === 0
    },
    dropzoneOptions () {
      return {
        url: '/null', // Dropzone doesn't work without this :(
        autoProcessQueue: false,
        maxFiles: 5,
        acceptedFiles: '.jpeg,.jpg,.png,.img,.bmp,.txt,.pdf,.doc,.docx,.odt,.wpd',
        previewTemplate: '<div></div>'
      }
    },
    isOpen: {
      get () {
        return this.value
      },
      set (value) {
        if (!value) {
          this.form.comment = ''
          this.form.uploads = []
        }
        this.$emit('input', value)
      }
    },
    rejected () {
      return this.form.uploads.filter(upload => upload.status === UPLOAD_STATUSES.failed)
    },
    rejectedItems () {
      const names = this.rejected.map(file => file.data.name)
      return names.join(', ')
    }
  },
  methods: {
    removeToken (token) {
      this.tokens = this.tokens.filter(t => t !== token)
    },
    setToastMessage (message, status = 'error') {
      this.$store.commit('setToastMessage', {
        message,
        status
      })
    },
    fileAdded (file) {
      const { name, size, type } = file
      const { uploads } = this.form

      if (uploads.length >= this.dropzoneOptions.maxFiles) {
        this.setToastMessage('Maximum of 5 files can be uploaded.')
        return
      }

      if (size <= 0) {
        console.warn('file is empty', file)
        return
      }

      if (uploads.some(f => this.fileAlreadySelected(f.data, file))) {
        console.warn('file already selected', file)
        return
      }
      const upload = {
        data: {
          name,
          size,
          type
        },
        file,
        status: UPLOAD_STATUSES.ready,
        token: null
      }
      this.form.uploads.push(upload)
    },
    fileAlreadySelected (a, b) {
      return a.name === b.name && a.size === b.size && a.type === b.type
    },
    close () {
      this.isOpen = false
    },
    removeFile (fileToRemove) {
      this.form.uploads = this.form.uploads.filter(file => {
        return file !== fileToRemove
      })
    },
    async submit () {
      if (!this.isValid || this.isLoading) {
        return
      }
      this.isLoading = true
      for (const upload of this.form.uploads.filter(file => file.status === UPLOAD_STATUSES.ready)) {
        upload.status = UPLOAD_STATUSES.processing
        try {
          const { data: { token } } = await uploadFeedbackFile(upload.file)
          upload.status = UPLOAD_STATUSES.success
          upload.token = token
          this.tokens.push(token)
        } catch (error) {
          console.error(error)
          upload.status = UPLOAD_STATUSES.failed
        }
      }
      if (this.rejected.length > 0) {
        this.isLoading = false
        return
      }
      try {
        const request = this.buildSubmitRequest()
        await submitFeedback(request)
        this.setToastMessage(this.successToastMessage, 'success')
        this.close()
      } catch (error) {
        console.error(error)
        this.setToastMessage('Failed to submit feedback.')
      }
      this.isLoading = false
    },
    buildSubmitRequest () {
      return {
        subject: 'Sales Central Feedback',
        comments: this.form.comment,
        fullName: this.authenticatedUser.name,
        email: this.authenticatedUser.email,
        url: window.location.href,
        uploads: this.tokens
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #eeeeee !important;
  .v-expansion-panel-content__wrap {
    padding: 0 24px 0;
  }
}
.border-dashed {
  border: 1px dashed #666666;
}
.rejected-alert {
  border-radius: 0;
}
</style>
