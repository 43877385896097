<template>
  <div>
    <template v-if="isComparison">
      <v-row
        class="fluid align-stretch mb-1">
        <v-col
          v-for="(id) in ids"
          :key="`entitySummary-${id}`"
          cols="12"
          :md="isDouble ? '6' : '4'"
          class="flex-grow-1">
          <div
            class="entity-summary-color-bar"
            :style="`background-color: ${colorById(id)}`">
          </div>
          <customer-summary
            v-if="entityType === 'customer'"
            class="fill-height"
            :customer-id="id"
            linked
            @loaded="loaded">
          </customer-summary>
          <branch-summary
            v-if="entityType === 'branch'"
            class="fill-height"
            :branch-id="id"
            @loaded="loaded">
          </branch-summary>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <customer-summary
        v-if="entityType === 'customer'"
        :customer-id="singleId"
        class="mb-4"
        linked
        @loaded="loaded">
      </customer-summary>
      <branch-summary
        v-if="entityType === 'branch'"
        :branch-id="singleId"
        class="mb-4"
        @loaded="loaded">
      </branch-summary>
    </template>
  </div>
</template>

<script>
import { CustomerSummary } from '@/components/CustomerSummary'
import BranchSummary from '@/components/BranchSummary.vue'

export default {
  name: 'EntitySummary',
  components: {
    CustomerSummary,
    BranchSummary
  },
  props: {
    entityType: {
      type: String,
      required: true,
      default: null
    },
    ids: {
      type: Array,
      required: true,
      default: () => []
    },
    colorById: {
      type: Function,
      required: false,
      default: () => ''
    }
  },
  data () {
    return {
      info: null,
      loading: false,
      loadedCount: 0
    }
  },
  computed: {
    isComparison () {
      return this.ids.length > 1
    },
    isDouble () {
      return this.ids.length === 2
    },
    singleId () {
      return this.ids[0]
    }
  },
  methods: {
    loaded () {
      // emit 'loaded' when all summaries have been loaded
      this.loadedCount++
      if (this.loadedCount === this.ids.length) this.$emit('loaded')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~styl/colors';

.entity-summary-color-bar {
  height: 4px;
}
</style>
