<template>
  <div>
    <h2
      v-if="!hideTitle"
      class="header-text mb-2">
      Branch Info
    </h2>
    <v-card>
      <v-list
        v-if="customer"
        two-line>
        <v-list-item>
          <v-list-item-avatar>
            <v-icon color="grey">
              business
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-subtitle>
              Branch: {{ branch | formatBranchName }}
            </v-list-item-subtitle>
            <v-list-item-title>
              Mgr: <anchor
                bold
                :href="`mailto:${customer.branchManagerEmail}`">
                {{ customer.branchManagerName }}
              </anchor>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <optional-expansion-panel
          bottom
          flat
          :enabled="$vuetify.breakpoint.xs">
          <template v-for="(person, index) in insideSalespeople">
            <div :key="`${person.winNt}-${index}`">
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon color="grey">
                    person
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-subtitle>Inside Salesperson</v-list-item-subtitle>
                  <v-list-item-title>
                    <span v-if="!person.name">
                      None Assigned
                    </span>
                    <anchor
                      v-else
                      bold
                      :href="`mailto:${person.email}?subject=${mailToSubject}`">
                      {{ person.name }}
                    </anchor>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
            </div>
          </template>

          <template v-for="person in outsideSalespeople">
            <div :key="person.winNt">
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon color="grey">
                    person
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    Outside Salesperson
                  </v-list-item-subtitle>
                  <v-list-item-title>
                    <anchor
                      bold
                      :href="`mailto:${person.email}?subject=${mailToSubject}`">
                      {{ person.firstName }} {{ person.lastName }}
                    </anchor>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
            </div>
          </template>

          <v-list-item>
            <v-list-item-avatar>
              <v-icon color="grey">
                person
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-subtitle>Credit Rep</v-list-item-subtitle>
              <v-list-item-title>
                <anchor
                  bold
                  :href="`mailto:${customer.creditRepEmail || defaultCreditRepEmail}?subject=${mailToSubject}`">
                  {{ customer.creditRep || 'None Assigned' }}
                </anchor>
              </v-list-item-title>
              <v-list-item-subtitle>
                <anchor
                  bold
                  class="d-flex align-center"
                  @click="emailCreditLinkDialogIsOpen = true">
                  <v-icon
                    color="info"
                    class="shrink mr-1">
                    email
                  </v-icon>
                  Email Credit Application
                </anchor>
                <v-dialog
                  v-model="emailCreditLinkDialogIsOpen"
                  max-width="760"
                  @keydown.esc="emailCreditLinkDialogIsOpen = false">
                  <v-card
                    justify-self-center>
                    <v-toolbar
                      flat
                      dark
                      color="primary">
                      <v-toolbar-title>
                        Send Credit Application Via Email
                      </v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-btn
                        icon
                        dark
                        @click="emailCreditLinkDialogIsOpen = false">
                        <v-icon>close</v-icon>
                      </v-btn>
                    </v-toolbar>
                    <v-card-text>
                      <v-form v-model="customerEmailIsValid">
                        <v-text-field
                          v-model="customerEmail"
                          label="Customer Email"
                          filled
                          :rules="rules">
                        </v-text-field>
                      </v-form>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn @click="emailCreditLinkDialogIsOpen = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        class="primary"
                        :disabled="!customerEmailIsValid"
                        @click="emailCreditApplication">
                        Send
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon color="grey">
                attach_money
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-subtitle>Account Type</v-list-item-subtitle>
              <v-list-item-title>
                {{ customer.accountType }} {{ taxExemptStatus }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider v-if="isPlattBanner"></v-divider>
          <v-list-item v-if="isPlattBanner">
            <v-list-item-avatar>
              <v-icon color="grey">
                event
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-subtitle>Resale Certification Date</v-list-item-subtitle>
              <v-list-item-title>
                {{ customer.resaleExpirationDate | formatDate }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </optional-expansion-panel>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Anchor from '@/components/Anchor.vue'
import OptionalExpansionPanel from '@/components/OptionalExpansionPanel.vue'
import { isRequired, isEmail } from '@/rules'

export default {
  name: 'BranchInfo',
  components: {
    Anchor,
    OptionalExpansionPanel
  },
  props: {
    hideTitle: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      customerEmail: null,
      customerEmailIsValid: false,
      emailCreditLinkDialogIsOpen: false,
      rules: [
        isRequired('Email is required'),
        isEmail('Must be a valid email address')
      ]
    }
  },
  computed: {
    ...mapGetters('user', ['user', 'banner', 'isPlattBanner']),
    ...mapState('currentCustomer', ['customer']),
    ...mapState('branches', ['branches']),
    branch () {
      return this.branches.find((x) => x.branchId === this.customer.branchId)
    },
    defaultCreditRepEmail () {
      return this.banner === 'platt'
        ? 'credit@platt.com'
        : 'customeradminrequests@rexelusa.com' // Also good for Gexpro
    },
    insideSalespeople () {
      const { insideSalespeople } = this.customer
      // TODO: fix this hack
      if (!insideSalespeople || insideSalespeople.length === 0) {
        return [{ name: null }]
      }
      return insideSalespeople.filter((x) => x.name)
    },
    outsideSalespeople () {
      const { outsideSalespeople } = this.customer
      return outsideSalespeople.filter((x) => x.name)
    },
    mailToSubject () {
      const { name, accountId } = this.customer
      return encodeURIComponent(`${name} - ${accountId}`)
    },
    taxExemptStatus () {
      return this.customer.taxExemptFlag === true ? ' - Tax Exempt' : ''
    }
  },
  methods: {
    emailCreditApplication () {
      if (this.banner === 'platt') {
        window.location.href = `mailto:${
          this.customerEmail
        }?subject=Invitation to Platt Credit App&body=https://creditapp.rexeltools.com/platt?notify=${this.user.email}`
      } else if (this.banner === 'rexel') {
        window.location.href = `mailto:${
          this.customerEmail
        }?subject=Invitation to Rexel Credit App&body=https://creditapp.rexeltools.com/rexel?notify=${this.user.email}`
      } else if (this.banner === 'gexpro') {
        window.location.href = `mailto:${
          this.customerEmail
        }?subject=Invitation to Gexpro Credit App&body=https://creditapp.rexeltools.com/gexpro?notify=${this.user.email}`
      }
      this.emailCreditLinkDialogIsOpen = false
    }
  }
}
</script>
