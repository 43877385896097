import { use } from 'vue/types/umd'
import { Banner, BANNERS, STOREFRONT_BANNERS } from './constants'
import { BannerDetails } from './models'

class BannerProvider {
  private initialized = false
  private banners: BannerDetails[] = []
  private _bannersMap: Map<Banner, BannerDetails> = new Map()
  private _bannerIdMapping: Record<number, Banner> = {}

  get bannersMap () {
    if (!this.initialized) throw new Error('Attempt to use BannerProvider before it\'s initialized. Ensure provider is initialized before use.')

    return this._bannersMap
  }

  get bannerIdMapping () {
    if (!this.initialized) throw new Error('Attempt to use BannerProvider before it\'s initialized. Ensure provider is initialized before use.')

    return this._bannerIdMapping
  }

  public init (banners: BannerDetails[]) {
    if (!banners) {
      throw new Error('Cannot initialize banner provider, banners is null or undefined')
    }

    this.banners = banners
    banners.forEach((bannerDetail) => {
      this._bannersMap.set(bannerDetail.bannerCode, bannerDetail)
      this._bannerIdMapping[bannerDetail.bannerId] = bannerDetail.bannerCode
    })

    this.initialized = true
  }

  /** Retrieves the full banner details for the provided banner code
   * Throws if banner is not found
  */
  public getDetails (banner: Banner) {
    if (!this.bannersMap.has(banner)) {
      throw new Error(`Invalid banner provided to BannerProvider.getDetails(). Banner: "${banner}"`)
    }

    return this.bannersMap.get(banner)!
  }

  /** Safely retrieves the appropriate banner code for the provided companyId/bannerId
   * Returns null if bannerId is not found
  */
  public tryGetCode (bannerId: number): Banner | null {
    if (!(bannerId in this.bannerIdMapping)) {
      return null
    }

    return this.bannerIdMapping[bannerId]
  }

  /** Retrieves the appropriate banner code for the provided companyId/bannerId
   * Throws if bannerId is not found
  */
  public getCode (bannerId: number): Banner {
    if (!(bannerId in this.bannerIdMapping)) {
      throw new Error(`companyId does not map to a known Banner in BannerProvider.getCode(). CompanyId: "${bannerId}"`)
    }

    return this.bannerIdMapping[bannerId]
  }

  /** Safely retrieves the appropriate bannerId/companyId for the provided bannerCode
   * Returns -1 if banner not found
  */
  public tryGetId (banner: string, fallback = -1) {
    if (!this.bannersMap.has(banner as any)) {
      return fallback
    }

    return this.getDetails(banner as Banner).bannerId
  }

  /** Retrieves the appropriate bannerId/companyId for the provided bannerCode
   * Throws if banner is not found
  */
  public getId (banner: Banner) {
    return this.getDetails(banner).bannerId
  }

  /** Retrieves the display-friendly name of the banner */
  public getName (banner: Banner) {
    return this.getDetails(banner).bannerName
  }

  /** Retrieves the display-friendly name of the banner */
  public getNameFromId (bannerId: number) {
    return this.getDetails(this.getCode(bannerId)).bannerName
  }

  public getUrl (banner: Banner) {
    return this.getDetails(banner).url
  }

  /** Retrieves the storefront matching BannerCodeEnum
   * This is used for GraphQl queries
   * Throws if banner not supported
   */
  public getStorefrontBannerCode (bannerArg: Banner | number): string {
    const code = this.tryGetStorefrontBannerCode(bannerArg)

    if (!code) {
      throw new Error(`No supported storefront banners for provided Id or code: "${bannerArg}"`)
    }

    return code
  }

  /** Safely retrieves the storefront matching BannerCodeEnum or NULL
   * This is used for GraphQl queries
   */
  public tryGetStorefrontBannerCode (bannerArg: Banner | number): string | null {
    let code = bannerArg
    if (typeof bannerArg === 'number') {
      code = this.getCode(bannerArg)
    }

    return STOREFRONT_BANNERS[code as Banner] ?? null
  }

  public isPlatt (banner: Banner | number) {
    return this.isBanner(banner, BANNERS.platt)
  }

  public isRexel (banner: Banner | number) {
    return this.isBanner(banner, BANNERS.rexel)
  }

  public isGexpro (banner: Banner | number) {
    return this.isBanner(banner, BANNERS.gexpro)
  }

  public isRexGex (banner: Banner | number) {
    const isGexpro = this.isGexpro(banner)
    const isRexel = this.isRexel(banner)
    return isGexpro || isRexel
  }

  private isBanner (bannerArg: Banner | number, banner: Banner) {
    if (typeof bannerArg === 'number') {
      return this.tryGetCode(bannerArg) === banner
    }

    return bannerArg === banner
  }
}

const bannerProvider = new BannerProvider()

export {
  bannerProvider
}
