<template>
  <v-dialog
    v-model="isShown"
    max-width="640px"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    :transition="$vuetify.breakpoint.smAndDown ? 'dialog-bottom-transition' : 'dialog-transition'"
    @keydown.esc="isShown = false">
    <v-toolbar
      flat
      dark
      color="primary">
      <v-toolbar-title>Login to Platt</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        icon
        dark
        @click="isShown = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card :class="{stacked: $vuetify.breakpoint.smAndDown}">
      <v-card-text>
        {{ message }}
      </v-card-text>
      <v-spacer v-if="$vuetify.breakpoint.smAndDown"></v-spacer>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn @click="isShown = false">
          Cancel
        </v-btn>
        <v-spacer v-if="!$vuetify.breakpoint.smAndDown"></v-spacer>
        <v-btn
          v-if="!isAdminLoggingIn"
          :href="customerSignInUrl"
          @click="deferDismissal">
          Customer Sign In
        </v-btn>
        <v-btn
          :href="adminSignInUrl"
          @click="deferDismissal">
          Admin Sign In
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import store from '@/store'

export default {
  name: 'PlattDotComSignInDialog',
  data () {
    return {
      isShown: false
    }
  },
  computed: {
    ...mapGetters({
      plattDotComSignInUrl: 'user/plattDotComSignInUrl',
      isAdminLoggingIn: 'user/isAdminLoggingIn'
    }),
    customerSignInUrl () {
      return `${this.plattDotComSignInUrl}&admin=false`
    },
    adminSignInUrl () {
      return `${this.plattDotComSignInUrl}&admin=true`
    },
    message () {
      return this.isAdminLoggingIn === true
        ? 'Would you like to login as an Administrator?'
        : 'Would you like to login as a Customer or an Administrator?'
    }
  },
  watch: {
    plattDotComSignInUrl (url, oldUrl) {
      if (url === oldUrl || url === null) return
      this.isShown = true
    },
    isShown (shown, wasShown) {
      if (shown !== wasShown && !shown) {
        store.commit('user/setPlattDotComSignInUrl', {
          plattDotComSignInUrl: null,
          isAdminLoggingIn: false
        })
      }
    }
  },
  methods: {
    // Defer dismissal until the next event loop to prevent the isShown watcher from
    // nullifying plattDotComSignInUrl as the user clicks on the login buttons.
    deferDismissal () {
      setTimeout(() => {
        this.isShown = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.v-card.stacked {
  display: flex;
  flex-direction: column;
  min-height: initial;
  height: calc(100% - 56px);

  .v-card__actions {
    flex-direction: column-reverse;
    align-items: stretch;

    .v-btn {
      margin-left: 0;
      margin-right: 0;

      &:not(:first-child) {
        margin-bottom: 8px;
      }
    }
  }
}
</style>
