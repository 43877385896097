<template>
  <page-loading-progress v-if="loading">
  </page-loading-progress>
  <v-container
    v-else
    fluid>
    <selected-cards
      v-if="!isMobile"
      :selected="selectedEntities"
      :entity-type="entityType"
      :entity-type-plural="entityTypePlural"
      :entity-id-prop-name="entityIdPropName"
      @remove="removeFromComparison">
      <template slot="button">
        <v-btn
          tile
          color="primary"
          :to="selectedEntities.length > 1 ? toCompare : toSingleEntity">
          {{ selectedEntities.length > 1 ? 'Compare' : 'View Report' }}
          <v-icon class="pl-1">
            arrow_forward
          </v-icon>
        </v-btn>
      </template>
    </selected-cards>

    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="4"
        class="mb-4">
        <v-text-field
          v-model="search"
          label="Search"
          append-icon="search"
          hide-details
          filled>
        </v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4">
        <select-filter
          :items="filterOptions"
          :selected.sync="selectedFilters"
          label="Filter by Region">
        </select-filter>
      </v-col>
    </v-row>

    <v-expand-transition mode="out-in">
      <chip-filters
        v-if="showChips"
        key-attr="value"
        label="Regions"
        :list="formattedSelectedFilters"
        :selected.sync="selectedFilters">
        <template
          slot="label">
          {{ entityType | formatTitleCase }} Filters:
        </template>
        <template
          slot-scope="{ item }">
          {{ item.text }}
        </template>
      </chip-filters>
    </v-expand-transition>
    <v-row class="pt-4">
      <v-col class="text-body-2">
        Select a {{ entityType | formatTitleCase }}
        <em class="hidden-xs-only">
          (Compare up to 3 at a time)
        </em>
      </v-col>
      <v-col class="table-legend">
        Sales from last 365
      </v-col>
    </v-row>
    <v-data-iterator
      :items="filteredEntities"
      :options.sync="pagination"
      :footer-props="{ 'items-per-page-options': [5, 10, 25] }"
      :search="search"
      :loading="loading"
      class="items-data-iterator"
      hide-default-header>
      <template #default="{ items }">
        <v-row no-gutters>
          <v-col
            v-for="item in items"
            :key="item[entityIdPropName]"
            cols="12">
            <selection-table-item-card
              :item="item"
              :item-key-prop="entityIdPropName"
              :selected="(selectedItemsHash[item[entityIdPropName]] || false)"
              :entity-type="entityType"
              :disabled="selectedItems.length >= 3 && !selectedItems.includes(item[entityIdPropName])"
              @selected="clickRow(item)"
              @deselected="clickRow(item)">
            </selection-table-item-card>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
  </v-container>
</template>

<script>
import EntityParams from '@/pages/Reports/shared/EntityParams.mixin'
import ChipFilters from '@/components/ChipFilters.vue'
import SelectFilter from '@/components/inputs/SelectFilter.vue'
import PageLoadingProgress from '@/components/PageLoadingProgress.vue'
import SelectedCards from './SelectedCards.vue'
import SelectionTableItemCard from './SelectionTableItemCard.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'SelectionTable',
  components: {
    ChipFilters,
    SelectFilter,
    PageLoadingProgress,
    SelectedCards,
    SelectionTableItemCard
  },
  mixins: [EntityParams],
  props: {
    entityType: {
      type: String,
      required: true,
      default: 'customer'
    },
    isSelected: {
      type: Boolean,
      required: true,
      default: false
    },
    childRoutes: {
      type: Object,
      required: true
    },
    entityIdPropName: {
      type: String,
      required: false,
      default: 'id'
    }
  },
  data () {
    return {
      loading: false,
      search: null,
      filterOptions: [],
      selectedFilters: [],
      pagination: {
        sortBy: ['name'],
        sortDesc: [false],
        itemsPerPage: 25
      },
      entities: [],
      selectedItems: [],
      selectedItemsHash: {}
    }
  },
  computed: {
    ...mapGetters('user', ['user', 'banner']),

    // Note: hiding headers in data-table for now
    headers () {
      return [
        {
          text: 'Compare',
          align: 'left',
          sortable: false
        },
        {
          text: `${this.entityType} Name`,
          align: 'left',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Sales',
          align: 'left',
          sortable: true,
          value: 'salesData'
        },
        {
          text: ' ', // Vuetify uses text as a key. This has to be a space to differentiate from the other empty header column
          sortable: false,
          value: ''
        }
      ]
    },
    isMobile () {
      return this.$vuetify.breakpoint.xs
    },
    formattedSelectedFilters () {
      return this.filterOptions.filter(({ text, value }) => {
        return this.selectedFilters.includes(value)
      })
    },
    showChips () {
      return !(this.selectedFilters.length === this.filterOptions.length ||
        this.selectedFilters.length === 0)
    },
    filteredEntities () {
      const entities = [...this.entities]

      // remove any duplicates
      const uniqueEntities = entities.filter((entity, index) => {
        const indexFind = entities.findIndex((entitySearch) => entitySearch.id === entity.id)
        if (indexFind < index) console.warn(`Duplicate ${this.entityType} found. Id = ${entity.id}`)
        return indexFind >= index
      })

      return this.showChips
        ? uniqueEntities.filter(entity => this.selectedFilters.includes(entity.filterProp))
        : uniqueEntities
    },
    selectedEntities () {
      return this.filteredEntities.filter((entity) =>
        this.selectedItems.includes(entity[this.entityIdPropName])
      )
    },
    toSingleEntity () {
      return {
        name: this.childRoutes.single,
        params: {
          entityType: this.entityType.toLowerCase(),
          id: this.selectedItems[0],
          ...this.childRoutes.firstLevel
        }
      }
    },
    toCompare () {
      return {
        name: this.childRoutes.compare,
        params: {
          entityType: this.entityType.toLowerCase(),
          ...this.childRoutes.firstLevel
        },
        query: { ids: this.selectedItems.join(',') }
      }
    }
  },
  watch: {
    filteredEntities (newVal) {
      this.pagination.page = 1
    },
    isSelected (newVal) {
      if (!newVal) {
        this.selectedItems = []
      }
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    changeSort (column) {
      if (this.pagination.sortBy === column) {
        this.pagination.sortDesc = !this.pagination.sortDesc
      } else {
        this.pagination.sortBy = column
        this.pagination.sortDesc = false
      }
    },

    fetchData () {
      this.loading = true
      const { fetch, accessor, filter, mapping, setupFilters, filterOptions } = this.entityDataParams
      fetch()
        .then((res) => {
          let entities = accessor ? accessor(res.data) : res.data
          entities = filter ? filter(entities) : entities
          entities = entities.map(mapping)
          this.entities = entities
          if (filterOptions || setupFilters) {
            this.filterOptions = filterOptions || setupFilters(res.data)
          }
        })
        .catch((e) => {
          this.$store.commit('setToastMessage', {
            message: 'Error fetching data'
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    clickRow (entity) {
      if (this.selectedItems.includes(entity[this.entityIdPropName])) {
        // Remove item
        this.selectedItems = this.selectedItems.filter((entityId) => entityId !== entity[this.entityIdPropName])
        this.selectedItemsHash[entity[this.entityIdPropName]] = false
      } else {
        if (this.selectedItems.length < 3) {
          // Add Item
          this.selectedItems.push(entity[this.entityIdPropName])
          this.selectedItemsHash[entity[this.entityIdPropName]] = true
        }
      }
      if (this.isMobile) {
        this.$router.push(this.toSingleEntity)
      }
    },
    removeFromComparison (branchId) {
      this.selectedItems = this.selectedItems.filter(
        (entityId) => entityId !== branchId
      )
      this.selectedItemsHash[branchId] = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~styl/colors';

.table-border-light {
  border-top: 1px solid $pastelGray;

  & ::v-deep .v-table__overflow {
    border-left: 1px solid $pastelGray;
    border-right: 1px solid $pastelGray;
  }
}

.items-data-iterator {
  border: 1px solid $pastelGray;
}
.row-item-card {
  border-bottom: 1px solid $pastelGray;

  &.active {
    background-color: $isabelline;
  }
}

.checkbox {
  width: 24px;
}
</style>
