<template>
  <v-dialog
    v-model="syncedAtRiskEmailDialog"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    scrollable
    width="760px"
    label="At Risk Email"
    @keydown.esc="closeEmailForm()">
    <v-card class="at-risk-dialog-card">
      <v-toolbar
        flat
        dark
        color="primary">
        <v-toolbar-title>At Risk Email</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          icon
          dark
          @click="closeEmailForm()">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-col>
            <v-select
              v-model="atRiskEmail.to"
              :items="atRiskEmailToOptions"
              label="To">
            </v-select>
            <v-text-field
              v-model="atRiskEmail.subject"
              label="Subject">
            </v-text-field>
            <v-textarea
              v-model="atRiskEmail.body"
              light
              background-color="#fff"
              auto-grow
              label="Message"
              filled>
            </v-textarea>
            <v-checkbox
              v-model="sendToMe"
              :label="`Email me a copy`">
            </v-checkbox>
          </v-col>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions :class="{stacked: $vuetify.breakpoint.smAndDown}">
        <v-spacer v-if="!$vuetify.breakpoint.smAndDown"></v-spacer>
        <file-uploader
          input-label="Add File"
          :on-upload="uploadDocument"
          :on-remove="removeDocument">
        </file-uploader>
        <v-btn
          class="no-text-transform"
          text
          @click="closeEmailForm()">
          Close
        </v-btn>
        <v-btn
          class="no-text-transform"
          color="primary"
          @click="sendAtRiskEmail">
          Send
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { getWelcomeEmailRecipientList, postAtRiskEmail, fetchCustomer, createActivityAfterAtRiskEmailSent } from '@/api/customers'
import FileUploader from '@/components/FormFileUploader.vue'
import { bannerProvider } from '@/features/banners'
import { mapGetters } from 'vuex'

export default {
  name: 'SendAtRiskEmail',
  components: {
    FileUploader
  },
  props: {
    customerId: {
      type: [Number, String],
      required: true
    },
    userName: {
      type: String,
      required: true
    },
    userEmail: {
      type: String,
      required: true
    },
    atRiskEmailDialog: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      atRiskEmail: {
        to: '',
        subject: '',
        body: ''
      },
      atRiskEmailToOptions: [],
      selectedFile: null,
      customer: {},
      sendToMe: false
    }
  },
  computed: {
    ...mapGetters('user', ['banner']),
    formattedBranchPhone () {
      return this.customer.branchTelephone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
    },
    syncedAtRiskEmailDialog: {
      get: function () {
        return this.atRiskEmailDialog
      },
      set: function (value) {
        this.$emit('update:atRiskEmailDialog', value)
      }
    },
    bannerInfo () {
      return bannerProvider.getDetails(this.banner)
    }
  },
  mounted () {
    this.getEmailAddressesForCustomer()
    this.getCustomerInfo()
  },
  methods: {
    setAtRiskEmailFields () {
      this.atRiskEmail.from = ''
      this.atRiskEmail.body = `${this.customer.name},\n\nThank you for being our customer! We appreciate your business and want to let you know about some new promotions that you might be interested in. Visit us at ${this.bannerInfo.url} where you can login, manage your account information, and order online. Need help? We'd love to hear from you. Please reach out to your ${this.customer.branchName} ${this.bannerInfo.bannerName} Branch at ${this.formattedBranchPhone}.
      \nAgain, we do appreciate you being a valued customer of ours and we look forward to helping you with all your electrical product needs.
      \nIf you have any questions please call us here at the branch or email me at ${this.userEmail}.\n\nThank You, \n\n${this.userName}`
    },
    getEmailAddressesForCustomer () {
      getWelcomeEmailRecipientList(this.customerId)
        .then((response) => {
          this.atRiskEmailToOptions = response.data.map((x) => ({
            text: `${x.name} - (${x.emailAddress})`,
            value: x.emailAddress
          }))

          this.atRiskEmail.to = this.atRiskEmailToOptions[0].value
        })
        .catch((error) => {
          console.error(error)
        })
    },
    getCustomerInfo () {
      fetchCustomer(this.customerId)
        .then((response) => {
          this.customer = response.data
          this.setAtRiskEmailFields()
        })
    },
    sendAtRiskEmail () {
      const formData = new FormData()
      if (this.selectedFile) {
        formData.append('attachment', this.selectedFile)
      }
      formData.append('to', this.atRiskEmail.to)
      formData.append('from', `${this.userName} <${this.userEmail}>`)
      formData.append('subject', this.atRiskEmail.subject)
      formData.append('body', this.atRiskEmail.body)
      if (this.sendToMe) {
        formData.append('additionalTo', this.userEmail)
      }

      postAtRiskEmail(formData)
        .then((res) => {
          createActivityAfterAtRiskEmailSent(this.bannerInfo.bannerId, this.customerId)
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.$emit('closeAtRiskEmailForm')
        })

      this.selectedFile = null
    },
    uploadDocument (file) {
      this.selectedFile = file
    },
    removeDocument () {
      this.selectedFile = null
    },
    closeEmailForm () {
      this.$emit('closeAtRiskEmailForm')
    }
  }
}
</script>

<style lang="scss" scoped>
.v-btn .v-icon {
  font-size: 20px !important;

  &.chevron {
    position: relative;
    left: 4px;
  }
}

.no-text-transform {
  text-transform: none !important;
}

.at-risk-dialog-card .v-card__actions.stacked {
  flex-direction: column-reverse;
  align-items: stretch;

  button {
    margin-left: 0;
    margin-right: 0;

    &:not(:first-child) {
      margin-bottom: 8px;
    }
  }
}
</style>
