const states = [
  {
    text: 'Alabama',
    value: 'AL'
  },
  {
    text: 'Alaska',
    value: 'AK'
  },
  {
    text: 'American Samoa',
    value: 'AS'
  },
  {
    text: 'Arizona',
    value: 'AZ'
  },
  {
    text: 'Arkansas',
    value: 'AR'
  },
  {
    text: 'California',
    value: 'CA'
  },
  {
    text: 'Colorado',
    value: 'CO'
  },
  {
    text: 'Connecticut',
    value: 'CT'
  },
  {
    text: 'Delaware',
    value: 'DE'
  },
  {
    text: 'District Of Columbia',
    value: 'DC'
  },
  {
    text: 'Federated States Of Micronesia',
    value: 'FM'
  },
  {
    text: 'Florida',
    value: 'FL'
  },
  {
    text: 'Georgia',
    value: 'GA'
  },
  {
    text: 'Guam',
    value: 'GU'
  },
  {
    text: 'Hawaii',
    value: 'HI'
  },
  {
    text: 'Idaho',
    value: 'ID'
  },
  {
    text: 'Illinois',
    value: 'IL'
  },
  {
    text: 'Indiana',
    value: 'IN'
  },
  {
    text: 'Iowa',
    value: 'IA'
  },
  {
    text: 'Kansas',
    value: 'KS'
  },
  {
    text: 'Kentucky',
    value: 'KY'
  },
  {
    text: 'Louisiana',
    value: 'LA'
  },
  {
    text: 'Maine',
    value: 'ME'
  },
  {
    text: 'Marshall Islands',
    value: 'MH'
  },
  {
    text: 'Maryland',
    value: 'MD'
  },
  {
    text: 'Massachusetts',
    value: 'MA'
  },
  {
    text: 'Michigan',
    value: 'MI'
  },
  {
    text: 'Minnesota',
    value: 'MN'
  },
  {
    text: 'Mississippi',
    value: 'MS'
  },
  {
    text: 'Missouri',
    value: 'MO'
  },
  {
    text: 'Montana',
    value: 'MT'
  },
  {
    text: 'Nebraska',
    value: 'NE'
  },
  {
    text: 'Nevada',
    value: 'NV'
  },
  {
    text: 'New Hampshire',
    value: 'NH'
  },
  {
    text: 'New Jersey',
    value: 'NJ'
  },
  {
    text: 'New Mexico',
    value: 'NM'
  },
  {
    text: 'New York',
    value: 'NY'
  },
  {
    text: 'North Carolina',
    value: 'NC'
  },
  {
    text: 'North Dakota',
    value: 'ND'
  },
  {
    text: 'Northern Mariana Islands',
    value: 'MP'
  },
  {
    text: 'Ohio',
    value: 'OH'
  },
  {
    text: 'Oklahoma',
    value: 'OK'
  },
  {
    text: 'Oregon',
    value: 'OR'
  },
  {
    text: 'Palau',
    value: 'PW'
  },
  {
    text: 'Pennsylvania',
    value: 'PA'
  },
  {
    text: 'Puerto Rico',
    value: 'PR'
  },
  {
    text: 'Rhode Island',
    value: 'RI'
  },
  {
    text: 'South Carolina',
    value: 'SC'
  },
  {
    text: 'South Dakota',
    value: 'SD'
  },
  {
    text: 'Tennessee',
    value: 'TN'
  },
  {
    text: 'Texas',
    value: 'TX'
  },
  {
    text: 'Utah',
    value: 'UT'
  },
  {
    text: 'Vermont',
    value: 'VT'
  },
  {
    text: 'Virgin Islands',
    value: 'VI'
  },
  {
    text: 'Virginia',
    value: 'VA'
  },
  {
    text: 'Washington',
    value: 'WA'
  },
  {
    text: 'West Virginia',
    value: 'WV'
  },
  {
    text: 'Wisconsin',
    value: 'WI'
  },
  {
    text: 'Wyoming',
    value: 'WY'
  }
]

const validBanners = [
  'rexel',
  'platt',
  'gexpro',
  'capitol-light',
  'mayer',
  // these next banners are not currently in
  // use and, as such, may change in the future
  'gexproserv',
  'psc',
  'res'
]

/** The Lifecycles & Ids for the various Customer Lifecycles */
const CUSTOMER_LIFECYCLES = {
  Inactive: 1,
  AtHighRisk: 2,
  AtRisk: 3,
  MonthlyActive: 4,
  ThirdOrder: 5,
  FirstOrder: 6,
  ActivePlus: 7,
  Active: 8,
  NewCustomer: 9,
  Leads: 10
} as const

/** The Lifecycle text representations for each Id */
const CUSTOMER_LIFECYCLES_TEXT = Object.freeze({
  1: 'Inactive',
  2: 'At High Risk',
  3: 'At Risk',
  4: 'Monthly Active',
  5: 'Third Order',
  6: 'First Order',
  7: 'Active Plus',
  8: 'Active',
  9: 'New Customer',
  10: 'Lead'
})

/** Base URIs */
const BASE_URI = Object.freeze({
  ordersProd: 'https://orders.rexelapps.com',
  ordersDemo: 'https://demoorders.rexelusa.net',
  ordersTest: 'https://testorders.rexelapps.info'
})

/** Globally used query params used by the app  */
const QUERY_PARAMS = Object.freeze({
  banner: 'banner',
  viewAs: 'viewAs'
})

/** Storage keys used by the app for local storage  */
const STORAGE_KEYS = Object.freeze({
  selectedBanner: 'selectedBanner',
  viewAsUser: 'viewAsUser'
})

const USER_TYPES = {
  Unset: 'Unset',
  SuperUser: 'SuperUser',
  RVP: 'RVP',
  Custom: 'Custom',
  Mgr: 'Mgr',
  AsstMgr: 'AsstMgr',
  Branch: 'Branch',
  Sales: 'Sales'
} as const
type UserType = keyof typeof USER_TYPES
type UserTypeList = {
  userTypes?: UserType[]
}

export {
  states,
  validBanners,
  CUSTOMER_LIFECYCLES,
  CUSTOMER_LIFECYCLES_TEXT,
  BASE_URI,
  QUERY_PARAMS,
  STORAGE_KEYS,

  USER_TYPES,
  UserType,
  UserTypeList
}
