<template>
  <v-expansion-panels
    v-if="enabled"
    v-model="active"
    flat
    tile
    :class="[{ 'custom bottom flat': !defaultStyle }]">
    <v-expansion-panel class="">
      <v-expansion-panel-header class="expansion-header d-flex justify-center flex-grow-0 mx-auto">
        <slot name="header">
          <span class="font-weight-bold">
            {{ header }}
          </span>
        </slot>
        <template #actions>
          <v-icon class="ml-2">
            $expand
          </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <slot></slot>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
  <div v-else>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'OptionalExpansionPanel',
  props: {
    enabled: {
      type: Boolean,
      required: false,
      default: false
    },
    defaultStyle: {
      type: Boolean,
      required: false,
      defualt: false
    }
  },
  data () {
    return {
      active: null
    }
  },
  computed: {
    header () {
      return this.active === 0 ? 'See less' : 'See more'
    }
  }
}
</script>

<style lang="scss" scoped>

.expansion-header {
  width: auto;
}

.custom.v-expansion-panel {
  &.bottom {
    li {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  &.flat {
    box-shadow: none;
  }
}

</style>
