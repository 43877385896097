<template>
  <router-view></router-view>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  name: 'App',
  computed: {
    pageTitle () {
      return this.$route.meta.title || 'Sales'
    }
  },
  beforeMount () {
    const isTest = this.$root.$environment === 'Test'
    this.getCurrentEnvironment(isTest)
  },
  methods: {
    ...mapActions('environment', ['getCurrentEnvironment'])
  }
}
</script>
