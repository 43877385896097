<template>
  <v-expansion-panels
    flat
    tile>
    <v-data-iterator
      :items="items">
      <template #default="{ items }">
        <v-expansion-panel
          v-for="(item, i) in items"
          :key="i"
          class="elevation-0">
          <v-expansion-panel-header class="pa-3">
            <template #actions>
              <v-icon class="mr-3">
                $expand
              </v-icon>
            </template>
            <v-row
              v-if="titleHeader"
              class="header flex-nowrap">
              <v-col
                v-if="titleHeader.slot"
                :key="i"
                cols="10"
                class="d-flex align-center pa-2 ma-0">
                <slot
                  :name="titleHeader.slot"
                  :item="item">
                </slot>
              </v-col>
              <v-col
                v-else
                class="flex-shrink-1"
                cols="auto">
                <responsive-table-cell
                  :header="titleHeader"
                  :item="item"
                  :mobile-break-point="mobileBreakPoint">
                </responsive-table-cell>
              </v-col>
              <v-col
                v-if="actionHeader && actionHeader.slot"
                cols="auto"
                class="pa-3 ml-auto"
                @click.stop>
                <slot
                  :name="actionHeader.slot"
                  :item="item">
                </slot>
              </v-col>
            </v-row>
            <div
              v-else
              class="header">
              Default Header Title
            </div>
          </v-expansion-panel-header>

          <v-expansion-panel-content class="pa-0">
            <v-card
              tile
              flat>
              <v-card-text class="py-0">
                <responsive-table-mobile-layout
                  :headers="nonTitleHeaders"
                  :item="item"
                  :mobile-break-point="mobileBreakPoint"
                  :clickable-rows="clickableRows"
                  @selectRow="selectRow">
                  <template
                    v-for="(index, name) in $scopedSlots"
                    #[name]="data">
                    <slot
                      :name="name"
                      v-bind="data"></slot>
                  </template>
                </responsive-table-mobile-layout>
              </v-card-text>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </template>
    </v-data-iterator>
  </v-expansion-panels>
</template>

<script>
import ResponsiveTableMobileLayout from './ResponsiveTableMobileLayout.vue'
import ResponsiveTableCell from './ResponsiveTableCell.vue'

export default {
  name: 'ResponsiveExpansionPanel',

  components: {
    ResponsiveTableMobileLayout,
    ResponsiveTableCell
  },

  props: {
    headers: {
      type: [Array],
      required: true
    },
    items: {
      type: [Array],
      required: true
    },
    mobileBreakPoint: {
      type: String,
      required: false,
      default: 'xs'
    },
    clickableRows: {
      type: Boolean,
      required: false,
      default: false
    },
    actionHeader: {
      type: Object,
      required: false,
      default: () => null
    }
  },

  computed: {
    titleHeader () {
      return this.headers.find(header => header.title)
    },
    nonTitleHeaders () {
      return this.headers.filter(header => !header.title && header.type !== 'action')
    }
  },

  methods: {
    selectRow (item) {
      this.$emit('selectRow', item)
    }
  }
}
</script>

<style scoped lang="scss">
  @import "~styl/colors";
  .v-expansion-panel {
    border-bottom: 1px solid $pastelGray;
    &:last-child {
      border-bottom: none;
    }

    // Border doesn't seem to show up on the bottom of the expansion panel when it's open, I have no idea why. obscured by something?
    // So when it's open we make the next sibling have a top border
    // This causes artifacts when expanding though....
    &.v-expansion-panel--active + .v-expansion-panel {
      will-change: auto;
      border-top: 1px solid $pastelGray;
    }
  }

  .header-icon {
    order: 0;
  }

  .header {
    order: 1;
  }
</style>
