<template>
  <v-container
    class="p-relative"
    fluid>
    <v-overlay
      color="white"
      absolute
      :value="loading">
      <v-progress-circular
        color="black"
        indeterminate
        size="64">
      </v-progress-circular>
    </v-overlay>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col md="4">
                <v-text-field
                  v-model="search"
                  hide-details
                  append-icon="search"
                  label="Search"
                  filled
                  @keypress.enter="searchWeb()">
                </v-text-field>
              </v-col>
              <v-spacer></v-spacer>
              <slot name="controls">
              </slot>
              <v-col
                cols="1"
                class="text-right d-flex align-center justify-center">
                <v-btn
                  color="default"
                  fab
                  small
                  :disabled="!leads || !leads.length"
                  :loading="exportLoading"
                  @click="exportReport()">
                  <v-icon style="height: auto;">
                    file_download
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text class="p-relative">
            <v-data-table
              :headers="headers"
              :items="leads"
              :sort-by.sync="pagination.sortBy"
              :sort-desc.sync="pagination.sortDesc"
              :footer-props="{ 'items-per-page-options': [5, 10, 25] }"
              :search="search"
              :loading="loading">
              <template #item="{item}">
                <tr
                  class="clickable"
                  @click="goToLead(item)">
                  <td>{{ item.fullName }}</td>
                  <td>{{ item.email }}</td>
                  <td>{{ item.phone | formatPhone('') }}</td>
                  <td>{{ item.qualified | formatYesNo }}</td>
                  <td>{{ item.converted | formatYesNo }}</td>
                  <td>{{ item.customerName }}</td>
                  <td>{{ item.active | formatYesNo }}</td>
                  <td>
                    <v-menu
                      bottom
                      left
                      :close-on-content-click="true"
                      p>
                      <template #activator="{ on }">
                        <v-btn
                          icon
                          v-on="on">
                          <v-icon
                            data-qa="more_vert"
                            color="black">
                            more_vert
                          </v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          @click="createActivity(item)">
                          <v-list-item-title>Create Activity</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </td>
                </tr>
              </template>
              <template slot="no-data">
                No data
              </template>
              <!-- TEMP FIX - Awaiting Vuetify fix for issue: https://github.com/vuetifyjs/vuetify/issues/14405 -->
              <!-- eslint-disable -->
              <template v-slot:footer.page-text="props">
                {{ props.pageStart }}-{{ props.pageStop }} of {{ props.itemsLength.toLocaleString('en-us') }}
              </template>
              <!-- eslint-enable -->
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'LeadsReportBase',
  props: {
    leads: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: false,
      default: true
    },
    exportLoading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      headers: [
        {
          text: 'Lead Name',
          align: 'left',
          sortable: true,
          value: 'fullName'
        },
        {
          text: 'Email',
          align: 'left',
          sortable: true,
          value: 'email'
        },
        {
          text: 'Phone',
          align: 'left',
          sortable: true,
          value: 'phone'
        },
        {
          text: 'Qualified',
          align: 'left',
          sortable: true,
          value: 'qualified'
        },
        {
          text: 'Converted',
          align: 'left',
          sortable: true,
          value: 'converted'
        },
        {
          text: 'Customer Name',
          align: 'left',
          sortable: true,
          value: 'customerName'
        },
        {
          text: 'Deleted',
          align: 'left',
          sortable: true,
          value: 'active'
        },
        {
          sortable: false,
          slot: 'action',
          type: 'action'
        }
      ],
      pagination: {
        sortBy: 'fullName',
        sortDesc: false,
        itemsPerPage: 25
      },
      search: null
    }
  },
  computed: {
    syncedLoading: {
      get: function () {
        return this.loading
      },
      set: function (value) {
        this.$emit('update:loading', value)
      }
    }
  },
  methods: {
    exportReport () {
      this.$emit('export-report')
    },
    createActivity (customer) {
      this.$router.push({
        name: 'create-activity',
        query: {
          companyId: customer.companyId,
          customerId: customer.customerId,
          categoryId: 6 // Category Id of 6 is Future/Potential Customer category
        }
      })
    },
    goToLead (lead) {
      this.$router.push({ name: 'lead-profile', query: { leadId: lead.leadId } })
    }
  }
}
</script>
