<template>
  <div>
    <div class="font-weight-medium">
      Sales Totals by Month
    </div>
    <v-card>
      <v-card-text>
        <v-row
          align="center">
          <v-col
            cols="12"
            sm="3"
            md="3"
            lg="3"
            class="text-center">
            <chart-legend
              v-if="formattedChartData.datasets.length > 0"
              :legends="legends">
            </chart-legend>
          </v-col>
          <v-col
            class="bl-1"
            cols="12"
            sm="9"
            md="9"
            lg="9">
            <div class="pa-6">
              <line-chart
                v-if="formattedChartData.datasets.length > 0"
                :chart-data="formattedChartData"
                :chart-options="chartOptions"
                :height="chartHeight">
              </line-chart>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { formatMoney, formatDate } from '@/globals/formatters'
import { getMonths } from '@/globals/time'
import sortBy from 'lodash/sortBy'
import LineChart from '@/components/Charts/LineChart.vue'
import ChartLegend from '@/components/Charts/ChartLegend.vue'

export default {
  name: 'CategoryChart',
  components: {
    LineChart,
    ChartLegend
  },
  props: {
    chartData: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: { display: false }
        }
      },
      chartHeight: 300,
      dataKey: 'sales'
    }
  },
  computed: {
    colors () {
      const {
        graphPrimary,
        graphSecondary
      } = this.$vuetify.theme.currentTheme
      return [graphSecondary, graphPrimary]
    },
    legends () {
      return this.summedSalesByYear.map((salesYear) => {
        const { amount } = salesYear
        return { ...salesYear, amount: formatMoney(amount) }
      })
    },
    summedSalesByYear () {
      const { dataKey, colors } = this

      // sum data by latest rolling 12 and previous 12
      const sortedData = sortBy([...this.chartData], ['salesPeriod'])

      let summedData = sortedData
        .reduce((list, value) => {
          const valuesYear = this.getYear(value.salesPeriod)
          const valuesYearIndex = list.findIndex(
            (item) => item.title === valuesYear
          )

          if (valuesYearIndex !== -1) {
            const salesYearInList = list[valuesYearIndex]
            const updatedYearValues = {
              ...salesYearInList,
              amount: salesYearInList.amount + value[dataKey],
              dataPoints: salesYearInList.dataPoints + 1
            }
            list[valuesYearIndex] = updatedYearValues
          } else {
            list.push({
              title: valuesYear,
              amount: value[dataKey],
              dataPoints: 1
            })
          }
          return list
        }, [])
      const allYears = summedData.map((sumYear) => sumYear.title)
      const twoMostRecentYears = allYears.sort().slice(allYears.length - 2)
      if (allYears.length > 2) {
        summedData = summedData.filter((sumYear) => twoMostRecentYears.includes(sumYear.title))
      }

      const sortedSummedData = sortBy(summedData, ['title']).reverse()
      const sortedSummedColoredData = sortedSummedData.map((salesYear, index) => {
        return { ...salesYear, color: colors[index] }
      })
      return sortedSummedColoredData
    },
    formattedChartData () {
      const { dataKey, colors } = this
      if (this.chartData.length === 0) return { labels: [], datasets: [] }

      const sortedData = sortBy([...this.chartData], ['salesPeriod'])

      // create object keyed by year
      const dataObj = sortedData.reduce((items, item) => {
        const year = this.getYear(item.salesPeriod)
        if (items[year] === undefined) {
          items[year] = {
            year,
            data: []
          }
        }
        items[year].data.push(item[dataKey])
        return items
      }, {})

      // if there are more than 2 years of data, filter to last 2
      const allYears = Object.keys(dataObj)
      const twoMostRecentYears = allYears.sort().slice(allYears.length - 2)
      let filteredDataObj = dataObj
      if (allYears.length > 2) {
        filteredDataObj = allYears.reduce((list, year) => {
          if (twoMostRecentYears.includes(year)) {
            list[year] = dataObj[year]
          }
          return list
        }, {})
      }

      const dataMappedByEntity = Object.keys(filteredDataObj)
        .map((key) => filteredDataObj[key])
      const sortedMappedData = sortBy(dataMappedByEntity, ['year'])
      const datasets = sortedMappedData.reverse().map((item, index) => {
        const color = colors[index]
        return {
          label: item.year,
          borderColor: color,
          data: item.data,
          pointBorderColor: color,
          pointBackgroundColor: 'white',
          pointBorderWidth: 2,
          pointRadius: 3,
          borderWidth: 2
        }
      })

      return { labels: getMonths(), datasets }
    }
  },
  methods: {
    getYear (salesPeriod) {
      return Number.parseInt(formatDate(salesPeriod, 'YYYY'))
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
