<template>
  <div>
    <base-dialog
      :show="isShown"
      :valid="isValid"
      :has-remove="!isNew"
      remove-label="Delete"
      @close="close"
      @submit="save"
      @remove="isConfirmationDialogOpen = true">
      <template slot="title">
        {{ isNew ? 'Create' : 'Edit' }} Mission and Strategies
      </template>
      <v-form
        v-if="form"
        ref="form"
        slot="content"
        v-model="isValid"
        @keydown.native.enter="preventPageSubmit">
        <v-row>
          <v-col>
            <h3 v-if="customerTitle">
              {{ customerTitle }}
            </h3>
          </v-col>
        </v-row>
        <v-col v-if="form.createDate">
          <div class="table-legend pt-2">
            Created: {{ form.createDate | formatDate }}
          </div>
        </v-col>
        <v-select
          v-model="form.budgetYear"
          v-required
          :disabled="!isNew"
          :items="missionsYears"
          label="Mission Year"
          class="mb-4"
          hide-details
          filled>
        </v-select>
        <v-col>
          <div class="text-subtitle-1 mt-4">
            Mission Owner: {{ form.ownerName }}
          </div>
        </v-col>
        <v-autocomplete
          v-model="employeeToAssign"
          :loading="loadingEmployees"
          :items="employeesFiltered"
          :search-input.sync="searchEmployeesTerm"
          hide-no-data
          filled
          return-object
          class="append-icon-no-transform"
          label="Change Mission Owner"
          append-icon="search"
          hide-details>
        </v-autocomplete>
        <v-col></v-col>
        <v-textarea
          v-model="form.mission"
          v-required
          :rules="rules.textarea"
          label="Mission"
          rows="3"
          auto-grow
          filled>
        </v-textarea>
        <v-textarea
          v-model="form.goalsAndStrategies"
          v-required
          :rules="rules.textarea"
          label="Goals / Strategies"
          rows="3"
          auto-grow
          filled>
        </v-textarea>
        <v-textarea
          v-model="form.playBookComment"
          label="Sales Manager Comment"
          rows="3"
          auto-grow
          filled>
        </v-textarea>
        <v-text-field
          v-model="form.totalPotential"
          v-required
          label="Total Potential"
          :rules="rules.potential"
          filled>
        </v-text-field>
        <v-textarea
          v-model="form.competitiveInfo"
          label="Competitive Information"
          height="100"
          auto-grow
          filled>
        </v-textarea>
        <v-textarea
          v-model="form.comments"
          label="Other Comments"
          rows="3"
          auto-grow
          filled>
        </v-textarea>
      </v-form>
    </base-dialog>
    <confirmation-dialog
      :show="isConfirmationDialogOpen"
      title="Are you sure you want to delete this mission / strategy?"
      action-label="Delete"
      action-color="error"
      :confirm-action="deleteItem"
      :cancel-action="closeConfirmationDialog">
    </confirmation-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { isRequired, isNotBlank, isNumber, isReasonableNumber } from '@/rules'
import BaseDialog from '@/components/BaseDialog.vue'
import debounce from 'lodash.debounce'
import ConfirmationDialog from '@/components/ConfirmationDialog.vue'
import { updateMissionAndStrategies, deleteMissionAndStrategies } from '@/api/customers'
import { employeeSearchEndpoint } from '@/api/employees'

export default {
  name: 'MissionAndStrategyForm',
  components: {
    BaseDialog,
    ConfirmationDialog
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    item: {
      type: Object,
      required: false,
      default: () => {}
    },
    selectedCustomerId: {
      type: Number,
      required: false,
      default: null
    },
    customerTitle: {
      type: String,
      required: false,
      default: null
    },
    missionYear: {
      type: Number,
      default: () => new Date().getFullYear(),
      required: false
    }
  },
  data () {
    return {
      form: {},
      isShown: false,
      isValid: null,
      saving: false,
      deleting: false,
      employees: [],
      searchEmployeesTerm: null,
      employeeToAssign: null,
      loadingEmployees: false,
      rules: {
        textarea: [
          isRequired('Description is required'),
          isNotBlank('Description cannot be blank')
        ],
        potential: [
          isRequired('Numeric value is required'),
          isNumber('Must be a number'),
          isReasonableNumber('Number must be greater than zero, less than a trillion')
        ]
      },
      isConfirmationDialogOpen: false
    }
  },
  computed: {
    ...mapGetters('currentCustomer', ['customerId']),
    ...mapGetters('user', ['user']),
    isNew () {
      return this.item
        ? !this.item.sequenceId
        : true
    },
    currentYear () {
      return new Date().getFullYear()
    },
    missionsYears () {
      return Array(3)
        .fill()
        .map((z, index) => (this.currentYear - 1) + index)
    },
    employeesFiltered () {
      const employees = this.employees
      if (!employees || !employees.length) {
        return []
      }
      return employees
        .map(a => {
          return {
            text: `${a.name.displayName} (${a.jobTitle})`,
            value: a.employeeSso,
            firstName: a.name.first,
            lastName: a.name.last,
            name: a.name.displayName,
            email: a.email,
            jobTitle: a.jobTitle,
            sso: a.employeeSso
          }
        })
    }
  },
  watch: {
    async show (newVal) {
      if (newVal) {
        this.isShown = newVal
        this.form = {
          budgetYear: this.missionYear || this.currentYear,
          mission: null,
          createDate: null,
          goalsAndStrategies: null,
          playBookComment: null,
          totalPotential: null,
          competitiveInfo: null,
          sequenceId: null,
          ownerSso: null,
          ownerName: this.isNew ? this.user.name : this.form.ownerName,

          ...this.item,
          // If selectedCustomerId is Passed in we'll use that
          customerId: this.selectedCustomerId || this.customerId
        }
      }
    },
    isShown (newVal) {
      if (newVal !== this.show) this.$emit('update:show', newVal)
    },
    searchEmployeesTerm: {
      handler: debounce(function (newVal) {
        this.loadingEmployees = true
        employeeSearchEndpoint(newVal, false).then(res => {
          this.employees = res.data
          this.loadingEmployees = false
        })
      }, 250)
    }
  },

  methods: {
    ...mapActions('currentCustomer', ['addStrategy', 'updateStrategy', 'deleteStrategy']),
    close () {
      this.closeConfirmationDialog()
      this.$emit('update:show', false)
      this.isShown = false
    },
    closeConfirmationDialog () {
      this.isConfirmationDialogOpen = false
    },
    async save () {
      this.saving = true
      this.form.ownerSso = this.employeeToAssign ? this.employeeToAssign.sso : this.form.ownerSso ? this.form.ownerSso : this.user.sso
      this.form.ownerName = this.employeeToAssign ? this.employeeToAssign.name : this.form.ownerName ? this.form.ownerName : this.user.name
      this.form.createDate = new Date()

      // No need to update current customer in vuex if we are not in customer profile
      if (this.selectedCustomerId || this.missionYear !== this.form.budgetYear) {
        await updateMissionAndStrategies(this.form)
      } else {
        this.form.sequenceId
          ? await this.updateStrategy(this.form)
          : await this.addStrategy(this.form)
      }
      // if we are coming from the sales planing page, and the user is creating a mission for the current year
      // update the count on the sales planning page for the specified customer
      if (this.selectedCustomerId && this.missionYear === this.form.budgetYear) {
        this.$emit('updateCount', this.selectedCustomerId)
      }
      this.searchEmployeesTerm = null
      this.employeeToAssign = null
      this.saving = false
      this.close()
    },
    async deleteItem () {
      this.deleting = true
      // No need to update current customer in vuex if we are not in customer profile
      if (this.selectedCustomerId) {
        const { customerId, sequenceId, budgetYear } = this.form
        await deleteMissionAndStrategies({ customerId, sequenceId, budgetYear })
      } else {
        await this.deleteStrategy(this.form)
      }
      this.deleting = false

      this.close()
    },
    preventPageSubmit (e) {
      e.preventDefault()
    }
  }
}
</script>
