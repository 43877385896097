const setIsFetching = (state, isFetching) => {
  state.isFetching = isFetching
}

const setIsSaving = (state, isSaving) => {
  state.isSaving = isSaving
}

const setSalesPlans = (state, salesPlans) => {
  const plansByVendorNumber = salesPlans.reduce((plans, plan) => {
    const vendorKey = plan.vendor || plan.vendorNumber
    if (vendorKey) { plans[vendorKey] = { ...plan, vendorKey } }

    return plans
  }, {})

  state.salesPlans = plansByVendorNumber
}

const updateSalesPlan = (state, plan) => {
  const oldPlan = state.salesPlans[plan.vendorKey]
  const newPlan = {}
  newPlan[`salesGoal${plan.propertySuffix}`] = plan.salesGoal
  newPlan[`gmGoal${plan.propertySuffix}`] = plan.gmGoal * 100
  state.salesPlans = {
    ...state.salesPlans,
    [plan.vendorKey]: { ...oldPlan, ...newPlan }
  }
}

export default {
  setIsFetching,
  setIsSaving,
  setSalesPlans,
  updateSalesPlan
}
