<template>
  <div
    v-if="pageName !== dashboardName"
    class="page-title-global"
    :class="[{'demo-banner': isDemo}, {'is-desktop' : $vuetify.breakpoint.mdAndUp}]">
    <v-container
      fluid
      class="px-4 py-0 d-flex align-center">
      <v-row no-gutters>
        <v-col class="page-title-global__arrow shrink">
          <slot name="backButton"></slot>
        </v-col>
        <v-col
          class="page-title-global__text grow"
          :class="{'includes-sub-text' : pageSubText}">
          <div class="page-name">
            {{ pageName }}
          </div>
          <div
            v-if="pageSubText"
            class="page-sub-text">
            {{ pageSubText }}
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
/* ***
 * This component is found in:
 * - Sales: @/components/PageTitleGlobal.vue
 * - BranchAdmin: @/components/PageTitleGlobal.vue
 * The plan is for these components to be added as an NPM dependency at a later date.
 * For now, just make sure any changes made in this component are made in both projects.
 * ***/
export default {
  name: 'PageTitleGlobal',
  props: {
    pageName: {
      type: [String, Boolean],
      required: false,
      default: false
    },
    pageSubText: {
      type: [String, Boolean],
      required: false,
      default: false
    },
    dashboardName: {
      type: String,
      required: false,
      default: 'Dashboard'
    },
    isDemo: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style lang="scss">
$demoBannerHeight: 32px;
$mobileToolbarHeight: 56px;
$desktopToolbarHeight: 64px;

.page-title-global {
  position: sticky;
  display: flex;
  top: $mobileToolbarHeight;
  height: $mobileToolbarHeight;
  background-color: #e3e3e3;
  z-index: 2;

  &__arrow {
    .v-btn {
      margin-left: 0;
    }
  }
  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .page-name {
      font-size: 18px;
      font-weight: 500;
    }
    .page-sub-text {
      font-size: 12px;
      font-weight: 400;
      color: #999999;
    }
    &.includes-sub-text {
      .page-name {
        font-size: 15px;
      }
    }
  }

  &.demo-banner{
    top: $mobileToolbarHeight + $demoBannerHeight;
  }

  &.is-desktop {
    top: $desktopToolbarHeight;
    height: $desktopToolbarHeight;
    padding-left: 0.05em;

    &.demo-banner {
      top: $desktopToolbarHeight + $demoBannerHeight;
    }
  }
}
</style>
