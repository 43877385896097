import axios from 'axios'
import { callApi } from '@/globals/utils'

export const fetchEmployeeDetails = (sso, banner) =>
  // Banner explicitly passed for authenticated user and viewed as user
  // TODO: this is same call as user/fetchUser -- should be consolidated
  callApi({
    url: '/api/user/details',
    noInterceptors: !!banner,
    params: { sso, banner }
  })

export const fetchEmployeeSalesStats = (sso, banner) =>
  // Banner explicitly passed for authenticated user and viewed as user
  callApi({
    url: '/api/report/employee-sales-stats',
    noInterceptors: !!banner,
    params: { sso, banner }
  })

export const employeeSearchEndpoint = (term, allBanners) =>
  axios.get('/api/employee-search/', { params: { query: term, allBanners: allBanners !== false } })
