import Vue from 'vue'
import Vuex from 'vuex'

import actions from './actions'
import getters from './getters'
import mutations from './mutations'

import activitiesModule from './modules/activities'

import actionPlanModule from './modules/actionPlan'
import branchesModule from './modules/branches'
import currentCustomerModule from './modules/currentCustomer'
import customersModule from './modules/customers'
import formModule from './modules/form'
import employeesModule from './modules/employees'
import employeeStatsModule from './modules/employeeStats'
import opportunitiesModule from './modules/opportunities'
import salesPlanningModule from './modules/salesPlanning'
import userModule from './modules/user'
import simplifiedDashboardModule from './modules/simplifiedDashboard'
import environmentModule from './modules/environment'
import activityFiltersModule from './modules/activitiyFilters'
import storefrontUsers from './modules/storefrontUsers'
import accountsModule from './modules/accounts'

import topSearchesOnWebModule from './modules/reports/topSearchesOnWeb'
import activitySummaryReportModule from './modules/reports/activitySummaryReport'
import opportunitySummaryReportModule from './modules/reports/opportunitySummaryReport'
import customerOverridesReportModule from './modules/reports/customerOverridesReport'

Vue.use(Vuex)

const state = {
  pageHeader: {
    page: '',
    text: '',
    backButton: false // || 'route-name'
  },
  appDataLoaded: false,
  loadingAppFailed: false,
  toastMessage: {
    message: '',
    status: 'success', // || 'error',
    icon: '', // material icon
    timeout: 6000
  },
  lastRoute: null,
  pageTitle: 'Loading...',
  errorPage: {
    statusCode: null,
    message: null
  },
  isCustomerPresent: false, // hides specific cards if customer is present; show Customer Present button,
  apolloProvider: null,
  openEditMyProfile: false,
  debug: false
}
const store = new Vuex.Store({
  state,
  actions,
  getters,
  mutations,
  modules: {
    activities: activitiesModule,
    actionPlan: actionPlanModule,
    branches: branchesModule,
    currentCustomer: currentCustomerModule,
    customers: customersModule,
    employees: employeesModule,
    employeeStats: employeeStatsModule,
    form: formModule,
    opportunities: opportunitiesModule,
    salesPlanning: salesPlanningModule,
    user: userModule,
    simplifiedDashboard: simplifiedDashboardModule,
    environment: environmentModule,
    activityFilters: activityFiltersModule,
    storefrontUsers,
    accounts: accountsModule,
    reports: {
      namespaced: true,
      modules: {
        topSearchesOnWeb: topSearchesOnWebModule,
        activitySummaryReport: activitySummaryReportModule,
        opportunitySummaryReport: opportunitySummaryReportModule,
        customerOverridesReport: customerOverridesReportModule
      }
    }
  }
})

export default store
