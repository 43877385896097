<template>
  <v-card
    tile
    :flat="flat"
    class="text-center text-md-left"
    :class="flat ? '' : 'mb-3'">
    <page-loading-progress
      v-if="loading"
      indeterminate>
    </page-loading-progress>
    <v-card-text
      v-else>
      <v-row>
        <v-col
          :cols="$vuetify.breakpoint.mdAndUp ? 'auto' : '12'"
          :class="{ 'align-center': $vuetify.breakpoint.smAndDown }"
          class="d-flex flex-column">
          <v-avatar
            size="80"
            class="profile"
            :style="{'backgroundImage': `url(${imageDetails.url})`}">
          </v-avatar>
        </v-col>
        <v-col
          :cols="$vuetify.breakpoint.mdAndUp ? 'auto' : '12'"
          :class="{ 'align-center': $vuetify.breakpoint.smAndDown }">
          <h3 class="black--text text--lighten-3">
            {{ branch | formatBranchName }}
            &nbsp;
            <anchor
              v-if="showViewAsLink"
              @click="viewAs">
              View As Branch
            </anchor>
          </h3>
          <v-row
            justify="center"
            justify-md="start">
            <v-col
              v-if="branch.branchManager"
              cols="auto"
              class="font-weight-bold black--text text--lighten-3">
              Mgr: {{ branch.branchManager.name }}
            </v-col>
            <v-col
              v-if="branch.regionName || branch.regionCode"
              cols="auto"
              class="font-weight-bold mx-2 black--text text--lighten-3">
              Region: {{ branch.regionName }} ({{ branch.regionCode }})
            </v-col>
          </v-row>
          <v-row
            align="center"
            align-md="start">
            <v-col
              v-if="fullAddress"
              cols="12">
              <v-icon class="mr-1">
                location_on
              </v-icon>
              <anchor
                :href="`http://maps.google.com/?q=${fullAddress}`"
                target="_blank">
                {{ fullAddress }}
              </anchor>
            </v-col>
            <v-col
              v-if="branch.phone"
              cols="12">
              <v-icon class="mr-1">
                call
              </v-icon>
              <anchor :href="`tel:${branch.phone}`">
                {{ branch.phone }}
              </anchor>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          v-if="branch.branchManagerEmail || branch.hoursOfOperation"
          cols="auto">
          <v-col>
            <v-row
              v-if="branch.branchManagerEmail"
              align="center"
              justify="center">
              <v-icon class="mr-1">
                email
              </v-icon>
              <anchor :href="`mailto:${branch.branchManagerEmail}`">
                {{ branch.branchManagerEmail }}
              </anchor>
            </v-row>
            <v-row
              v-if="branch.hoursOfOperation"
              align="center"
              justify="center">
              <v-icon class="mr-1">
                av_timer
              </v-icon>{{ branch.hoursOfOperation }}
            </v-row>
          </v-col>
        </v-col>
        <v-spacer></v-spacer>
        <v-col
          :cols="$vuetify.breakpoint.mdAndUp ? 'auto' : '12'"
          class="d-flex flex-column">
          <v-row
            justify="center"
            justify-md="end">
            <v-menu
              bottom
              left
              :close-on-content-click="true">
              <template #activator="{ on }">
                <v-icon
                  data-qa="kabobMenu"
                  v-on="on">
                  more_vert
                </v-icon>
              </template>
              <v-list>
                <v-list-item
                  data-qa="viewDetails">
                  <download-link
                    :href="branchReportExportUrl"
                    class="shrink">
                    <v-list-item-title>Report Card</v-list-item-title>
                  </download-link>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-row>
          <v-row
            justify="center"
            justify-md="end"
            :class="{ 'mt-3': $vuetify.breakpoint.mdAndUp }">
            <img
              :src="bannerLogo"
              alt="Branch Logo"
              width="120"
              class="ma-2 shrink" />
          </v-row>
        </v-col>
        <!-- <v-row
          justify="end"
          column
          :class="$vuetify.breakpoint.smAndDown ? 'mt-3' : ''"
          class="xs12 md-max-width-150 text-md-right">
          <v-col>
            <v-menu
              bottom
              left
              :close-on-content-click="true">
              <template v-slot:activator="{ on }">
                <v-icon
                  data-qa="kabobMenu"
                  v-on="on">
                  more_vert
                </v-icon>
              </template>
              <v-list>
                <v-list-item
                  data-qa="viewDetails">
                  <download-link
                    :href="branchReportExportUrl"
                    class="shrink">
                    <v-list-item-title>Report Card</v-list-item-title>
                  </download-link>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
          <v-col
            :mt-3="$vuetify.breakpoint.mdAndUp">
            <img
              :src="bannerLogo"
              alt="Branch Logo"
              width="120"
              class="ma-2 shrink" />
          </v-col>
        </v-row> -->
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import { bannerFromId } from '@/globals/utils'
import { branchReportCardUrl } from '@/api/reports'
import Anchor from '@/components/Anchor.vue'
import DownloadLink from '@/components/DownloadLink.vue'
import PageLoadingProgress from '@/components/PageLoadingProgress.vue'
import { identityService } from '@/features/identity'

const plattLogo = require('@/assets/images/logo-platt.svg')
const rexelLogo = require('@/assets/images/logo-rexel.svg')
const gexproLogo = require('@/assets/images/logo-gexpro.svg')
const mayerLogo = require('@/assets/images/logo-mayer.svg')

export default {
  name: 'BranchSummary',
  components: {
    Anchor,
    DownloadLink,
    PageLoadingProgress
  },
  props: {
    branchDetails: {
      type: Object,
      required: false,
      default: null
    },
    branchId: {
      type: Number,
      required: false,
      default: null
    },
    flat: {
      type: Boolean,
      required: false,
      default: false
    },
    showViewAs: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      branch: {},
      loading: false
    }
  },
  computed: {
    baseImageUrl () {
      return 'https://rexel-cdn.com/branches/storefront'
    },
    imageDetails () {
      const { imageFolderName, branchName } = this.branch

      // image not found
      if (typeof imageFolderName !== 'string') {
        return {
          url: 'https://rexel-cdn.com/products/$%7Bp.catNum%7D.jpg?i=0EA7A6C5-7DD2-4482-9229-D628D3AFAE9D&f=80',
          alt: 'No image found'
        }
      }

      return {
        url: imageFolderName.startsWith('http')
          ? imageFolderName
          : `${this.baseImageUrl}/${imageFolderName}/640.jpg`,
        alt: branchName
      }
    },
    fullAddress (col) {
      if (!this.branch.address) return ''
      const { address1, address2, city, state, postalCode } = this.branch.address
      const address = address1 ? address1.trim() : null
      const addressArray = [address, address2, city, state, postalCode].filter((val) => !!val)
      return addressArray.join(', ')
    },
    bannerLogo () {
      const { bannerId } = this.branch
      switch (bannerId) {
        case 1:
          return plattLogo
        case 2:
          return rexelLogo
        case 3:
          return gexproLogo
        case 10:
          return mayerLogo
        default:
          return null
      }
    },
    branchReportExportUrl () {
      return branchReportCardUrl(this.branch.branchNumber)
    },
    showViewAsLink () {
      const canUserViewAs = this.$store.getters['user/userCanViewAs']
      const currentViewAs = this.$store.getters['user/viewAsSSO']
      const thisBranchViewAs = this.branch.branchManager.viewAsSSO

      return canUserViewAs && this.showViewAs && currentViewAs !== thisBranchViewAs
    }
  },
  watch: {
    branchDetails: {
      immediate: true,
      handler (val) {
        this.branch = {}
        this.loading = true
        if (val) {
          this.branch = val
        }
        this.$emit('loaded')
        this.loading = false
      }
    },
    branchId: {
      immediate: true,
      async handler (val) {
        this.branch = {}
        this.loading = true
        if (val) {
          const branch = await this.getBranch(val)
          if (branch) this.branch = branch
        } else {
          this.branch = this.branchDetails
        }
        this.$emit('loaded')
        this.loading = false
      }
    }
  },
  methods: {
    ...mapActions('branches', ['getBranch']),
    async viewAs () {
      const { bannerId, viewAsSSO } = this.branchDetails.branchManager
      const banner = bannerFromId(bannerId)

      await identityService.setViewAsUser(viewAsSSO, banner)
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~styl/colors";

.align-self-start {
  align-self: start;
}

@media (min-width: 960px) {
  .layout.md-max-width-150 {
    max-width: 140px;
  }
}
</style>
