<template>
  <v-app-bar
    :class="['accent', 'sales-header']"
    dark
    flat
    app
    clipped-left
    :height="toolbarHeight"
    :style="{ top: 'inherit' }">
    <div
      class="d-flex align-center nav-icon-logo"
      :class="{'mobile': $vuetify.breakpoint.smAndDown}">
      <v-app-bar-nav-icon
        dark
        data-qa="sidebar_menu"
        @click.stop="toggleSidebar">
      </v-app-bar-nav-icon>
      <div class="sales-logo">
        <router-link :to="{ name: 'dashboard' }">
          <span>
            <img
              :class="`${$vuetify.breakpoint.smAndDown ? 'mobile-logo' : ''}`"
              :src="logoSrc" />
          </span>
        </router-link>
      </div>
    </div>

    <auto-complete
      hide-details
      background-color="primary"
      color="white"
      class="global-search hidden-sm-and-down"
      has-item-slot
      :search-params="{ source: 'all', filters: ['includeJobAccounts'], orderBy: ['sales desc'] }"
      @search="goToSearchPage($event)"
      @select="selectSearchItem">
      <template
        slot="item"
        slot-scope="{ item }">
        <customer-list-tile-layout
          :customer="item.value"
          :prepend-sales-stats="false"
          :append-status="false"></customer-list-tile-layout>
      </template>
    </auto-complete>
    <v-spacer></v-spacer>
    <template
      v-if="$vuetify.breakpoint.mdAndUp && $hasFeature('Header_ExternalLinks')">
      <v-menu offset-y>
        <template #activator="{ on }">
          <v-btn
            class="mx-4"
            text
            outlined
            dark
            v-on="on">
            External Links
            <v-icon right>
              keyboard_arrow_down
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(link, i) in externalLinks"
            :key="`link_${i}`"
            :href="`${link.href}`"
            target="_blank">
            <v-list-item-title class="font-weight-medium">
              {{ link.name }}
            </v-list-item-title>
          </v-list-item>
          <!-- Passing the label-maker route name as a the to prop on the following v-list-item wasn't working correctly -->
          <router-link
            :to="{ name: 'label-maker', params: { ...$route.params } }"
            class="text-decoration-none">
            <v-list-item
              class="v-list-item--link">
              <v-list-item-title class="font-weight-medium">
                Label Maker Tool
              </v-list-item-title>
            </v-list-item>
          </router-link>
        </v-list>
      </v-menu>
    </template>

    <header-actions>
    </header-actions>
    <platt-dot-com-sign-in-dialog>
    </platt-dot-com-sign-in-dialog>
  </v-app-bar>
</template>
<script>
import Vue from 'vue'
import { getExternalLinks } from '@/features/externalLinks'
import { CustomerListTileLayout } from '@/components/CustomerSearch'
import AutoComplete from '@/components/AutoComplete.vue'
import HeaderActions from './HeaderActions.vue'
import PlattDotComSignInDialog from '@/components/PlattDotComSignInDialog.vue'

export default Vue.extend({
  name: 'PageHeader',
  components: {
    AutoComplete,
    CustomerListTileLayout,
    HeaderActions,
    PlattDotComSignInDialog
  },
  props: {
    // bind toolbarHeight from BaseLayout
    toolbarHeight: {
      type: Number,
      required: true,
      default: 56
    }
  },
  computed: {
    logoSrc () {
      return require('@/assets/images/logo.svg')
    },
    externalLinks () {
      return getExternalLinks()
    }
  },
  methods: {
    formatSearchDisplay (item) {
      return `${item.customerName} - ${item.companyCustomerNumber}`
    },
    goToSearchPage (search) {
      this.$router.push({
        name: 'customers',
        query: { term: search, source: 'all', filters: [], orderBy: 'sales desc', page: '1' }
      })
    },
    selectSearchItem (item) {
      this.searchText = null
      if (item) {
        this.$router.push({
          name: 'customer-profile',
          params: { customerId: item.customerId }
        })
      }
    },
    toggleSidebar () {
      this.$emit('toggleSidebar')
    },
    formatCustomer (customer) {
      return {
        ...customer,
        rolling12sales: null
      }
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~styl/colors';

// 240px is sidenav width, 16px is toolbar padding
.nav-icon-logo {
  width: calc(240px - 16px);
  &.mobile {
    flex-grow: 1;
  }
}

.sales-header {
  max-width: 100vw;
  .sales-logo {
    width: auto;
    margin-left: 2px;

    @media(min-width: 600px) {
      width: 180px;
    }

    img {
      max-width: 139.5px;
      vertical-align: middle;
    }
  }

  .mobile-logo {
    height: 35px;
    width: 100%;
  }
}

// Adjusts nav-bar padding on smaller mobile screens ie: iPhone X
@media only screen and (max-width: 380px) {
  >>>.v-toolbar__content {
      padding: 4px 8px !important;
  }
}
</style>
