const setEmployeeSalesStats = (state, payload) => {
  state.employeeSalesStats = {
    ...state.employeeSalesStats,
    [payload.sso]: payload
  }
}

const setEmployeeDetails = (state, payload) => {
  state.employeeDetails = {
    ...state.employeeDetails,
    [payload.sso]: payload
  }
}

// only for logged in user
const setSkills = (state, payload) => {
  state.employeeSalesStats = {
    ...state.employeeSalesStats,
    [payload.sso]: payload
  }
}

export default {
  setEmployeeSalesStats,
  setEmployeeDetails,
  setSkills
}
