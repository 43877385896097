import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  states: [],
  regions: [],
  branchEmployees: {},
  branchDetails: {},
  branches: [],
  branchesLoaded: false,
  groups: [],
  groupsLoaded: false,
  currentGroup: {
    title: '',
    description: '',
    userList: []
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
