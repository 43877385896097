import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  roles: [],
  entityId: null,
  webUserParent: null,
  impersonationUrl: '',
  users: [],
  pims: [],
  newMigratedPims: [],
  newPimsLoaded: false,
  pimsAreMigrated: false,
  newPimId: 0,
  pimEnabledRexGexAccountIds: [
    1108655, // Blackops Cash (shipTo/billTo)
    794717, // Creative Lighting & Power LLC (shipTo/billTo)
    1146498, // Electrik Pros LLC (shipTo/billTo)
    1045673, // Top Flight Electric (shipTo/billTo)
    1216031, // Southern States VMI Acct (shipTo)
    633319, // Titan Electrical Service of SWFL IN (shipTo)
    1182240, // Sargent
    1343771, // Coup Electric.
    15807, // A-American
    1314664, // ALEXA AIR INC DBA AND SERVICES
    1344145, // IMPACT HOME SERVICES
    1330905, // Green Air
    913923, // Condee Cooling
    297867, // Smith Electric Inc Englewood
    1379426, // Integrity Home Solution Services
    1128240 // WESTERN MIDSTREAM
  ],
  rexGexPimEntityId: null,
  rexGexPimImpersonationUrl: ''
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
