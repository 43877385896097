import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  salesPlans: {},
  salesPlansBillTo: {},
  salesPlansShipTo: {},
  salesPlansJobs: {},
  isFetching: false,
  isSaving: false
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
