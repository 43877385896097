import store from '@/store'
import Vue from 'vue'

const rexGexLinks = [
  {
    name: 'SPA Tool',
    href: 'https://mdmapps.rexelusa.com/ords/mmapxprd/f?p=SPA:CURRENT_INSTRUCTIONS:7598243914570:::::'
  },
  {
    name: 'Dollarization Tool',
    href: 'https://rexelapps.rexelusa.com/ords/apexextp/f?p=274:148:7751367329699:::148:P148_APP_ID:163'
  }
]

const plattProdLinks = [
  {
    name: 'PlattNet',
    href: 'http://plattnet.platt.com'
  },
  {
    name: 'Platt.com',
    href: 'https://www.platt.com'
  },
  {
    name: 'Dollarization Tool',
    href: 'https://rexelapps.rexelusa.com/ords/apexextp/f?p=274:148:7751367329699:::148:P148_APP_ID:163',
    hide: true
  }
]

const plattTestLinks = [
  {
    name: 'PlattNet',
    href: 'http://plattnet.platt.com'
  },
  {
    name: 'Platt.com',
    href: 'https://platt.test.rexelapps.info/'
  },
  {
    name: 'Dollarization Tool',
    href: 'https://rexelapps.rexelusa.com/ords/apexextp/f?p=274:148:7751367329699:::148:P148_APP_ID:163'
  }
]

export const getExternalLinks = () => {
  const isPlatt = store.getters['user/isPlattBanner']

  if (!isPlatt) {
    return rexGexLinks
  }

  if (Vue.prototype.$environment === 'Test') {
    return plattTestLinks
  }

  return plattProdLinks
}
