import _format from 'date-fns/format'
import _addMonths from 'date-fns/add_months'
import _addWeeks from 'date-fns/add_weeks'
import _addDays from 'date-fns/add_days'
import _subMonths from 'date-fns/sub_months'
import _subWeeks from 'date-fns/sub_weeks'
import _subDays from 'date-fns/sub_days'
import _startOfYear from 'date-fns/start_of_year'
import _startOfMonth from 'date-fns/start_of_month'
import _startOfWeek from 'date-fns/start_of_week'
import _endOfWeek from 'date-fns/end_of_week'
import _isDate from 'date-fns/is_date'
import _differenceInSeconds from 'date-fns/difference_in_seconds'

export const addMonths = _addMonths
export const addWeeks = _addWeeks
export const addDays = _addDays
export const subMonths = _subMonths
export const subWeeks = _subWeeks
export const subDays = _subDays
export const startOfWeek = _startOfWeek
export const endOfWeek = _endOfWeek
export const startOfMonth = _startOfMonth
export const differenceInSeconds = _differenceInSeconds

export const isDateString = (dateString) => {
  // validate if string can be resolved to a date (e.g. 'mm-dd-yy', 'yyyy/mm/dd')
  return !isNaN(Date.parse(dateString))
}

export const getDaysOfWeek = () => [
  'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'
]

export const months = () => [
  'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
]

export const currentMonth = () => {
  const date = new Date()
  return months()[date.getMonth()]
}

export const getMonths = (format = 'MMM', startingDate = null, months = 12) => {
  if (!_isDate(startingDate)) {
    startingDate = isDateString(startingDate)
      ? new Date(startingDate)
      : _startOfYear(new Date())
  }
  return Array.from(Array(months).keys()).map((monthOffset) => {
    const dateOffset = addMonths(startingDate, monthOffset)
    return _format(dateOffset, format)
  })
}

export const getDateYearBefore = (date = new Date(), numYears = 1) => {
  // accepts date as Date object or string (e.g. "yyyy-MM-ddTHH:mm:ss")
  // if no date is passed, default to today
  date = new Date(date)
  const year = date.getFullYear()
  const yearsBefore = date.setYear(year - numYears)
  return new Date(yearsBefore)
}
export const getDateMonthsBefore = (date = new Date(), numMonths = 12) => {
  // accepts date as Date object or string (e.g. "yyyy-MM-ddTHH:mm:ss")
  // if no date is passed, default to today
  date = new Date(date)
  const month = date.getMonth()
  const monthsBefore = date.setMonth(month - numMonths)
  return new Date(monthsBefore)
}
