<template>
  <div>
    <v-card>
      <v-card-text>
        <page-loading-progress
          v-if="!yearlyData.length"
          indeterminate>
        </page-loading-progress>
        <v-row
          v-else
          align="center">
          <v-col
            cols="12"
            sm="3"
            md="3"
            lg="3"
            class="text-center">
            <chart-legend
              v-if="formattedChartData.datasets.length"
              :legends="legends">
            </chart-legend>
          </v-col>
          <v-col
            class="bl-1"
            cols="12"
            sm="9"
            md="9"
            lg="9">
            <div class="pa-6">
              <line-chart
                v-if="formattedChartData.datasets.length"
                :chart-data="formattedChartData"
                :chart-options="chartOptions"
                :height="chartHeight">
              </line-chart>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { formatMoney, formatDate } from '@/globals/formatters'
import { getMonths } from '@/globals/time'
import sortBy from 'lodash/sortBy'
import PageLoadingProgress from '@/components/PageLoadingProgress.vue'
import LineChart from '@/components/Charts/LineChart.vue'
import ChartLegend from '@/components/Charts/ChartLegend.vue'

export default {
  name: 'ProductChart',
  components: {
    PageLoadingProgress,
    LineChart,
    ChartLegend
  },
  props: {
    monthlyData: {
      type: Array,
      required: true
    },
    yearlyData: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: { display: false }
        }
      },
      chartHeight: 300,
      dataKey: 'sales'
    }
  },
  computed: {
    colors () {
      const {
        graphPrimary,
        graphSecondary
      } = this.$vuetify.theme.currentTheme
      return [graphSecondary, graphPrimary]
    },
    legends () {
      // filter to two latest years
      const sortedData = sortBy(this.yearlyData, ['year']).reverse()
      const filteredData = sortedData.slice(0, 2)

      return filteredData.map((salesYear, index) => {
        const { sales, year } = salesYear
        return { title: year, amount: formatMoney(sales), color: this.colors[index] }
      })
    },
    formattedChartData () {
      const { dataKey, colors } = this
      if (this.monthlyData.length === 0) return { labels: [], datasets: [] }

      const sortedData = sortBy([...this.monthlyData], ['salesPeriod'])

      // create object keyed by year
      const dataObj = sortedData.reduce((items, item) => {
        const year = this.getYear(item.salesPeriod)
        if (items[year] === undefined) {
          items[year] = {
            year,
            data: []
          }
        }
        items[year].data.push(item[dataKey])
        return items
      }, {})

      // if there are more than 2 years of data, filter to last 2
      const allYears = Object.keys(dataObj)
      const twoMostRecentYears = allYears.sort().slice(allYears.length - 2)
      let filteredDataObj = dataObj
      if (allYears.length > 2) {
        filteredDataObj = allYears.reduce((list, year) => {
          if (twoMostRecentYears.includes(year)) {
            list[year] = dataObj[year]
          }
          return list
        }, {})
      }

      const dataMappedByEntity = Object.keys(filteredDataObj)
        .map((key) => filteredDataObj[key])
      const sortedMappedData = sortBy(dataMappedByEntity, ['year']).reverse()
      const datasets = sortedMappedData.map((item, index) => {
        const color = colors[index]
        return {
          label: item.year,
          borderColor: color,
          data: item.data,
          pointBorderColor: color,
          pointBackgroundColor: 'white',
          pointBorderWidth: 2,
          pointRadius: 3,
          borderWidth: 2
        }
      })

      return { labels: getMonths(), datasets }
    }
  },
  methods: {
    getYear (salesPeriod) {
      return Number.parseInt(formatDate(salesPeriod, 'YYYY'))
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
