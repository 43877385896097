<template>
  <v-dialog
    v-model="syncedIsDialogOpen"
    max-width="640px"
    @keydown.esc="close"
    @close="close">
    <v-toolbar
      flat
      dark
      color="primary">
      <v-toolbar-title>Enriched Customer Data</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        icon
        dark
        @click="close">
        <v-icon>close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card class="enriched-data">
      <v-card-text>
        <v-form
          v-model="isValid">
          <v-container
            fluid>
            <v-row>
              <v-col cols="12">
                <h3>{{ customerTitle }}</h3>
              </v-col>
              <v-col
                cols="12"
                sm="6">
                <v-text-field
                  v-model="form.vanCount"
                  label="Number of Vans"
                  :rules="rules.isNumber"
                  filled>
                </v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6">
                <v-text-field
                  v-model="form.shopCount"
                  label="Number of Shops"
                  :rules="rules.isNumber"
                  filled>
                </v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6">
                <v-text-field
                  v-model="form.employeeCount"
                  label="Number of Employees"
                  :rules="rules.isNumber"
                  filled>
                </v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6">
                <v-text-field
                  v-model="form.percentMarketShare"
                  label="Percent of Market Share"
                  :rules="rules.isNumber"
                  filled>
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="form.customerSoftware"
                  label="Customer Software"
                  filled>
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.stop="close">
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          :disabled="!isValid"
          @click="save()">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { isNumber } from '@/rules'

export default {
  name: 'EnrichedDataDialog',
  props: {
    isDialogOpen: {
      type: Boolean,
      required: true
    },
    selectedCustomerId: {
      type: Number,
      required: false,
      default: null
    },
    customerTitle: {
      type: String,
      required: false,
      default: 'Customer Details'
    }
  },
  data () {
    return {
      form: {
        vanCount: null,
        shopCount: null,
        employeeCount: null,
        percentMarketShare: null
      },
      isValid: false,
      rules: {
        isNumber: [isNumber('Only numbers are allowed')]
      }
    }
  },
  computed: {
    ...mapGetters('user', ['user']),
    ...mapState('currentCustomer', ['customer']),
    ...mapGetters('currentCustomer', ['customerId']),
    enrichedCustomerData () {
      return this.customer.enrichedCustomerData
    },
    syncedIsDialogOpen: {
      get: function () {
        return this.isDialogOpen
      },
      set: function (value) {
        this.$emit('update:isDialogOpen', value)
      }
    }
  },
  watch: {
    async isDialogOpen (val) {
      if (val) {
        if (this.selectedCustomerId) {
          await this.getCustomer(this.selectedCustomerId)
        }
        this.form = {
          ...this.enrichedCustomerData,
          percentMarketShare: this.enrichedCustomerData.percentMarketShare
            ? (this.enrichedCustomerData.percentMarketShare * 100).toFixed(2)
            : null
        }
      }
    }
  },
  methods: {
    ...mapActions('currentCustomer', ['setEnriched', 'getCustomer']),
    close () {
      this.$emit('close')
    },
    async save () {
      const enrichedCustomerData = {
        ...this.form,
        percentMarketShare: this.form.percentMarketShare
          ? this.form.percentMarketShare / 100
          : null
      }

      await this.setEnriched({ customerId: this.customerId, enrichedCustomerData })
      this.close()
    }
  }
}
</script>
