// import { bannerFromId } from '@/globals/utils'

const banner = (state) => {
  if (state.user.isViewingAs) {
    return state.user.viewAsBanner
  }

  return state.user.selectedBanner
}

const availableBanners = (state) => state.user.availableBanners
const isPlattBanner = (state) => banner(state) === 'platt'
const isMayerBanner = (state) => banner(state) === 'mayer'
const isCapitolLightBanner = (state) => banner(state) === 'capitol-light'

const isViewingAs = (state) => state.user.isViewingAs

const authenticatedUser = (state) => state.user.authedUserDetails

const user = (state) => {
  if (state.user.isViewingAs) {
    return state.user.viewAsUser
  }

  return state.user
}

const userCanViewAs = (state) => {
  return state.user.canViewAs
}

const isActiveUserElevated = (state) => {
  const activeUser = user(state)
  const elevatedUserTypes = ['SuperUser', 'RVP', 'Custom']
  return elevatedUserTypes.includes(activeUser.userType)
}

const displayName = (state) => {
  const {
    firstName,
    lastName
  } = user(state)

  return `${firstName} ${lastName}`
}

const viewAsSSO = (state) => {
  return state.user.viewAsSso
}

const viewAsBanner = (state) => {
  return state.user.viewAsBanner
}

const accessToken = (state) => state.accessToken

const plattDotComSignInUrl = (state) => {
  return state.plattDotComSignInUrl
}

const isAdminLoggingIn = (state) => {
  return state.isAdminLoggingIn
}

export default {
  banner,
  availableBanners,
  isPlattBanner,
  isMayerBanner,
  isCapitolLightBanner,
  isViewingAs,

  authenticatedUser,
  user,
  userCanViewAs,
  displayName,
  viewAsSSO,
  viewAsBanner,
  isActiveUserElevated,
  accessToken,
  plattDotComSignInUrl,
  isAdminLoggingIn
}
