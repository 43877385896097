import { BannerFeatureFlagName } from '../constants'
import { LogType } from './logType'
import { ParsedStackTrace, StackPartType } from './parsedStackTrace'

const SHOW_STACK_TRACE = false // Show single line stack item for the claling component
const SHOW_FULL_STACK_TRACE = false // SHow full stack trace of callers, very verbose

const logColors = {
  positive: '#89b00e',
  warning: '#ad7f1c',
  error: '#de1814',
  groupName: '#139ccf',
  componentName: '#0295e6',
  memberName: '#e6b502'
}

interface FeatureLogEntry {
  type: LogType;
  feature: BannerFeatureFlagName;
  message?: string;
  context: Record<string, any> | string | undefined;
  stackInfo: ParsedStackTrace;
}

class FeatureProviderDebugWriter {
  logItemsBuffer: FeatureLogEntry[] = []
  flushLogTimeout: number = 0

  public debugLog (type: LogType, feature: BannerFeatureFlagName, message?: string, context?: Record<string, any> | string) {
    window.clearTimeout(this.flushLogTimeout)

    this.logItemsBuffer.push({
      type,
      feature,
      message,
      context,
      stackInfo: new ParsedStackTrace((new Error('StackLog')).stack)
    })

    this.flushLogTimeout = window.setTimeout(() => {
      this.flushLog()
    }, 250)
  }

  flushLog () {
    if (!this.logItemsBuffer.length) {
      return
    }
    console.group('%c Banner Feature Calls Group', 'font-size: 16px;')

    const logGroups: Record<string, FeatureLogEntry[]> = {}

    // Group log entries up by feature
    this.logItemsBuffer
      .sort((a, b) => a.feature.localeCompare(b.feature))
      .forEach((logItem) => {
        const groupKey = logItem.feature.substring(0, logItem.feature.indexOf('_')) // Get definition up to first _
        if (!(groupKey in logGroups)) {
          logGroups[groupKey] = []
        }

        logGroups[groupKey].push(logItem)
      })

    // Print out each group
    Object.keys(logGroups).forEach((featureKey) => {
      const group = logGroups[featureKey]

      console.group(`%c ${featureKey}`, `color: ${logColors.groupName}; font-weight: bold; font-size: 14px;`)

      group.forEach((logItem) => {
        this.printLogItem(logItem)
      })

      console.groupEnd()
    })

    console.groupEnd()

    this.logItemsBuffer.length = 0
  }

  printLogItem (item: FeatureLogEntry) {
    const formats = this.getFormattedMessages(item)

    const message = `${formats.feature} %c | ${formats.componentName}${formats.memberName}`

    const args: any[] = [
      formats.featureStyle,
      '', // Blank format for pipe
      formats.componentStyle,
      formats.memberStyle
    ]

    if (item.message && item.type !== LogType.Positive) {
      args.push(`↵\n         ${item.message}`)
    }

    if (item.context && item.type !== LogType.Positive) {
      args.push(`↵\n         ${item.context}`)
    }

    if (SHOW_STACK_TRACE && item.stackInfo.stackPartType === StackPartType.VueScript) {
      args.push(`↵\n${item.stackInfo.vueComponentPart}`)
    }

    console.log(message, ...args)

    if (SHOW_FULL_STACK_TRACE) {
      console.log(item.stackInfo.stackTrace)
    }
  }

  getFormattedMessages (item: FeatureLogEntry) {
    let featureStyle = 'border-left: 4px solid'
    switch (item.type) {
      case LogType.Positive:
        featureStyle += `${logColors.positive}; color: ${logColors.positive};`
        break
      case LogType.Warning:
        featureStyle += `${logColors.warning}; color: ${logColors.warning};`
        break
      case LogType.Error:
        featureStyle += `${logColors.error}; color: ${logColors.error};`
        break
    }

    const formats = {
      feature: `%c ${item.feature}`,
      featureStyle,
      componentName: `%c ${item.stackInfo.vueComponentName}.vue`,
      componentStyle: `color: ${logColors.componentName}`,
      memberName: item.stackInfo.vueComponentMember ? `%c > ${item.stackInfo.vueComponentMember}` : '%c',
      memberStyle: `color: ${logColors.memberName}`
    }

    return formats
  }
}

const debugWriter = new FeatureProviderDebugWriter()
export {
  debugWriter,
  FeatureLogEntry
}
