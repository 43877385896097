<template>
  <div>
    <v-card class="my-4">
      <v-card-text>
        <v-row>
          <v-col
            cols="10"
            sm="6"
            md="4">
            <v-text-field
              v-model="search"
              label="Search"
              hide-details
              append-icon="search"
              filled>
            </v-text-field>
          </v-col>
          <v-col class="text-right">
            <download-link :href="exportUrl">
              <v-btn
                small
                fab>
                <v-icon>file_download</v-icon>
              </v-btn>
            </download-link>
          </v-col>
        </v-row>
        <div class="text-right font-weight-bold">
          Sales and SKUs from last 365
        </div>
        <div
          v-if="categorySales">
          <v-data-table
            v-if="$vuetify.breakpoint.mdAndUp"
            :headers="formattedHeaders"
            :items="categorySales"
            :options.sync="pagination"
            :footer-props="{ 'items-per-page-options': [5, 10, 25] }"
            :search="search"
            :custom-sort="customSort">
            <template
              slot="item"
              slot-scope="props">
              <tr
                class="clickable"
                @click="clickRow(props.item.categoryId)">
                <td>
                  <img
                    v-if="props.item.imageFileFolderName"
                    :src="`https://rexel-cdn.com/Products/${props.item.categoryName}.jpg?i=${props.item.imageFileFolderName}&f=35`"
                    alt="Product image" />
                  <img
                    v-else
                    src="https://rexel-cdn.com/products/$%7Bp.catNum%7D.jpg?i=0EA7A6C5-7DD2-4482-9229-D628D3AFAE9D&f=35"
                    alt="Product image not found" />
                </td>
                <td>{{ props.item.categoryName }}</td>
                <td>
                  {{ props.item.currentYearSales | formatMoney }}
                  <span :class="{'green--text text--darken-2': props.item.yearOverYearSales > 0, 'error--text': props.item.yearOverYearSales < 0}">
                    ({{ (props.item.yearOverYearSales) | formatPercent }})
                  </span>
                </td>
                <td>
                  {{ props.item.currentYearProductCount |formatNumber }}
                  <span :class="{'green--text text--darken-2': props.item.yearOverYearProductCount > 0, 'error--text': props.item.yearOverYearProductCount < 0}">
                    ({{ (props.item.yearOverYearProductCount) | formatPercent }})
                  </span>
                </td>
                <td>{{ props.item.previousYearSales | formatMoney }}</td>
                <td>{{ props.item.previousYearProductCount | formatNumber }}</td>
                <td>
                  <v-icon>arrow_forward</v-icon>
                </td>
              </tr>
            </template>
            <!-- TEMP FIX - Awaiting Vuetify fix for issue: https://github.com/vuetifyjs/vuetify/issues/14405 -->
            <!-- eslint-disable -->
            <template v-slot:footer.page-text="props">
              {{ props.pageStart }}-{{ props.pageStop }} of {{ props.itemsLength.toLocaleString('en-us') }}
            </template>
            <!-- eslint-enable -->
          </v-data-table>
          <responsive-table
            v-else
            :headers="formattedHeaders"
            :items="categorySales"
            :route-settings="routeSettings"
            :pagination.sync="pagination"
            :rows-per-page-items="[5, 10, 25]"
            no-data-text="No Data"
            mobile-break-point="smAndDown"
            :search="search"
            @rowClickEvent="rowClickEvent">
            <template
              #image="{item}">
              <v-row

                justify="start">
                <v-col class="ml-1 shrink">
                  <img
                    v-if="item.imageFileFolderName"
                    :src="`https://rexel-cdn.com/Products/${item.categoryName}.jpg?i=${item.imageFileFolderName}&f=35`"
                    alt="Product image" />
                  <img
                    v-else
                    src="https://rexel-cdn.com/products/$%7Bp.catNum%7D.jpg?i=0EA7A6C5-7DD2-4482-9229-D628D3AFAE9D&f=35"
                    alt="Product image not found" />
                </v-col>
                <v-col class="ml-2">
                  <h3>
                    {{ item.categoryName }}
                  </h3>
                </v-col>
              </v-row>
            </template>
          </responsive-table>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ResponsiveTable from '@/components/ResponsiveTable/ResponsiveTable.vue'
import DownloadLink from '@/components/DownloadLink.vue'
import CategoryLevelParams from './CategoryLevelParams.mixin'

export default {
  name: 'CategoryTable',
  components: {
    DownloadLink,
    ResponsiveTable
  },
  mixins: [CategoryLevelParams],
  props: {
    categorySales: {
      type: Array,
      required: true
    },
    entityType: {
      type: String,
      required: true
    },
    entityId: {
      type: Number,
      required: false,
      default: null
    },
    exportUrl: {
      type: String,
      required: true
    },
    primaryColumn: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      staticHeaders: [
        {
          text: '',
          sortable: false,
          value: this.$vuetify.breakpoint.mdAndUp ? '' : this.primaryColumn,
          slot: 'image'
        },
        {
          text: 'Sales $',
          align: 'left',
          sortable: true,
          value: 'currentYearSales'
        },
        {
          text: 'SKUs',
          align: 'left',
          sortable: true,
          value: 'currentYearProductCount'
        },
        {
          text: 'Sales $ (Prev Year)',
          align: 'left',
          sortable: true,
          value: 'previousYearSales'
        },
        {
          text: 'SKUs (Prev Year)',
          align: 'left',
          sortable: true,
          value: 'previousYearProductCount'
        },
        {
          text: ' ', // Vuetify uses text as a key. This has to be a space to differentiate from the other empty header column
          sortable: false,
          value: ''
        }
      ],
      pagination: {
        sortDesc: true,
        itemsPerPage: 25,
        sortBy: 'currentYearSales'
      },
      search: null,
      routeSettings: {
        clickableRows: true,
        eventWithArgs: true,
        parameterNames: ['categoryId']
      }
    }
  },
  computed: {
    categoryId () {
      return this.$route.params.categoryId
    },
    formattedHeaders () {
      const headers = this.staticHeaders.slice()
      const categoryHeader = {
        text: this.$vuetify.breakpoint.mdAndUp ? this.primaryColumn : '',
        align: 'left',
        sortable: true,
        value: this.$vuetify.breakpoint.mdAndUp ? 'categoryName' : '',
        class: 'text-capitalize'
      }
      headers.splice(1, 0, categoryHeader)
      return headers
    }
  },
  methods: {
    changeSort (column) {
      if (this.pagination.sortBy === column) {
        this.pagination.sortDesc = !this.pagination.sortDesc
      } else {
        this.pagination.sortBy = column
        this.pagination.sortDesc = false
      }
    },
    customSort (items, index, isDescending) {
      return items.sort(function (a, b) {
        if (a.categoryName === 'Uncategorized') return -1
        if (b.categoryName === 'Uncategorized') return 1
        if (a[index] === b[index]) return 0

        if (!isDescending) {
          return a[index] < b[index] ? -1 : 1
        } else {
          return a[index] < b[index] ? 1 : -1
        }
      })
    },
    rowClickEvent (params) {
      this.clickRow(params.categoryId)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
