<template>
  <div :class="[$vuetify.breakpoint.xs ? 'mt-4' : '']">
    <v-row

      align="center"
      justify="center">
      <v-col
        v-for="legend in legends"
        :key="legend.title"
        cols="6"
        sm="12"
        class="py-0">
        <div class="legend-title mb-1">
          {{ formatLegendYearLabel(legend.title) }}
        </div>
        <hr
          :style="{'border-color': legend.color, 'max-width': '50%'}"
          :class="{'legend-dash': legend.dash}"
          class="legend-color mb-1" />
        <div
          class="legend-amount mb-2"
          :class="$vuetify.breakpoint.smAndUp ? 'subtitle-1' : 'body-1'">
          <strong>{{ legend.amount }}{{ legendAmountSymbol }}</strong>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'ChartLegend',
  props: {
    legends: {
      type: [Array],
      required: true
    },
    goal: {
      type: Number,
      required: false,
      default: null
    },
    goalLabel: {
      type: [String, Number],
      default: null
    },
    goalYear: {
      type: [Number, String],
      required: false,
      default: null
    },
    legendAmountSymbol: {
      type: [String],
      required: false,
      default: null
    },
    showSalesGoal: {
      type: Boolean,
      default: false
    },
    dialogData: {
      type: Object,
      default: () => {
        return {
          title: String,
          goal: Number,
          action: Function
        }
      }
    }
  },
  data () {
    return {
      dialog: false
    }
  },
  computed: {
    newGoal () {
      const { goal } = this.dialogData

      return goal ? `${goal}` : null
    }
  },
  methods: {
    closeDialog () {
      this.dialog = false
    },
    formatLegendYearLabel (title) {
      if (parseInt(title) === new Date().getFullYear()) {
        const d = new Date()
        d.setMonth(d.getMonth() - 1)
        title = `${title} (YTD - ${d.toLocaleString('en-us', { month: 'short' })})`
      }

      return title
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~styl/colors';

.edit-goal {
  position: relative;
  i {
    position: absolute;
    right: 0;
    transform: translateY(-50%);
  }
}

.legend-color {
  border: 0;
  border-top: 5px solid;
  width: 85%;
  margin: 0 auto;

  @media (min-width: 600px) {
    width: 40px;
  }

  &.legend-dash {
    border-style: dashed;
  }
}

.legend-title {
  color: $spanishGray;
  font-size: 11px;
}

.legend-amount {
  color: #363b4e;
}
</style>
