import { render, staticRenderFns } from "./SendAtRiskEmail.vue?vue&type=template&id=52173fdb&scoped=true"
import script from "./SendAtRiskEmail.vue?vue&type=script&lang=js"
export * from "./SendAtRiskEmail.vue?vue&type=script&lang=js"
import style0 from "./SendAtRiskEmail.vue?vue&type=style&index=0&id=52173fdb&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52173fdb",
  null
  
)

export default component.exports