<template>
  <!-- TODO: This should be refactored in the future -->
  <v-dialog
    v-model="searchDialog"
    class="mobile-search-dialog"
    hide-overlay
    :transition="false"
    scrollable>
    <!-- Search Toggle -->
    <template #activator="{ on }">
      <v-icon
        data-qa="searchIconMobile"
        dark
        v-on="on">
        search
      </v-icon>
    </template>

    <!-- Search Bar -->
    <v-toolbar
      flat
      dark
      color="primary"
      class="mobile-search-dialog">
      <!-- Back Arrow - Closes Search -->
      <!-- <v-col
        class="pl-0 shrink"
        style="z-index:9999">
        <v-icon
          dark
          @click="searchDialog = false">
          arrow_back
        </v-icon>
      </v-col> -->

      <!-- Search Input -->
      <v-col>
        <auto-complete
          background-color="primary"
          color="white"
          class="mobile-search"
          :focus="searchDialog"
          has-item-slot
          @search="goToSearchPage"
          @select="selectSearchItem">
          <template
            slot="item"
            slot-scope="{ item }">
            <customer-item-slot :item="item"></customer-item-slot>
          </template>
        </auto-complete>
      </v-col>
    </v-toolbar>
  </v-dialog>
</template>

<script>
import AutoComplete from '@/components/AutoComplete.vue'
import { CustomerItemSlot } from '@/components/CustomerSearch'

export default {
  name: 'MobileSearchDialog',

  components: {
    AutoComplete,
    CustomerItemSlot
  },

  data: () => ({
    searchDialog: false
  }),

  methods: {
    goToSearchPage (search) {
      this.searchDialog = false
      this.$router.push({
        name: 'customers',
        query: { term: search, source: 'all' }
      })
    },

    selectSearchItem (item) {
      this.searchDialog = false
      window.scrollTo(0, 0)
      if (item) {
        this.$router.push({
          name: 'customer-profile',
          params: { customerId: item.customerId }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.mobile-search-dialog {
  left: 0;
  margin-top: 0;
  position: absolute;
  top: 0;

  .mobile-search {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
}
</style>
