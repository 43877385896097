import { maxLength, isNotBlank, isEmail, isRequired, meetsRegex } from '@/rules'

export const customerFormRules = {
  address1: [
    isRequired('Address required'),
    maxLength(30, 'This field can not be greater than 30 characters')
  ],
  city: [
    isRequired('City required'),
    maxLength(25, 'This field can not be greater than 25 characters')
  ],
  companyName: [
    isRequired('Name is required'),
    maxLength(30, 'This field can not be greater than 30 characters'),
    // Next line is to disable all non-ASCII characters
    meetsRegex(
      // eslint-disable-next-line
      /^[\x00-\x7F]*$/,
      'Only English alpha-numeric characters are allowed'
    )
  ],
  email: [
    isRequired('Email is required'),
    isEmail('Must be a valid email address')
  ],
  firstName: [isRequired('Name is required')],
  lastName: [isRequired('Last name is required')],
  phone: [isRequired('Phone number is required')],
  postalCodeNotRequired: [
    maxLength(10, 'This field can not be greater than 10 characters')
  ],
  postalCode: [
    isRequired('Postal code required'),
    isNotBlank('Postal code must not be blank'),
    meetsRegex(
      // eslint-disable-next-line
      /(^\d{5}$)|(^\d{5}-\d{4}$)/,
      'Not a valid postal code'
    )
  ],
  state: [isRequired('State required')]
}
