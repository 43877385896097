const getFilteredBranches = (state) => (filters) => {
  if (!filters.length || filters.length === 12) return state.branches

  return state.branches.filter((branch) => filters.includes(branch.stateCode))
}

const getBranchEmployeesById = (state) => (id) => {
  return state.branchEmployees[id]
}

const getBranchDetailsById = (state) => (id) => {
  return state.branchDetails[id]
}

export default {
  getFilteredBranches,
  getBranchEmployeesById,
  getBranchDetailsById
}
