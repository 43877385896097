<template>
  <div>
    <v-card class="my-4">
      <v-card-text>
        <product-table-header
          :entity-id="entityId"
          :has-search-categories="hasSearchCategories"
          :fetch-jobs="fetchJobs"
          :export-url="exportUrl"
          :initial-start-date="initialStartDate"
          :initial-end-date="initialEndDate"
          @updateFilters="updateFilters"
          @updateSearch="updateSearch">
        </product-table-header>
        <responsive-table
          v-if="formattedSalesData"
          :route-settings="routeSettings"
          :headers="formattedHeaders"
          :items="formattedSalesData"
          :pagination.sync="pagination"
          :rows-per-page-items="refetchRules.onPagination ? [25] : [5, 10, 25]"
          :no-data-text="noDataText"
          :custom-sort="customSort"
          :total-items="totalItems || undefined"
          mobile-break-point="smAndDown"
          :search="refetchRules.onPagination ? null : search"
          no-results-text="No matching products found."
          @rowClickEvent="rowClickEvent">
          <template
            #pref="{item}">
            <v-checkbox
              v-model="item.preferred"
              class="checkbox"
              hide-details
              @change="setPreferredProduct(item)">
            </v-checkbox>
          </template>
          <template
            #image="{item}">
            <v-row

              justify="start">
              <v-col class="shrink pb-0">
                <a
                  v-if="item.image"
                  :href="`${baseProductUrl}${formatItemNumber(item.itemNumber)}`"
                  target="_blank">
                  <img
                    :src="`https://rexel-cdn.com/Products/${item.catNum}.jpg?i=${item.image}&f=35`"
                    alt="Product image" />
                </a>
                <img
                  v-else
                  src="https://rexel-cdn.com/products/$%7Bp.catNum%7D.jpg?i=0EA7A6C5-7DD2-4482-9229-D628D3AFAE9D&f=35"
                  alt="Product image not found" />
              </v-col>
              <v-col
                v-if="$vuetify.breakpoint.smAndDown"
                class="pb-0">
                <h3>
                  {{ item.catNum }}
                </h3>
              </v-col>
            </v-row>
          </template>
          <template
            #description="{item}">
            <v-col class="ma-0 pa-0">
              <h4> {{ item.description }}</h4>
            </v-col>
          </template>
          <template
            #itemNumber="{item}">
            <v-row>
              <v-col
                v-if="$vuetify.breakpoint.smAndDown">
                {{ item.itemNumber }}
              </v-col>
              <v-col
                v-else>
                <a
                  :href="`${baseProductUrl}${formatItemNumber(item.itemNumber)}`"
                  target="_blank">
                  {{ item.itemNumber }}
                </a>
              </v-col>
            </v-row>
          </template>
        </responsive-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { setPreferredProduct } from '@/api/reports'
import EntityParams from './ListingEntityParams.mixin'
import ProductLevelParams from './ProductLevelParams.mixin'
import ProductTableHeader from './ProductTableHeader.vue'
import { formatDate } from '@/globals/formatters'
import ResponsiveTable from '@/components/ResponsiveTable/ResponsiveTable.vue'

export default {
  name: 'ProductTable',
  components: {
    ProductTableHeader,
    ResponsiveTable
  },
  mixins: [EntityParams, ProductLevelParams],
  props: {
    salesData: {
      type: [Array, Object],
      required: true
    },
    entityType: {
      type: String,
      required: true
    },
    entityId: {
      type: Number,
      required: false,
      default: null
    },
    loading: {
      type: Boolean,
      required: true
    },
    showPreferred: {
      type: Boolean,
      required: false,
      default: false
    },
    initialStartDate: {
      type: String,
      required: true
    },
    initialEndDate: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      ownSalesData: [],
      showClickRow: false, // NOTE: dummy boolean to disable access to product drill down
      pagination: {
        sortDesc: true,
        itemsPerPage: 25,
        sortBy: 'sales'
      },
      filters: {},
      isExactMatchFilter: false,
      search: null
    }
  },
  computed: {
    ...mapGetters(['baseProductUrl']),
    ...mapGetters('user', ['banner']),
    productId () {
      return this.$route.params.productId
    },
    noDataText () {
      if (this.loading) return 'Loading product data...'
      return this.isExactMatchFilter
        ? 'No products found (this search is looking for an exact match).'
        : 'No products found.'
    },
    showFullFilters () {
      return this.entityDataParams.refetchRules.onFilter
    },
    routeSettings () {
      return {
        clickableRows: this.$vuetify.breakpoint.smAndDown,
        eventWithArgs: true,
        parameterNames: ['itemNumber']
      }
    },
    staticHeaders () {
      return [
        {
          text: '',
          sortable: false,
          value: '',
          slot: 'image'
        },
        {
          text: this.$vuetify.breakpoint.smAndDown ? '' : 'Cat Name',
          align: 'left',
          sortable: true,
          value: this.$vuetify.breakpoint.smAndDown ? '' : 'catNum',
          class: 'text-capitalize'
        },
        {
          text: this.$vuetify.breakpoint.smAndDown ? '' : 'Description',
          align: 'left',
          sortable: true,
          value: this.$vuetify.breakpoint.smAndDown ? '' : 'description',
          slot: this.$vuetify.breakpoint.smAndDown ? 'description' : ''
        },
        {
          text: 'Mfr',
          align: 'left',
          sortable: true,
          value: 'mfrName'
        },
        {
          text: 'DC Stocked',
          align: 'left',
          sortable: true,
          value: 'dcStocked'
        },
        {
          text: 'Item #',
          align: 'left',
          sortable: true,
          value: this.$vuetify.breakpoint.smAndDown ? 'itemNumber' : '',
          slot: this.$vuetify.breakpoint.smAndDown ? '' : 'itemNumber'
        },
        {
          text: 'Quantity',
          align: 'left',
          sortable: true,
          value: 'quantity'
        },
        {
          text: 'Tickets',
          align: 'left',
          sortable: true,
          value: 'tickets'
        },
        {
          text: 'Latest Purchase',
          align: 'left',
          sortable: true,
          value: 'maxOrderDate',
          exclude: this.entityType !== 'customer',
          type: 'date'
        },
        {
          text: 'Sales $',
          align: 'left',
          sortable: true,
          value: 'sales',
          type: 'money'
        }
      ]
    },
    formattedHeaders () {
      const headers = this.staticHeaders.slice().filter((header) => !header.exclude)

      if (this.showPreferred) {
        headers.unshift({
          text: 'Pref',
          align: 'left',
          sortable: false,
          slot: 'pref'
        })
      }
      if (this.showClickRow) {
        headers.push({
          text: ' ',
          sortable: false,
          value: ''
        })
      }
      return headers
    },
    isDataEmpty () {
      const { salesData } = this
      return !salesData || (Array.isArray(salesData) && !salesData.length)
    },
    totalItems () {
      const { totalItemsAccessor } = this.entityDataParams
      if (this.isDataEmpty) return 0
      return totalItemsAccessor
        ? totalItemsAccessor(this.salesData)
        : null
    },
    formattedSalesData () {
      const { salesData } = this

      const { fetchAccessor, mapping } = this.entityDataParams
      if (this.isDataEmpty) return []
      let productSales = fetchAccessor ? fetchAccessor(salesData) : salesData
      productSales = productSales.map(mapping)

      // for row keys, set unique id for each row
      return productSales.map((d, index) => ({
        id: index,
        ...d
      }))
    },
    searchPropertyNamesForExport () {
      // get staticHeader values for searchPropertyNames
      return this.staticHeaders.filter((header) => !header.exclude).map((headers) => headers.value)
    }
  },
  watch: {
    pagination (newVal, oldVal) {
      if (this.refetchRules.onPagination && oldVal.page) {
        this.$emit('refetch', { pagination: newVal, search: this.search, filters: this.filters })
      }
    },
    salesData (newVal) {

    }
  },
  methods: {
    async fetchSalesData () {
      const { salesData } = this
      const { fetchAccessor, mapping } = this.entityDataParams

      let productSales = fetchAccessor ? await fetchAccessor(salesData) : salesData
      productSales = productSales.map(mapping)

      this.ownSalesData = productSales.map((d, index) => ({
        id: index,
        ...d
      }))
    },
    changeSort (column) {
      if (this.pagination.sortBy === column) {
        this.pagination.sortDesc = !this.pagination.sortDesc
      } else {
        this.pagination.sortBy = column
        this.pagination.sortDesc = false
      }
    },
    customSort (items, index, isDescending) {
      return items.sort(function (a, b) {
        if (a[index] === b[index]) return 0

        if (!isDescending) {
          return a[index] < b[index] ? -1 : 1
        } else {
          return a[index] < b[index] ? 1 : -1
        }
      })
    },
    updateFilters ({ filters, startDate, endDate, isExactMatchFilter }) {
      // Don't apply new filtering while loading - prevent extra calls to API
      // -OR- branch and company only have search - prevent extras call to API
      if (this.loading || this.entityType === 'branch' || this.entityType === 'company') {
        return null
      }

      // emit refetch (to get fresh results) in 3 cases:
      //  - timeframe is changed
      //  - new filter is not clientOnlyFilter
      //  - new filter is clientOnlyFilter, but previous filter was not clientOnlyFilter
      const timeframeChanged = (this.startDate !== startDate) || (this.endDate !== endDate)

      const newFilterKey = Object.keys(filters).filter((filterKey) => {
        return !!filters[filterKey]
      })

      const isClientOnlyFilter = false
      const emitRefetch = timeframeChanged || !isClientOnlyFilter || (isClientOnlyFilter && Object.keys(this.filters).length)

      if (isClientOnlyFilter) {
        this.search = filters[newFilterKey]
        this.filters = {}
      } else {
        this.search = null
        this.filters = filters
      }
      if (emitRefetch) {
        // merge timeframe into filters
        const filters = { ...this.filters, startDate, endDate }
        this.$emit('refetch', { pagination: this.pagination, filters })
      }

      this.filters = filters
      this.startDate = startDate
      this.endDate = endDate
      this.isExactMatchFilter = isExactMatchFilter
    },
    updateSearch ({ search }) {
      this.search = search
      if (this.refetchRules.onPagination) this.$emit('refetch', { pagination: this.pagination, search })
    },
    setPreferredProduct (item) {
      setPreferredProduct(this.entityId, item.itemNumber, !item.preferred)
        .then(() => {
          this.$store.commit('setToastMessage', {
            message: 'Preference successfully updated',
            status: 'success'
          })
        })
        .catch((e) => {
          this.$store.commit('setToastMessage', {
            message: 'Error setting preferred items',
            status: 'error'
          })
        })
    },
    formatDate (date) {
      return formatDate(date, 'MM/DD/YYYY') ? formatDate(date, 'MM/DD/YYYY') : '-'
    },
    formatItemNumber (catNum) {
      return this.banner === 'platt' ? catNum : Number(catNum)
    },
    rowClickEvent (params) {
      window.open(`${this.baseProductUrl}${params.itemNumber}`, '_blank').focus()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
