<template>
  <div class="back-button">
    <v-btn
      class="back-button"
      icon
      @click="goBack()">
      <v-icon v-if="!pointRight">
        arrow_back
      </v-icon>
      <v-icon v-else>
        arrow_forward
      </v-icon>
    </v-btn>
  </div>
</template>
<script>
export default {
  name: 'BackButton',
  props: {
    to: {
      type: [String, Boolean, Object],
      required: false,
      default: null
    },
    pointRight: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function () {
    const url = this.to ? this.to : this.$router.history
    return {
      url
    }
  },
  methods: {
    goBack () {
      const isString = typeof (this.to) === 'string'
      if (isString) {
        this.$router.push({ name: this.to })
      } else if (this.to) {
        this.$router.push(this.to)
      } else {
        this.$store.dispatch('goBack')
      }
    }
  }
}
</script>
