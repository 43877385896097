export default {
  data () {
    return {
      productLevels: ['section', 'product']
    }
  },
  computed: {
    productLevel () {
      return this.$route.params.productLevel
    }
  },
  methods: {
    clickRow (productId) {
      const {
        entityId,
        entityIds,
        entityType,
        productLevel,
        productLevels
      } = this
      const childLevelIndex = productLevels.indexOf(productLevel) + 1
      const isCompany = entityType === 'company'
      const route = {
        name: 'top-sold-products-report-list' + isCompany ? '' : '-company',
        params: {
          productLevel: productLevels[childLevelIndex],
          entityType: this.entityType.toLowerCase(),
          productId
        }
      }
      if (entityIds) {
        route.query = {
          ids: entityIds.join(',')
        }
      } else {
        route.params.id = entityId
      }
      this.$router.push(route)
    }
  }
}
