<template>
  <div>
    <v-card class="my-4">
      <v-card-text>
        <v-row>
          <v-col
            cols="10"
            sm="6"
            md="4">
            <v-text-field
              v-model="search"
              label="Search"
              hide-details
              append-icon="search"
              filled>
            </v-text-field>
          </v-col>
          <v-col class="text-right">
            <v-btn
              small
              fab
              @click="buildExcelSpreadsheet()">
              <v-icon>file_download</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <div class="text-right font-weight-bold">
          Sales and SKUs from last 365
        </div>
        <v-data-table
          v-if="parsedDataForComparison"
          :headers="formattedHeaders"
          :items="parsedDataForComparison"
          :sort-by.sync="pagination.sortBy"
          :sort-desc.sync="pagination.sortDesc"
          :items-per-page.sync="pagination.itemsPerPage"
          :footer-props="{ 'items-per-page-options': [5, 10, 25] }"
          :search="search"
          :custom-sort="customSort">
          <template
            slot="item"
            slot-scope="props">
            <tr
              class="clickable"
              @click="clickRow(props.item.categoryId)">
              <td>
                <img
                  v-if="props.item.imageFileFolderName"
                  :src="`https://rexel-cdn.com/Products/${props.item.categoryName}.jpg?i=${props.item.imageFileFolderName}&f=35`"
                  alt="Product image" />
                <img
                  v-else
                  src="https://rexel-cdn.com/products/$%7Bp.catNum%7D.jpg?i=0EA7A6C5-7DD2-4482-9229-D628D3AFAE9D&f=35"
                  alt="Product image not found" />
              </td>
              <td>{{ props.item.categoryName }}</td>
              <td
                v-for="id in entityIds"
                :key="`category-column-${id}`">
                {{ props.item[id] | formatMoney }}
              </td>
              <td>
                <v-icon>arrow_forward</v-icon>
              </td>
            </tr>
          </template>
          <!-- TEMP FIX - Awaiting Vuetify fix for issue: https://github.com/vuetifyjs/vuetify/issues/14405 -->
          <!-- eslint-disable -->
          <template v-slot:footer.page-text="props">
            {{ props.pageStart }}-{{ props.pageStop }} of {{ props.itemsLength.toLocaleString('en-us') }}
          </template>
          <!-- eslint-enable -->
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import zipcelx from 'zipcelx'
import EntityParams from './ListingEntityParams.mixin'
import CategoryLevelParams from './CategoryLevelParams.mixin'

export default {
  name: 'CategoryTableCompare',
  mixins: [EntityParams, CategoryLevelParams],
  props: {
    categorySales: {
      type: Array,
      required: true
    },
    entityType: {
      type: String,
      required: true
    },
    entityIds: {
      type: Array,
      required: true
    },
    entityIdentifier: {
      type: String,
      required: true
    },
    exportUrl: {
      type: String,
      required: true
    },
    primaryColumn: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      staticHeaders: [
        {
          text: '',
          sortable: false,
          value: ''
        },
        {
          text: ' ', // Vuetify uses text as a key. This has to be a space to differentiate from the other empty header column
          sortable: false,
          value: ''
        }
      ],
      pagination: {
        sortDesc: true,
        itemsPerPage: 25,
        sortBy: null
      },
      search: null
    }
  },
  computed: {
    categoryId () {
      return this.$route.params.categoryId
    },
    formattedHeaders () {
      const headers = this.staticHeaders.slice()
      const categoryHeader = {
        text: this.primaryColumn,
        align: 'left',
        sortable: true,
        value: 'categoryName',
        class: 'text-capitalize'
      }
      headers.splice(1, 0, categoryHeader)
      const dataHeaders = this.entityIds.map((id) => {
        const name = this.entityNames[id]
        return {
          text: name,
          align: 'left',
          sortable: true,
          value: id
        }
      })
      headers.splice(2, 0, ...dataHeaders)
      return headers
    },
    parsedDataForComparison () {
      const { categorySales, entityIdentifier } = this
      const dataCategoryIdHash = categorySales.reduce((memo, x) => {
        const key = x.categoryId
        if (!memo[key]) { memo[key] = [] }
        memo[key].push(x)
        return memo
      }, {})

      // parse into array of categories with entity (customer, branch, etc.) data values keyed by entityIdentifier
      const parsedData = Object.keys(dataCategoryIdHash).map((categoryId) => {
        const entityDataById = dataCategoryIdHash[categoryId].reduce((memo, d) => {
          const obj = {}
          obj[d[entityIdentifier]] = d.currentYearSales
          obj.imageFileFolderName = d.imageFileFolderName
          obj.categoryName = d.categoryName
          return { ...memo, ...obj }
        }, {})
        return { categoryId, ...entityDataById }
      })
      return parsedData
    }
  },
  mounted () {
    this.getNames()
    this.setInitialSort()
  },
  methods: {
    changeSort (column) {
      if (this.pagination.sortBy === column) {
        this.pagination.sortDesc = !this.pagination.sortDesc
      } else {
        this.pagination.sortBy = column
        this.pagination.sortDesc = false
      }
    },
    categorySalesSort (index, isDescending) {
      return function (a, b) {
        if (a.categoryName === 'Uncategorized') return -1
        if (b.categoryName === 'Uncategorized') return 1
        if (a[index] === b[index]) return 0

        if (!isDescending) {
          return a[index] < b[index] ? -1 : 1
        } else {
          return a[index] < b[index] ? 1 : -1
        }
      }
    },
    customSort (items, index, isDescending) {
      return items.sort(this.categorySalesSort(index, isDescending))
    },
    setInitialSort () {
      this.pagination.sortBy = this.entityIds[0]
    },
    buildExcelSpreadsheet () {
      const fileName = 'TopSalesByCategoryComparison'
      const rowOrder = []

      const fileHeaders = this.formattedHeaders
        .filter(header => header.text.trim())
        .map(item => {
          if (item.text) {
            typeof item.value === 'string'
              ? rowOrder.unshift(item.value)
              : rowOrder.push(item.value)

            return {
              value: item.text.charAt(0).toUpperCase() + item.text.slice(1),
              type: 'string'
            }
          }
          return item
        })

      const fileRows = this.parsedDataForComparison
        .sort(this.categorySalesSort(this.pagination.sortBy, this.pagination.sortDesc))
        .map(item => {
          const row = []

          rowOrder.forEach(val => {
            const obj = {}
            obj.value = item[val]
            obj.type = typeof item[val]
            row.push(obj)
          })

          return row
        })

      zipcelx({
        filename: fileName,
        sheet: {
          data: [fileHeaders].concat(fileRows)
        }
      })
    }
  }
}
</script>
