<template>
  <v-dialog
    v-model="syncedOpen"
    max-width="500px"
    @keydown.esc="close()">
    <v-toolbar
      flat
      dark
      color="primary">
      <v-toolbar-title>High Risk Feedback</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        icon
        dark
        @click="close()">
        <v-icon>close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card>
      <v-card-text>
        <div class="modal-explanation py-2">
          Complete feedback if answers are known, otherwise complete later.
        </div>
        <div class="mb-4 font-weight-bold">
          {{ customer.customerName }} ({{ customer.companyCustomerNumber }})
        </div>
        <v-select
          v-model="selectedReason"
          :items="reasonsSelectList"
          label="Reason customer is not purchasing:"
          filled
          :rules="rules.selectionRequired">
        </v-select>
        <v-select
          v-model="selectedResponse"
          :items="responsesSelectList"
          label="Actions taken:"
          filled
          :rules="rules.selectionRequired">
        </v-select>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="close()">
          Complete Later
        </v-btn>
        <v-btn
          :disabled="!selectedReason || !selectedResponse"
          :loading="loading"
          color="primary"
          @click="submit()">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { saveHighRiskCustomerActionAsync } from '@/api/reports'
import { isRequired } from '@/rules'

export default {
  name: 'AtRiskTakeActionDialog',

  props: {
    open: {
      type: Boolean,
      required: true
    },
    customer: {
      type: Object,
      required: true
    },
    /** @type { {churnReasonId: number, churnReasonDescription: string}[] } */
    churnReasons: {
      type: Array,
      required: true
    },
    /** @type { {churnResponseId: number, churnResponseDescription: string}[] } */
    churnResponses: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      /** @type { {churnReasonId: number, churnReasonDescription: string}[] } */
      // churnReasons: [],
      /** @type { {churnResponseId: number, churnResponseDescription: string}[] } */
      // churnResponses: [],
      rules: {
        selectionRequired: [
          isRequired('Must select an option')
        ]
      },
      selectedReason: null,
      selectedResponse: null,
      loading: false
    }
  },

  computed: {
    ...mapGetters('user', ['user']),
    syncedOpen: {
      get: function () {
        return this.open
      },
      set: function (value) {
        this.$emit('update:open', value)
      }
    },
    /** @returns { {value: number, text: string}[] } */
    reasonsSelectList () {
      return this.churnReasons.map((x) => {
        return {
          value: x.churnReasonId,
          text: x.churnReasonDescription
        }
      })
    },
    /** @returns { {value: number, text: string}[] } */
    responsesSelectList () {
      return this.churnResponses.map((x) => {
        return {
          value: x.churnResponseId,
          text: x.churnResponseDescription
        }
      })
    }
  },

  methods: {
    close () {
      this.$emit('close')
    },
    async submit () {
      const action = {
        companyId: this.user.companyId,
        customerId: this.customer.customerId,
        reasonId: this.selectedReason,
        responseId: this.selectedResponse
      }

      this.loading = true
      const success = await saveHighRiskCustomerActionAsync(action)
      this.loading = false

      if (success) {
        this.$store.commit('setToastMessage', {
          message: 'Responses saved',
          status: 'success'
        })
        this.$emit('submit-succeeded')
      } else {
        this.$store.commit('setToastMessage', {
          message: 'Error saving selected responses',
          status: 'error'
        })
        this.$emit('submit-failed')
      }
    }
  }
}
</script>
