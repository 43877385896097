import customersModule from './customers'
import vendorsModule from './vendors'

const state = {
}

export default {
  namespaced: true,
  state,
  modules: {
    customers: customersModule,
    vendors: vendorsModule
  }
}
