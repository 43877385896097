const setIsFetching = (state, isFetching) => {
  state.isFetching = isFetching
}

const setIsSaving = (state, isSaving) => {
  state.isSaving = isSaving
}

const setSalesPlans = (state, payload) => {
  const customerType = typeof payload.customerType === 'undefined' ? false : payload.customerType
  const salesPlans = typeof payload.salesPlans === 'undefined' ? payload : [payload.salesPlans]
  salesPlans.forEach(plan => {
    plan.planningYears = {}

    for (let i = 0; i < 5; i++) {
      const planningYear = new Date().getFullYear() + i
      const objKeyAppendVal = i ? i + 1 : ''

      plan.planningYears[planningYear] = {
        salesGoalStock: plan['salesGoalStock' + objKeyAppendVal],
        gmGoalStock: plan['gmGoalStock' + objKeyAppendVal],
        salesGoalDirect: plan['salesGoalDirect' + objKeyAppendVal],
        gmGoalDirect: plan['gmGoalDirect' + objKeyAppendVal],
        salesGoalTotal: plan['salesGoalTotal' + objKeyAppendVal],
        gmGoalTotal: plan['gmGoalTotal' + objKeyAppendVal]
      }

      delete plan['salesGoalStock' + objKeyAppendVal]
      delete plan['gmGoalStock' + objKeyAppendVal]
      delete plan['salesGoalDirect' + objKeyAppendVal]
      delete plan['gmGoalDirect' + objKeyAppendVal]
      delete plan['salesGoalTotal' + objKeyAppendVal]
      delete plan['gmGoalTotal' + objKeyAppendVal]
    }
  })

  const plansByCustomerId = salesPlans.reduce((plans, plan) => {
    plans[plan.customerId] = plan
    return plans
  }, {})

  switch (customerType) {
    case 'billToCustomer':
      state.salesPlansBillTo = plansByCustomerId
      break
    case 'shipToCustomer':
      state.salesPlansShipTo = plansByCustomerId
      break
    case 'jobsCustomer':
      state.salesPlansJobs = plansByCustomerId
      break
    default:
      state.salesPlans = plansByCustomerId
  }
}

const updateSalesPlan = (state, plan) => {
  const oldPlan = state.salesPlans[plan.customerId]
  state.salesPlans = {
    ...state.salesPlans,
    [plan.customerId]: { ...oldPlan, ...plan }
  }
}

export default {
  setIsFetching,
  setIsSaving,
  setSalesPlans,
  updateSalesPlan
}
