<template>
  <v-card class="mb-4">
    <page-loading-progress v-if="loading || !info">
    </page-loading-progress>
    <v-container
      v-else
      fluid>
      <v-row>
        <v-col
          cols="12"
          sm="3"
          md="2"
          lg="2"
          xl="1"
          class="hidden-sm-and-down">
          <v-avatar size="75">
            <img
              v-if="info.imageFileFolderName"
              :src="`https://rexel-cdn.com/Products/${info.categoryId}.jpg?i=${info.imageFileFolderName}`"
              alt="Product image" />
            <img
              v-else
              src="https://rexel-cdn.com/products/$%7Bp.catNum%7D.jpg?i=0EA7A6C5-7DD2-4482-9229-D628D3AFAE9D&f=35"
              alt="Product image not found" />
          </v-avatar>
        </v-col>
        <v-col>
          <h3>{{ info.categoryName }}</h3>
          <div class="summary-row">
            {{ info.description }}
          </div>
          <div class="badges mt-4 hidden-sm-and-down">
            <div v-if="info.markets.length > 0">
              Markets:
              <v-chip
                v-for="(market, i) in info.markets"
                :key="`market_${i}`"
                small
                label
                color="primary"
                class="badge no-border-radius ml-2">
                <span class="white--text">
                  {{ market }}
                </span>
              </v-chip>
            </div>
            <div v-if="info.keywords.length > 0">
              Keywords:
              <v-chip
                v-for="(keyword, i) in info.keywords"
                :key="`keyword_${i}`"
                small
                label
                color="primary"
                class="badge no-border-radius ml-2">
                <span class="white--text">
                  {{ keyword }}
                </span>
              </v-chip>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { getCategoryInfo } from '@/api/reports'
import PageLoadingProgress from '@/components/PageLoadingProgress.vue'

export default {
  name: 'CategoryInfo',
  components: {
    PageLoadingProgress
  },
  props: {
    categoryId: {
      type: [Number, String],
      required: true
    }
  },
  data () {
    return {
      info: null,
      loading: false
    }
  },
  watch: {
    categoryId () {
      this.fetchData()
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      this.loading = true
      getCategoryInfo(this.categoryId)
        .then((res) => {
          this.info = res.data
        })
        .catch((e) => {
          console.error(e)
          this.$store.commit('setToastMessage', {
            message: 'Error fetching stats'
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~styl/colors';

h3 {
  font-size: 18px;
}

.headline {
  font-weight: 500;
  font-size: 30px !important;
}

.summary-row {
  color: $lightGrey;
}
</style>
