import {
  getCategoryForCompanyReport,
  byCategoryForCompanyReportExportUrl,
  getByCategoryForCustomerReport,
  byCategoryForCustomerReportExportUrl,
  getCategoryForBranchReport,
  byCategoryForBranchReportExportUrl,
  getCategoryForEmployeeReport,
  byCategoryForEmployeeReportExportUrl
} from '@/api/reports'
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      entityNames: {}
    }
  },
  computed: {
    validEntityTypes () {
      return ['customer', 'branch', 'company']
    },
    isValidEntityType () {
      return this.validEntityTypes.includes(this.entityType)
    },
    entityTypePlural () {
      switch (this.entityType) {
        case 'branch':
          return 'branches'
        default:
          return this.entityType + 's'
      }
    },
    entityDataParams () {
      /*
        For EntityListing: category sales data for selected entity(ies)
        Define parameters for different report entities: customer, branch, employee
        {
          fetch = api call
          identifier = id prop in categorySales
          chartDataIdentifier = id prop in chartData
          lookupEntity = use entity id to get name
          exportUrl = export url
        }
        NOTE: identifier and chartDataIdentifier are only needed for comparison
      */
      switch (this.entityType) {
        case 'customer':
          return {
            fetch: getByCategoryForCustomerReport,
            identifier: 'customerId',
            chartDataIdentifier: 'customerID',
            lookupEntity: {
              getter: this.getCustomer,
              nameProp: 'name'
            },
            exportUrl: byCategoryForCustomerReportExportUrl
          }
        case 'branch':
          return {
            fetch: getCategoryForBranchReport,
            identifier: 'branchId',
            chartDataIdentifier: 'branchId',
            lookupEntity: {
              getter: this.getBranch,
              nameProp: 'branchName'
            },
            exportUrl: byCategoryForBranchReportExportUrl
          }
        case 'employee':
          return {
            fetch: getCategoryForEmployeeReport,
            identifier: 'employeeId',
            chartDataIdentifier: 'employeeId',
            exportUrl: byCategoryForEmployeeReportExportUrl
          }
        case 'company':
          return {
            fetch: getCategoryForCompanyReport,
            exportUrl: byCategoryForCompanyReportExportUrl
          }
        default:
          // entityType not recognized...
          console.error('Report cannot be shown')
          return {}
      }
    }
  },
  methods: {
    ...mapActions('customers', ['getCustomer']),
    ...mapActions('branches', ['getBranch']),
    getNames (id) {
      const {
        getter,
        nameProp
      } = this.entityDataParams.lookupEntity

      this.entityIds.forEach(async (id) => {
        const entity = await getter(id)
        this.$set(this.entityNames, id, entity[nameProp])
      })
    }
  }
}
