<template>
  <LineChartJS
    class="line-chart"
    data-qa="line-chart"
    :data="chartData"
    :options="mergedOptions"></LineChartJS>
</template>

<script>
// import accounting from 'accounting'
import { Line as LineChartJS } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement)

export default {
  name: 'LineChart',
  components: { LineChartJS },
  props: {
    chartData: {
      type: Object,
      required: true,
      default: () => {}
    },
    chartOptions: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      defaultOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            min: 0,
            ticks: {
              callback (value) {
                if (value < 1000) {
                  return value.toString()
                } else if (value < 10000) {
                  return Math.round(value / 100) / 10 + 'K'
                } else if (value < 1000000) {
                  return Math.round(value / 1000) + 'K'
                } else if (value < 10000000) {
                  return (value / 1000000).toFixed(2) + 'M'
                } else if (value < 1000000000) {
                  return Math.round(value / 1000000) + 'M'
                } else if (value < 1000000000000) {
                  return Math.round(value / 1000000000) + 'B'
                }
              }
            }
          }
        }
      }
    }
  },
  computed: {
    mergedOptions () {
      return {
        ...this.defaultOptions,
        ...this.chartOptions
      }
    }
  }
}
</script>
