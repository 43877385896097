const setCategories = (state, categories) => {
  state.categories = categories
}
const setStatuses = (state, statuses) => {
  state.statuses = statuses
}
const setTypes = (state, types) => {
  state.types = types
}
const setDuplicatedActivity = (state, activity) => {
  state.duplicatedActivity = activity
}

const SET_START_DATE = (state, date) => {
  state.startDate = date
}

const SET_END_DATE = (state, date) => {
  state.endDate = date
}

export default {
  setCategories,
  setStatuses,
  setTypes,
  setDuplicatedActivity,
  SET_START_DATE,
  SET_END_DATE
}
