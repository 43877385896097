import TopSoldProductsReport from '@/pages/Reports/TopSoldProductsReport/TopSoldProductsReport.vue'
import TopSoldProductsListing from '@/pages/Reports/TopSoldProductsReport/ProductListing/ProductListing.vue'

/*
  Routes for Top Sold Products Reports

  entityTypes = [customer, branch, region?, employee]
    - company is treated as a special case since it has no comparison

  categoryLevels = [section, product]
*/

export const topSoldProductsRoutes = [
  {
    path: 'reports/top-sold-products',
    name: 'top-sold-products-report',
    component: TopSoldProductsReport,
    meta: { title: 'Top Sold Products' }
  }, {
    // company-wide route
    path: 'reports/top-sold-products/listing/company/:productLevel/:productId?',
    name: 'top-sold-products-report-list-company',
    meta: { title: 'Top Products List: Company' },
    component: TopSoldProductsListing,
    props: (route) => {
      return {
        ...route.params,
        id: null,
        entityType: 'company'
      }
    }
  }, {
    // entity comparison route
    path: 'reports/top-sold-products/compare/:entityType/:productLevel/:productId?',
    name: 'top-sold-products-report-list-compare',
    component: TopSoldProductsListing,
    meta: { title: 'Top Products List: Compare' },
    props: (route) => {
      // cast ids as array of numbers
      const ids = (route.query.ids || '')
        .split(',')
        .map((id) => Number.parseInt(id))
      return {
        ...route.params,
        ids
      }
    }
  }, {
    // single entity (not company)
    path: 'reports/top-sold-products/listing/:entityType/:id/:productLevel/:productId?',
    name: 'top-sold-products-report-list',
    component: TopSoldProductsListing,
    meta: { title: 'Top Products List' },
    props: (route) => {
      // cast id as number, wrap in array
      const id = Number.parseInt(route.params.id)
      return {
        ...route.params,
        ids: [id]
      }
    }
  }
]
