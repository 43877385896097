import { fetchBranch, fetchBranches } from '@/api/branches'
import { formatBranchName } from '@/globals/formatters'

const getBranch = async ({ commit, state: { branches } }, branchId) => {
  const cachedBranch = branches.find(b => ~~b.branchId === ~~branchId)
  if (cachedBranch) return cachedBranch

  const { data } = await fetchBranch(branchId)
  commit('setBranch', data.branch)
  return data.branch
}

const getBranches = ({ commit, state: { branches, branchesLoaded } }) => {
  if (!branches || branches.length === 0 || !branchesLoaded) {
    fetchBranches().then(res => {
      const formattedBranches = res.data
        ? res.data.map((branch) => ({
          ...branch,
          value: branch.branchNumber,
          text: formatBranchName(branch)
        }))
        : []
      commit('setBranches', formattedBranches)
    })
  }
}

// method to bind branchid rather than branch number
const getBranchesId = ({ commit, state: { branchesById, branchesByIdLoaded } }) => {
  if (!branchesById || branchesById.length === 0 || !branchesByIdLoaded) {
    fetchBranches().then(res => {
      const formattedBranches = res.data
        ? res.data.map((branch) => ({
          ...branch,
          value: branch.branchId,
          text: formatBranchName(branch)
        }))
        : []
      commit('setBranchesById', formattedBranches)
    })
  }
}

const resetBranches = ({ commit, state: { branchesLoaded } }) => {
  if (branchesLoaded) {
    commit('resetBranches')
  }
}

export default {
  getBranch,
  getBranches,
  getBranchesId,
  resetBranches
}
