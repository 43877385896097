import { validateEmail } from '@/globals/utils'
const UserProfile = () => import('@/pages/UserProfile/UsersProfile.vue')

export const userRoutes = [
  {
    name: 'user-profile',
    path: 'user-profile/',
    meta: { title: 'User Profile' },
    props: route => ({ email: route.query.email }),
    component: UserProfile,
    beforeEnter: (to, from, next) => {
      const emailIsValid = validateEmail(to.query.email)
      if (!emailIsValid) {
        next({ path: 'customers', query: { source: 'users' } })
        return
      }
      next()
    }
  }
]
