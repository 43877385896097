import axios from 'axios'
import { callApi, generateUrl } from '@/globals/utils'

export const createActivity = (activityGroup, form) =>
  axios.post(`/api/activities/add/${activityGroup}`, form)

export const getCreateActivityInOutlookUrl = (customerId, subject, location, description, date, time) =>
  generateUrl('/api/outlook/create-event', { customerId, subject, location, description, date, time })

export const getActivity = (rrn, webId, type) =>
  axios.get(`/api/activities/detail?rrn=${rrn}&webId=${webId}&activityType=${type}`)

export const getActivities = (startDate, endDate) =>
  axios.get(`/api/activities?startDate=${startDate}&endDate=${endDate}`)

export const getPrograms = () =>
  axios.get('/api/activities/getprograms')

export const getTypes = () =>
  axios.get('/api/activities/gettypes')

export const getVendors = () =>
  axios.get('/api/activities/getvendors')

export const updateActivity = (type, form) =>
  axios.post(`/api/activities/update/${type}`, form)

export const getExportEventUrl = (activity, comments, customerId, location, date) =>
  generateUrl('/api/outlook/create-event', { activity, comments, customerId, location, date })

export const getOutlookSyncUrl = () =>
  axios.get('/api/outlook/sync-url')

export const searchActivities = (term, customerId, top = 10) =>
  callApi({ url: `/api/v1/Activities/search?term=${term}`, params: { top, customerId } })

export const getCustomerChurnStatus = (companyId, customerId) =>
  callApi({ url: '/api/v1/customers-at-high-risk/check-status', params: { companyId, customerId } })

export const getChurnReasonsAndResponses = (companyId) =>
  axios.get('/api/v1/customers-at-high-risk/reasons-and-responses')

/**
   * @returns {{ reasons: [], responses: [] }}
   */
export const getChurnReasonsAndResponsesAsync = async (companyId) => {
  try {
    const res = await axios.get('/api/v1/customers-at-high-risk/reasons-and-responses')
    return res.data
  } catch (err) { // Should really be using a service for this, that abstracts these calls & handling away
    return null
  }
}

// Methods for single activity
export const isV2Activity = (activity) => !(activity.dataSourceCode === 'PLT')

export const editLink = (activity) => {
  if (activity !== null) {
    if (isV2Activity(activity)) {
      // rexel
      return {
        name: 'v2-edit-activity',
        params: { id: activity.activityId }
      }
    } else {
      const { activityType, rrn, uniqueIDForWebCreated } = activity
      return {
        name: 'edit-activity',
        params: {
          type: activityType.toLowerCase(),
          rrn,
          webId: uniqueIDForWebCreated
        }
      }
    }
  }
  return {}
}

export const duplicateLink = (activity) => {
  if (isV2Activity(activity)) {
    return {
      name: 'v2-create-activity',
      query: { duplicate: true },
      params: { id: activity.activityId, recurringId: activity.recurringActivityId }
    }
  }
  return {
    name: 'create-activity',
    query: { duplicate: true }
  }
}

export const exportEventLink = (act) => {
  const { activity, comments, customerID, location, scheduleDate } = act
  return getExportEventUrl(activity, comments || '', customerID, location, scheduleDate)
}

export const activityType = (activity) => {
  return isV2Activity(activity)
    ? activity.activityType
    : activity.activity
}
