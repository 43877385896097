<template>
  <v-container
    fluid>
    <v-row
      class="fluid"
      align="center"
      :reverse="$vuetify.breakpoint.xs">
      <v-col
        v-if="isComparison"
        cols="auto"
        class="text-h6">
        Selected {{ entityTypePlural | formatTitleCase }}
      </v-col>
      <v-spacer></v-spacer>
      <v-col
        cols="auto"
        class="text-right">
        <v-btn
          :to="{name: 'sales-by-category-report'}"
          tile
          color="primary">
          Back to Category Report
        </v-btn>
      </v-col>
    </v-row>
    <entity-summary
      :entity-type="entityType"
      :ids="ids"
      :color-by-id="colorById"
      class="mb-4"
      @loaded="summaryLoading = false">
    </entity-summary>
    <category-info
      v-if="categoryId && Number.parseInt(categoryId) !== -1"
      :category-id="categoryId">
    </category-info>
    <page-loading-progress v-if="summaryLoading || loading">
    </page-loading-progress>
    <template v-else-if="chartData.length || categorySales.length">
      <template v-if="isComparison">
        <category-chart-compare
          :color-by-id="colorById"
          :chart-data="chartData"
          :entity-type="entityType"
          :entity-ids="ids"
          :entity-identifier="entityChartIdentifier">
        </category-chart-compare>
        <category-table-compare
          v-if="categoryLevel !== 'item'"
          :category-sales="categorySales"
          :entity-type="entityType"
          :entity-ids="ids"
          :entity-identifier="entityIdentifier"
          :export-url="exportUrl"
          :primary-column="categoryLevel">
        </category-table-compare>
      </template>
      <template v-else>
        <category-chart
          :chart-data="chartData">
        </category-chart>
        <category-table
          v-if="categoryLevel !== 'item'"
          :category-sales="categorySales"
          :entity-id="singleId"
          :entity-type="entityType"
          :export-url="exportUrl"
          :primary-column="categoryLevel">
        </category-table>
      </template>
    </template>
  </v-container>
</template>

<script>
import PageLoadingProgress from '@/components/PageLoadingProgress.vue'
import EntityParams from './ListingEntityParams.mixin'
import EntitySummary from '../../shared/EntitySummary.vue'
import { mapGetters } from 'vuex'
import CategoryInfo from './CategoryInfo.vue'
import CategoryChart from './CategoryChart.vue'
import CategoryChartCompare from './CategoryChartCompare.vue'
import CategoryTable from './CategoryTable.vue'
import CategoryTableCompare from './CategoryTableCompare.vue'

export default {
  name: 'CategoryListing',
  components: {
    EntitySummary,
    CategoryInfo,
    CategoryChart,
    CategoryChartCompare,
    CategoryTable,
    CategoryTableCompare,
    PageLoadingProgress
  },
  mixins: [EntityParams],
  props: {
    categoryLevel: {
      // route param
      type: String,
      required: true,
      default: null
    },
    categoryId: {
      // route param
      type: [Number, String],
      required: false,
      default: null
    },
    entityType: {
      // route param
      type: String,
      required: true,
      default: null
    },
    ids: {
      // route param
      type: Array,
      required: false,
      default: () => []
    }
  },
  data () {
    return {
      summaryLoading: false,
      loading: false,
      categorySales: [],
      chartData: []
    }
  },
  computed: {
    ...mapGetters('user', ['banner']),
    isComparison () {
      return this.ids.length > 1
    },
    entityIsCompany () {
      return this.entityType === 'company'
    },
    singleId () {
      return this.ids[0]
    },
    fetchMethod () {
      return this.entityDataParams.fetch
    },
    exportUrl () {
      const { ids, categoryLevel, categoryId } = this
      const { exportUrl } = this.entityDataParams
      return exportUrl(`${categoryLevel} list`, ids, categoryId)
    },
    entityIdentifier () {
      return this.entityDataParams.identifier
    },
    entityChartIdentifier () {
      const { chartDataIdentifier, identifier } = this.entityDataParams
      return chartDataIdentifier || identifier
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (newVal) {
        if (this.isValidEntityType) this.fetchData()
      }
    },
    isValidEntityType: {
      immediate: true,
      handler (newVal) {
        if (!newVal) this.$router.push({ name: 'sales-by-category-report' })
      }
    }
  },
  async beforeMount () {
    this.$store.commit('setPageHeader', {
      page: 'Reports',
      text: 'Top Sales by Category'
    })
    if (this.entityType !== 'company') this.summaryLoading = true
  },
  methods: {
    async fetchData () {
      try {
        const { isComparison, entityIsCompany, fetchMethod, ids, singleId, categoryId } = this
        this.loading = true
        this.categorySales = []
        this.chartData = []

        const fetchArgs = isComparison
          ? [ids, categoryId]
          : entityIsCompany
            ? [categoryId]
            : [singleId, categoryId]

        const res = await fetchMethod(...fetchArgs)
        const { categorySales, chartData } = res.data
        this.categorySales = categorySales
        this.chartData = chartData
      } catch (e) {
        console.error(e)
        this.$store.commit('setToastMessage', {
          message: 'Error fetching sales data',
          status: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    colorById (id) {
      const { ids } = this
      const { graphPrimary, graphSecondary, graphTertiary } = this.$vuetify.theme.currentTheme
      // safely converts both to ints before comparing
      const index = ids.findIndex(i => ~~i === ~~id)
      if (index === -1) return graphPrimary
      return [graphPrimary, graphSecondary, graphTertiary][index]
    }
  }
}
</script>
