export const simplifiedDashboard = (state) => {
  return state.simplifiedDashboard
}

export const displaySimplifiedDashboard = (state) => {
  return state.displaySimplifiedDashboard
}

export const simplifiedDashboardLoaded = (state) => {
  return state.loaded
}
