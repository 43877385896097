<template>
  <v-row
    dense
    class="py-2 flex-nowrap"
    align="center">
    <v-col
      cols="auto"
      class="flex-grow-1 flex-shrink-1"
      :style="!flexIt ? 'width: 400px' : ''">
      <customer-list-tile-title
        :customer="customer"
        :append-status="appendStatus"
        :status="shipToBillToStatus(customer)">
      </customer-list-tile-title>

      <v-col class="text--primary pa-0">
        <slot name="subtitle">
          <customer-list-tile-subtitle
            :customer="customer"
            :banner="getBannerName(customer.companyId)">
          </customer-list-tile-subtitle>
        </slot>
      </v-col>
    </v-col>

    <v-spacer></v-spacer>

    <v-col
      v-if="!prependSalesStats && customer.rolling12Sales"
      class="list-title">
      {{ customer.rolling12Sales | formatMoney }}
    </v-col>

    <v-col>
      <v-row
        dense
        justify="end">
        <v-col
          cols="auto"
          class=" list-sales d-flex flex-column">
          {{ (customer.rolling12Sales || 0) | formatMoney }}
        </v-col>
      </v-row>
      <v-row
        dense
        justify="end">
        <v-col
          v-if="customer.isEnterprise"
          cols="auto"
          class="shrink pt-0">
          <img
            src="@/assets/icons/is-enterprise.svg"
            alt="Is Enterprise logo" />
        </v-col>

        <v-col
          v-else
          cols="auto"
          class="shrink pt-0">
          <div class="icon">
            <img
              v-if="customer.plattPlus && $hasFeature('Customers_Plus')"
              src="@/assets/icons/is-plus.svg"
              alt="Is Plus" />
            <img
              v-if="!customer.plattPlus && $hasFeature('Customers_Plus')"
              src="@/assets/icons/is-not-plus.svg"
              alt="Is Not Plus" />
            <img
              v-if="customer.pim && $hasFeature('Customers_Pim')"
              src="@/assets/icons/is-pim.svg"
              alt="Is PIM" />
            <img
              v-if="!customer.pim && $hasFeature('Customers_Pim')"
              src="@/assets/icons/is-not-pim.svg"
              alt="Is Not PIM" />
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex'
import CustomerListTileSubtitle from './CustomerListTileSubtitle.vue'
import CustomerListTileTitle from './CustomerListTileTitle.vue'
import { bannerProvider } from '@/features/banners'

export default {
  name: 'CustomerListTileDesktop',
  components: {
    CustomerListTileSubtitle,
    CustomerListTileTitle
  },
  props: {
    customer: {
      type: Object,
      required: true
    },
    prependSalesStats: {
      type: Boolean,
      default: true
    },
    appendStatus: {
      type: Boolean,
      default: true
    },
    flexIt: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters('user', ['banner'])
  },
  methods: {
    shipToBillToStatus (customer) {
      const statuses = []
      if (customer.jobAccountFlag) {
        statuses.push('Job Account')
        return `(${statuses.join(', ')})`
      }
      if (customer.shipToFlag) {
        statuses.push('Ship-To')
      }
      if (customer.billToFlag) {
        statuses.push('Bill-To')
      }
      return `${statuses.join(', ')}`
    },
    getBannerName (companyId) {
      return bannerProvider.getNameFromId(companyId)
    }
  }
}
</script>
<style  lang="scss" scoped>
@import '~styl/colors';

.vertical-align-items * {
  vertical-align: middle;
}

.customer-status {
  display: inline-block;
  height: 10px;
  width: 10px;

  border-radius: 50%;
  margin-right: 6px;
  margin-bottom: 3px;
}

.list-title {
  font-weight: 500;
  text-transform: uppercase;
  color: #000 !important
}

.muted {
  color: $spanishGray;
  font-size: 14px;
}

.sub-title-section {
  display: inline-block;
  margin-right: 15px;
  background-color: #eee;
  font-size: 12px;
  padding: 3px 10px;
  margin-top: 5px;
}

.list-sales {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
}

.icon img {
  margin: 2px 0 2px 5px;
}

</style>
