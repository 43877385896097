<template>
  <v-container
    fluid>
    <v-row
      class="my-6"
      align="center"
      justify="center">
      <v-progress-circular
        class="center-progress"
        indeterminate
        size="50">
      </v-progress-circular>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'PageLoadingProgress'
}
</script>
