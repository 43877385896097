<template>
  <v-dialog
    v-model="openModal"
    max-width="100%"
    fullscreen>
    <v-card :style="`height 100vh;`">
      <v-btn
        class="close-button mr-1"
        icon
        dark
        @click="close()">
        <v-icon>close</v-icon>
      </v-btn>
      <v-card-text
        class="pa-0 ma-0"
        :style="`height: 100vh;`">
        <iframe
          :style="`width: 100%; height: 100vh; border: none;`"
          :src="iframeUrl"
          class="ma-0"></iframe>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'EditMyProfileDialog',
  computed: {
    ...mapGetters(['openEditMyProfile']),
    openModal () {
      return this.openEditMyProfile
    },
    iframeUrl () {
      return this.$environment === 'Production' ? 'https://myprofile.rexelapps.com' : 'https://testmyprofile.rexelapps.com'
    },
    isMobile () {
      return this.$vuetify.breakpoint.xsOnly
    },
    isTablet () {
      return this.$vuetify.breakpoint.smOnly
    }
  },
  methods: {
    ...mapActions(['setOpenMyProfile']),
    close () {
      this.setOpenMyProfile(false)
    }
  }
}
</script>

<style lang="scss" scoped>

// Button has to float over the iframe toolbar
.close-button {
  position: fixed;
  right: 32px;
  top: 14px;
}
</style>
