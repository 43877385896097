<template>
  <page-loading-progress v-if="loading">
  </page-loading-progress>
  <v-container
    v-else
    fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col md="4">
                <v-text-field
                  v-model="search"
                  hide-details
                  append-icon="search"
                  label="Search"
                  filled>
                </v-text-field>
              </v-col>
              <v-col md="3">
                <v-select
                  v-model="selectedAccountType"
                  :items="accountTypeOptions"
                  label="Account Type"
                  filled>
                </v-select>
              </v-col>
              <v-col class="text-right">
                <v-btn
                  color="default"
                  fab
                  small
                  :loading="exportLoading"
                  @click="exportReport()">
                  <v-icon style="height: auto;">
                    file_download
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <h4 class="text-right mr-2 pb-4">
              <em>Last 30 Days</em>
            </h4>
            <nth-order-report-table
              :loading="loading"
              :search="search"
              :orders="filteredOrderReportData"
              :first-order="firstOrder"
              :third-order="thirdOrder">
            </nth-order-report-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import PageLoadingProgress from '@/components/PageLoadingProgress.vue'
import NthOrderReportTable from './NthOrderReportTable.vue'

export default {
  name: 'CustomerNthOrderReportBase',
  components: {
    PageLoadingProgress,
    NthOrderReportTable
  },
  props: {
    firstOrderReportData: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: false,
      default: true
    },
    exportLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    firstOrder: {
      type: Boolean,
      required: false,
      default: false
    },
    thirdOrder: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      selectedAccountType: null,
      search: null,
      accountTypeOptions: [
        { text: 'All', value: 'all' },
        { text: 'Bill-To', value: 'billTo' },
        { text: 'Bill-To/Ship-To', value: 'billToShipTo' },
        { text: 'Ship-To', value: 'shipTo' },
        { text: 'Job Account', value: 'jobAccount' }
      ]
    }
  },
  computed: {
    ...mapGetters('user', ['user']),
    syncedLoading: {
      get: function () {
        return this.loading
      },
      set: function (value) {
        this.$emit('update:loading', value)
      }
    },
    filteredOrderReportData () {
      return this.firstOrderReportData.filter(item => {
        switch (this.selectedAccountType) {
          case 'billTo':
            return item.accountType === 'Bill to'
          case 'billToShipTo':
            return item.accountType === 'Bill to/Ship to'
          case 'jobAccount':
            return item.accountType === 'Job Account'
          case 'shipTo':
            return item.accountType === 'Ship to'
          default:
            return item.accountType
        }
      })
    }
  },
  methods: {
    exportReport () {
      this.$emit('export-report')
    }
  }
}
</script>
