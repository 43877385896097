import { callApi } from '@/globals/utils'

export const getActivitiesV2 = (startDate, endDate) =>
  callApi({ url: '/api/v2/activities', params: { startDate, endDate } })

export const getActivityDetailV2 = (id, recurringActivityId) =>
  callApi({ url: '/api/v2/activities/detail', params: { id, recurringActivityId } })

export const getTypesV2 = () =>
  callApi({ url: '/api/v2/activities/types' })

export const getParticipantTypesV2 = () =>
  callApi({ url: '/api/v2/activities/participant-types' })

export const getStatusTypesV2 = () =>
  callApi({ url: '/api/v2/activities/status-types' })

export const getCategoriesV2 = () =>
  callApi({ url: '/api/v2/activities/categories' })

export const getCustomersForUser = () =>
  callApi({ url: '/api/v2/activities/customer-ids' })

export const addActivityV2 = (payload) =>
  callApi({
    method: 'POST',
    url: '/api/v2/activities/add',
    payload,
    messages: {
      success: 'Successfully added activity',
      error: 'Failed to add activity'
    }
  })

export const updateActivityV2 = (payload) =>
  callApi({ method: 'POST', url: '/api/v2/activities/update', payload })

export const requestDocumentDetails = (id) => {
  return callApi({ url: `/api/v2/activities/${id}/documents` })
}

export const deleteActivityDocument = (id, fileId) =>
  callApi({
    method: 'POST',
    url: `/api/v2/activities/${id}/documents/delete`,
    params: { fileId },
    messages: {
      success: 'Successfully removed file',
      error: 'Failed to remove file'
    }
  })

export const addActivityDocument = (id, files) => {
  const formData = new FormData()
  files.forEach((file) => {
    formData.append('file', file)
  })
  const headers = {
    'content-type': 'multipart/form-data'
  }
  const success = files.length > 1
    ? `Successfully added ${files.length} files`
    : 'Successfully added a file'

  return callApi({
    method: 'POST',
    url: `/api/v2/activities/${id}/documents/upload`,
    payload: formData,
    headers,
    messages: {
      success,
      error: 'Failed to attach the document(s)'
    }
  })
}

export const getActivityOpportunities = (id) => {
  return callApi({ url: `/api/v2/activities/${id}/opportunities` })
}
