<template>
  <v-expand-transition>
    <div v-if="selected.length">
      <v-row align="center">
        <v-col class="mx-1">
          Selected {{ selected.length }} of {{ maxSelected }}
          <span class="text-capitalize">
            {{ entityTypePlural }}
          </span> to compare
        </v-col>
      </v-row>
      <v-row class="mb-4">
        <v-col
          v-for="(entity, index) in selected"
          :key="index"
          cols="12"
          sm="auto">
          <v-sheet
            color="grey lighten-1"
            class="elevation-1 fill-height">
            <v-row
              dense
              class="position-relative ma-0 pa-1 pr-4">
              <v-col
                class="hidden-sm-and-down pr-1 shrink">
                <v-avatar
                  v-if="entity.initials"
                  size="40"
                  class="primary elevation-4 customer-avatar-gradient">
                  <span class="white--text">
                    {{ entity.initials }}
                  </span>
                </v-avatar>
                <v-avatar
                  v-else
                  size="40"
                  class="mr-1">
                  <img
                    v-if="entity.imageUrl"
                    :src="entity.imageUrl"
                    :alt="`${entityType} image`" />
                  <img
                    v-else
                    src="https://rexel-cdn.com/products/$%7Bp.catNum%7D.jpg?i=0EA7A6C5-7DD2-4482-9229-D628D3AFAE9D&f=80"
                    :alt="`${entityType} image not found`" />
                </v-avatar>
              </v-col>
              <v-col>
                <div class="font-weight-bold">
                  {{ entity.name }} ({{ entity.companyId || entity.id }})
                </div>
                <div class="text-caption">
                  <span
                    v-if="entity.extraText"
                    class="grey--text text--darken-2">
                    {{ entity.extraText }}:
                  </span> {{ entity.extraValue }}
                </div>
              </v-col>
              <v-btn
                fab
                absolute
                small
                class="remove-button primary elevation-2"
                @click="removeFromComparison(entity.branchId)">
                <v-icon size="20px">
                  close
                </v-icon>
              </v-btn>
            </v-row>
          </v-sheet>
        </v-col>
        <v-col>
          <slot name="button"></slot>
        </v-col>
      </v-row>
    </div>
  </v-expand-transition>
</template>

<script>
export default {
  name: 'SelectedCards',
  props: {
    selected: {
      type: Array,
      required: true
    },
    maxSelected: {
      type: Number,
      required: false,
      default: 3
    },
    entityType: {
      type: String,
      required: true
    },
    entityTypePlural: {
      type: String,
      required: true
    },
    entityIdPropName: {
      type: String,
      required: false,
      default: 'id'
    }
  },
  methods: {
    removeFromComparison (branchId) {
      this.$emit('remove', branchId)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~styl/colors';

.position-relative {
  position: relative;
}

.remove-button.v-btn {
  top: -8px;
  right: -8px;
  height: 25px;
  width: 25px;
}
</style>
