<template>
  <v-dialog
    v-model="syncedShow"
    :fullscreen="fullScreen"
    :persistent="persistent"
    scrollable
    :max-width="maxWidth"
    :width="width"
    @keydown.esc="close"
    @click:outside="clickOutside">
    <v-card tile>
      <v-toolbar
        tile
        flat
        dark
        color="primary">
        <v-toolbar-title data-qa="modalTitle">
          <!-- title slot -->
          <slot name="title">
          </slot>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          data-qa="modalClose"
          icon
          dark
          @click="close">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text ref="content">
        <v-container
          :class="containerClass"
          fluid>
          <v-row>
            <v-col cols="12">
              <!-- content slot -->
              <slot name="content">
              </slot>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-spacer></v-spacer>
      <v-divider></v-divider>
      <v-card-actions class="shrink">
        <slot name="cardActions">
          <v-btn
            v-if="hasRemove"
            data-qa="modalDelete"
            color="error"
            @click="remove">
            Delete
          </v-btn>
          <v-col
            v-if="hasClearAll"
            class="clearAllFilters"
            data-qa="modalClearAll"
            @click="clearAll()">
            Clear All
          </v-col>
          <v-spacer></v-spacer>
          <v-btn
            data-qa="modalCancel"
            @click="close">
            {{ cancelLabel }}
          </v-btn>
          <v-btn
            v-if="hasSubmit"
            data-qa="modalSave"
            :disabled="!valid"
            :loading="submitting"
            color="primary"
            @click="submit()">
            {{ submitLabel }}
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'BaseDialog',
  props: {
    fullScreen: {
      type: Boolean,
      required: false,
      default: false
    },
    show: {
      type: Boolean,
      required: true
    },
    valid: {
      type: Boolean,
      required: false,
      default: true
    },
    cancelLabel: {
      type: String,
      required: false,
      default: 'Cancel'
    },
    submitLabel: {
      type: String,
      required: false,
      default: 'Submit'
    },
    submitting: {
      type: Boolean,
      required: false,
      default: false
    },
    hasSubmit: {
      type: Boolean,
      required: false,
      default: true
    },
    hasRemove: {
      type: Boolean,
      required: false,
      default: false
    },
    hasClearAll: {
      type: Boolean,
      required: false,
      default: false
    },
    maxWidth: {
      type: [Number, String],
      required: false,
      default: 600
    },
    persistent: {
      type: Boolean,
      required: false,
      default: true
    },
    width: {
      type: [Number, String],
      required: false,
      default: 'auto'
    },
    containerClass: {
      type: String,
      required: false,
      default: null
    },
    resetScrollTop: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
    syncedShow: {
      get: function () {
        return this.show
      },
      set: function (value) {
        this.$emit('update:show', value)
      }
    }
  },
  watch: {
    show (val) {
      if (val && this.resetScrollTop) {
        this.$nextTick(() => {
          this.$refs.content.scrollTop = 0
        })
      }
    }
  },
  methods: {
    clickOutside () {
      if (this.persistent) {
        return
      }
      this.close()
    },
    close () {
      this.$emit('close', true)
    },
    submit () {
      this.$emit('submit', true)
    },
    remove () {
      this.$emit('remove', true)
    },
    clearAll () {
      this.$emit('clearAll', true)
    }
  }
}
</script>

<style lang="scss" scoped>
.clearAllFilters {
  color: black;
  text-decoration: underline;
  cursor: pointer;
}
</style>
