const getCategoryById = (state) => (id) => {
  return state.categories.find((category) => category.activityCategoryId === id)
}
const getCategoryByLabel = (state) => (label) => {
  return state.categories.find((status) => status.activityCategory === label)
}
const getStatusByLabel = (state) => (label) => {
  return state.statuses.find((status) => status.activityStatus === label)
}
const getStatusLabelById = (state) => (id) => {
  return state.statuses.find((status) => status.activityStatusId === id)
}
const getTypeById = (state) => (id) => {
  return state.types.find((type) => type.activityTypeId === id)
}
const getDuplicatedActivity = (state) => {
  return state.duplicatedActivity
}

const getStartDate = (state) => {
  return state.startDate
}

const getEndDate = (state) => {
  return state.endDate
}

export default {
  getCategoryById,
  getCategoryByLabel,
  getStatusByLabel,
  getStatusLabelById,
  getTypeById,
  getDuplicatedActivity,
  getStartDate,
  getEndDate
}
