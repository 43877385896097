const setSelectedTabIndex = (state, selectedTabIndex) => {
  state.selectedTabIndex = selectedTabIndex
}
const setSavedDateRange = (state, savedDateRange) => {
  state.savedDateRange = savedDateRange
}
export default {
  setSelectedTabIndex,
  setSavedDateRange
}
