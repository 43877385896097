<template>
  <div>
    <div class="text-subtitle-1 font-weight-medium">
      Sales Totals by Month
    </div>
    <v-card>
      <v-card-text>
        <v-row
          align="center">
          <v-col
            cols="12"
            sm="3"
            md="3"
            lg="3"
            class="text-center">
            <chart-legend
              v-if="formattedChartData.datasets.length > 0"
              :legends="legends">
            </chart-legend>
          </v-col>
          <v-col
            class="bl-1"
            cols="12"
            sm="9"
            md="9"
            lg="9">
            <div class="pa-6">
              <line-chart
                v-if="formattedChartData.datasets.length > 0"
                :chart-data="formattedChartData"
                :chart-options="chartOptions"
                :height="chartHeight">
              </line-chart>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { formatMoney, formatDate } from '@/globals/formatters'
import { filterByLastTwelveMonths } from '@/globals/utils'
import sortBy from 'lodash/sortBy'
import LineChart from '@/components/Charts/LineChart.vue'
import ChartLegend from '@/components/Charts/ChartLegend.vue'
import EntityParams from './ListingEntityParams.mixin'

export default {
  name: 'CategoryChartCompare',
  components: {
    LineChart,
    ChartLegend
  },
  mixins: [EntityParams],
  props: {
    entityType: {
      type: String,
      required: true
    },
    entityIdentifier: {
      type: String,
      required: true
    },
    entityIds: {
      type: Array,
      required: true
    },
    colorById: {
      type: Function,
      required: true
    },
    chartData: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: { display: false }
        }
      },
      chartHeight: 300,
      dataKey: 'sales'
    }
  },
  computed: {
    legends () {
      const { summedSalesByEntity, colorById } = this
      return summedSalesByEntity.map((entity, index) => {
        const { amount, title } = entity
        const name = this.entityNames[title]
        return {
          ...entity,
          color: colorById(title),
          title: name,
          amount: formatMoney(amount)
        }
      })
    },
    latestYearOfData () {
      return filterByLastTwelveMonths(this.chartData, 'salesPeriod')
    },
    summedSalesByEntity () {
      const { dataKey, latestYearOfData, entityIdentifier } = this
      const summedData = latestYearOfData
        .reduce((list, value) => {
          const entityId = value[entityIdentifier]
          const entityIndex = list.findIndex(
            (item) => item.title === entityId
          )

          if (entityIndex !== -1) {
            const entityInList = list[entityIndex]
            const updatedValues = {
              ...entityInList,
              amount: entityInList.amount + value[dataKey],
              dataPoints: entityInList.dataPoints + 1
            }
            list[entityIndex] = updatedValues
          } else {
            list.push({
              title: entityId,
              amount: value[dataKey],
              dataPoints: 1
            })
          }
          return list
        }, [])

      const sortedData = sortBy(summedData, ['title'])
      return sortedData
    },
    formattedChartData () {
      const { dataKey, latestYearOfData, colorById, entityIdentifier } = this

      // create object keyed by entityId
      const dataObj = latestYearOfData
        .reduce((items, item) => {
          const entityId = item[entityIdentifier]
          if (items[entityId] === undefined) {
            items[entityId] = {
              entityId,
              data: []
            }
          }
          items[entityId].data.push(item[dataKey])
          return items
        }, {})
      const dataMappedByEntity = Object.keys(dataObj)
        .map((key) => dataObj[key])

      const sortedData = sortBy(dataMappedByEntity, ['entityId'])
      const datasets = sortedData.map((item) => {
        const color = colorById(item.entityId)
        return {
          label: item.entityId,
          borderColor: color,
          data: item.data,
          pointBorderColor: color,
          pointBackgroundColor: 'white',
          pointBorderWidth: 2,
          pointRadius: 3,
          borderWidth: 2
        }
      })

      let labels = latestYearOfData
        .map((item) => formatDate(item.salesPeriod, 'MMM'))
      labels = [...new Set(labels)]

      return { labels, datasets }
    }
  },
  mounted () {
    this.getNames()
  }
}
</script>

<style lang="scss" scoped>
</style>
