import { AppInsights } from 'applicationinsights-js'

function addIdentityClaimToObject (store, obj, claimName) {
  if (store.getters && store.getters['user/user'] && Object.prototype.hasOwnProperty.call(store.getters['user/user'], claimName)) {
    obj[claimName] = store.getters['user/user'][claimName]
  }
}

/**
 * Install function passed to Vue.use() show documentation in vue.js website.
 *
 * @param Vue
 * @param options
 */
function install (Vue, options) {
  const { id } = options

  // if we have a key
  if (id) {
    Vue.appInsights = AppInsights
    Vue.appInsights.downloadAndSetup({ instrumentationKey: id })

    const { router, store } = options
    const routePrefix = options.roleName ? options.roleName + '.' : ''

    router.beforeEach((to, from, next) => {
      if (store.getters['user/user'] && store.getters['user/user'].email) {
        Vue.appInsights.setAuthenticatedUserContext(store.getters['user/user'].email)
      }
      Vue.appInsights.startTrackPage(routePrefix + to.name)
      next()
    })
    router.afterEach(to => {
      const props = {}
      addIdentityClaimToObject(store, props, 'email')
      addIdentityClaimToObject(store, props, 'sso')
      addIdentityClaimToObject(store, props, 'branch_id')
      addIdentityClaimToObject(store, props, 'banner')
      addIdentityClaimToObject(store, props, 'imp_email')
      Vue.appInsights.stopTrackPage(routePrefix + to.name, window.location.href.substr(0, Math.min(2048, window.location.href.length)), props)
    })
    Vue.appInsights.config.enableCorsCorrelation = true
    if (options.roleName) {
      Vue.appInsights.queue.push(function () {
        Vue.appInsights.context.addTelemetryInitializer(function (envelope) {
          envelope.tags['ai.cloud.role'] = options.roleName
        })
      })
    }

    Object.defineProperty(Vue.prototype, '$appInsights', {
      get: function get () {
        return Vue.appInsights
      }
    })
  }
}

// auto install for navigator
if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(install)
}

export default install
