<template>
  <v-system-bar
    app
    window
    dark
    color="error">
    <v-spacer></v-spacer>
    <v-icon>warning</v-icon>
    <span class="white--text">
      Training Mode
    </span>
    <span v-if="$vuetify.breakpoint.mdAndUp">
      : Data is overwritten weekly. Changes made here will not be saved.
    </span>
    <v-spacer></v-spacer>
  </v-system-bar>
</template>

<script>
export default {
  name: 'DemoHeader',
  components: {},
  props: {},
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {

  }
}
</script>

<style lang="scss" scoped>

</style>
